import React from "react";
import ReactDOM from "react-dom";
import "./Bootstrap/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Login from "./Pages/Login";
import ProjectSelection from "./Pages/ProjectSelection";
import Trialbalance from "./Pages/printpreviews";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createStore, applyMiddleware, compose } from "redux";
import AllReducers from "./Pages/reducers/allreducers";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(AllReducers, enhancer);

 window.$url = "https://posdemo.aiksol.com/";
//window.$url = "https://localhost:44369/";
if (module.hot && process.env.NODE_ENV !== "production") {
  module.hot.accept();
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          {/* <Route exact path="/ProjectSelection" component={ProjectSelection} /> */}
          <Route exact path="/Dashboard" component={App} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
