import React, { useState } from "react";
import "../../../Bootstrap/haroon.css";
import { SaleContractForm } from "./SaleContractForm";
import { SaleContractTable } from "./SaleContractTable";

export const SaleContractMain = () => {
  const changecomponent = () => {
    setComponentState(<SaleContractForm formCancel={formCancel} />);
  };

  const formCancel = () => {
    setComponentState(<SaleContractTable changecomponent={changecomponent} />);
  };

  const [componentState, setComponentState] = useState(
    <SaleContractTable changecomponent={changecomponent} />
  );

  return <>{componentState}</>;
};
