import React, { useState, useRef } from "react";
import axios from "axios";
import "../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";
import { toast } from "react-toastify";

import CashBookPrint from "../Prints/CashBookPrint";

const BankBook = () => {
  const [cashaccounts, setcashaccounts] = useState([]);
  const [credit, setCreditTotal] = useState();
  const [debit, setdebitTotal] = useState();
  const [Total, setTotal] = useState();
  const [loader1, setloader1] = useState(false);

  const apicashbook = () => {
    setdebitTotal();
    setCreditTotal();
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/BankBook?From=${
        document.getElementById("cashfrom").value
      }&To=${document.getElementById("cashto").value}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("bankbook", result);
        if (result.data.success) {
          setcashaccounts(result.data.banks);
          setTotal(result.data.total.toLocaleString());
          setdebitTotal(result.data.debit.toLocaleString());
          setCreditTotal(result.data.credit.toLocaleString());
          setloader1(false);
        } else {
          toast.info(result.data.message);
          setloader1(false);
        }
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //   useEffect(() => {
  //     apicashbook();
  //   }, []);
  return (
    <>
      <div hidden>
        <CashBookPrint
          ref={componentRef}
          allaccounts={cashaccounts}
          debitTotal={debit}
          creditTotal={credit}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="col-12 pt-1">
            <div className="row mt-1">
              <div className="col-3 text-left">
                <b>Debit= {debit}</b>
              </div>
              <div className="col-3 text-center">
                <b>Credit= {credit}</b>
              </div>
              <div className="col-3 text-right">
                <b>Balance= {Total}</b>
              </div>
              <div className="col-3 text-right">
                {/* <span className="ml-2 float-right">
                  <button
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span> */}
                <span className="loaderclass float-right">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-4 text-right">
                From:
                <span>
                  {" "}
                  <input
                    type="date"
                    className="datefield"
                    id="cashfrom"
                    defaultValue={moment().format("YYYY-07-01")}
                  />
                </span>
              </div>
              <div className="col-4 text-right">
                To:
                <span>
                  {" "}
                  <input
                    type="date"
                    className="datefield"
                    id="cashto"
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </span>
              </div>
              <div className="col-4 text-right pr-5">
                <span>
                  <button
                    id="callapibutn"
                    onClick={apicashbook}
                    className="tabletopbutton   text-right ml-2 "
                  >
                    GO
                  </button>
                  <button
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span>
              </div>
            </div>

            <hr className="mt-2"></hr>
            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table table-hover">
                  <thead>
                    <tr className="">
                      <th>Date</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Voucher No</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashaccounts.map((x) => (
                      <tr>
                        <th>
                          {
                            moment(x.transdate)
                              .format("DD MMM, YY")
                              .split("T")[0]
                          }
                        </th>
                        <th>{x?.acc?.accounttitle}</th>
                        <th>{x?.narration}</th>
                        {x?.referenceno == null || x?.referencetype == null ? (
                          <th>{x?.vreferenceno}</th>
                        ) : (
                          <th>
                            {x?.referencetype.concat("-", x?.referenceno)}
                          </th>
                        )}
                        {x.transtype === "Debit" ? (
                          <th>{x?.amount.toLocaleString()}</th>
                        ) : (
                          <th>0</th>
                        )}
                        {x.transtype === "Credit" ? (
                          <th>{x?.amount.toLocaleString()}</th>
                        ) : (
                          <th>0</th>
                        )}
                        <th>{x?.balance.toLocaleString()}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-md-4  ">
                Total Debit:
                <input
                  type="text"
                  id="debittotal"
                  value={debit}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
              <div className="col-md-4   ">
                Total Credit:
                <input
                  type="text"
                  id="credittotal"
                  value={credit}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
              <div className="col-md-4   ">
                Total Balance:
                <input
                  type="text"
                  id="credittotal"
                  value={Total}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankBook;
