import React, { useState, useEffect, useRef } from "react";
import "../../../Bootstrap/haroon.css";
import axios from "axios";
import ReactDOM from "react-dom";
import { IoMdAddCircle } from "react-icons/io";
import moment from "moment";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import Delete from "../../../Images/delete.png";
import SaleInvoicePrint from "../../Prints/SaleInvoicePrint";

export const SaleInvoiceForm = (props) => {
  const [loader1, setloader1] = useState(false);
  const [tablerow, settablerow] = useState([]);
  const [sellersdata, setsellersdata] = useState([]);
  const [brandsdata, setbrandsdata] = useState([]);
  const [yarndata, setyarndata] = useState([]);
  const [purchasecontracts, setpurchasecontracts] = useState([]);
  const [allaccounts, setallaccounts] = useState([]);
  const [purchasecontractdetails, setpurchasecontractdetails] = useState([]);
  const [printdata, setprintdata] = useState();
  const [SInvNo, setSInvNo] = useState("");
  const [saletax, setsaletax] = useState(0);
  const [SellerName, setSellerName] = useState("");
  const [taxavailable, settaxavailable] = useState("with tax");
  const [taxavailablebool, settaxavailablebool] = useState(true);
  const [showtaxoptions, setshowtaxoptions] = useState(true);
  const [saleContractCheck, setsaleContractCheck] = useState(false);
  const [SellerId, setSellerId] = useState("");
  const [SaleContractId, setSaleContractId] = useState(null);
  const handleaddrow = () => {
    const item = {
      itemname: "",
      qty: "",
      rate: "",
      brand: "",
      salestax: "",
      amount: "",
      // avgweight: "",
      netamount: "",
      brandid: "",
      yarnid: "",
    };

    settablerow([...tablerow, item]);
  };

  useEffect(() => {
    if (taxavailable === "with tax") {
      settaxavailablebool(true);
    } else if (taxavailable === "without tax") {
      settaxavailablebool(false);
    }
  }, [taxavailable]);

  useEffect(() => {
    if (tablerow.length === 0) {
      setshowtaxoptions(true);
      settaxavailable("with tax");
    } else {
      setshowtaxoptions(false);
    }
  }, [tablerow]);

  useEffect(() => {
    if (purchasecontractdetails.length === 0) {
      tablerow.forEach((x, idx) => {
        document.getElementById(`itemname${idx}`).disabled = false;
        document.getElementById(`brandname${idx}`).disabled = false;
        document.getElementById(`rate${idx}`).disabled = false;
      });
    } else {
      tablerow.forEach((x, idx) => {
        document.getElementById(`itemname${idx}`).disabled = true;
        document.getElementById(`brandname${idx}`).disabled = true;
        document.getElementById(`rate${idx}`).disabled = true;
      });
    }

    let val = 0;
    tablerow.forEach((x) => {
      if (x.netamount !== "") {
        val += parseFloat(x.netamount);
      }
    });
    document.getElementById("totalamount").value = val;
  }, [tablerow]);

  useEffect(() => {
    if (saleContractCheck === true) {
      setshowtaxoptions(false);
      settaxavailable("with tax");
      settaxavailablebool(true);
      settablerow([]);
      if (document.getElementById("wtax")) {
        document.getElementById("wtax").checked = true;
      }
    } else {
      setpurchasecontractdetails([]);
    }
  }, [saleContractCheck]);
  console.log(purchasecontractdetails);

  // console.log(saleContractCheck ,taxavailable , taxavailablebool )
  const handledeleterow = (idx) => {
    const rows = [...tablerow];
    rows.splice(idx, 1);
    settablerow(rows);
  };

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  const purchaseinvoicepopulate = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Sale/PopulateSaleInvoice`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        if (result.data.IsSuccess) {
          setloader1(false);
          console.log(result);
          setsellersdata(result.data.Client);
          setbrandsdata(result.data.Brand);
          // setyarndata(result.data.Product);
          setsaletax(result?.data?.Tax[0]?.TaxAmount);
          setallaccounts(result.data.AllAccounts);
          setSInvNo(result.data.SaleInvoiceNumber);
        }
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  //Customers autosuggestion code
  var suggestionlist = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("demo"));
    } else {
      console.log(value);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = sellersdata
          .sort()
          .filter((v) => regex.test(v.ClientName));
      }

      suggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("demo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li className="listcss" onClick={() => suggestionselected(item)}>
                {item === undefined ? item : item.ClientName}
              </li>
            ))}
          </ul>,
          document.getElementById("demo")
        );
      }
    }
  };

  const OnSellerChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("sellerList").childNodes;
    let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        find = true;
        let item = sellersdata?.find(
          (item, idx) =>
            item?.Accid === parseInt(options[i].attributes.attributeId.value)
        );
        setSellerName(item.Accounttitle);
        setpurchasecontracts(item.SaleContracts);
        setSellerId(item.Accid);
        document.getElementById("sellerid").value = item.Accid;
        document.getElementById("address").value = "";

        break;
      }
    }
    if (!find) {
      setSellerName("");
      setpurchasecontracts([]);
      setSellerId("");
      document.getElementById("sellerid").value = "";
      document.getElementById("address").value = "";
    }
  };
  const suggestionselected = (val) => {
    console.log(val);
    document.getElementById("sellername").value = val.ClientName;
    document.getElementById("sellerid").value = val.ClientId;
    document.getElementById("address").value = val.ClientAddress;
    setpurchasecontracts(val.SaleContracts);
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById("demo"));
  };

  //Purchase contracts autosuggestion code
  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("demo1"));
    } else {
      console.log(value);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = purchasecontracts
          .sort()
          .filter((v) => regex.test(v.SaleContractId));
      }

      suggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("demo1"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li className="listcss" onClick={() => suggestionselected1(item)}>
                {item === undefined ? item : item.SaleContractId}
              </li>
            ))}
          </ul>,
          document.getElementById("demo1")
        );
      }
    }
  };
  const suggestionselected1 = (val) => {
    console.log("val", val);
    setSaleContractId(val.SaleContractId);
    // document.getElementById("purchasecontract").value = val.SaleContractId;
    setpurchasecontractdetails(val.SaleContractDetails);

    suggestionlist = [];
    ReactDOM.render(null, document.getElementById("demo1"));
  };

  //Yarn type autosuggestion code
  var suggestionlist = [];
  const onTextChange2 = (e, idx) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById(`yarn${idx}`));
    } else {
      console.log(value);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = yarndata.sort().filter((v) => regex.test(v.ProductType));
      }

      suggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById(`yarn${idx}`));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => suggestionselected2(item, idx)}
              >
                {item === undefined
                  ? item
                  : `${item.ProductType} - (${item.Composition})`}
              </li>
            ))}
          </ul>,
          document.getElementById(`yarn${idx}`)
        );
      }
    }
  };
  const suggestionselected2 = (val, idx) => {
    console.log(val);
    document.getElementById(
      `itemname${idx}`
    ).value = `${val.ProductType} - (${val.Composition})`;
    tablerow[idx].itemname = `${val.ProductType} - (${val.Composition})`;
    tablerow[idx].yarnid = val.ProductId;
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById(`yarn${idx}`));
  };

  //Brand name  autosuggestion code
  var suggestionlist = [];
  const onTextChange3 = (e, idx) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById(`brand${idx}`));
    } else {
      console.log(value);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = brandsdata.sort().filter((v) => regex.test(v.BrandName));
      }

      suggestionlist = suggestions;
      // suggestionlist.push("Add New");
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById(`brand${idx}`));
      } else {
        ReactDOM.render(
          <ul className="suggestbox">
            {suggestionlist.map((item) => (
              <li
                className="listcss"
                onClick={() => suggestionselected3(item, idx)}
              >
                {item === undefined ? item : item.BrandName}
              </li>
            ))}
          </ul>,
          document.getElementById(`brand${idx}`)
        );
      }
    }
  };
  const suggestionselected3 = (val, idx) => {
    console.log(val);
    document.getElementById(`brandname${idx}`).value = val.BrandName;
    tablerow[idx].brand = val.BrandName;
    tablerow[idx].brandid = val.BrandId;
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById(`brand${idx}`));
  };

  useEffect(() => {
    purchaseinvoicepopulate();
  }, []);

  useEffect(() => {
    if (purchasecontractdetails.length !== 0) {
      let array = [],
        totalamount = 0;

      purchasecontractdetails.forEach((x, idx) => {
        console.log("purchasecontractdetails", x);
        array.push({
          SaleContractDetailId: x.SaleContractDetailId,
          itemname: x.Product.Name,
          qty: x.RemainingQty,
          remqty: x.RemainingQty,
          rate: x.Rate,
          brand: x.Brand.BrandName,
          taxPercentage: x.TaxPercentage,
          taxAmount: x.TaxAmount,
          amount: x.RemainingQty * x.Rate,
          // avgweight: "",
          netamount: x.RemainingQty * x.Rate + x.TaxAmount,
          brandid: x.Brand.BrandId,
          yarnid: x.Product.ProductId,
        });

        totalamount += parseFloat(x.netamount);
      });

      settablerow(array);
      console.log(array);
      document.getElementById("totalamount").value = totalamount;

      // document.getElementById("addbutton").style.display = "none";
    }
  }, [purchasecontractdetails]);

  const addpurchaseinvoicenew = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        SaleContractDetailId: x.SaleContractDetailId,
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: x.rate,
        NetAmount: parseInt(x.netamount),
        // AvgWeight: parseInt(x.avgweight),
      });
    });

    let data = {
      SaleContractId: SaleContractId,
      // document.getElementById("purchasecontract").value === ""
      // ? null
      // :
      //  document.getElementById("purchasecontract").value,
      Narration: document.getElementById("remarks").value,
      PartyInvoiceNo: document.getElementById("partyInvoiceNo").value,
      AccountId: Number(document.getElementById("sellerid").value),
      Date: document.getElementById("date").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      DueDate: moment()
        .add(
          parseInt(
            document.getElementById("duedate").options[
              document.getElementById("duedate").selectedIndex
            ].dataset.val
          ),
          "days"
        )
        .format("YYYY-MM-DD"),
      SaleInvoiceDetails: array,
    };
    console.log(data);
    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (saleContractCheck === true && SaleContractId === null) {
      toast.info("Please Select Contract No");
      return;
    }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;

    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Sale/AddSaleInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          document.getElementById("sellerid").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("partyInvoiceNo").value = "";
          // document.getElementById("purchasecontract").value = "";
          setpurchasecontracts([]);
          setSaleContractId("");
          settaxavailable("with tax");
          setpurchasecontractdetails([]);
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchaseinvoiceclose = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        SaleContractDetailId: x.SaleContractDetailId,
        ProductId: x.yarnid,
        ProductQty: parseInt(x.qty),
        Amount: parseInt(x.amount),
        BrandId: parseInt(x.brandid),
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: parseInt(x.rate),
        // AvgWeight: x.avgweight,
        NetAmount: parseInt(x.netamount),
      });
    });

    let data = {
      SaleContractId: SaleContractId,
      // document.getElementById("purchasecontract").value === ""
      //   ? null
      //   : document.getElementById("purchasecontract").value,
      PartyInvoiceNo: document.getElementById("partyInvoiceNo").value,
      Narration: document.getElementById("remarks").value,
      AccountId: document.getElementById("sellerid").value,
      Date: document.getElementById("date").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      DueDate: moment()
        .add(
          parseInt(
            document.getElementById("duedate").options[
              document.getElementById("duedate").selectedIndex
            ].dataset.val
          ),
          "days"
        )
        .format("YYYY-MM-DD"),
      SaleInvoiceDetails: array,
    };
    console.log(data);

    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (saleContractCheck === true && SaleContractId === null) {
      toast.info("Please Select Contract No");
      return;
    }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;

    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Sale/AddSaleInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          document.getElementById("sellerid").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("partyInvoiceNo").value = "";
          // document.getElementById("purchasecontract").value = "";
          setpurchasecontracts([]);
          settaxavailable("with tax");
          setSaleContractId("");
          setpurchasecontractdetails([]);

          props.formCancel();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchaseinvoiceprint = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        SaleContractDetailId: x.SaleContractDetailId,
        ProductId: x.yarnid,
        ProductQty: parseInt(x.qty),
        Amount: parseInt(x.amount),
        BrandId: parseInt(x.brandid),
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: parseInt(x.rate),
        // AvgWeight: x.avgweight,
        NetAmount: parseInt(x.netamount),
      });
    });

    let data = {
      SaleContractId: SaleContractId,
      // document.getElementById("purchasecontract").value === ""
      //   ? null
      //   : document.getElementById("purchasecontract").value,
      // Discount: document.getElementById("discountamount").value,
      // Freight: document.getElementById("freightamount").value,
      // Others: document.getElementById("othersamount").value,
      // NetTotalAmount: document.getElementById("nettotalamount").value,
      PartyInvoiceNo: document.getElementById("partyInvoiceNo").value,
      SaleInvoiceNumber: SInvNo,
      Narration: document.getElementById("remarks").value,
      AccountId: Number(document.getElementById("sellerid").value),
      Date: document.getElementById("date").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      DueDate: moment()
        .add(
          parseInt(
            document.getElementById("duedate").options[
              document.getElementById("duedate").selectedIndex
            ].dataset.val
          ),
          "days"
        )
        .format("YYYY-MM-DD"),
      SaleInvoiceDetails: array,
    };

    console.log(data);
    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (saleContractCheck === true && SaleContractId === null) {
      toast.info("Please Select Contract No");
      return;
    }

    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;

    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Sale/AddSaleInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          document.getElementById("sellerid").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("partyInvoiceNo").value = "";
          // document.getElementById("purchasecontract").value = "";
          setpurchasecontracts([]);
          setpurchasecontractdetails([]);
          settaxavailable("with tax");
          setSaleContractId("");
          setprintdata(result.data.SaleInvoice);
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  //TABLE FUNCTIONS
  const OnItemChange = (e, idx) => {
    let value = e.target.value;
    var options = document.getElementById(`yarn${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = yarndata?.find(
          (x) => x.Name === options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(`itemname${idx}`).value = item.Name;
        tablerow[idx].itemname = item.Name;
        tablerow[idx].yarnid = item.ProductId;
        break;
      }
      // document.getElementById(`itemname${idx}`).value = "";
      tablerow[idx].itemname = "";
      tablerow[idx].yarnid = "";
    }
  };
  //Brand name  autosuggestion code
  const OnBrandChange = (e, idx) => {
    let value = document.getElementById(`brandname${idx}`).value;
    var options = document.getElementById(`brand${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = brandsdata?.find(
          (x) => x.BrandName === options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(`brandname${idx}`).value = item.BrandName;
        tablerow[idx].brand = item.BrandName;
        tablerow[idx].brandid = item.BrandId;
        setyarndata(item.Products);

        break;
      }
      // document.getElementById(`brandname${idx}`).value = e.target.value;
      tablerow[idx].brand = "";
      tablerow[idx].brandid = "";
    }
  };

  // const avgweightchange = (e, idx) => {
  //   tablerow[idx].avgweight = e.target.value;
  // };

  const quantitychange = (e, idx) => {
    //   let newTableRow = JSON.parse(JSON.stringify(tablerow))
    tablerow[idx].qty = e.target.value;
    //TOTAL VALUE CALCULATION
    if (tablerow[idx].rate === "" || tablerow[idx].qty === "") {
      console.log(tablerow[idx].rate);
      document.getElementById(`netamount${idx}`).value = 0;
      document.getElementById("totalamount").value = 0;
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = 0;
        document.getElementById(`taxAmount${idx}`).value = 0;
        document.getElementById("totalamount").value = 0;
      }
    } else {
      let val1 = parseFloat(tablerow[idx].qty) * parseFloat(tablerow[idx].rate);
      let val2 = (parseFloat(saletax) / 100) * val1;

      document.getElementById(`netamount${idx}`).value = (val1 + val2).toFixed(
        2
      );
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`taxAmount${idx}`).value = val2.toFixed(2);
        tablerow[idx].taxAmount = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }
      tablerow[idx].netamount = (val1 + val2).toFixed(2);

      let val = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += parseFloat(x.netamount);
        }
      });
      // settotalamount(val)
      document.getElementById("totalamount").value = val.toFixed(2);
    }
    //   settablerow(newTableRow)
  };
  const ratechange = (e, idx) => {
    tablerow[idx].rate = e.target.value;

    if (tablerow[idx].rate === "" || tablerow[idx].qty === "") {
      console.log(tablerow[idx].rate);
      document.getElementById(`netamount${idx}`).value = 0;
      document.getElementById("totalamount").value = 0;
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = 0;
        document.getElementById(`taxAmount${idx}`).value = 0;
        document.getElementById("totalamount").value = 0;
      }
    } else {
      //TOTAL VALUE CALCULATION
      let val1 = parseFloat(tablerow[idx].qty) * parseFloat(tablerow[idx].rate);
      let val2 = (parseFloat(saletax) / 100) * val1;

      document.getElementById(`netamount${idx}`).value = (val1 + val2).toFixed(
        2
      );

      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`taxAmount${idx}`).value = val2.toFixed(2);
        tablerow[idx].taxAmount = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }
      tablerow[idx].netamount = (val1 + val2).toFixed(2);
      let val = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += parseFloat(x.netamount);
        }
      });
      document.getElementById("totalamount").value = val.toFixed(2);
      // settotalamount(val)
    }
  };

  const netamountchange = (e, idx) => {
    if (taxavailablebool) {
      tablerow[idx].netamount =
        Number(tablerow[idx].taxAmount) + Number(tablerow[idx].amount);
    } else {
      tablerow[idx].netamount = Number(tablerow[idx].amount);
    }
  };

  console.log(purchasecontracts);

  useEffect(() => {
    if (printdata !== undefined) {
      handlePrint1();
    }
  }, [printdata]);
  return (
    <>
      <div hidden>
        <SaleInvoicePrint ref={componentRef1} printdata={printdata} />
      </div>
      <div
        className=" tableContainer2 overflow-auto pageheading Sitka mt-3"
        style={{ height: "95%" }}
      >
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row ">
            <div className="col-2 mt-3 text-right">
              <span className="txt">Contract</span>
            </div>

            <div className="col-4 mt-3 pt-1 text-left">
              <span>
                {" "}
                <input
                  type="checkbox"
                  id="saleContractCheck"
                  onChange={(e) => {
                    setsaleContractCheck(e.target.checked);
                    console.log(e.target.checked);
                    settablerow([]);
                  }}
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3  text-right">
              <span className="txt">S.Inv No</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  disabled
                  type="text"
                  value={SInvNo ? `S.Inv-${SInvNo}` : ""}
                  className="input_border formInput"
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt"> Customer</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <div className="row">
                <div className="col-4 pr-0">
                  <input
                    placeholder="ID"
                    disabled
                    type="number"
                    id="sellerid"
                    className="input_border formInput "
                  ></input>
                </div>
                <div className="col-8 pl-0 pr-5">
                  <span>
                    {" "}
                    <input
                      placeholder="Enter Name"
                      type="text"
                      id="sellername"
                      list="sellerList"
                      onChange={OnSellerChange}
                      // value={SellerName}
                      // name="SellerName"
                      className="input_border formInput "
                      autoComplete="off"
                    ></input>
                    <datalist id="sellerList">
                      {allaccounts?.map((v, idx) => {
                        // console.log(v);
                        return (
                          <option
                            attributeId={v?.Accid}
                            value={v?.Accounttitle}
                          >
                            {v?.Accounttitle +
                              "(" +
                              v?.HeadingNavigation?.AccountTitle +
                              ")"}
                          </option>
                        );
                      })}
                    </datalist>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-2 mt-3  text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4 mt-3  text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="date"
                  className="input_border formInput"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></input>
              </span>
            </div>
            <div hidden className="col-2 mt-3 text-right">
              <span className="txt">Address</span>
            </div>
            <div hidden className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  disabled
                  type="text"
                  id="address"
                  className="input_border formInput "
                ></input>
                <input type="hidden" id="sumvalue" />
              </span>
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Party Inv No.</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  id="partyInvoiceNo"
                  className="input_border formInput "
                ></input>
              </span>
            </div>

            {saleContractCheck ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Contract No.</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <select
                      className="input_border formInput "
                      id="purchasecontract"
                      onChange={(e) => {
                        console.log(JSON.parse(e.target.value));
                        setSaleContractId(e.target.value);
                        setpurchasecontractdetails(
                          e.target.options[e.target.selectedIndex].dataset.idx
                            ? JSON.parse(
                                e.target.options[e.target.selectedIndex].dataset
                                  .idx
                              )
                            : null
                        );
                      }}
                    >
                      <option>Select</option>
                      {purchasecontracts.map((x) => (
                        <option
                          value={x.SaleContractId}
                          // data-idx={JSON.stringify(x.SaleContracts)}

                          data-idx={JSON.stringify(x.SaleContractDetails)}
                        >
                          SCont-{x.SaleContSerialNo}
                        </option>
                      ))}
                    </select>
                    {/* <input
                    type="text"
                    id="purchasecontract"
                    onChange={onTextChange1}
                    autoComplete="off"
                    className="input_border formInput "
                  ></input>
                  <span id="demo1"></span> */}
                  </span>
                </div>
              </>
            ) : (
              <div className="col-6 mt-3 text-left"></div>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Remarks</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <textarea
                  style={{ height: "40px" }}
                  id="remarks"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>

            <div className="col-2 mt-3 text-right"></div>
            <div className="col-4 mt-3">
              {showtaxoptions ? (
                <div className="row">
                  <div className="col-6">
                    <input
                      onClick={(e) => {
                        settaxavailable(e.target.value);
                        setsaletax(17);
                      }}
                      type="radio"
                      id="wtax"
                      name="taxradio"
                      value="with tax"
                      defaultChecked
                    />{" "}
                    <label for="wtax">With Tax</label>
                  </div>
                  <div className="col-6">
                    <input
                      onClick={(e) => {
                        settaxavailable(e.target.value);
                        setsaletax(0);
                      }}
                      type="radio"
                      id="wotax"
                      name="taxradio"
                      value="without tax"
                    />{" "}
                    <label for="wotax">Without Tax</label>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="col-12  mt-3 ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th>Sr.</th>
                  <th>Item Type</th>
                  <th>Item</th>
                  <th>No. of Bags</th>
                  {/* <th>Avg. Weight(KGs)</th> */}
                  <th>Rate</th>
                  {taxavailablebool ? (
                    <>
                      <th>Amount</th>
                      <th>Sales Tax</th>
                    </>
                  ) : (
                    <></>
                  )}

                  <th>Net Amount</th>
                  {purchasecontractdetails.length === 0 ? (
                    <th onClick={handleaddrow}>
                      <IoMdAddCircle
                        id="addbutton"
                        className="drow"
                        size="2em"
                        color="black"
                      />
                    </th>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                {tablerow.map((x, idx) => (
                  <tr key={idx}>
                    <th>{idx + 1}</th>

                    <th>
                      <input
                        key={`${x.brand}${idx}`}
                        id={`brandname${idx}`}
                        onChange={(e) => {
                          OnBrandChange(e, idx);
                          console.log(tablerow);
                        }}
                        defaultValue={x.brand}
                        className="form-control"
                        type="text"
                        list={`brand${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`brand${idx}`}>
                        {brandsdata?.map((v, idx) => {
                          return (
                            <option attributeId={v?.BrandName}>
                              {v.BrandName}
                            </option>
                          );
                        })}
                      </datalist>
                    </th>
                    <th>
                      <input
                        key={`${x.itemname}${idx}`}
                        id={`itemname${idx}`}
                        onChange={(e) => {
                          OnItemChange(e, idx);
                        }}
                        defaultValue={x.itemname}
                        className="form-control"
                        type="text"
                        list={`yarn${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`yarn${idx}`}>
                        {yarndata?.map((v, idx) => {
                          return (
                            <option attributeId={v?.Name}>{v?.Name}</option>
                          );
                        })}
                      </datalist>
                    </th>
                    <th>
                      <input
                        onChange={(e) => {
                          quantitychange(e, idx);
                        }}
                        id={`qty${idx}`}
                        key={`${x.qty}${idx}`}
                        defaultValue={x.qty}
                        className="form-control"
                      ></input>
                    </th>
                    {/* <th>
                      <input
                        onChange={(e) => {
                          avgweightchange(e, idx);
                        }}
                        id={`avgweight${idx}`}
                        key={`${x.avgweight}${idx}`}
                        defaultValue={x.avgweight}
                        className="form-control"
                      ></input>
                    </th> */}
                    <th>
                      <input
                        onChange={(e) => {
                          ratechange(e, idx);
                        }}
                        id={`rate${idx}`}
                        key={`${x.rate}${idx}`}
                        defaultValue={x.rate}
                        className="form-control"
                      ></input>
                    </th>

                    {taxavailablebool ? (
                      <>
                        <th>
                          <input
                            disabled
                            id={`total${idx}`}
                            key={`${x.amount}${idx}`}
                            defaultValue={x.amount}
                            className="form-control"
                          ></input>
                        </th>
                        <th>
                          <input
                            disabled
                            id={`taxAmount${idx}`}
                            key={`${x.taxAmount}${idx}`}
                            defaultValue={x.taxAmount}
                            className="form-control"
                          ></input>
                        </th>
                      </>
                    ) : (
                      <></>
                    )}

                    <th>
                      <input
                        disabled
                        onChange={(e) => {
                          netamountchange(e, idx);
                        }}
                        id={`netamount${idx}`}
                        key={`${x.netamount}${idx}`}
                        value={x.netamount}
                        className="form-control"
                      ></input>
                    </th>
                    <th>
                      <span onClick={() => handledeleterow(idx)}>
                        <img
                          alt="AddRow"
                          style={{
                            cursor: "pointer",
                            width: "19px",
                            marginTop: "8px",
                          }}
                          src={Delete}
                        ></img>
                      </span>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1 text-right">Total:</div>
            <div className="col-3 mt-1">
              <input
                id="totalamount"
                className="input_border formInput"
                disabled
              ></input>
            </div>

            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1 text-right">Due Date:</div>
            <div className="col-3 mt-1">
              <select id="duedate" className="input_border formInput">
                <option data-val={"30"}>30 Days</option>
                <option data-val={"60"}>60 Days</option>
                <option data-val={"90"}>90 Days</option>
                <option data-val={"120"}>120 Days</option>
                <option data-val={"150"}>150 Days</option>
                <option data-val={"180"}>180 Days</option>
              </select>
            </div>
          </div>

          <div className="col-12 mt-5  text-right">
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoicenew}
                className="tabletopbutton  text-right greenbutton"
              >
                {"Save & New"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoiceprint}
                className="tabletopbutton ml-2 text-right bluebutton"
              >
                {"Save & Print"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoiceclose}
                className="tabletopbutton ml-2 text-right redbutton"
              >
                {"Save & Close"}
              </button>
            </span>
            <span>
              <button
                id="crvCancel"
                className="tabletopbutton ml-2 text-right "
                onClick={props.formCancel}
              >
                Cancel
              </button>
            </span>
            <span className="ml-2 float-right">
              <div className="loaderclass">
                {" "}
                {loader1 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
