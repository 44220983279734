import React, { lazy, Suspense, useState } from "react";
import LazyLoader from "../../LazyLoader/LazyLoader";
const ChartOfAccountForm = lazy(()=> import ("./ChartOfAccountForm"))
const ChartOfAccountTable = lazy(()=> import ("./ChartOfAccountTable"))


const ChartOfAccountMain = () => {
  const [showForm, setshowForm] = useState(false);

  return (
    <Suspense fallback={<LazyLoader/>}>
      {showForm ? (
        <ChartOfAccountForm setshowForm={setshowForm} showForm={showForm} />
      ) : (
        <ChartOfAccountTable setshowForm={setshowForm} showForm={showForm} />
      )}
    </Suspense>
  );
};

export default ChartOfAccountMain;
