import axios from 'axios';

export const GetAllBankAccountsData = (data) =>{
    return ({
        type:'getallbankaccountsdata',
        payload:data,
        loader:false
    })
}

export const getallbankaccounts =() =>{
    return(dispatch) =>{
        axios({
            url:`${window.$url}api/Accounts/GetBankAccounts`,
            method:"get",
            headers:{
                Authorization: `Bearer ${localStorage.getItem("Token")}`,
                "content-type":"application/json"
            }
        }).then(
            (result) => {
                console.log(result)
                if(result.data.IsSuccess){
                    dispatch(GetAllBankAccountsData(result.data.Bank))
                }
                
            },
            (error) =>{
                console.log(error);
            }
        )
    }
}