import React, { useState, useEffect } from "react";
import "./Bootstrap/App.css";
import "./Bootstrap/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import menu from "./Images/menu.svg";
import { BsBell, BsFillPersonLinesFill } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney, GiPapers, GiBookshelf } from "react-icons/gi";
import { BsNewspaper } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { FiTruck } from "react-icons/fi";
import { AiOutlineBarChart, AiFillSetting } from "react-icons/ai";
import { MdAddShoppingCart, MdDashboard, MdStore } from "react-icons/md";
import Accounts from "./Pages/Accounts/Accounts";
import "./Bootstrap/haroon.css";
import Dashboard from "./Pages/Dashboard";
import Purchase from "./Pages/Purchase/Purchase";
import Sale from "./Pages/Sales/Sale";
import Ledger from "./Pages/Ledger/Ledger";
import Comagent from "./Pages/ComAgent/Comagent";
import Reports from "./Pages/Reports/Reports";
import Inventory from "./Pages/Inventory/Inventory";
import Vouchers from "./Pages/Vouchers/Vouchers";
import { RiArrowDropDownLine } from "react-icons/ri";
import icon from "../src/Images/seasonFour.jpg";
import { useHistory } from "react-router-dom";
import Settings from "./Pages/Settings/Settings";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function SideButton(props) {
  return (
    <button
      onClick={props.onClick}
      className={
        props.active
          ? "lightPurple row  sideText sideButton ml-2 mr-1"
          : "row  sideText sideButton ml-2 mr-1"
      }
    >
      {props.icon}
      {props.isOpen ? (
        <p className=" ml-4 mr-auto sideText whiteText">{props.text}</p>
      ) : null}
    </button>
  );
}
function SideBarOpen() {
  const [isOpen, setState] = useState(true);
  const handleToggle = () => {
    isOpen ? setState(false) : setState(true);
  };
  const [chosen3, setChosen3] = useState("Dashboard");
  const [Menu, setMenu] = useState([
    //*Dashboard
    {
      text: "Dashboard",
      icon: (
        <MdDashboard
          color="white"
          size="2.2em"
          style={{ marginLeft: "15px", marginTop: "8px" }}
        />
      ),
      component: <Dashboard></Dashboard>,
    },
    //*Vouchers
    {
      text: "Vouchers",
      icon: (
        <RiFilePaper2Line
          color="white"
          size="2.2em"
          style={{ marginLeft: "15px", marginTop: "8px" }}
        />
      ),
      component: <Vouchers></Vouchers>,
    },
    //*accounts button in sidebar
    {
      text: "Accounts",
      icon: (
        <GiBookshelf
          color="white"
          size="2.2em"
          style={{ marginLeft: "15px", marginTop: "8px" }}
        />
      ),
      component: <Accounts></Accounts>,
    },
    //*sales button in sidebar
    {
      text: "Sale",
      icon: (
        <FiTruck
          color="white"
          size="2em"
          style={{ marginLeft: "18px", marginTop: "8px" }}
        />
      ),
      component: <Sale></Sale>,
    },
    //*purchase button in sidebar
    {
      text: "Purchase",
      icon: (
        <MdAddShoppingCart
          color="white"
          size="2em"
          style={{ marginLeft: "15px", marginTop: "5px" }}
        />
      ),
      component: <Purchase />,
    },
    //*inventory button in sdiebar
    // {
    //   text: "Inventory",
    //   icon: (
    //     <svg className="ml-3 sideIcon" viewBox="0 0 31.159 31.159">
    //       <g transform="translate(-6112 -1946)">
    //         <g class="whiteIcon" transform="translate(6112 1946)">
    //           <path
    //             d="M30.738,10.233,16.132.171a.976.976,0,0,0-1.1,0L.422,10.233a.972.972,0,0,0-.422.8V29.861a1.3,1.3,0,0,0,1.3,1.3h2.6V15.579a1.3,1.3,0,0,1,1.3-1.3H25.966a1.3,1.3,0,0,1,1.3,1.3V31.159h2.6a1.3,1.3,0,0,0,1.3-1.3V11.035A.974.974,0,0,0,30.738,10.233Z"
    //             transform="translate(0 0)"
    //           />
    //           <path
    //             class="a"
    //             d="M10.369,19v.974a.974.974,0,0,1-1.947,0V19H6.149a.649.649,0,0,0-.649.649v5.193a.649.649,0,0,0,.649.649h6.491a.649.649,0,0,0,.649-.649V19.649A.649.649,0,0,0,12.641,19Z"
    //             transform="translate(1.641 5.667)"
    //           />
    //           <path
    //             class="a"
    //             d="M17.369,19v.974a.974.974,0,0,1-1.947,0V19H13.149a.649.649,0,0,0-.649.649v5.193a.649.649,0,0,0,.649.649h6.491a.649.649,0,0,0,.649-.649V19.649A.649.649,0,0,0,19.641,19Z"
    //             transform="translate(3.729 5.667)"
    //           />
    //           <path
    //             class="a"
    //             d="M13.869,13v.974a.974.974,0,1,1-1.947,0V13H9.649A.649.649,0,0,0,9,13.649v5.193a.649.649,0,0,0,.649.649h6.491a.649.649,0,0,0,.649-.649V13.649A.649.649,0,0,0,16.141,13Z"
    //             transform="translate(2.685 3.878)"
    //           />
    //         </g>
    //       </g>
    //     </svg>
    //   ),
    //   component: <Inventory></Inventory>,
    // },
    //*reports button in sidebar  this section is commented because all the reports for each section are added in their respective tabs
    {
      text: "Bank/Cheque",
      icon: (
        <BsNewspaper
          color="white"
          size="1.7em"
          style={{ marginLeft: "20px", marginTop: "10px" }}
        />
      ),
      component: <Ledger></Ledger>,
    },
    {
      text: "Inventory",
      icon: (
        <MdStore
          color="white"
          size="2em"
          style={{ marginLeft: "15px", marginTop: "5px" }}
        />
      ),
      component: <Inventory></Inventory>,
    },
    {
      text: "Comm. Agent",
      icon: (
        <BsFillPersonLinesFill
          color="white"
          size="2em"
          style={{ marginLeft: "15px", marginTop: "5px" }}
        />
      ),
      component: <Comagent></Comagent>,
    },
    {
      text: "Reports",
      icon: (
        <GiPapers
          color="white"
          size="1.7em"
          style={{ marginLeft: "20px", marginTop: "10px" }}
        />
      ),
      component: <Reports></Reports>,
    },
    {
      text: "Settings",
      icon: (
        <AiFillSetting
          color="white"
          size="1.7em"
          style={{ marginLeft: "20px", marginTop: "10px" }}
        />
      ),
      component: <Settings />,
    },
  ]);

  return (
    <>
      <div className="col-auto pl-0 purple fullHeight">
        <div className="row">
          <div className="col">
            <img
              onClick={handleToggle}
              className="mt-2 toggle"
              src={menu}
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-auto pt-3">
            {/* <img className='mt-2 ml-3 logo' src={shortLogo}></img><img className='mt-2 ml-1 logo' src={logoName}></img>  */}
            {isOpen ? (
              <h3 className="pl-2">
                <span style={{ color: "white" }}>Season</span>
                <span style={{ color: "yellow" }}>Four</span>
              </h3>
            ) : null}
          </div>
        </div>
        <div className=" mt-5 sidebar_items ">
          {Menu.map((t) => (
            <SideButton
              isOpen={isOpen}
              text={t.text}
              icon={t.icon}
              onClick={() => {
                setChosen3(t.text);
              }}
              active={t.text === chosen3}
            />
          ))}
        </div>
      </div>
      <div
        style={{ height: "100%", width: "70%" }}
        className="col p-0  lightGrey "
      >
        <div style={{ height: "6%" }} className="col p-0   lightGrey ">
          <div className="navbar-style">
            <span className="ml-4 mt-2 icon2-font">
              {" "}
              <BsBell color="#3c3c74" size="1em" />
            </span>
            <span className=" ml-4 textPurple" style={{ marginTop: "10px" }}>
              {" "}
              <div
                className="col-auto p-0 purple"
                style={{ height: "28px", width: "40px" }}
              >
                <img
                  className="chatboximage"
                  src={icon}
                  alt="rafi-icon"
                  style={{ color: "black" }}
                />
              </div>
            </span>
            <span className=" ml-1 ">
              <Dropdown className="accountBtn">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="accountBtn"
                >
                  <RiArrowDropDownLine className="drop"></RiArrowDropDownLine>
                </Dropdown.Toggle>
                <Dropdown.Menu className="purple">
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.clear();
                    }}
                    href="/"
                    style={{ color: "white" }}
                  >
                    LogOut
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
            <span className="ml-2 my-auto mr-2 Sitka"></span>
          </div>
        </div>
        <hr />
        <div style={{ height: "93%" }} className="col lightGrey ">
          {Menu.map((t) => (t.text === chosen3 ? t.component : null))}
        </div>
      </div>
      <ToastContainer theme="dark"/>
    </>
  );
}
function App() {
  let history = useHistory();

  if (localStorage.getItem("Token") === null) {
    history.push({ pathname: "/" });
  }

  return (
    <div className="row m-0 w-100 fullHeight">
      <SideBarOpen />
    </div>
  );
}
export default App;
