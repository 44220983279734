import axios from 'axios';

export const GetAllPaymentVouchersData = (data) =>{
    return ({
        type:'getallpaymentvouchersdata',
        payload:data,
        loader:false
    })
}

export const getallpaymentvouchers =() =>{
    return(dispatch) =>{
        axios({
            url:`${window.$url}api/PaymentVoucher/GetAllPaymentVouchers?type=Cash`,
            method:"get",
            headers:{
                Authorization: `Bearer ${localStorage.getItem("Token")}`,
                "content-type":"application/json"
            }
        }).then(
            (result) => {
                console.log(result)
                if(result.data.IsSuccess){
                    dispatch(GetAllPaymentVouchersData(result.data.PaymentVoucher))
                }
                
            },
            (error) =>{
                console.log(error);
            }
        )
    }
}