import React, { Component } from "react";
import logo from "../../../../Images/seasonFour.jpg";

export default class DiscardChqueSummaryPrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Discarded Cheque</u>
              </b>
            </h3>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.
                      </th>
                      <th scope="col" className="th1">
                        Bank Name
                      </th>
                      <th scope="col" className="th1">
                        Account Name
                      </th>
                      <th scope="col" className="th1">
                        Account Number
                      </th>
                      <th scope="col" className="th1">
                        Cheque No
                      </th>
                      <th scope="col" className="th1">
                        Cheque Book
                      </th>
                      <th scope="col" className="th1">
                        Narration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props?.DiscardedChequedata?.length > 0
                      ? this.props?.DiscardedChequedata.map((x, idx) => (
                          <tr>
                            <td scope="col" className="th1">
                              {idx + 1}
                            </td>
                            <td scope="col" className="th1">
                              {x.Bank?.BankName}
                            </td>
                            <td scope="col" className="th1">
                              {x.Bank?.BankAccountName}
                            </td>

                            <td scope="col" className="th1">
                              {x.Bank?.AccountNumber}
                            </td>
                            <td scope="col" className="th1">
                              {x.ChequeNumber}
                            </td>
                            <td scope="col" className="th1">
                              {x.ChequeBook?.ChequeBookTitle}
                            </td>
                            <td scope="col" className="th1">
                              {x.Narration}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
