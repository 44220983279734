const allbankaccountsdata = {data: [], loading: true};

export const GetAllBankAccountsDataReducer = (state = allbankaccountsdata, action) =>{
    switch(action.type){
        case 'getallbankaccountsdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}