import React, { useState, useEffect, useRef } from "react";
import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { PurchaseContractTable } from "./PurchaseContractTable";
import { PurchaseContractForm } from "./PurchaseContractForm";

export const PurchaseContractMain = () => {
    const changecomponent = () => {
      setComponentState(<PurchaseContractForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <PurchaseContractTable
          changecomponent={changecomponent}
        />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <PurchaseContractTable
        changecomponent={changecomponent}
      />
    );
  
    return <>{componentState}</>;
  };