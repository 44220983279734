import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";
import BankBook from "../ReportsNew/BankBook";
import CashBook from "../ReportsNew/CashBook";
import CashFlow from "../ReportsNew/CashFlow";
import TrialBalance from "../ReportsNew/TrialBalance";
import BalanceSheetMain from "../ReportsNew/BalanceSheet/BalanceSheetMain";
import IncomeStatementMain from "../ReportsNew/IncomeStatement/IncomeStatementMain";


// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
        count === "Trial Balance"
          ? "blue"
          : count === "Balance Sheet"
          ? "red"
          : count === "Income Statement"
          ? "seaGreen"
          : count === "Cash Book"
          ? "darkred"
          : count === "Cash Flow"
          ? "seaGreen"
          : count === "Bank Book"
          ? "red"
          : count
      }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Sale = () => {
  const button = [
    { button1: "Trial Balance", component: <TrialBalance /> },
    { button1: "Balance Sheet", component: <BalanceSheetMain /> },
    { button1: "Income Statement", component: <IncomeStatementMain /> },
    { button1: "Cash Flow", component: <CashFlow /> },
    { button1: "Cash Book", component: <CashBook /> },
    { button1: "Bank Book", component: <BankBook /> },
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Trial Balance",
    component: <TrialBalance />,
  });

  return (
    <>
      <div style={{ height: "10%" }}>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "87%" }}>
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Sale;
