import React, { useState, useEffect } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

import axios from "axios";
import {
  PurchaseInvoicePageMain,
  PurchaseContractInvoicePageMain,
  PurchaseRegister,
  CommissionAgentTable,
  BrandWisePurchase,
} from "./PurchaseTable";
import { PurchaseContractMain } from "../PurchaseNew/PurchaseContract/PurchaseContractMain";
import { PurchaseInvoiceMain } from "../PurchaseNew/PurchaseInvoice/PurchaseInvoiceMain";
import { PurchaseRegisterMain } from "../PurchaseNew/PurchaseRegister/PurchaseRegisterMain";
import { DeliveryReport } from "../PurchaseNew/PurchaseDeliveryReport/PurchaseDeliveryReportMain";
import SellerMain from "../PurchaseNew/Seller/SellerMain";
import { SellerSaleSummaryMain } from "../PurchaseNew/SellerSaleSummary/SellerSaleSummaryMain";

// PRIMARY PRODUCTION BUTTONS COMPONENTS

const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
        count === "Purchase Invoice"
          ? "darkred"
          : count === "Purchase Contract"
          ? "blue"
          : count === "Sellers"
          ? "seaGreen"
          : count === "Commission Agent"
          ? "blue"
          : count === "Brand Wise Purchase"
          ? "darkred"
          // : count === "Purchase Register"
          // ? "purple"
          : count === "Seller Summary"
          ? "red" :
          count === "Delivery Report"
          ? "purple"
          : count
      }`}
    >
      {count}
    </button>
  );
};

const Btngroup2 = ({ active, count, onClick, styling }) => {
  return (
    <button
      onClick={onClick}
      className={
        active ? `secondarybuttonClicked ${styling}` : "secondarybutton"
      }
    >
      {count}
    </button>
  );
};
// PRODUCTION PAGE COMPONENT
const Sale = () => {
  const [chosen, setChosen] = useState({
    // button: "New Purchase",
    // component: <Newpurchasesection />,
    // underline: "btmborderblue",
  });

  const one = [
    {
      // component: <PurchaseInvoicePageMain />,
      component : <PurchaseInvoiceMain/>
    },
  ];
  const two = [
    {
      // component:  <PurchaseContractInvoicePageMain />,
      component : <PurchaseContractMain/>
    },
  ];
  const three = [
    {
      component: <SellerMain />,
    },
  ];
  const six = [
    {
      component: <SellerSaleSummaryMain />,
    },
  ];
  const seven = [
    {
      component: <DeliveryReport />,
    },
  ];
  

  // const five = [
  //   {
  //     component: <CommissionAgentTable />,
  //   },
  // ];
  // const four = [
  //   {
  //     // component: <PurchaseRegister />,
  //     component: <PurchaseRegisterMain/> NEW One
  //   },
  // ];
  // const six = [
  //   {
  //     component: <BrandWisePurchase />,
  //   },
  // ];

  const button = [
    { button1: "Purchase Contract", button2: two },
    { button1: "Purchase Invoice", button2: one },
    // { button1: "Purchase Register", button2: four },
    { button1: "Sellers", button2: three },
    { button1: "Seller Summary", button2: six },
    { button1: "Delivery Report", button2: seven },

    
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Purchase Contract",
    button2: two,
  });
  return (
    <>
      <div style={{ height: "10%" }}>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
              setChosen(b.button2[0]);
            }}
          />
        ))}
        <br></br>
        {/* MAPPING SECONDARY BUTTONS ON THE PRODUCTION PAGE */}
        {chosen2.button2.map((b) =>
          b.button !== undefined ? (
            <Btngroup2
              key={b.button}
              count={b.button}
              styling={b.underline}
              active={b.button === chosen.button}
              onClick={() => setChosen(b)}
            />
          ) : null
        )}
      </div>
      <div style={{ height: "87%" }}>
        {chosen2.button2.map((b) =>
          b.button === chosen.button ? b.component : null
        )}
      </div>
    </>
  );
};

export default Sale;
