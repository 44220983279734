import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import "../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";

import { getallpopulateaccounts } from "../../actions/getpopulateaccounts";
import { useSelector, useDispatch } from "react-redux";
import LedgerPrint from "./GeneralLedgerPrint";
import { toast } from "react-toastify";

const GeneralLedgerMain = () => {
  const [stateledger, setLedger] = useState([]);
  const [credittotal, setcredittotal] = useState(0);
  const [debittotal, setdebittotal] = useState(0);
  const [balancetotal, setbalancetotal] = useState(0);
  const [statebalance, setBalance] = useState(0);
  const [category, setcategory] = useState("");
  const [loader1, setloader1] = useState(false);
  const [accountname, setaccountname] = useState("");
  const [fromdate, setfromdate] = useState(moment().format("YYYY-MM-DD"));
  const [todate, settodate] = useState(moment().format("YYYY-MM-DD"));

  const [accountlist, setaccountlist] = useState([]);
  const [Paymentaccount, setPaymentaccount] = useState([]);
  const [affiliateAccid, setaffiliateAccid] = useState(null);

  let dispatch = useDispatch();
  let populateaccounts = useSelector(
    (state) => state.GetAllPopulateAccountsDataReducer
  );

  useEffect(() => {
    dispatch(getallpopulateaccounts());
  }, [dispatch]);

  useEffect(() => {
    if (populateaccounts.data.AllAccounts !== undefined) {
      setPaymentaccount(populateaccounts.data.AllAccounts);
    }
  }, [populateaccounts]);

  const getgeneralledger = () => {
    let data = {
      AccId: affiliateAccid,
      From: document.getElementById("from").value,
      To: document.getElementById("to").value,
    };

    if (
      document.getElementById("from").value === "" ||
      document.getElementById("to").value === "" ||
      affiliateAccid === ""
    ) {
      toast.info("Please fill all the fields!");
      setaccountlist([]);
      setcredittotal("");
      setdebittotal("");
    } else {
      setloader1(true);
      axios({
        url: window.$url + `api/Reports/GeneralLedgerComplete`,
        data: JSON.stringify(data),
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setloader1(false);
          console.log(result);

          setaccountlist(result.data.account);

          setcredittotal(result.data.credit);
          setdebittotal(result.data.debit);

          let balance = Number(result.data.debit) - Number(result.data.credit);

          // result.data.account.forEach((x) => {
          //   if (x.balance !== "" || x.balance !== null) {
          //     balance += parseFloat(x.balance);
          //   }

          // });

          setbalancetotal(balance);
        },
        (error) => {
          setloader1(false);
          console.log(error);
        }
      );
    }
  };
  console.log(accountlist);

  const OnSellerChange = (e) => {
    let value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("demo"));
      setaccountlist([]);
      setcredittotal("");
      setdebittotal("");
      setaccountname("");
      setaffiliateAccid("");
    } else {
      console.log(e.target.value);
      var options = document.getElementById("sellerList").childNodes;
      for (var i = 0; i < options.length; i++) {
        if (options[i]?.value === value) {
          let item = Paymentaccount?.find(
            (item, idx) =>
              item.Accid ===
              parseInt(options[i].attributes.attributeId.value)
          );
          console.log(item);
          console.log(options);

          document.getElementById("cashPaymentAccount").value =
            item.Accounttitle;
          setaccountname(item.Accounttitle);
          setaffiliateAccid(item.Accid);

          break;
        }
        setaccountname("");
        setaffiliateAccid("");
      }
    }
  };
  var suggestionlist = [];

  // const onTextChange = (e) => {
  //   const value = e.target.value;
  //   if (value == "") {
  //     ReactDOM.render(null, document.getElementById("demo"));
  //     setaccountlist([]);
  //     setcredittotal("");
  //     setdebittotal("");
  //     setaccountname("");
  //     setaffiliateAccid("");
  //   } else {
  //     console.log(value);
  //     let suggestions = [];
  //     if (value.length > 0) {
  //       const regex = new RegExp(`${value}`, "i");
  //       suggestions = Paymentaccount.sort().filter((v) =>
  //         regex.test(v.Accounttitle)
  //       );
  //     }

  //     suggestionlist = suggestions;
  //     // suggestionlist.push("Add New");
  //     if (suggestionlist.length === 0) {
  //       ReactDOM.render(null, document.getElementById("demo"));
  //     } else {
  //       ReactDOM.render(
  //         <ul className="suggestbox">
  //           {suggestionlist.map((item) => (
  //             <li className="listcss" onClick={() => suggestionselected(item)}>
  //               {item === undefined ? item : item.Accounttitle}
  //             </li>
  //           ))}
  //         </ul>,
  //         document.getElementById("demo")
  //       );
  //     }
  //   }
  // };
  const suggestionselected = (val) => {
    document.getElementById("cashPaymentAccount").value = val.Accounttitle;
    setaccountname(val.Accounttitle);
    setaffiliateAccid(val.Accid);
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById("demo"));
    console.log(affiliateAccid);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log(accountname);
  return (
    <>
      <div hidden>
        <LedgerPrint
          ref={componentRef}
          accountlist={accountlist}
          accountname={accountname}
          fromdate={fromdate}
          todate={todate}
          debitTotal={debittotal}
          creditTotal={credittotal}
          balancetotal={balancetotal}
          category={category}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitka dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                {/* <span>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Account"
                    className="input_border"
                    onChange={onTextChange}
                    id="cashPaymentAccount"
                  ></input>{" "}
                  <span id="demo"></span>
                </span> */}
                <span>
                  {" "}
                  <input

                    placeholder="Enter Name"
                    type="text"
                    id="cashPaymentAccount"
                    list="sellerList"
                    onChange={OnSellerChange}
                    className="input_border mx-2"
                    autoComplete="off"
                  ></input>
                  <datalist id="sellerList">
                    {Paymentaccount?.map((v, idx) => {
                      return (
                        <option attributeId={v?.Accid} value={v?.Accounttitle}>
                          {`${v?.Accounttitle}(${v?.HeadingNavigation?.AccountTitle})`}
                        </option>
                      );
                    })}
                  </datalist>
                </span>
                <span>
                  From:{" "}
                  <input
                    id="from"
                    type="date"
                    autoComplete="off"
                    defaultValue={moment().format("YYYY-07-01")}
                    placeholder="Search By City"
                    className="input_border ml-2"
                  ></input>{" "}
                </span>
                <span>
                  To:{" "}
                  <input
                    id="to"
                    type="date"
                    autoComplete="off"
                    defaultValue={moment().format("YYYY-MM-DD")}
                    placeholder="Search By City"
                    className="input_border ml-2"
                  ></input>
                </span>
              </span>
              <span className="ml-2">
                <button
                  id="callapibutn"
                  className="tabletopbutton"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>
              <span className=" ml-1 float-right">
                <button
                  className="tabletopbutton1 text-right"
                  onClick={getgeneralledger}
                >
                  Go
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th className="th1" scope="col">
                    Sr.
                  </th>
                  <th>Date</th>
                  <th>Voucher No</th>
                  <th>Account Category</th>
                  <th>Narration</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {accountlist.map((x, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{x.dateadded.split("T")[0]}</td>
                    <td>{x.referenceno}</td>
                    <td>{x.acc.accountcategory}</td>
                    <td>{x.narration}</td>
                    <td>
                      {x.transtype === "Debit"
                        ? parseFloat(x.amount).toLocaleString()
                        : null}
                    </td>
                    <td>
                      {x.transtype === "Credit"
                        ? parseFloat(x.amount).toLocaleString()
                        : null}
                    </td>
                    <td>{x.balance}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      type="text"
                      className="input_border formInput"
                      disabled
                      placeholder={
                        debittotal !== null
                          ? parseFloat(debittotal).toLocaleString()
                          : 0
                      }
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input_border formInput"
                      disabled
                      placeholder={
                        credittotal !== null
                          ? parseFloat(credittotal).toLocaleString()
                          : 0
                      }
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="input_border formInput"
                      placeholder={
                        balancetotal !== null
                          ? balancetotal.toLocaleString()
                          : null
                      }
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralLedgerMain;
