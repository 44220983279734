import React, { useState, useEffect, useRef } from "react";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { getallbankreceiptvouchers } from "../../actions/getallbankreceiptvouchers";
import BankReceiptPrint from "../../Prints/BankRecieptPrint";
import BankReceiptAllPrint from "../../Prints/BankRecieptAllPrint";

export const BankReceiptTable = (props) => {
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [Voucherlist, setVoucherlist] = useState([]);
  const [printdata, setprintdata] = useState();

  var today = new Date();
  var todaydate = today.getDate();
  if (todaydate.toString().length === 1) {
    todaydate = "0" + todaydate.toString();
  }
  var currentmonth = today.getMonth();
  if (currentmonth.toString().length === 1) {
    currentmonth = "0" + (today.getMonth() + 1).toString();
  }
  var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;

  //Table Data fiter
  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setVoucherlist(suggestionbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.Account.Accounttitle));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setVoucherlist([]);
      } else {
        setVoucherlist(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setVoucherlist(suggestionbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.SecondaryKey));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setVoucherlist([]);
      } else {
        setVoucherlist(suggestionlist);
      }
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printdatafunction = (val) => {
    setprintdata(val);
  };

  let dispatch = useDispatch();
  let bankreceiptvouchers = useSelector(
    (state) => state.GetAllBankReceiptVouchersDataReducer
  );

  useEffect(() => {
    dispatch(getallbankreceiptvouchers());
  }, [dispatch]);

  useEffect(() => {
    if (bankreceiptvouchers !== undefined) {
      setVoucherlist(bankreceiptvouchers.data);
      setsuggestionbuyersdata(bankreceiptvouchers.data);
    }
  }, [bankreceiptvouchers]);

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  useEffect(() => {
    if (printdata) {
      handlePrint();
    }
  }, [printdata]);

  return (
    <>
      <div hidden>
        <BankReceiptPrint
          ref={componentRef}
          date={date}
          printdata={printdata}
        />
        <BankReceiptAllPrint
          ref={componentRef1}
          date={date}
          Voucherlist={Voucherlist}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitka datatablecss">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Search By Account"
                  className="input_border"
                ></input>
                <input
                  type="text"
                  autoComplete="off"
                  onChange={onTextChange2}
                  placeholder="Search By Voucher No."
                  className="input_border ml-2"
                ></input>
              </span>
              <span className="ml-2 ">
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={handlePrint1}
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <button
                  onClick={props.changecomponent}
                  className="tabletopbutton1 text-right"
                >
                  New
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {bankreceiptvouchers.loading && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class=" ">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Voucher Number
                  </th>
                  <th scope="col" className="th1">
                    Invoice No.
                  </th>
                  <th scope="col" className="th1">
                    Account
                  </th>
                  <th scope="col" className="th1">
                    Bank
                  </th>
                  <th scope="col" className="th1">
                    Cheque
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                  <th scope="col" className="th1">
                    Print
                  </th>
                </tr>
              </thead>
              <tbody>
                {Voucherlist !== undefined
                  ? Voucherlist.map((x, idx) => (
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td class="trpointer">
                          {x.Voucherdate.substring(0, 10)}
                        </td>
                        <td class="trpointer">{x.SecondaryKey}</td>
                        <td class="trpointer">
                          {" "}
                          {x.SaleInvoice?.map((inv) => {
                            return `SInv-${inv?.SaleInvSerialNo}`;
                          }).toString()}
                        </td>
                        <td class="trpointer">{x.Account.Accounttitle}</td>
                        <td class="trpointer">{x.Acc.Accounttitle}</td>
                        <td class="trpointer">{x.ChequeNumber}</td>
                        <td class="trpointer">{x.Narration}</td>
                        <td class="trpointer">
                          {x.Debit !== null ? x.Debit.toLocaleString() : null}
                        </td>
                        <td
                          onClick={() => {
                            printdatafunction(x);
                          }}
                          style={{ paddingTop: "10px" }}
                        >
                          <TiPrinter size="1.2em" />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
