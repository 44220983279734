import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

  import {
    AccountPayablePDC,
    ChequeSummary,
    AccountReceivablePDC
  } from "../Accounts/AccountTable";
import BankAccountsMain from "../AccountsNew/BankAccounts/BankAccountsMain";
import DiscardChqueSummaryMain from "../Bank/Cheque/DiscardChqueSummary/DiscardChqueSummaryMain";

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${count === "General Ledger"
        ? "blue"
        : count === "General Journal"
          ? "red"
          : count === "Bank Accounts"
          ? "seaGreen"
          : count === "PDC Payables"
          ? "darkred"
          : count === "PDC Receivables"
          ? "blue"
          : count === "Cheque Summary"
          ? "red"
          : count === "Discarded Cheque"
          ? "purple"
          : count
        }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Sale = () => {
  const button = [
    { button1: "Bank Accounts", component: <BankAccountsMain/> },
    { button1: "PDC Payables", component: <AccountPayablePDC></AccountPayablePDC> },
    { button1: "PDC Receivables", component: <AccountReceivablePDC></AccountReceivablePDC> },
    { button1: "Cheque Summary", component: <ChequeSummary></ChequeSummary> },
    { button1: "Discarded Cheque", component: <DiscardChqueSummaryMain/> },

  ];
  const [chosen2, setChosen2] = useState({
    button1: "Bank Accounts",
    component: <BankAccountsMain/> ,
  });


  return (
    <>
      <div style={{ height: "10%" }} >
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "89%" }} >
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Sale;
