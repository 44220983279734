import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Loader from "react-loader-spinner";
import Delete from "../../../Images/delete.png";
import moment from "moment";
import { IoMdAddCircle } from "react-icons/io";
import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { PurchaseInvoiceIndividual } from "./PurchaseInvoicePrint";

export const PurchaseInvoiceForm = (props) => {
  const [SellerId, setSellerId] = useState("");
  const [AllAccounts, setAllAccounts] = useState([]);
  const [PurchaseContractCheck, setPurchaseContractCheck] = useState(false);
  const [PInvNo, setPInvNo] = useState("");
  const [loader1, setloader1] = useState(false);
  const [tablerow, settablerow] = useState([]);
  const [sellersdata, setsellersdata] = useState([]);
  const [brandsdata, setbrandsdata] = useState([]);
  const [yarndata, setyarndata] = useState([]);
  const [purchasecontracts, setpurchasecontracts] = useState([]);
  const [purchasecontractdetails, setpurchasecontractdetails] = useState([]);
  const [printdata, setprintdata] = useState();
  const [saletax, setsaletax] = useState(17);
  const [contractId, setcontractId] = useState("");
  const [SellerName, setSellerName] = useState("");
  const [taxavailable, settaxavailable] = useState("with tax");
  const [taxavailablebool, settaxavailablebool] = useState(true);
  const [showtaxoptions, setshowtaxoptions] = useState(true);
  useEffect(() => {
    if (taxavailable === "with tax") {
      settaxavailablebool(true);
    } else if (taxavailable === "without tax") {
      settaxavailablebool(false);
    }
  }, [taxavailable]);

  useEffect(() => {
    if (tablerow.length === 0) {
      setshowtaxoptions(true);
      settaxavailable("with tax");
    } else {
      setshowtaxoptions(false);
    }
    console.log(tablerow);
  }, [tablerow]);

  const handleaddrow = () => {
    const item = {
      itemname: "",
      composition: "",
      qty: "",
      rate: "",
      brand: "",
      salestax: 0,
      amount: 0,
      // avgweight: "",
      brandid: "",
      yarnid: "",
    };

    settablerow([...tablerow, item]);
  };

  useEffect(() => {
    console.log(tablerow, "tablerow");
    if (tablerow.length === 0) {
      document.getElementById("addbutton").style.display = "block";
      // document.getElementById("sellername").value = "";
      // document.getElementById("sellerid").value = "";
      // document.getElementById("address").value = "";
      setcontractId("");
      // document.getElementById("purchasecontract").value = "";
      document.getElementById("totalamount").value = "";
      document.getElementById("totalqty").value = "";
      document.getElementById("sellername").value = "";

      setpurchasecontracts([]);
      setpurchasecontractdetails([]);
      setSellerId("");
      setSellerName("");
    }

    if (purchasecontractdetails.length === 0) {
      tablerow.forEach((x, idx) => {
        document.getElementById(`itemname${idx}`).disabled = false;
        document.getElementById(`brandname${idx}`).disabled = false;
        document.getElementById(`rate${idx}`).disabled = false;
      });
    } else {
      tablerow.forEach((x, idx) => {
        document.getElementById(`itemname${idx}`).disabled = true;
        document.getElementById(`brandname${idx}`).disabled = true;
        document.getElementById(`rate${idx}`).disabled = true;
      });
    }

    let val = 0;
    let quantity = 0;
    tablerow.forEach((x) => {
      if (x.netamount !== "") {
        val += parseFloat(x.netamount);
        quantity += parseFloat(x.qty);
      }
    });
    document.getElementById("totalqty").value = quantity;

    document.getElementById("totalamount").value = val;
    // document.getElementById("nettotalamount").value = val;
  }, [tablerow]);

  const handledeleterow = (idx) => {
    const rows = [...tablerow];
    rows.splice(idx, 1);
    settablerow(rows);

    // document.getElementById("discountamount").value = "";
    // document.getElementById("freightamount").value = "";
    // document.getElementById("othersamount").value = "";
    // document.getElementById("nettotalamount").value = "";
    // setnettotalstate(0);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const purchaseinvoicepopulate = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Purchase/PopulatePurchaseInvoice`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        if (result.data.IsSuccess) {
          setAllAccounts(result.data?.AllAccounts);
          setsellersdata(result.data?.Seller);
          setbrandsdata(result.data?.Brand);
          setyarndata(result.data?.Product);
          setsaletax(result?.data?.Tax[0]?.TaxAmount);
          setPInvNo(result.data?.PurchaseInvoiceNumber);
        }
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  console.log(SellerName, SellerId);
  //Customers autosuggestion codes

  const OnSellerChange = (e) => {
    let value = document.getElementById("sellername").value;
    console.log(e.target.value);
    var options = document.getElementById("sellerList").childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = sellersdata?.find((x, idx) => {
          console.log(value);
          // console.log( e.target.options[e.target.selectedIndex].dataset.attributeId);
          return (
            x.Accid ===
            parseInt(
              options[i].attributes.attributeId.value
              // e.target.options[e.target.selectedIndex].dataset.attributeId
            )
          );
        });

        setSellerName(item.Accounttitle);
        setSellerId(item.Accid);
        setpurchasecontracts(item.PurchaseContracts);
        break;
      }
    }
  };

  const OnAccountChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("AccountList").childNodes;
    // let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        // find = true;

        let item = AllAccounts?.find(
          (item, idx) =>
            // console.log(item.Accid , options[i].attributes.attributeId.value)
            item.Accid === parseInt(options[i].attributes.attributeId.value)
        );

        console.log(item);
        console.log(options);

        setSellerName(item.Accounttitle);
        setSellerId(item.Accid);
        setpurchasecontracts([]);
        console.log(item.PurchaseContracts);
        break;
      }
    }
  };

  console.log(purchasecontracts, SellerName);

  useEffect(() => {
    purchaseinvoicepopulate();
  }, []);

  useEffect(() => {
    if (purchasecontractdetails?.length !== 0) {
      let array = [];
      let totalamount = 0;
      let quantity = 0;

      purchasecontractdetails.forEach((x, idx) => {
        console.log(x);
        setsaletax(x.TaxPercentage);
        array.push({
          itemname: x.Product.ProductType,
          composition: x.Product.Composition,
          qty: x.RemainingQty,
          remqty: x.RemainingQty,
          rate: x.Rate,
          brand: x.Brand.BrandName,
          taxAmount: x.TaxAmount,
          taxPercentage: x.TaxPercentage,
          salestax: x.SaleTax,
          amount: x.RemainingQty * x.Rate,
          netamount: x.RemainingQty * x.Rate + x.TaxAmount,
          // avgweight: "",
          brandid: x.Brand.BrandId,
          yarnid: x.Product.ProductId,
        });

        totalamount += parseFloat(x.netamount);
        quantity += parseFloat(x.qty);
      });

      settablerow(array);
      console.log(array);
      document.getElementById("totalqty").value = quantity;
      document.getElementById("totalamount").value = totalamount;

      // setnettotalstate(
      //   totalamount +
      //     Number(document.getElementById("othersamount").value) +
      //     Number(document.getElementById("freightamount").value) -
      //     Number(document.getElementById("discountamount").value)
      // );

      document.getElementById("addbutton").style.display = "none";
    }
  }, [purchasecontractdetails]);

  // const finalcalculationfunction = () => {
  //   setnettotalstate(
  //     Number(document.getElementById("totalamount").value) -
  //       Number(document.getElementById("discountamount").value) +
  //       Number(document.getElementById("freightamount").value) +
  //       Number(document.getElementById("othersamount").value)
  //   );
  // };

  const addpurchaseinvoicenew = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: x.rate,
        // AvgWeight: x.avgweight,
        NetAmount: parseInt(x.netamount),
      });
    });

    let data = {
      Date: document.getElementById("date").value,
      Narration: document.getElementById("remarks").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      TotalQty: parseInt(document.getElementById("totalqty").value),
      PurchaseContractId:
        contractId === ""
          ? // document.getElementById("purchasecontract").value === ""
            null
          : contractId,
      AccountId: SellerId,

      //  document.getElementById("purchasecontract").value,
      // Discount: document.getElementById("discountamount").value,
      // Freight: document.getElementById("freightamount").value,
      // Others: document.getElementById("othersamount").value,
      // NetTotalAmount: document.getElementById("nettotalamount").value,
      // SellerAddress: document.getElementById("address").value,
      PurchaseInvoiceDetails: array,
    };

    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    // if (contractId === "") {
    //   toast.info("Please Select Contract");
    //   return;
    // }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.amount === "") {
        toast.info(`Please Select Amount at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.salestax === "") {
        toast.info(`Please Select Sales Tax at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;
    setloader1(true);

    console.log("beforeAPI", data);
    console.log(document.getElementById("totalqty").value, data);

    document.getElementById("crvSave").disabled = true;
    console.log("beforePost", data);
    axios({
      url: window.$url + `api/Purchase/AddPurchaseInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setSellerId("");
          setSellerName("");
          setPurchaseContractCheck(false);
          // document.getElementById("sellerid").value = "";
          // (document.getElementById("sellername").value = "");
          // document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          // document.getElementById("discountamount").value = "";
          // document.getElementById("freightamount").value = "";
          // document.getElementById("othersamount").value = "";
          // document.getElementById("nettotalamount").value = "";
          // document.getElementById("purchasecontract").value = "";
          // setnettotalstate("");
          document.getElementById("sellername").value = "";

          setcontractId("");
          settaxavailable("with tax");
          setpurchasecontracts([]);
          setpurchasecontractdetails([]);
          purchaseinvoicepopulate();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchaseinvoiceclose = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: x.rate,
        NetAmount: parseInt(x.netamount),
        // AvgWeight: x.avgweight,
      });
    });

    let data = {
      PurchaseContractId:
        contractId === ""
          ? // document.getElementById("purchasecontract").value === ""
            null
          : contractId,
      // document.getElementById("purchasecontract").value,
      // Discount: document.getElementById("discountamount").value,
      // Freight: document.getElementById("freightamount").value,
      // Others: document.getElementById("othersamount").value,
      // NetTotalAmount: document.getElementById("nettotalamount").value,
      Narration: document.getElementById("remarks").value,
      AccountId: SellerId,
      SellerName: SellerName,
      TotalQty: parseInt(document.getElementById("totalqty").value),
      // SellerAddress: document.getElementById("address").value,
      Date: document.getElementById("date").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      PurchaseInvoiceDetails: array,
    };

    console.log(data);

    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    // if (contractId === "") {
    //   toast.info("Please Select Contract");
    //   return;
    // }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.amount === "") {
        toast.info(`Please Select Amount at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.salestax === "") {
        toast.info(`Please Select Sales Tax at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;
    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Purchase/AddPurchaseInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setSellerId("");
          setPurchaseContractCheck(false);
          settaxavailable("with tax");

          setSellerName("");

          //  (document.getElementById("sellername").value = "");
          // document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          // document.getElementById("discountamount").value = "";
          // document.getElementById("freightamount").value = "";
          // document.getElementById("othersamount").value = "";
          // document.getElementById("nettotalamount").value = "";
          setcontractId("");
          // document.getElementById("purchasecontract").value = "";
          // setnettotalstate("");
          document.getElementById("sellername").value = "";

          setpurchasecontracts([]);
          setpurchasecontractdetails([]);
          purchaseinvoicepopulate();

          props.formCancel();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchaseinvoiceprint = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.taxAmount),
        TaxPercentage: parseInt(saletax),
        Rate: x.rate,
        // AvgWeight: x.avgweight,
        NetAmount: parseInt(x.netamount),
      });
    });

    let data = {
      PurchaseContractId:
        contractId === ""
          ? // document.getElementById("purchasecontract").value === ""
            null
          : contractId,
      // document.getElementById("purchasecontract").value,
      // Discount: document.getElementById("discountamount").value,
      // Freight: document.getElementById("freightamount").value,
      // Others: document.getElementById("othersamount").value,
      // NetTotalAmount: document.getElementById("nettotalamount").value,
      Narration: document.getElementById("remarks").value,
      AccountId: SellerId,
      TotalQty: parseInt(document.getElementById("totalqty").value),

      // SellerAddress: document.getElementById("address").value,
      Date: document.getElementById("date").value,
      NetTotalAmount: parseInt(document.getElementById("totalamount").value),
      PurchaseInvoiceDetails: array,
    };

    if (SellerId === "") {
      toast.info("Please Add Vendor");
      return;
    }
    // if (document.getElementById("purchasecontract").value === "") {
    //   toast.info("Please Select Contract");
    //   return;
    // }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.amount === "") {
        toast.info(`Please Select Amount at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.salestax === "") {
        toast.info(`Please Select Sales Tax at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;
    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Purchase/AddPurchaseInvoice`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setSellerId("");
          setSellerName("");
          setPurchaseContractCheck(false);

          // document.getElementById("sellerid").value = "";
          // (document.getElementById("sellername").value = "");
          // document.getElementById("address").value = "";
          document.getElementById("remarks").value = "";
          // document.getElementById("discountamount").value = "";
          // document.getElementById("freightamount").value = "";
          // document.getElementById("othersamount").value = "";
          // document.getElementById("nettotalamount").value = "";
          // document.getElementById("purchasecontract").value = ""

          // setnettotalstate("");
          settaxavailable("with tax");

          setcontractId("");
          document.getElementById("sellername").value = "";

          setpurchasecontracts([]);
          setpurchasecontractdetails([]);
          purchaseinvoicepopulate();
          setprintdata(result.data.PurchaseInvoice);
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  //TABLE FUNCTIONS
  //Yarn type autosuggestion code

  const OnItemChange = (e, idx) => {
    let value = e.target.value;
    var options = document.getElementById(`yarn${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = yarndata?.find(
          (x) =>
            `${x.ProductType} - (${x.Composition})` ===
            options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(
          `itemname${idx}`
        ).value = `${item.ProductType} - (${item.Composition})`;
        tablerow[idx].itemname = `${item.ProductType} - (${item.Composition})`;
        tablerow[idx].yarnid = item.ProductId;
        break;
      }
      // document.getElementById(`itemname${idx}`).value = "";
      tablerow[idx].itemname = "";
      tablerow[idx].yarnid = "";
    }
  };
  //Brand name  autosuggestion code
  const OnBrandChange = (e, idx) => {
    let value = e.target.value;
    var options = document.getElementById(`brand${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = brandsdata?.find(
          (x) => x.BrandName === options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(`brandname${idx}`).value = item.BrandName;
        tablerow[idx].brand = item.BrandName;
        tablerow[idx].brandid = item.BrandId;
        break;
      }
      // document.getElementById(`brandname${idx}`).value = "";
      tablerow[idx].brand = "";
      tablerow[idx].brandid = "";
    }
  };

  const quantitychange = (e, idx) => {
    if (e.target.value > tablerow[idx].remqty) {
      tablerow[idx].qty = tablerow[idx].remqty;
      document.getElementById(`qty${idx}`).value = tablerow[idx].remqty;
      //TOTAL VALUE CALCULATION
      let val1 = Number(tablerow[idx].remqty) * Number(tablerow[idx].rate);
      let val2 = (Number(saletax) / 100) * val1;
      document.getElementById(`netamount${idx}`).value = val1 + val2;
      tablerow[idx].netamount = val1 + val2;

      console.log(val1);
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`taxAmount${idx}`).value = val2.toFixed(2);
        tablerow[idx].taxAmount = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }

      let val = 0;
      let quantity = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += Number(x.netamount);
          quantity += Number(x.qty);
        }
      });
      document.getElementById("totalqty").value = quantity;
      document.getElementById("totalamount").value = val;
    } else {
      tablerow[idx].qty = e.target.value;

      //TOTAL VALUE CALCULATION
      let val1 = Number(tablerow[idx].qty) * Number(tablerow[idx].rate);
      let val2 = (Number(saletax) / 100) * val1;
      console.log(val2);
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`taxAmount${idx}`).value = val2.toFixed(2);
        tablerow[idx].taxAmount = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }
      document.getElementById(`netamount${idx}`).value = val1 + val2;
      tablerow[idx].netamount = val1 + val2;

      let val = 0;
      let quantity = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += Number(x.netamount);
          quantity += Number(x.qty);
        }
      });
      document.getElementById("totalqty").value = quantity;
      document.getElementById("totalamount").value = val;
    }
  };
  const amountchange = (e, idx) => {
    tablerow[idx].amount = e.target.value;
  };
  const ratechange = (e, idx) => {
    tablerow[idx].rate = e.target.value;

    //TOTAL VALUE CALCULATION
    let val1 = Number(tablerow[idx].qty) * Number(tablerow[idx].rate);
    let val2 = (Number(saletax) / 100) * val1;
    console.log(val1);
    if (taxavailablebool) {
      document.getElementById(`total${idx}`).value = val1;
      document.getElementById(`taxAmount${idx}`).value = val2.toFixed(2);
      tablerow[idx].taxAmount = val2.toFixed(2);
      tablerow[idx].amount = val1;
    }
    tablerow[idx].netamount = val1 + val2;
    document.getElementById(`netamount${idx}`).value = val1 + val2;

    let val = 0;
    let quantity = 0;

    tablerow.forEach((x) => {
      if (x.netamount !== "") {
        val += Number(x.netamount);
        quantity += Number(x.qty);
      }
    });
    document.getElementById("totalqty").value = quantity;
    document.getElementById("totalamount").value = val;
    // document.getElementById("nettotalamount").value = val;
  };
  // const avgweightchange = (e, idx) => {
  //   tablerow[idx].avgweight = e.target.value;
  // };

  const netamountchange = (e, idx) => {
    tablerow[idx].netamount =
      Number(tablerow[idx].taxAmount) + Number(tablerow[idx].amount);
  };

  useEffect(() => {
    if (printdata !== undefined) {
      handlePrint();
    }
  }, [printdata]);

  return (
    <>
      <div hidden>
        <PurchaseInvoiceIndividual ref={componentRef} printdata={printdata} />
      </div>
      <div
        className=" tableContainer2 overflow-auto pageheading Sitka mt-3"
        style={{ height: "95%" }}
      >
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-3">
            <div className="col-2 text-right">
              <span className="txt">Contract</span>
            </div>

            <div className="col-4  text-left">
              <span>
                {" "}
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setPurchaseContractCheck(e.target.checked);
                    console.log(e.target.checked);
                    document.getElementById("sellername").value = "";
                    setpurchasecontracts([]);
                    setpurchasecontractdetails([]);
                    setcontractId("");
                    settablerow([]);
                    setSellerId("");
                    setSellerName("");
                  }}
                ></input>
              </span>
            </div>

            <div className="col-2  text-right">
              <span className="txt">P.Inv No</span>
            </div>
            <div className="col-4  text-left">
              <span>
                {" "}
                <input
                  disabled
                  type="text"
                  value={PInvNo ? `PInv-${PInvNo}` : ""}
                  className="input_border formInput"
                ></input>
              </span>
            </div>

            {PurchaseContractCheck ? (
              <>
                <div className="col-2 text-right">
                  <span className="txt"> Vendor</span>
                </div>
                <div className="col-4  text-left">
                  <span>
                    {" "}
                    <input
                      placeholder="Enter Name"
                      type="text"
                      id="sellername"
                      list="sellerList"
                      onChange={OnSellerChange}
                      // name="SellerName"
                      defaultValue={SellerName}
                      className="input_border formInput "
                      autoComplete="off"
                    ></input>
                    <datalist id="sellerList">
                      {sellersdata?.map((v, idx) => {
                        // console.log(v);
                        return (
                          <option
                            attributeId={v?.Accid}
                            value={v?.Accounttitle}
                          >
                            {`${v?.Accounttitle}(${v?.HeadingNavigation?.AccountTitle})`}
                          </option>
                        );
                      })}
                    </datalist>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="col-2 text-right">
                  <span className="txt"> Vendor</span>
                </div>
                <div className="col-4  text-left">
                  <span>
                    {" "}
                    <input
                      placeholder="Enter Name"
                      type="text"
                      list="AccountList"
                      id="sellername"
                      onChange={OnAccountChange}
                      defaultValue={SellerName}
                      // name="SellerName"
                      className="input_border formInput "
                      autoComplete="off"
                    ></input>
                    <datalist id="AccountList">
                      {AllAccounts?.map((v, idx) => {
                        // console.log(v);
                        return (
                          <option
                            attributeId={v?.Accid}
                            value={v?.Accounttitle}
                          >
                            {`${v?.Accounttitle}(${v?.HeadingNavigation?.AccountTitle})`}
                          </option>
                        );
                      })}
                    </datalist>
                  </span>
                </div>
              </>
            )}

            <div className="col-2 mt-3  text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4 mt-3   text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="date"
                  className="input_border formInput"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></input>
              </span>
            </div>

            {PurchaseContractCheck && (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Purchase contract No.</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <select
                      className="input_border formInput "
                      id="purchasecontract"
                      // onChange={handelChange}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setcontractId(e.target.value);
                        setpurchasecontractdetails(
                          JSON.parse(
                            e.target.options[e.target.selectedIndex].dataset.idx
                          )
                        );
                      }}
                    >
                      <option>Select</option>
                      {purchasecontracts.length > 0 &&
                        purchasecontracts?.map((x, idx) => {
                          console.log(x);
                          return (
                            <option
                              value={x.PurchaseContractId}
                              data-idx={JSON.stringify(
                                x.PurchaseContractDetails
                              )}
                            >
                              PCont-{x.PurchaseContSerialNumber}
                            </option>
                          );
                        })}
                    </select>
                    {/* <input
                    type="text"
                    id="purchasecontract"
                    onChange={onTextChange1}
                    autoComplete="off"
                    className="input_border formInput "
                  ></input>
                  <span id="demo1"></span> */}
                  </span>
                </div>
              </>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Remarks</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <textarea
                  style={{ height: "40px" }}
                  id="remarks"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>
            {!PurchaseContractCheck && (
              <>
                <div className="col-2 mt-3 text-right"></div>
                <div className="col-4 mt-3">
                  {showtaxoptions ? (
                    <div className="row">
                      <div className="col-6">
                        <input
                          onClick={(e) => {
                            settaxavailable(e.target.value);
                            setsaletax(17);
                          }}
                          type="radio"
                          id="wtax"
                          name="taxradio"
                          value="with tax"
                          defaultChecked
                        />{" "}
                        <label for="wtax">With Tax</label>
                      </div>
                      <div className="col-6">
                        <input
                          onClick={(e) => {
                            settaxavailable(e.target.value);
                            setsaletax(0);
                          }}
                          type="radio"
                          id="wotax"
                          name="taxradio"
                          value="without tax"
                        />{" "}
                        <label for="wotax">Without Tax</label>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-12  mt-3 ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr onClick={() => console.log(tablerow)}>
                  <th>Sr.</th>
                  <th>Item</th>
                  <th>Brand</th>
                  <th>No. of Bags</th>
                  {/* <th>Avg. Weight(KGs)</th> */}
                  <th>Rate</th>
                  {taxavailablebool ? <th>Amount</th> : <></>}
                  {taxavailablebool ? <th>Sales Tax</th> : <></>}
                  <th>Net Amount</th>
                  <th onClick={handleaddrow}>
                    <IoMdAddCircle
                      id="addbutton"
                      className="drow"
                      size="2em"
                      color="black"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {tablerow.map((x, idx) => (
                  <tr>
                    <th>{idx + 1}</th>
                    <th>
                      <input
                        key={`${x.itemname}${idx}`}
                        id={`itemname${idx}`}
                        onChange={(e) => {
                          OnItemChange(e, idx);
                        }}
                        defaultValue={x.itemname}
                        className="form-control"
                        type="text"
                        list={`yarn${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`yarn${idx}`}>
                        {yarndata?.map((v, idx) => {
                          return (
                            <option
                              attributeId={`${v.ProductType} - (${v.Composition})`}
                            >
                              {v.ProductType} - {v.Composition}
                            </option>
                          );
                        })}
                      </datalist>
                    </th>
                    <th>
                      <input
                        key={`${x.brand}${idx}`}
                        id={`brandname${idx}`}
                        onChange={(e) => {
                          OnBrandChange(e, idx);
                          console.log(tablerow);
                        }}
                        defaultValue={x.brand}
                        className="form-control"
                        type="text"
                        list={`brand${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`brand${idx}`}>
                        {brandsdata?.map((v, idx) => {
                          return (
                            <option attributeId={v?.BrandName}>
                              {v.BrandName}
                            </option>
                          );
                        })}
                      </datalist>
                    </th>

                    <th>
                      <input
                        onChange={(e) => {
                          quantitychange(e, idx);
                        }}
                        id={`qty${idx}`}
                        key={`${x.qty}${idx}`}
                        defaultValue={x.qty}
                        className="form-control"
                      ></input>
                    </th>
                    {/* <th>
                      <input
                        onChange={(e) => {
                          avgweightchange(e, idx);
                        }}
                        id={`avgweight${idx}`}
                        key={`${x.avgweight}${idx}`}
                        defaultValue={x.avgweight}
                        className="form-control"
                      ></input>
                    </th>*/}
                    <th>
                      <input
                        onChange={(e) => {
                          ratechange(e, idx);
                        }}
                        id={`rate${idx}`}
                        key={`${x.rate}${idx}`}
                        defaultValue={x.rate}
                        className="form-control"
                      ></input>
                    </th>
                    {taxavailablebool ? (
                      <>
                        <th>
                          <input
                            disabled
                            onChange={(e) => {
                              amountchange(e, idx);
                            }}
                            id={`total${idx}`}
                            key={`${x.amount}${idx}`}
                            defaultValue={x.amount}
                            className="form-control"
                          ></input>
                        </th>
                        <th>
                          <input
                            disabled
                            id={`taxAmount${idx}`}
                            key={`${x.taxAmount}${idx}`}
                            value={x.taxAmount}
                            className="form-control"
                          ></input>
                        </th>
                      </>
                    ) : (
                      <></>
                    )}

                    <th>
                      <input
                        disabled
                        onChange={(e) => {
                          netamountchange(e, idx);
                        }}
                        id={`netamount${idx}`}
                        key={`${x.netamount}${idx}`}
                        value={x.netamount}
                        className="form-control"
                      ></input>
                    </th>
                    <th>
                      <span onClick={() => handledeleterow(idx)}>
                        <img
                          alt="AddRow"
                          style={{
                            cursor: "pointer",
                            width: "19px",
                            marginTop: "8px",
                          }}
                          src={Delete}
                        ></img>
                      </span>
                    </th>
                  </tr>
                ))}
                {/* <tr >
                    <th></th>
                    <th>
                    <input
                id="totalqty"
                className="form-control"
                disabled
              ></input>
                    
                    </th>
               
                  </tr> */}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-3 mt-1 text-right">Qty:</div>
            <div className="col-2 mt-1">
              <input
                id="totalqty"
                className="input_border formInput"
                disabled
              ></input>
            </div>
            <div className="col-2 mt-1 text-right"></div>

            <div className="col-3 mt-1 text-right">Total:</div>
            <div className="col-2 mt-1">
              <input
                id="totalamount"
                className="input_border formInput"
                disabled
              ></input>
            </div>

            {/* <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Discount:</div>
              <div className="col-3 mt-1">
                <input
                  type="number"
                  id="discountamount"
                  className="input_border formInput"
                  onChange={finalcalculationfunction}
                  defaultValue={0}
                ></input>
              </div>
  
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Freight:</div>
              <div className="col-3 mt-1">
                <input
                  type="number"
                  id="freightamount"
                  className="input_border formInput"
                  onChange={finalcalculationfunction}
                  defaultValue={0}
                ></input>
              </div>
  
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Others:</div>
              <div className="col-3 mt-1">
                <input
                  type="number"
                  id="othersamount"
                  className="input_border formInput"
                  onChange={finalcalculationfunction}
                  defaultValue={0}
                ></input>
              </div>
  
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Net Total:</div>
              <div className="col-3 mt-1">
                <input
                  disabled
                  id="nettotalamount"
                  className="input_border formInput"
                  value={nettotalstate}
                ></input>
              </div> */}
          </div>

          <div className="col-12 mt-5  text-right">
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoicenew}
                className="tabletopbutton  text-right greenbutton"
              >
                {"Save & New"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoiceprint}
                className="tabletopbutton ml-2 text-right bluebutton"
              >
                {"Save & Print"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoiceclose}
                className="tabletopbutton ml-2 text-right redbutton"
              >
                {"Save & Close"}
              </button>
            </span>
            <span>
              <button
                id="crvCancel"
                className="tabletopbutton ml-2 text-right "
                onClick={props.formCancel}
              >
                Cancel
              </button>
            </span>
            <span className="ml-2 float-right">
              <div className="loaderclass">
                {" "}
                {loader1 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
