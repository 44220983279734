import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

export const SaleTaxMain = (props) => {
  const [SaleTax, setSaleTax] = useState(0);
  const [loader1, setloader1] = useState(false);
  const [EditedSaleTax, setEditedSaleTax] = useState(0);

  //Table Data fiter
  const getSaleTax = () => {
    setloader1(true);
    axios({
      url: window.$url + `api/Setting/GetAllTax`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "get",
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        setSaleTax(result.data.Tax[0]);
        setEditedSaleTax(result.data.Tax[0]?.TaxAmount);
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };

  const updateSaleTax = () => {
    setloader1(true);
    let data = { TaxId: SaleTax?.TaxId, TaxAmount: EditedSaleTax };
    axios({
      url: window.$url + `api/Setting/UpdateTax`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        document.getElementById("saletaxmodal").style.display = "none";
        getSaleTax();
        alert(result.data.Message);
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };
  useEffect(() => {
    getSaleTax();
  }, []);

  return (
    <>
      <div id="saletaxmodal" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close"
            onClick={() => {
              document.getElementById("saletaxmodal").style.display = "none";
            }}
          >
            &times;
          </span>
          <div className="row">
            <div className="col-2">
              <h5>Sale Tax</h5>
            </div>
            <div className="col-7">
              <input
                placeholder="saletax"
                onChange={(e) => {
                  setEditedSaleTax(e.target.value);
                }}
                value={EditedSaleTax}
                type="number"
                id="saletax"
                className="input_border formInput "
              ></input>
            </div>
            <div className="col-3">
              <button
                id="crvSave"
                onClick={updateSaleTax}
                className="tabletopbutton greenbutton"
              >
                {"Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" tableContainer2 overflow-auto pageheading Sitka mt-3"
        style={{ height: "95%" }}
      >
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-3">
            <div className="col-2 text-right">
              <span className="txt"> Sale Tax</span>
            </div>
            <div className="col-4  text-left">
              <div className="row">
                <div className="col-8 pr-0">
                  <input
                    placeholder="saletax"
                    disabled
                    value={SaleTax?.TaxAmount}
                    type="number"
                    id="saletax"
                    className="input_border formInput "
                  ></input>
                </div>
              </div>
            </div>
            <div className="col-2 text-right">
              <span className="txt">
                {" "}
                <button
                  id="crvSave"
                  onClick={() => {
                    document.getElementById("saletaxmodal").style.display =
                      "block";
                  }}
                  className="tabletopbutton  text-right greenbutton"
                >
                  {"Edit"}
                </button>
              </span>
            </div>
          </div>

          <div className="col-12 mt-5  text-right">
            <span></span>

            {/* <span>
              <button
                id="crvSave"
                onClick={addpurchaseinvoiceclose}
                className="tabletopbutton ml-2 text-right redbutton"
              >
                {"Save & Close"}
              </button>
            </span> */}
            {/* <span>
              <button
                id="crvCancel"
                className="tabletopbutton ml-2 text-right "
                onClick={props.formCancel}
              >
                Cancel
              </button>
            </span> */}
            <span className="ml-2 float-right">
              <div className="loaderclass">
                {" "}
                {loader1 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
