import React, { useState, useRef } from "react";
import axios from "axios";

import "../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";

import Balancesheet  from "./BalanceSheetPrint";

/* voucher start */
// cash payment page



 const BalanceSheetMain = () => {
  const [loader1, setloader1] = useState(false);
  const [data, setdata] = useState();
  const [from, setfrom] = useState(moment().format("YYYY-07-01"));
  const [to, setto] = useState(moment().format("YYYY-MM-DD"));
  const [liabilities, setliabilities] = useState();
  const [assets, setassets] = useState();
  const [equity, setequity] = useState();
  const [assetsbalance, setassetsbalance] = useState(0);
  const [liabilitiesbalance, setliabilitiesbalance] = useState(0);
  const [equitybalance, setequitybalance] = useState(0);

  const apicashbook = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/BalanceSheet?From=${from}&To=${to}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("balance sheet", result);
        setloader1(false);
        setdata(result.data);
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div hidden>
        <Balancesheet
          ref={componentRef}
          liabilities={liabilities}
          assets={assets}
          equity={equity}
          liabilitiesbalance={liabilitiesbalance}
          assetsbalance={assetsbalance}
          equitybalance={equitybalance}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row mt-2">
            <div className="col-4 text-right">
              From:
              <span>
                {" "}
                <input
                  type="date"
                  className="datefield"
                  id="cashfrom"
                  onChange={(e) => setfrom(e.target.value)}
                  defaultValue={moment().format("YYYY-07-01")}
                />
              </span>
            </div>
            <div className="col-4 text-right">
              To:
              <span>
                {" "}
                <input
                  type="date"
                  className="datefield"
                  id="cashto"
                  onChange={(e) => setto(e.target.value)}
                  defaultValue={moment().format("YYYY-MM-DD")}
                />
              </span>
            </div>
            <div className="col-4 text-right pr-5">
              <span>
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={() => {
                    apicashbook();
                  }}
                >
                  GO
                </button>
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>

          <hr className="mt-3"></hr>
          <div className="col-12">
            <div className="row mt-5">
              <div className="col-6 green_txt">
                <h5>
                  <b>ASSETS</b>
                </h5>
              </div>
              <div className="col-6 red_txt">
                <h5>
                  <b>CAPITAL AND LIABILITIES</b>
                </h5>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 green_txt">
                <h5>
                  <b>
                    <u>FIXED ASSETS</u>
                  </b>
                </h5>
              </div>
              <div className="col-6 red_txt">
                <h5>
                  <b>
                    <u>OWNER'S EQUITY</u>
                  </b>
                </h5>
              </div>
            </div>

            <div className="row mt-2">
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* FIXED ASSETS */}
                    <tbody>
                      {data !== undefined && data.FixedAssets !== undefined
                        ? data.FixedAssets.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}
                      {data !== undefined &&
                      data.DutiesAndTaxesNegativeSum !== 0 ? (
                        <tr>
                          <td className="nobordertop">Duties and Taxes Paid</td>

                          <td className="nobordertop text-right">
                            {-data.DutiesAndTaxesNegativeSum}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.FixedAssetSum !== undefined
                            ? (
                                -data.DutiesAndTaxesNegativeSum +
                                data.FixedAssetSum
                              ).toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* CAPITAL TABLE */}
                    <tbody>
                      {data !== undefined && data.CapitalList !== undefined
                        ? data.CapitalList.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.CapitalListSum !== undefined
                            ? data.CapitalListSum.toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* LESS ACCUMULATED DEPRECIATION */}
                    <tbody>
                      <tr>
                        <td className="nobordertop">
                          Less Accumulated Depreciation
                        </td>

                        <td className="nobordertop text-right"></td>
                      </tr>
                      {data !== undefined &&
                      data.AccumulatedDepreciation !== undefined
                        ? data.AccumulatedDepreciation.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.DepreciationSum !== undefined
                            ? data.DepreciationSum.toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* DRAWING LIST TABLE */}
                    <tbody>
                      <tr>
                        <td className="nobordertop">Less Drawings</td>

                        <td className="nobordertop text-right"></td>
                      </tr>
                      {data !== undefined && data.DrawingList !== undefined
                        ? data.DrawingList.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.DrawingListSum !== undefined
                            ? data.DrawingListSum.toLocaleString()
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td className="nobordertop">
                          <b>RETAINED EARNINGS</b>
                        </td>
                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.RetainedEarning !== undefined
                            ? data.RetainedEarning.toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div class=" col-6"></div>
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* OTHER LIST TABLE */}
                    <tbody>
                      {data !== undefined && data.OtherEquityList !== undefined
                        ? data.OtherEquityList.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.OtherEquitiesListSum !== undefined
                            ? (
                                data.OtherEquitiesListSum + data.RetainedEarning
                              ).toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 ">
                <div className="row">
                  <div className="col-6 green_txt">
                    <h5>
                      <b>NET FIXED ASSETS</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u>
                          {data !== undefined &&
                          data.FixedAssetSum !== undefined &&
                          data.DepreciationSum !== undefined
                            ? (
                                -data.DutiesAndTaxesNegativeSum +
                                data.FixedAssetSum -
                                data.DepreciationSum
                              ).toLocaleString()
                            : null}
                        </u>
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 ">
                <div className="row">
                  <div className="col-6 red_txt">
                    <h5>
                      <b>TOTAL EQUITY</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u>
                          {data !== undefined &&
                          data.OtherEquitiesListSum !== undefined &&
                          data.DrawingListSum !== undefined &&
                          data.CapitalListSum !== undefined
                            ? (
                                data.OtherEquitiesListSum +
                                data.CapitalListSum +
                                data.RetainedEarning +
                                data.DrawingListSum
                              ).toLocaleString()
                            : null}
                        </u>
                        {console.log(
                          data !== undefined &&
                            data.OtherEquitiesListSum !== undefined &&
                            data.DrawingListSum !== undefined &&
                            data.CapitalListSum !== undefined
                            ? (
                                data.OtherEquitiesListSum +
                                data.CapitalListSum +
                                data.RetainedEarning +
                                data.DrawingListSum
                              ).toLocaleString()
                            : null
                        )}
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 green_txt">
                <h5>
                  <b>
                    <u>CURRENT ASSETS</u>
                  </b>
                </h5>
              </div>
              <div className="col-6 red_txt">
                <h5>
                  <b>
                    <u>CURRENT LIABILITIES</u>
                  </b>
                </h5>
              </div>
            </div>

            <div className="row mt-2">
              <div class=" col-6">
                <div class="table-responsive col-6">
                  <table class="table">
                    <thead></thead>
                    {/* CURRENT ASSETS */}
                    <tbody>
                      {data !== undefined && data.CurrentAssets !== undefined
                        ? data.CurrentAssets.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      <tr>
                        <td className="nobordertop">
                          <b>TOTAL</b>
                        </td>

                        <td className="bordertop text-right">
                          {data !== undefined &&
                          data.CurrentAssetsSum !== undefined
                            ? data.CurrentAssetsSum.toLocaleString()
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class=" col-6">
                <div className="row">
                  <div class="table-responsive col-6">
                    <table class="table">
                      <thead></thead>
                      <tbody>
                        {/* CURRENT LIABILITIES */}
                        {data !== undefined &&
                        data.CurrentLiabilities !== undefined
                          ? data.CurrentLiabilities.map((x) => (
                              <tr>
                                <td className="nobordertop ">{x.Name}</td>
                                <td className="nobordertop  text-right">
                                  {x.Amount !== null
                                    ? x.Amount.toLocaleString()
                                    : 0}
                                </td>
                              </tr>
                            ))
                          : null}
                        {data !== undefined &&
                        data.DutiesAndTaxesPositiveSum > 0 ? (
                          <tr>
                            <td className="nobordertop">
                              Duties and Taxes Payable
                            </td>

                            <td className="nobordertop text-right">
                              {data.DutiesAndTaxesPositiveSum}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td className="nobordertop">
                            <b>TOTAL</b>
                          </td>

                          <td className="bordertop text-right">
                            {data !== undefined &&
                            data.CurrentLiabilitiesSum !== undefined
                              ? (
                                  data.DutiesAndTaxesPositiveSum +
                                  data.CurrentLiabilitiesSum
                                ).toLocaleString()
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 red_txt">
                    <h5>
                      <b>EXPENSES PAYABLE</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u></u>
                      </b>
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div class="table-responsive col-6">
                    <table class="table">
                      <thead></thead>

                      <tbody>
                        {data !== undefined &&
                        data.LongTermLiabilities !== undefined
                          ? data.LongTermLiabilities.map((x) => (
                              <tr>
                                <td className="nobordertop">{x.Name}</td>

                                <td className="nobordertop text-right">
                                  {x.Amount.toLocaleString()}
                                </td>
                              </tr>
                            ))
                          : null}

                        <tr>
                          <td className="nobordertop">
                            <b>TOTAL</b>
                          </td>

                          <td className="bordertop text-right">
                            {data !== undefined &&
                            data.LongTermLiabilitiesSum !== undefined
                              ? data.LongTermLiabilitiesSum.toLocaleString()
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u></u>
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6 "></div>
              <div className="col-6 ">
                <div className="row">
                  <div className="col-6 red_txt">
                    <h5>
                      <b>TOTAL LIABILITIES</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u>
                          {data !== undefined &&
                          data.CurrentLiabilitiesSum !== undefined &&
                          data.LongTermLiabilitiesSum !== undefined
                            ? (
                                data.CurrentLiabilitiesSum +
                                data.LongTermLiabilitiesSum
                              ).toLocaleString()
                            : null}
                        </u>
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 ">
                <div className="row">
                  <div className="col-6 green_txt">
                    <h5>
                      <b>TOTAL ASSETS</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u>
                          {data !== undefined &&
                          data.FixedAssetSum !== undefined &&
                          data.DepreciationSum !== undefined &&
                          data.CurrentAssetsSum !== undefined
                            ? (
                                data.FixedAssetSum +
                                data.DepreciationSum +
                                data.CurrentAssetsSum
                              ).toLocaleString()
                            : null}
                        </u>
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-6 ">
                <div className="row">
                  <div className="col-6 red_txt">
                    <h5>
                      <b>LIABILITIES + EQUITY</b>
                    </h5>
                  </div>
                  <div className="col-6  text-right">
                    <h5>
                      <b>
                        <u>
                          {data !== undefined &&
                          data.CurrentLiabilitiesSum !== undefined &&
                          data.LongTermLiabilitiesSum !== undefined &&
                          data.OtherEquitiesListSum !== undefined &&
                          data.DrawingListSum !== undefined &&
                          data.CapitalListSum !== undefined
                            ? (
                                data.CurrentLiabilitiesSum +
                                data.LongTermLiabilitiesSum +
                                (data.OtherEquitiesListSum +
                                  data.DrawingListSum +
                                  data.CapitalListSum +
                                  data.RetainedEarning)
                              ).toLocaleString()
                            : null}
                        </u>
                      </b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BalanceSheetMain
