import React, { useState } from "react";
import "../../../Bootstrap/haroon.css";
import { SaleInvoiceForm } from "./SaleInvoiceForm";
import { SaleInvoiceTable } from "./SaleInvoiceTable";

export const SaleInvoiceMain = () => {
    const changecomponent = () => {
      setComponentState(<SaleInvoiceForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <SaleInvoiceTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <SaleInvoiceTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };