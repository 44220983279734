import React, { useState, useRef } from "react";
import axios from "axios";
import "../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

import { useEffect } from "react";
import TrialBalancePrint from "../Prints/TrailBalancePrint";

const TrialBalance = () => {
  const [allTrailBalances, setallTrailBalances] = useState([]);
  const [totalCredit, settotalCredit] = useState("");
  const [totalDebit, settotalDebit] = useState("");
  const [loader1, setloader1] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const apicashbook = () => {
    settotalDebit("");
    settotalCredit("");
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/TrialBalanceNew`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("bankbook", result);
        if (result.data) {
          setallTrailBalances(result?.data?.trials);
          settotalDebit(result?.data?.td);
          settotalCredit(result?.data?.tc);

          setloader1(false);
        } else {
          toast.info(result.data.message);
          setloader1(false);
        }
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    apicashbook();
  }, []);

  return (
    <>
      <div hidden>
        <TrialBalancePrint
          ref={componentRef}
          allaccounts={allTrailBalances}
          debitTotal={totalDebit}
          creditTotal={totalCredit}
          Total={totalDebit - totalCredit}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="col-12 mt-2">
            <div className="row mt-1">
              <div className="col-3 text-left">
                <b>Debit= {totalDebit.toLocaleString()}</b>
              </div>
              <div className="col-3 text-center">
                <b>Credit= {totalCredit.toLocaleString()}</b>
              </div>
              <div className="col-3 text-right">
                <b>Balance= {(totalDebit - totalCredit).toLocaleString()}</b>
              </div>

              <div className="col-3 text-right">
                <span className="ml-2 float-right">
                  <button
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span>
                <span className="loaderclass float-right">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </span>
              </div>
            </div>

            <hr className="mt-2"></hr>
            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table table-hover">
                  <thead>
                    <tr className="">
                      {/* <th>Date</th> */}
                      <th>Particulars</th>
                      <th>Account Category</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allTrailBalances.map((x) => {
                      console.log(x);
                      return (
                        <tr>
                          <td>{x.particular}</td>
                          <td>{x.accountCategory}</td>
                          <td>{x.debit.toLocaleString()}</td>
                          <td>{x.credit.toLocaleString()}</td>
                          <td>{x.balance.toLocaleString()}</td>
                          <td></td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td></td>
                      <td>
                        <b>Total: </b>
                      </td>
                      <td>
                        <b>
                          <b>{totalDebit.toLocaleString()}</b>
                        </b>
                      </td>
                      <td>
                        <b>
                          <b>{totalCredit.toLocaleString()}</b>
                        </b>
                      </td>
                      <td>
                        <b>
                          <b>{(totalDebit - totalCredit).toLocaleString()}</b>
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrialBalance;
