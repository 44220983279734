import React, { useState, useEffect } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";


import CashPaymentMain from "../VouchersNew/CashPayment/CashPaymentMain";
import CashReceiptMain from "../VouchersNew/CashReceipt/CashReceiptMain";
import BankReceiptMain from "../VouchersNew/BankReceipt/BankReceiptMain";
import BankPaymentMain from "../VouchersNew/BankPayment/BankPaymentMain";
import GeneralJournalMain from "../VouchersNew/GeneralJournal/GeneralJournalMain";
import GeneralLedgerMain from "../VouchersNew/GeneralLedger/GeneralLedgerMain";
import JournalVoucherMain from "../VouchersNew/JournalVoucher/JournalVoucherMain";
// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button2 ${active ? "button2Clicked" : ""} ${
        count === "Cash Receipt"
        ? "blue"
        : count === "Cash Payment"
        ? "red"
        : count === "Bank Receipt"
        ? "seaGreen"
        : count === "Bank Payment"
        ? "darkred"
        : count === "Journal"
        ? "red"
        : count === "General Ledger"
        ? "blue"
        : count === "General Journal"
        ? "seaGreen"
        : count
        }`}
    >
      {count}
    </button>
  );
};

//SECONDARY PRODUCTION BUTTONS COMPONENTS
const Btngroup2 = ({ active, count, onClick, styling }) => {
  return (
    <button
      onClick={onClick}
      className={
        active ? `secondarybuttonClicked ${styling}` : "secondarybutton"
      }
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Accounts = () => {
  const [chosen, setChosen] = useState({
    component: 
    // <CashReceiptPageMain />
    <CashReceiptMain/>,
  });
  // Cash vouchers
  const cashReceipt = [
    {
      component: 
      // <CashReceiptPageMain />
      <CashReceiptMain/>,
    },
  ];
  // Banks
  const cashPayment = [
    {
      component: 
      <CashPaymentMain/>
      ,
    },
  ];
  // journal
  const bankReceipt = [
    {
      component: 
      // <BankReceiptPageMain />
      <BankReceiptMain/>
      ,
    },
  ];
  // Land vouchers
  const bankPayment = [
    {
      component: 
      // <BankPaymentPageMain />
      <BankPaymentMain/>
      ,
    },
    
  ];
  // Unit vouchers
  const Journal = [
    {
      component: <JournalVoucherMain />,
    },
  ];

  const one = [
    {
      component: <GeneralJournalMain />,
    },
  ];
  const two = [
    {
      component: <GeneralLedgerMain />,
    },
  ];
  
  const button = [
    { button1: "Cash Receipt", button2: cashReceipt },
    { button1: "Cash Payment", button2: cashPayment },
    { button1: "Bank Receipt",button2: bankReceipt},
    { button1: "Bank Payment", button2: bankPayment },
    { button1: "Journal", button2: Journal },
    { button1: "General Ledger", button2: two },
    { button1: "General Journal", button2: one },
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Cash Receipt",
    button2:cashReceipt,
  });
  return (
    <>
      <div>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
              setChosen(b.button2[0]);
            }}
          />
        ))}
        <br></br>
        {/* MAPPING SECONDARY BUTTONS ON THE PRODUCTION PAGE */}
        {chosen2.button2.map((b) =>
          b.button !== undefined ? (
            <Btngroup2
              key={b.button}
              count={b.button}
              styling={b.underline}
              active={b.button === chosen.button}
              onClick={() => setChosen(b)}
            />
          ) : null
        )}
      </div>
      {/*Work Order BUTTONS*/}
      {chosen2.button2.map((b) =>
        b.button === chosen.button ? b.component : null
      )}
    </>
  );
};

export default Accounts;
