import React, { lazy, Suspense, useState } from "react";
import LazyLoader from "../../LazyLoader/LazyLoader";
const BankAccountsForm = lazy(()=> import ("./BankAccountsForm"))
const BankAccountsTable = lazy(()=> import ("./BankAccountsTable"))


const BankAccountsMain = () => {
  const [showForm, setshowForm] = useState(false);

  return (
    <Suspense fallback={<LazyLoader/>}>
      {showForm ? (
        <BankAccountsForm setshowForm={setshowForm} showForm={showForm} />
      ) : (
        <BankAccountsTable setshowForm={setshowForm} showForm={showForm} />
      )}
    </Suspense>
  );
};

export default BankAccountsMain;
