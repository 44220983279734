import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { TiPrinter } from "react-icons/ti";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { Cashpaymentallprint, Cashpaymentprint } from "./CashPaymentPrint";
import { getallpaymentvouchers } from "../../actions/getallpaymentvouchers";
export const CashPaymentTable = (props) => {
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [Voucherlist, setVoucherlist] = useState([]);
  const [printdata, setprintdata] = useState();
  const [loader1, setloader1] = useState(false);

  var today = new Date();
  var todaydate = today.getDate();
  if (todaydate.toString().length === 1) {
    todaydate = "0" + todaydate.toString();
  }
  var currentmonth = today.getMonth();
  if (currentmonth.toString().length === 1) {
    currentmonth = "0" + (today.getMonth() + 1).toString();
  }
  var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;

  function getAllpaymentvouchers() {
    setloader1(true);
    axios({
      url:
        window.$url +
        `/PaymentVoucher/GetAllPaymentVouchers?projectid=${localStorage.getItem(
          "ProjectId"
        )}&type=Cash`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "get",
    }).then(
      (res) => {
        console.log("Voucher cash payment", res);

        setVoucherlist(res.data);

        setsuggestionbuyersdata(res.data);

        setloader1(false);
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  }

  let dispatch = useDispatch();
  let paymentvouchers = useSelector(
    (state) => state.GetAllPaymentVouchersDataReducer
  );

  useEffect(() => {
    dispatch(getallpaymentvouchers());
  }, [dispatch]);

  useEffect(() => {
    if (paymentvouchers !== undefined) {
      setVoucherlist(paymentvouchers.data);
      setsuggestionbuyersdata(paymentvouchers.data);

      let purchaseinvoicelist = [];
      paymentvouchers.data.forEach((x, idx) => {
        x.PurchaseInvoice.forEach((y, idy) => {
          purchaseinvoicelist.push(y.PurchaseInvoiceId);
        });

        paymentvouchers.data[idx].purchaseinvoicelist =
          purchaseinvoicelist.toString();
        purchaseinvoicelist = [];
      });
    }
  }, [paymentvouchers]);

  //Table Data fiter
  var suggestionlist = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setVoucherlist(suggestionbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.Account.Accounttitle));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setVoucherlist([]);
      } else {
        setVoucherlist(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setVoucherlist(suggestionbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.SecondaryKey));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setVoucherlist([]);
      } else {
        setVoucherlist(suggestionlist);
      }
    }
  };
  var i = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printdatafunction = (val) => {
    setprintdata(val);
  };
  useEffect(() => {
    if (printdata) {
      handlePrint();
    }
  }, [printdata]);

  console.log(paymentvouchers);

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  return (
    <>
      <div hidden>
        <Cashpaymentprint
          ref={componentRef}
          date={date}
          printdata={printdata}
        />
        <Cashpaymentallprint
          ref={componentRef1}
          date={date}
          Voucherlist={Voucherlist}
        />
      </div>

      <div className="SettingContainer1 mt-3 Sitka datatablecss">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Search By Account"
                  className="input_border"
                ></input>
                <input
                  type="text"
                  autoComplete="off"
                  onChange={onTextChange2}
                  placeholder="Search By Voucher No."
                  className="input_border ml-2"
                ></input>
              </span>
              <span className="ml-2">
                <button
                  id="callapibutn"
                  onClick={handlePrint1}
                  className="tabletopbutton"
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <button
                  onClick={props.changecomponent}
                  className="tabletopbutton1 text-right"
                >
                  New
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {paymentvouchers.loading && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class=" ">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Voucher Number
                  </th>
                  <th scope="col" className="th1">
                    Invoice Number
                  </th>
                  <th scope="col" className="th1">
                    Account
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                  <th scrop="col">Print</th>
                </tr>
              </thead>
              <tbody>
                {Voucherlist !== undefined
                  ? Voucherlist.map((x, idx) => (
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td class="trpointer">
                          {x.Voucherdate.substring(0, 10)}
                        </td>
                        <td class="trpointer">{x.SecondaryKey}</td>
                        <td class="trpointer">
                          {x.PurchaseInvoice.map((inv) => {
                            return `PInv-${inv?.PurchaseInvSerialNo}`;
                          }).toString()}
                        </td>
                        <td class="trpointer">{x.Account.Accounttitle}</td>
                        <td class="trpointer">{x.Narration}</td>
                        <td class="trpointer">{x.Credit.toLocaleString()}</td>
                        <td
                          onClick={() => {
                            printdatafunction(x);
                            // handlePrint();
                          }}
                          style={{ paddingTop: "0px" }}
                        >
                          <TiPrinter size="1.2em" />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {/* <div className="row  mt-3 pb-2">
              <div className="col-12 text-right">
                <span>
                  <button
                    onClick={() => {
                      getNextdata(firstPage);
                    }}
                    className="tabletopbutton text-right ml-2 mr-2"
                  >
                    First
                  </button>
                </span>
                <span>
                  <button
                    onClick={() => {
                      getNextdata(nextPage);
                    }}
                    className="tabletopbutton  text-right mr-2"
                  >
                    Next
                  </button>
                </span>
                <span>
                  <button
                    onClick={() => {
                      getNextdata(previousPage);
                    }}
                    className="tabletopbutton  text-right mr-2"
                  >
                    Previous
                  </button>
                </span>
                <span>
                  <button
                    onClick={() => {
                      getNextdata(lastPage);
                    }}
                    className="tabletopbutton  text-right mr-2"
                  >
                    Last
                  </button>
                </span>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
