import React, { Component } from "react";
import logo from "../../Images/seasonFour.jpg";
class CashFlowPrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div className="row m-3 p-0" style={{ border: "5px solid darkgrey" }}>
          <div className="col-12 text-center mt-2">
            <h4 className="headingscss">
              <b>
                <u>Cash Flow</u>
              </b>
            </h4>

            <hr style={{ backgroundColor: "black" }} />
            <div className="row mt-2">
              <div className="col-6 text-left">
                <h5>CASH IN</h5>
              </div>
              <div className="col-6 text-right">
                <h5>CASH OUT</h5>
              </div>
            </div>
            <div className="row mt-3">
              <div class="table-responsive col-6">
                <table class="table">
                  <thead>
                    <tr>
                      <th>MAIN ACC.</th>
                      <th>OPPOSITE ACC.</th>
                      <th>DEBIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data !== undefined
                      ? this.props.data.map((x) =>
                          x.DebitAmount !== 0 ? (
                            <tr>
                              <td>{x.MainAccount}</td>
                              <td>{x.OppositeAccount}</td>
                              <td>{x.DebitAmount}</td>
                            </tr>
                          ) : null
                        )
                      : null}
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Debit:</b>
                      </td>
                      <td>{this.props.debit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive col-6">
                <table class="table">
                  <thead>
                    <tr>
                      <th>MAIN ACC.</th>
                      <th>OPPOSITE ACC.</th>
                      <th>CREDIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data !== undefined
                      ? this.props.data.map((x) =>
                          x.CreditAmount !== 0 ? (
                            <tr>
                              <td>{x.MainAccount}</td>
                              <td>{x.OppositeAccount}</td>
                              <td>{x.CreditAmount}</td>
                            </tr>
                          ) : null
                        )
                      : null}
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Credit:</b>
                      </td>
                      <td>{this.props.credit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CashFlowPrint;
