import React, { Component } from "react";
import logo from "../Images/seasonFour.jpg";

export class Purchaseinvoice extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Purchase Invoice</u>
              </b>
            </h3>
            <div className="row">
              <div className="col-6 text-left mt-2">
                <b>Date:</b>
              </div>
              <div className="col-6 text-right mt-2">
                <b>Voucher No.</b>
              </div>

              <div className="col-6 text-left mt-3">
                <b>Vendor Name:</b>
              </div>
              <div className="col-6 text-right mt-3">
                <b>Purchase Contract No.</b>
              </div>
            </div>
            <hr style={{ backgroundColor: "black", marginTop: "20px" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead style={{ backgroundColor: "lightgray" }}>
                    <tr>
                      <th>Sr.</th>
                      <th>Item</th>

                      <th>Brand</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Sales Tax</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Total:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>

              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Discount:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>

              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Freight:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>

              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Others:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>

              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Net Total:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export class Purchasecontract extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Purchase Contract</u>
              </b>
            </h3>
            <div className="row">
              <div className="col-6 text-left mt-2">
                <b>Date:</b>
              </div>
              <div className="col-6 text-right mt-2">
                <b>Voucher No.</b>
              </div>

              <div className="col-6 text-left mt-3">
                <b>Vendor Name:</b>
              </div>
              <div className="col-6 text-right mt-3">
                <b>WITH TAX</b>
              </div>
            </div>
            <hr style={{ backgroundColor: "black", marginTop: "20px" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead style={{ backgroundColor: "lightgray" }}>
                    <tr>
                      <th>Sr.</th>
                      <th>Item</th>

                      <th>Brand</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Sales Tax</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Total:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Salecontract extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Sale Contract</u>
              </b>
            </h3>
            <div className="row">
              <div className="col-6 text-left mt-2">
                <b>Date:</b>
              </div>
              <div className="col-6 text-right mt-2">
                <b>Voucher No.</b>
              </div>

              <div className="col-6 text-left mt-3">
                <b>Customer Name:</b>
              </div>
              <div className="col-6 text-right mt-3">
                <b>WITH TAX</b>
              </div>
            </div>
            <hr style={{ backgroundColor: "black", marginTop: "20px" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead style={{ backgroundColor: "lightgray" }}>
                    <tr>
                      <th>Sr.</th>
                      <th>Item</th>

                      <th>Brand</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Sales Tax</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1"></div>
              <div className="col-3 mt-1 text-right">Total:</div>
              <div className="col-3 mt-1">
                <input
                  id="journalNarration"
                  className="input_border formInput"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}





export class CommissionAgentlist extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Commission Agents</u>
              </b>
            </h3>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th className="th1">Name</th>
                      <th className="th1">Cell No</th>
                      <th className="th1">Email</th>
                      <th className="th1">CNIC</th>
                      <th className="th1">City</th>
                      <th className="th1">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.sellersdata !== undefined
                      ? this.props.sellersdata.map((x, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{x.CommisionAgentName}</td>

                            <td>{x.CellNumber}</td>
                            <td>{x.Email}</td>
                            <td>{x.AgentCnic}</td>
                            <td>{x.City}</td>
                            <td>{x.Address}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export class Bankreceiptallprint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Bank Receipt</u>
              </b>
            </h3>
            <br />
            <h5>{this.props.date !== undefined ? this.props.date : null}</h5>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.No
                      </th>
                      <th scope="col" className="th1">
                        Date
                      </th>
                      <th scope="col" className="th1">
                        Voucher Number
                      </th>
                      <th scope="col" className="th1">
                        Account
                      </th>
                      <th scope="col" className="th1">
                        Bank
                      </th>
                      <th scope="col" className="th1">
                        Cheque
                      </th>
                      <th scope="col" className="th1">
                        Narration
                      </th>
                      <th scope="col" className="th1">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.Voucherlist.map((x, idx) => (
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td class="trpointer">
                          {x.Voucherdate.substring(0, 10)}
                        </td>
                        <td class="trpointer">{x.SecondaryKey}</td>
                        <td class="trpointer">{x.Account.Accounttitle}</td>
                        <td class="trpointer">{x.Acc.Accounttitle}</td>
                        <td class="trpointer">{x.ChequeNumber}</td>
                        <td class="trpointer">{x.Narration}</td>
                        <td class="trpointer">
                          {x.Debit !== null ? x.Debit.toLocaleString() : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>

            <div className="col-4 text-center">Prepared By</div>
            <div className="col-4 text-center">Checked By</div>
            <div className="col-4 text-center">Approved By</div>
          </div>
        </div>
      </div>
    );
  }
}
export class Cashreceiptallprint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Cash Receipts</u>
              </b>
            </h3>
            <br />
            <h5>{this.props.date !== undefined ? this.props.date : null}</h5>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.No
                      </th>
                      <th scope="col" className="th1">
                        Date
                      </th>
                      <th scope="col" className="th1">
                        Voucher Number
                      </th>
                      <th scope="col" className="th1">
                        Invoice Number
                      </th>
                      <th scope="col" className="th1">
                        Account
                      </th>
                      <th scope="col" className="th1">
                        Narration
                      </th>
                      <th scope="col" className="th1">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.Voucherlist.map((x, idx) => (
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td class="trpointer">
                          {x.Voucherdate.substring(0, 10)}
                        </td>
                        <td class="trpointer">{x.SecondaryKey}</td>
                        <td class="trpointer">{x.SaleInvoiceId}</td>
                        <td class="trpointer">{x.Account.Accounttitle}</td>
                        <td class="trpointer">{x.Narration}</td>
                        <td class="trpointer">
                          {x.Debit !== null ? x.Debit.toLocaleString() : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>

            <div className="col-4 text-center">Prepared By</div>
            <div className="col-4 text-center">Checked By</div>
            <div className="col-4 text-center">Approved By</div>
          </div>
        </div>
      </div>
    );
  }
}



export class Recovery extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Recovery Sheet</u>
              </b>
            </h3>
            <h6>{this.props.month !== undefined ? this.props.month : null}</h6>

            <hr style={{ backgroundColor: "black" }} />
            {this.props.recoveryfilter !== undefined
              ? this.props.recoveryfilter.map((x, i) =>
                  x.installmentPlan !== null && x.installmentPlan.length > 0 ? (
                    <div>
                      <div className="row mt-3">
                        <div className="col-12 text-left">
                          <h4>{i + 1}</h4>{" "}
                        </div>
                        <div className="col-6 text-left">
                          <b>Contract Date:</b> {x.contractDate.split("T")[0]}
                        </div>
                        <div className="col-6 text-right">
                          <b>Buyer: </b>
                          {x.accounttitle}
                        </div>

                        <div className="col-6 text-left">
                          <b>Unit: </b>
                          {x.unitNumber + "-" + x.phaseName}
                        </div>
                        <div className="col-6 text-right">
                          <b>Installments: </b>
                          {x.noOfInstallments}
                        </div>

                        <div className="col-6 text-left">
                          <b>Rate: </b>
                          {x.saleRate.toLocaleString()}
                        </div>
                        <div className="col-6 text-right">
                          <b>Total Price: </b>
                          {x.totalPrice.toLocaleString()}
                        </div>

                        <div className="col-6 text-left">
                          <b>Advance Amount: </b>
                          {x.advancePayment !== null
                            ? x.advancePayment.toLocaleString()
                            : null}
                        </div>
                        <div className="col-6 text-right">
                          <b>Pending: </b>
                          {(
                            x.advancePayment - x.advanceReceived
                          ).toLocaleString()}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div class="table-responsive col-12">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Sr.No</th>
                                <th>Due Date</th>
                                <th>Due Amount</th>
                                <th>Pending Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {x.installmentPlan.map((y, i) => (
                                <tr>
                                  <td>{++i}</td>
                                  <td>{y.dueDate.split("T")[0]}</td>
                                  <td>{y.dueAmount.toLocaleString()}</td>
                                  <td className="ml-2">
                                    {y.pendingamount.toLocaleString()}
                                  </td>
                                  <td>{y.status}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ) : null
                )
              : null}
          </div>
        </div>
      </div>
    );
  }
}


export class Cashreceiptprint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4>
              <b>
                <u>Cash Receipt</u>
              </b>
            </h4>
            <h5>
              <b>Voucher No.: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.SecondaryKey
                : null}
            </h5>
            <h5 className="text-left">
              <b>Invoice No.: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.SaleInvoiceId
                : null}
            </h5>
            <h5 className="text-left">
              <b>Entry Date: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.Voucherdate.split("T")[0]
                : null}
            </h5>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>SR.</th>
                      <th>PARTICULARS</th>
                      <th>NARRATION</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.printdata !== undefined
                      ? this.props.printdata.Transaction.map((x, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{x.Acc.Accounttitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.Transtype === "Debit"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                            <td>
                              {x.Transtype === "Credit"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="row mt-4">
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>

                <div className="col-4 text-center">Prepared By</div>
                <div className="col-4 text-center">Checked By</div>
                <div className="col-4 text-center">Approved By</div>
              </div>

              <div className="row mt-5">
                <div className="col-8 text-right">
                  <b>Print Date:</b>
                </div>
                <div className="col-4 ">{this.props.date}</div>
              </div>
              <div className="row">
                <div className="col-8 text-right">
                  <b>Receiver:</b>
                </div>
                <div className="col-4 text-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}





export class RecoveryPrint extends Component {
  render() {
    var i = 0;
    return (
      <div
        style={{ backgroundColor: "white", height: "100%", padding: "10px" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div className="row m-3 p-0" style={{ height: "80%" }}>
          <div className="col-12 text-center mt-2">
            <h4 className="headingscss">
              <b>Installements Recovery Sheet</b>
            </h4>
            <span className="float-right">
              <b>{this.props.monthname}</b>
            </span>
            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Contract</th>
                      <th>Buyer</th>
                      <th>Unit</th>
                      <th>Installements</th>
                      <th>Sale Rate</th>
                      <th>Advance Payment</th>
                      <th>Total Price</th>
                      <th>Pending Installments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.recovery === null ||
                    this.props.recovery === undefined
                      ? null
                      : this.props.recovery.map((x) =>
                          x.installmentPlan !== null &&
                          x.installmentPlan.length > 0 ? (
                            <div>
                              <tr>
                                <td>{++i}</td>
                                <td>{x.contractDate.split("T")[0]}</td>
                                <td>{x.accounttitle}</td>
                                <td>{x.phaseName + "-" + x.unitNumber}</td>
                                <td>{x.noOfInstallments}</td>
                                <td>{x.saleRate.toLocaleString()}</td>
                                <td>
                                  {x.advancePayment !== null
                                    ? x.advancePayment.toLocaleString()
                                    : null}
                                </td>
                                <td>{x.totalPrice.toLocaleString()}</td>
                                <td>
                                  {x.installmentPlan.map((y) => (
                                    <p>
                                      <b>{y.dueDate.split("T")[0]}</b>
                                      <b className="ml-2">
                                        {y.pendingamount !== null
                                          ? y.pendingamount.toLocaleString()
                                          : null}
                                      </b>
                                    </p>
                                  ))}
                                </td>
                              </tr>
                            </div>
                          ) : null
                        )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        <b>Total: </b>
                      </td>
                      <td>
                        <b>
                          {this.props.totalval !== undefined
                            ? this.props.totalval.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class RecoveryIndividual extends Component {
  render() {
    var i = 0;
    return (
      <div
        style={{ backgroundColor: "white", height: "100%", padding: "10px" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div className="row m-3 p-0" style={{ height: "80%" }}>
          <div className="col-12 text-center mt-2">
            <h4 className="headingscss">
              <b>{this.props.clientName} Recovery History</b>
            </h4>
            <hr style={{ backgroundColor: "black" }} />
            <div className="col-12 text-center mt-5">
              <b>Advance</b>
            </div>
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Pending</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.multiple !== undefined ? (
                      <tr>
                        <td>
                          {this.props.multiple.advancePayment.toLocaleString()}
                        </td>
                        <td>
                          {(
                            this.props.multiple.advancePayment -
                            this.props.multiple.advanceReceived
                          ).toLocaleString()}
                        </td>
                        <td className="ml-2">
                          {this.props.multiple.advancePayment -
                            this.props.multiple.advanceReceived !==
                          0
                            ? "Pending"
                            : "Paid"}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-12 text-center mt-5">
              <b>Installments</b>
            </div>
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Due Date</th>
                      <th>Due Amount</th>
                      <th>Pending Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.multiple !== undefined
                      ? this.props.multiple.installmentPlan.map((y, i) => (
                          <tr>
                            <th scope="row">{++i}</th>
                            <td>{y.dueDate.split("T")[0]}</td>
                            <td>{y.dueAmount.toLocaleString()}</td>
                            <td className="ml-2">
                              {y.pendingamount.toLocaleString()}
                            </td>
                            <td>{y.status}</td>
                          </tr>
                        ))
                      : null}
                    <tr>
                      <th scope="row"></th>
                      <td></td>
                      <td>
                        <b>Pending Sum:</b>
                      </td>
                      <td className="ml-2">
                        {this.props.sumpending.toLocaleString()}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
