import React from "react";
// import Loader from "react-loader-spinner";

function LazyLoader() {
  return (
    <>
      {/* <Loader type="ThreeDots" color="#205370" height={20} width={"100% !important"} /> */}
    </>
  );
}

export default LazyLoader;
