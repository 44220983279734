import {combineReducers} from 'redux';
import {GetAllPopulateAccountsDataReducer} from "../reducers/getpopulateaccount"
import {GetAllReceiptVouchersDataReducer} from "../reducers/getallreceiptvouchers"
import {GetAllCashAccountsDataReducer} from "../reducers/getcashaccounts"
import {GetAllPaymentVouchersDataReducer} from "../reducers/getallpaymentvouchers"
import {GetAllBankReceiptVouchersDataReducer} from "../reducers/getallbankreceiptvouchers"
import {GetAllBankPaymentVouchersDataReducer} from "../reducers/getallbankpaymentvouchers"
import {GetAllBankAccountsDataReducer} from "../reducers/getbankaccounts"
import {GetAllJournalVouchersDataReducer} from "../reducers/getalljournalvouchers"

const AllReducers = combineReducers({
    GetAllPopulateAccountsDataReducer : GetAllPopulateAccountsDataReducer,
    GetAllReceiptVouchersDataReducer : GetAllReceiptVouchersDataReducer,
    GetAllCashAccountsDataReducer : GetAllCashAccountsDataReducer,
    GetAllPaymentVouchersDataReducer : GetAllPaymentVouchersDataReducer,
    GetAllBankReceiptVouchersDataReducer : GetAllBankReceiptVouchersDataReducer,
    GetAllBankAccountsDataReducer : GetAllBankAccountsDataReducer,
    GetAllBankPaymentVouchersDataReducer : GetAllBankPaymentVouchersDataReducer,
    GetAllJournalVouchersDataReducer : GetAllJournalVouchersDataReducer
})

export default AllReducers