import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import {
  Sellersaleprint,
} from "./SellerSaleSummaryPrint";
import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";


export const SellerSaleSummaryMain = () => {
  const [loader1, setloader1] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [contractdata, setcontractdata] = useState([]);
  const [contractdatafilter, setcontractdatafilter] = useState([]);

  const [invoicedetail, setinvoicedetail] = useState([]);

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  var suggestionlist = [];
  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.Date.split("T")[0]));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.PartyName));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };

  const getallpurchasecontract = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/SellerPurchaseSummary`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        setcontractdata(result.data.Data);
        setcontractdatafilter(result.data.Data);

        // let totalbags = 0;
        // result.data.PurchaseInvoice.forEach((x) => {
        //   x.PurchaseInvoiceDetails.forEach((y) => {
        //     totalbags += y.ProductQty
        //   })
        //   x.TotalBags = totalbags
        //   totalbags = 0
        // })

        setrefresh(!refresh);
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };
  useEffect(() => {
    getallpurchasecontract();
  }, []);

  useEffect(() => {
    if (invoicedetail.length !== 0) {
      document.getElementById("modalAddnewAccount").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount").style.display = "none";
    }
  }, [invoicedetail]);

  return (
    <>
      <div hidden>
        <Sellersaleprint
          ref={componentRef1}
          printname={"Invoice"}
          contractdata={contractdata}
        />
      </div>
      <div id="modalAddnewAccount" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close "
            onClick={() => {
              setinvoicedetail([]);
            }}
          >
            &times;
          </span>
          <div>
            <table class="table table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                 

                  <th scope="col" className="th1">
                    Yarn Type
                  </th>
                  <th scope="col" className="th1">
                    Composition
                  </th>
                  <th scope="col" className="th1">
                    Quantity
                  </th>
                  {/* <th scope="col" className="th1">
                    GSM
                  </th> */}
                  <th scope="col" className="th1">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoicedetail !== undefined
                  ? invoicedetail.map((x, idx) => (
                      <tr>
                        <th scope="col" className="th1">
                          {idx + 1}
                        </th>
                     
                        <th scope="col" className="th1">
                          {x.YarnType}
                        </th>
                        <th scope="col" className="th1">
                          {x.Composition}
                        </th>
                        <th scope="col" className="th1">
                          {x.TotalQuantity}
                        </th>
                        {/* <th scope="col" className="th1">
                          {x.GSM}
                        </th> */}
                        <th scope="col" className="th1">
                          {x.Amount.toLocaleString()}
                        </th>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                {/* <input
                  type="date"
                  autoComplete="off"
                  placeholder="Date"
                  className="input_border"
                  onChange={onTextChange1}
                  id="fromto"
                ></input> */}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Seller Name"
                  className="input_border ml-2"
                  onChange={onTextChange2}
                ></input>
              </span>
              <span className="ml-2">
                <button
                  onClick={handlePrint1}
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    PartyName
                  </th>

                  {/* <th scope="col" className="th1">
                    Discount{" "}
                  </th>
                  <th scope="col" className="th1">
                    Others{" "}
                  </th>
                  <th scope="col" className="th1">
                    Freight{" "}
                  </th> */}
                  <th scope="col" className="th1">
                    Total{" "}
                  </th>
                  <th scope="col" className="th1">
                    Paid{" "}
                  </th>

                  {/* <th scope="col" className="th1">
                    Net{" "}
                  </th> */}
                </tr>
              </thead>
              <tbody style={{ fontSize: "10pt" }}>
                {contractdata !== undefined
                  ? contractdata.map((x, idx) => {
                      return (
                        <tr 
                        // onClick={() => setinvoicedetail(x.Products)}
                        >
                          <td>{idx + 1}</td>

                          <td>{x?.PartyName}</td>
                          {/* <td>
                            {x?.Discount ? x?.Discount.toLocaleString() : 0}
                          </td>
                          <td>{x?.Others ? x?.Others.toLocaleString() : 0}</td>
                          <td>
                            {x?.Freight ? x?.Freight.toLocaleString() : 0}
                          </td> */}
                          {/* <td>
                            {x?.TotalAmount
                              ? x?.TotalAmount.toLocaleString()
                              : 0}
                          </td> */}
                          <td>
                            {x.NetTotalAmount
                              ? x.NetTotalAmount.toLocaleString()
                              : 0}
                          </td>

                          <td>
                            {x.PaidAmount ? x.PaidAmount.toLocaleString() : 0}
                          </td>

                          
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};