import React, { Component } from "react";
import logo from "../../Images/seasonFour.jpg";
class CashBookPrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4 className="headingscss">
              <b>
                <u>Cash Book</u>
              </b>
            </h4>
            <span className="float-right">
              <b> CurrentBalance {this.props.currentbalance}</b>
            </span>
            <hr style={{ backgroundColor: "black" }} />
            <div className="row mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>DATE</th>
                      <th>NARRATION</th>
                      <th>VOUCHER</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.allaccounts !== undefined
                      ? this.props.allaccounts.map((x) => (
                          <tr>
                            <th>{x.transdate.split("T")[0]}</th>
                            <th>{x.narration}</th>
                            <th>
                              {x.voucherNumber}
                              {x.vreferenceno}
                            </th>
                            {x.transtype === "Debit" ? (
                              <th>{x.amount.toLocaleString()}</th>
                            ) : (
                              <th></th>
                            )}
                            {x.transtype === "Credit" ? (
                              <th>{x.amount.toLocaleString()}</th>
                            ) : (
                              <th></th>
                            )}
                          </tr>
                        ))
                      : null}
                    <tr>
                      <td></td>
                      <td></td>

                      <td className="text-right">
                        <b>Total: </b>
                      </td>
                      <td>
                        <b>
                          {this.props.debitTotal !== undefined
                            ? this.props.debitTotal
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {this.props.debitTotal !== undefined
                            ? this.props.creditTotal
                            : null}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CashBookPrint;
