import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";
 
 export default class ItemWiseStockPrint extends Component {
    render() {
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div
            className="row m-3 p-0"
            // style={{ border: "5px solid darkgrey", height: "80%" }}
          >
            <div className="col-12 text-center mt-2">
              <h3>
                <b>
                  <u>Item Wise Stock Report</u>
                </b>
              </h3>
  
              <hr style={{ backgroundColor: "black" }} />
              <div className="mt-4">
                <div class="table-responsive col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="th1">
                          Sr.
                        </th>
                        <th scope="col" className="th1">
                          Yarn Type
                        </th>
                        <th scope="col" className="th1">
                          Brand
                        </th>
                     
                        <th scope="col" className="th1">
                          Qty In Hand
                        </th>
                        <th scope="col" className="th1">
                          Purchase Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.contractdata !== undefined
                        ? this.props?.contractdata.map((x, idx) => (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>{x.Product?.ProductType} - ({x.Product?.Composition})</td>
                              <td>{x.Brand?.BrandName}</td>
                              <td>{x.QtyinHand}</td>
                              <td>{x.WeightedAvgCost}</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }