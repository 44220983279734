import React, { lazy, Suspense, useState } from "react";
import LazyLoader from "../../LazyLoader/LazyLoader";
const CustomerForm = lazy(()=> import ("./CustomerForm"))
const CustomerTable = lazy(()=> import ("./CustomerTable"))


const CustomerMain = () => {
  const [showForm, setshowForm] = useState(false);
  const [buyer, setbuyer] = useState(null);

  return (
    <Suspense fallback={<LazyLoader/>}>
      {showForm ? (
        <CustomerForm buyer={buyer} setshowForm={setshowForm} showForm={showForm} />
      ) : (
        <CustomerTable setbuyer={setbuyer} setshowForm={setshowForm} showForm={showForm} />
      )}
    </Suspense>
  );
};

export default CustomerMain;
