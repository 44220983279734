import React, { useState} from "react";
import { BankReceiptForm } from "./BankReceiptForm";
import { BankReceiptTable } from "./BankReceiptTable";
const BankReceiptMain = () => {
    const changecomponent = () => {
      setComponentState(<BankReceiptForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <BankReceiptTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <BankReceiptTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };
  export default BankReceiptMain;
