import React, { lazy, Suspense, useState } from "react";
import LazyLoader from "../../LazyLoader/LazyLoader";
const JournalVoucherForm = lazy(()=> import ("./JournalVoucherForm"))
const JournalVoucherTable = lazy(()=> import ("./JournalVoucherTable"))


const JournalVoucherMain = () => {
  const [showForm, setshowForm] = useState(false);

  return (
    <Suspense fallback={<LazyLoader/>}>
      {showForm ? (
        <JournalVoucherForm setshowForm={setshowForm} showForm={showForm} />
      ) : (
        <JournalVoucherTable setshowForm={setshowForm} showForm={showForm} />
      )}
    </Suspense>
  );
};

export default JournalVoucherMain;
