import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { TiPrinter } from "react-icons/ti";

import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { PurchaseInvoiceIndividual, PurchaseInvoicelist } from "./PurchaseInvoicePrint";

export const PurchaseInvoiceTable = (props) => {
  const [loader1, setloader1] = useState(false);
  const [contractdata, setcontractdata] = useState([]);
  const [contractdatafilter, setcontractdatafilter] = useState([]);
  const [contractdetailsdata, setcontractdetailsdata] = useState([]);
  const [printdata, setprintdata] = useState();

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var suggestionlist = [];

  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.PurchaseInvSerialNo));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };
  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.SellerName));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };
  const onTextChange3 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) =>
            regex.test(
              v.Date !== null && v.Date !== undefined
                ? v.Date.split("T")[0]
                : null
            )
          );
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };

  const getallpurchasecontract = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Purchase/GetAllPurchaseInvoices`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        setcontractdata(result?.data?.PurchaseInvoice);
        setcontractdatafilter(result?.data?.PurchaseInvoice);
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };
  useEffect(() => {
    getallpurchasecontract();
  }, []);

  useEffect(() => {
    if (contractdetailsdata.length !== 0) {
      document.getElementById("modalAddnewAccount").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount").style.display = "none";
    }
  }, [contractdetailsdata]);

  console.log(contractdetailsdata);
  return (
    <>
      <div hidden>
        <PurchaseInvoiceIndividual ref={componentRef} printdata={printdata} />
        <PurchaseInvoicelist
          ref={componentRef1}
          printname={"Invoice"}
          contractdata={contractdata}
        />
      </div>
      <div id="modalAddnewAccount" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close "
            onClick={() => {
              setcontractdetailsdata([]);
            }}
          >
            &times;
          </span>
          <div>
            <table class="table table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Yarn Type
                  </th>
                  <th scope="col" className="th1">
                    Brand
                  </th>
                  <th scope="col" className="th1">
                    No. Of Bags
                  </th>
                  {/* <th scope="col" className="th1">
                    Avg Weight(KGs)
                  </th> */}
                  <th scope="col" className="th1">
                    Rate
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                  <th scope="col" className="th1">
                    S.Tax
                  </th>
                  <th scope="col" className="th1">
                    Net Amount
                  </th>
                  <th scope="col" className="th1">
                    <TiPrinter
                      onClick={handlePrint}
                      style={{ cursor: "pointer" }}
                      size="1.5em"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {contractdetailsdata !== null
                  ? contractdetailsdata.map((x, idx) => (
                      <tr>
                        <th scope="col" className="th1">
                          {idx + 1}
                        </th>
                        <th scope="col" className="th1">
                        {x.Product.ProductType} {x.Product.Composition ? ` - (${x.Product.Composition})` : ""}

                        </th>
                        <th scope="col" className="th1">
                          {x.Brand.BrandName}
                        </th>
                        <th scope="col" className="th1">
                          {x.ProductQty}
                        </th>
                        {/* <th scope="col" className="th1">
                          {x.AvgWeight}
                        </th> */}
                        <th scope="col" className="th1">
                          {x.Rate}
                        </th>
                        <th scope="col" className="th1">
                          {x.Amount?.toLocaleString()}
                        </th>
                        <th scope="col" className="th1">
                          {x.TaxAmount}
                        </th>
                        <th scope="col" className="th1">
                          {x.NetAmount?.toLocaleString()}
                        </th>
                        <th scope="col" className="th1">
                          {}
                        </th>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Date"
                  className="input_border"
                  onChange={onTextChange3}
                ></input>
              </span>
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Invoice No."
                  className="input_border"
                  onChange={onTextChange1}
                ></input>
              </span>
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Vendor"
                  className="input_border"
                  onChange={onTextChange2}
                ></input>
              </span>
              <span className="ml-2">
                <button
                  onClick={handlePrint1}
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <button
                  onClick={props.changecomponent}
                  className="tabletopbutton1 text-right"
                >
                  New
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Inv No.
                  </th>
                  <th scope="col" className="th1">
                    Contract No.
                  </th>
                  <th scope="col" className="th1">
                    Vendor
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  {/* <th scope="col" className="th1">
                      Discount{" "}
                    </th>
                    <th scope="col" className="th1">
                      Others{" "}
                    </th>
                    <th scope="col" className="th1">
                      Freight{" "}
                    </th> */}
                  <th scope="col" className="th1">
                    Net Amount
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "10pt" }}>
                {contractdata?.map((x, idx) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setcontractdetailsdata(x.PurchaseInvoiceDetails);
                      setprintdata(x);
                    }}
                  >
                    <td>{idx + 1}</td>
                    <td>{x.Date.split("T")[0]}</td>
                    <td>
                      {x.PurchaseInvSerialNo !== null
                        ? `PInv - ${x.PurchaseInvSerialNo}`
                        : ""}
                    </td>
                    <td>
                      {x.PurchaseContract !== null
                        ? `PCont - ${x.PurchaseContract?.PurchaseContSerialNumber}`
                        : ""}
                    </td>

                    <td>{x.Account.Accounttitle}</td>
                    <td>{x.Narration}</td>

                    {/* <td>{x.Discount}</td>
                      <td>{x.Others}</td>
                      <td>{x.Freight}</td> */}
                    <td>{x.NetTotalAmount?.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
