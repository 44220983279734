import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import CashFlowPrint from "../Prints/CashFlowPrint";
import moment from "moment";
import { toast } from "react-toastify";

const CashFlow = () => {
  const [data, setdata] = useState([]);
  const [debit, setdebit] = useState(0);
  const [credit, setcredit] = useState(0);
  const [loader1, setloader1] = useState(false);
  const [from, setfrom] = useState(moment().format("YYYY-07-01"));
  const [to, setto] = useState(moment().format("YYYY-MM-DD"));

  const apicashbook = () => {
    if (from === "" || to === "") {
      toast.info("Please fill all the fields!");
    } else {
      setdebit(0);
      setcredit(0);
      setloader1(true);
      axios({
        url: `${window.$url}api/Reports/CashFlow?From=${from}&To=${to}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log("CASHFLOW", result);
          setloader1(false);
          setdata(result.data.Data);

          let debit = 0,
            credit = 0;

          result.data.Data.forEach((x) => {
            debit += parseFloat(x.DebitAmount);
          });

          result.data.Data.forEach((x) => {
            credit += parseFloat(x.CreditAmount);
          });

          setdebit(debit.toLocaleString());
          setcredit(credit.toLocaleString());
        },
        (error) => {
          console.log(error);
          setloader1(false);
        }
      );
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div hidden>
        <CashFlowPrint
          ref={componentRef}
          data={data}
          credit={credit}
          debit={debit}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="col-12 pt-1">
            <span> From: </span>
            <input
              type="date"
              id="cashfrom"
              onChange={(e) => setfrom(e.target.value)}
              defaultValue={moment().format("YYYY-07-01")}
              className="input_border  ml-1"
            ></input>
            <span> To: </span>
            <input
              type="date"
              id="cashto"
              onChange={(e) => setto(e.target.value)}
              defaultValue={moment().format("YYYY-MM-DD")}
              className="input_border  ml-1"
            ></input>
            <span className="ml-2">
              <button
                id="callapibutn"
                className="tabletopbutton   text-right ml-2 "
                onClick={() => {
                  apicashbook();
                }}
              >
                GO
              </button>
            </span>
            <span className="ml-2 float-right">
              <button
                id="callapibutn"
                className="tabletopbutton   text-right ml-2 "
                onClick={handlePrint}
              >
                Print
              </button>
            </span>
            <div className="loaderclass float-right">
              {" "}
              {loader1 && (
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              )}
            </div>
            <hr className="mt-3"></hr>
            <div className="row mt-1">
              <div className="col-6 text-left">
                <b>Cash In= {debit}</b>
              </div>
              <div className="col-6 text-right">
                <b>Cash Out= {credit}</b>
              </div>
            </div>

            <div className="row mt-2">
              <div class="table-responsive col-6">
                <table class="table table-hover">
                  <thead>
                    <tr className="">
                      <th>Main Account</th>
                      <th>Opposite Account</th>
                      <th>Debit Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length !== 0
                      ? data.map((x) =>
                          x.DebitAmount !== 0 ? (
                            <tr>
                              <td>{x.MainAccount}</td>
                              <td>{x.OppositeAccount}</td>
                              <td>{x.DebitAmount}</td>
                            </tr>
                          ) : null
                        )
                      : null}
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Debit:</b>
                      </td>
                      <td>{debit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive col-6">
                <table class="table table-hover">
                  <thead>
                    <tr className="">
                      <th>Main Account</th>
                      <th>Opposite Account</th>
                      <th>Credit Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length !== 0
                      ? data.map((x) =>
                          x.CreditAmount !== 0 ? (
                            <tr>
                              <td>{x.MainAccount}</td>
                              <td>{x.OppositeAccount}</td>
                              <td>{x.CreditAmount}</td>
                            </tr>
                          ) : null
                        )
                      : null}
                    <tr>
                      <td></td>
                      <td>
                        <b>Total Credit:</b>
                      </td>
                      <td>{credit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashFlow;
