import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";
import { SaleTaxMain } from "./SettingTable";

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
        count === "Sale Tax"
          ? "blue"
          : count === "Balance Sheet"
          ? "red"
          : count === "Income Statement"
          ? "seaGreen"
          : count === "Cash Book"
          ? "darkred"
          : count === "Cash Flow"
          ? "seaGreen"
          : count === "Bank Balances"
          ? "red"
          : count
      }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Settings = () => {
  const button = [{ button1: "Sale Tax", component: <SaleTaxMain /> }];
  const [chosen2, setChosen2] = useState({
    button1: "Sale Tax",
    component: <SaleTaxMain />,
  });

  return (
    <>
      <div style={{ height: "10%" }}>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "87%" }}>
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Settings;
