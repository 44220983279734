import React, { useState, useEffect, useRef } from "react";
import "../../../Bootstrap/haroon.css";
import axios from "axios";
import { TiPrinter } from "react-icons/ti";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import SaleDeliveryReportPrint , { SaleDeliveryReportIndividualPrint}from "./SaleDeliveryReportPrint"
import moment from "moment";


export const DeliveryReport = (props) => {
    const componentRef1 = useRef();
    const handlePrint1 = useReactToPrint({
      content: () => componentRef1.current,
    });
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const [loader1, setloader1] = useState(false);
    const [contractdata, setcontractdata] = useState([]);
    const [contractdatafilter, setcontractdatafilter] = useState([]);
    const [contractdetailsdata, setcontractdetailsdata] = useState([]);
    const [printdata, setprintdata] = useState();
  
  
    var suggestionlist = [];
  

    const onTextChange1 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) => regex.test(v.SaleContSerialNo));
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    const onTextChange2 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) => regex.test(v.ClientName));
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    const onTextChange3 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) =>
              regex.test(
                v.CommisionAgent !== null
                  ? v.CommisionAgent.CommisionAgentName
                  : null
              )
            );
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
  
    const onTextChange4 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) =>
              regex.test(
                v.Date !== null && v.Date !== undefined
                  ? v.Date.split("T")[0]
                  : null
              )
            );
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
  
    const getallpurchasecontract = () => {
      setloader1(true);
      axios({
        url: `${window.$url}api/Reports/DeliveryReportSale`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setloader1(false);
          console.log(result);
          setcontractdata(result?.data?.SaleContract);
          setcontractdatafilter(result?.data?.SaleContract);
        },
        (error) => {
          setloader1(false);
          console.log(error);
        }
      );
    };
  
    useEffect(() => {
      getallpurchasecontract();
    }, []);
  
    useEffect(() => {
      if (contractdetailsdata.length !== 0) {
        document.getElementById("modalAddnewAccount").style.display = "block";
      } else {
        document.getElementById("modalAddnewAccount").style.display = "none";
      }
    }, [contractdetailsdata]);
  
    
  
    return (
      <>
        <div hidden>
          <SaleDeliveryReportIndividualPrint ref={componentRef} printdata={printdata} />
          <SaleDeliveryReportPrint
            ref={componentRef1}
            printname={"Contract"}
            contractdata={contractdata}
          />
        </div>
   
  
        <div id="modalAddnewAccount" class="modalscreen ">
          <div class="modal-content-screen1">
            <span
              class="close "
              onClick={() => {
                setcontractdetailsdata([]);
              }}
            >
              &times;
            </span>
            <div>
              <table class="table table-hover">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Yarn Type
                    </th>
                    <th scope="col" className="th1">
                      Brand
                    </th>
                    <th scope="col" className="th1">
                      No. Of Bags
                    </th>

                    <th scope="col" className="th1">
                      Rate
                    </th>
                    <th scope="col" className="th1">
                      Amount
                    </th>
                    <th scope="col" className="th1">
                      S.Tax
                    </th>
                    <th scope="col" className="th1">
                      Net Amount
                    </th>
                    <th scope="col" className="th1">
                      Remaining Qty
                    </th>
                    <th scope="col" className="th1">
                      <TiPrinter
                        onClick={handlePrint}
                        style={{ cursor: "pointer" }}
                        size="1.5em"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contractdetailsdata !== null
                    ? contractdetailsdata?.map((x, idx) => (
                        <tr>
                          <th scope="col" className="th1">
                            {idx + 1}
                          </th>
                          <th scope="col" className="th1">
                            {x.Product.ProductType}-({x.Product.Composition})
                          </th>
                          <th scope="col" className="th1">
                            {x.Brand.BrandName}
                          </th>
                          <th scope="col" className="th1">
                            {x.ProductQty}
                          </th>
                          <th scope="col" className="th1">
                            {x.Rate}
                          </th>
                          <th scope="col" className="th1">
                            {x.Amount}
                          </th>
                          <th scope="col" className="th1">
                            {x.TaxAmount}
                          </th>
                          <th scope="col" className="th1">
                            {x.NetAmount}
                          </th>
                          <th scope="col" className="th1">
                            {x.RemainingQty}
                          </th>
                          <th scope="col" className="th1">
                            {}
                          </th>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
          <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
            <div className="row row1_style input_border ">
              <div className="col-12">
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={onTextChange4}
                    placeholder="Search By Date"
                    className="input_border"
                  ></input>
                </span>
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={onTextChange1}
                    placeholder="Search By Contract No."
                    className="input_border"
                  ></input>
                </span>
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={onTextChange2}
                    placeholder="Search By Customer"
                    className="input_border"
                  ></input>
                </span>
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    onChange={onTextChange3}
                    placeholder="Search By Agent"
                    className="input_border"
                  ></input>
                </span>
  
                <span className="ml-2">
                  <button
                    onClick={handlePrint1}
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                  >
                    Print
                  </button>
                </span>
            
                <span className="ml-2 float-right">
                  <div className="loaderclass">
                    {" "}
                    {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="row ">
              <table class="table tableCash table-hover">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Date
                    </th>
                    <th scope="col" className="th1">
                      Contract No.
                    </th>
                    <th scope="col" className="th1">
                      Customer
                    </th>
                    <th scope="col" className="th1">
                    Delivery Date
                    </th>
                    
                    <th scope="col" className="th1">
                      Com. Agent / Amount
                    </th>
                    <th scope="col" className="th1">
                      Narration
                    </th>
                    <th scope="col" className="th1">
                      Amount
                    </th>
                    <th scope="col" className="th1">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "10pt" }}>
                  {contractdata?.map((x, idx) => (
                    <tr style={{ cursor: "pointer" }}>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {idx + 1}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.Date.split("T")[0]}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        SCont-{x.SaleContSerialNo}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.Account?.Accounttitle}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.DeliveryDate ? moment(x.DeliveryDate).format("YYYY-MM-DD") :""}
                      </td>
                      
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.CommisionAgent !== null
                          ? x.CommisionAgent.CommisionAgentName
                          : null}
                        {x.CommisionAgent.CommisionAgentName !== null
                          ? " / "
                          : null}
                        {x.CommisionAmount}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.Narration}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.TotalAmount !== null
                          ? x.TotalAmount.toLocaleString()
                          : null}
                      </td>
                      <td
                        onClick={() => {
                          setcontractdetailsdata(x.SaleContractDetails);
                          setprintdata(x);
                        }}
                      >
                        {x.Status}
                      </td>
                   
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };