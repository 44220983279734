import React, { useState, useEffect, useRef } from "react";
import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { PurchaseInvoiceForm } from "./PurchaseInvoiceForm";
import { PurchaseInvoiceTable } from "./PurchaseInvoiceTable";

export const PurchaseInvoiceMain = () => {
    const changecomponent = () => {
      setComponentState(<PurchaseInvoiceForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <PurchaseInvoiceTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <PurchaseInvoiceTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };