import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import Delete from "../../../Images/delete.png";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { Cashpaymentprint } from "./CashPaymentPrint";
import { getallpaymentvouchers } from "../../actions/getallpaymentvouchers";
import {  useDispatch } from "react-redux";
import { toast } from "react-toastify";
var converter = require("number-to-words");

export const CashPaymentForm = (props) => {
  const [Paymentaccount, setPaymentaccount] = useState([]);
  const [printdata, setprintdata] = useState();
  const [loader2, setloader2] = useState(false);
  const [affiliateAccid, setaffiliateAccid] = useState(null);
  const [displayinvoice, setdisplayinvoice] = useState(false);
  const [initialdata, setinitialdata] = useState(null);
  const [saleinvoices, setsaleinvoices] = useState([]);
  const [invoiceamount, setinvoiceamount] = useState(null);
  const [conversionamount, setconversionamount] = useState(0);
  const [list, setlist] = useState([]);
  const [invoiceidlist, setinvoiceidlist] = useState([]);
  const [invoiceamountlist, setinvoiceamountlist] = useState([]);
  const [AccountTitle, setAccountTitle] = useState("");
  const [accountid, setaccountid] = useState(null);
  const [selectedInvoice, setselectedInvoice] = useState([]);
  const [SaleInvoiceListID, setSaleInvoiceListID] = useState([]);

  var today = new Date();
  var todaydate = today.getDate();
  if (todaydate.toString().length === 1) {
    todaydate = "0" + todaydate.toString();
  }
  var currentmonth = today.getMonth();
  if (currentmonth.toString().length === 1) {
    currentmonth = "0" + (today.getMonth() + 1).toString();
  }
  var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;

  let dispatch = useDispatch();

 
  const [CashAcc, setCashAcc] = useState([]);


  useEffect(() => {
    if (initialdata?.CashAccounts.length > 0) {
      console.log(initialdata?.CashAccounts[0].Accid);
      setCashAcc(initialdata?.CashAccounts[0].Accid);
    } else {
      setCashAcc(null);
    }
  }, [initialdata]);

  console.log(CashAcc)
  const OnAccountChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("accountList").childNodes;
    let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        find = true;
        let item = Paymentaccount?.find(
          (item, idx) =>
            // console.log(item)
            item?.Accid === parseInt(options[i].attributes.attributeId.value)
        );

        // console.log(item);
        // console.log(options);
        // setaffiliateAccid(item.Accid);
        setAccountTitle(item.Accounttitle);
        
        document.getElementById("cashReceiptAccount").value = item.Accounttitle
        setaccountid(item.Accid);
        if (displayinvoice) {
          setsaleinvoices(item.PurchaseInvoice);
        } else {
          setsaleinvoices([]);
        }
        break;
      }
    }
    if (!find) {
      setaccountid("");
      setAccountTitle("");
      document.getElementById("cashReceiptAccount").value = ""
      setsaleinvoices([]);
    }
  };


  // const saveandnew = () => {
  //   if (document.getElementById("cashPaymentDate").value === "") {
  //     toast.info("Date is Required");
  //   }
  //   else if (document.getElementById("cashPaymentAccount").value === "") {
  //     toast.info("Select an Account");
  //   }
  //   else if (document.getElementById("comPaymentamount").value === "") {
  //     toast.info("Enter an Amount");
  //   }
  //   else if (document.getElementById("comPaymentamount").value === "0") {
  //     toast.info("Enter an Amount greater than ZERO");
  //   }
  //   else if (document.getElementById("cashPaymentNarration").value === "") {
  //     toast.info("Enter a Narration");

  //   }
  //   else {
  //     setloader2(true);
  //     var data = {
  //       EntryDate:moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       SellerAccId: affiliateAccid,
  //       Paymenttype: "Cash",
  //       VoucherType: "C-PV",
  //       Amount: parseFloat(document.getElementById("comPaymentamount").value.replace(/,/g, '')),
  //       VoucherDate: document.getElementById("cashPaymentDate").value,
  //       Narration: document.getElementById("cashPaymentNarration").value,
  //       AccId: 845
  //     };

  //     document.getElementById("crvSave").disabled = true;
  //     document.getElementById("cpvCancel").disabled = true;

  //     axios({
  //       method: "post",
  //       url:
  //         window.$url +
  //         `api/PaymentVoucher/AddPaymentVoucher`,
  //       data: JSON.stringify(data),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
  //         "content-type": "application/json",
  //       },
  //     }).then(
  //       (result) => {
  //         console.log(result);
  //         dispatch(getallpaymentvouchers())
  //         toast.info(result.data.Message);
  //         document.getElementById("comPaymentamount").value = "";
  //         document.getElementById("cashPaymentNarration").value = "";
  //         document.getElementById("cashPaymentAccount").value = "";

  //         setaffiliateAccid(null)
  //         setloader2(false);
  //         document.getElementById("crvSave").disabled = false;
  //         document.getElementById("cpvCancel").disabled = false;
  //       },
  //       (error) => {
  //         toast.info(error);
  //         setloader2(false);
  //         document.getElementById("crvSave").disabled = false;
  //         document.getElementById("cpvCancel").disabled = false;
  //       }
  //     );
  //   }
  // };
  const saveandclose = () => {
    if (list.length !== 0) {
      setloader2(true);

      let Input = [];

      list.forEach((x) => {
        Input.push({
          SellerAccId: x.SellerAccId,
          InvoiceIds: x.InvoiceId !== null ? x.InvoiceId : null,
          EntryDate: x.EntryDate,
          Paymenttype: x.Paymenttype,
          VoucherType: x.VoucherType,
          VoucherDate: x.VoucherDate,
          Amount: x.Amount,
          Narration: x.Narration,
          AccId: x.AccId,
        });
      });

      document.getElementById("crvSave").disabled = true;
      document.getElementById("cpvCancel").disabled = true;

      axios({
        method: "post",
        url: window.$url + `api/PaymentVoucher/AddPaymentVoucher`,
        data: JSON.stringify(Input),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          dispatch(getallpaymentvouchers());
          toast.info(result.data.Message);
          props.formCancel();
        },
        (error) => {
          toast.info(error);
          setloader2(false);
        }
      );
    } else {
      toast.info("Please enter voucher details!");
    }
  };
  // const saveandprint = () => {
  //   if (document.getElementById("cashPaymentDate").value === "") {
  //     toast.info("Date is Required");
  //   }
  //   else if (document.getElementById("cashPaymentAccount").value === "") {
  //     toast.info("Select an Account");
  //   }
  //   else if (document.getElementById("comPaymentamount").value === "") {
  //     toast.info("Enter an Amount");
  //   }
  //   else if (document.getElementById("comPaymentamount").value === "0") {
  //     toast.info("Enter an Amount greater than ZERO");
  //   }
  //   else if (document.getElementById("cashPaymentNarration").value === "") {
  //     toast.info("Enter a Narration");

  //   }
  //   else {
  //     setloader2(true);
  //     var data = {
  //       EntryDate:moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       SellerAccId: affiliateAccid,
  //       Paymenttype: "Cash",
  //       VoucherType: "C-PV",
  //       Amount: parseFloat(document.getElementById("comPaymentamount").value.replace(/,/g, '')),
  //       VoucherDate: document.getElementById("cashPaymentDate").value,
  //       Narration: document.getElementById("cashPaymentNarration").value,
  //       AccId: 845
  //     };

  //     document.getElementById("crvSave").disabled = true;
  //     document.getElementById("cpvCancel").disabled = true;

  //     axios({
  //       method: "post",
  //       url:
  //         window.$url +
  //         `api/PaymentVoucher/AddPaymentVoucher`,
  //       data: JSON.stringify(data),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
  //         "content-type": "application/json",
  //       },
  //     }).then(
  //       (result) => {
  //         console.log(result);
  //         toast.info(result.data.Message);

  //         setloader2(false);
  //         document.getElementById("crvSave").disabled = false;
  //         document.getElementById("cpvCancel").disabled = false;

  //         if(result.data.IsSuccess){
  //           setprintdata({
  //             VoucherNumber:result.data.Id,
  //             Voucherdate:document.getElementById("cashPaymentDate").value,
  //             Account:{
  //               Accounttitle:document.getElementById("cashPaymentAccount").value
  //             },
  //             PurchaseInvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //             Accountid:affiliateAccid,
  //             Narration:document.getElementById("cashPaymentNarration").value,
  //             Credit:document.getElementById("comPaymentamount").value
  //           })
  //           document.getElementById("comPaymentamount").value = "";
  //         document.getElementById("cashPaymentNarration").value = "";
  //         document.getElementById("cashPaymentAccount").value = "";

  //         setaffiliateAccid(null)

  //         dispatch(getallpaymentvouchers())
  //         }

  //       },
  //       (error) => {
  //         toast.info(error);
  //         setloader2(false);
  //         document.getElementById("crvSave").disabled = false;
  //         document.getElementById("cpvCancel").disabled = false;
  //       }
  //     );
  //   }
  // };

  const paymentselleraccounts = () => {
    setloader2(true);
    axios({
      method: "get",
      url: window.$url + "api/PaymentVoucher/PopulateAccounts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setPaymentaccount(result.data.PaymentAccount);
        setinitialdata(result.data);
        setloader2(false);
      },
      (error) => {
        toast.info(error);
        setloader2(false);
      }
    );
  };

  console.log(Paymentaccount);
  useEffect(() => {
    paymentselleraccounts();
  }, []);

  var i = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handledeleterow = (idx) => {
    const rows = [...list];
    rows.splice(idx, 1);
    setlist(rows);
  };

  const printdatafunction = (val) => {
    setprintdata(val);
  };

  useEffect(() => {
    if (printdata !== undefined) {
      handlePrint();
    }
  }, [printdata]);

  const addtolistbutton = () => {
    var data = {
      AccountTitle: AccountTitle,
      SellerAccId: accountid,
      InvoiceId: SaleInvoiceListID !== null ? SaleInvoiceListID : null,
      InvoiceSerialNo:
        document.getElementById("purchaseinvoicedropdown") !== null
          ? document.getElementById("purchaseinvoicedropdown").value
          : null,
      EntryDate: moment().format("YYYY-MM-DD"),
      Paymenttype: "Cash",
      VoucherType: "CP Voucher",
      VoucherDate: document.getElementById("cashPaymentDate").value,
      Amount: parseFloat(
        document.getElementById("comPaymentamount").value.replace(/,/g, "")
      ),
      Narration: document.getElementById("cashPaymentNarration").value,
      AccId: CashAcc,
    };
    console.log(data);
    if (
      accountid !== null ||
      document.getElementById("comPaymentamount").value !== ""
      // document.getElementById("cashPaymentNarration").value !== ""
    ) {
      if (SaleInvoiceListID !== null) {
        if (SaleInvoiceListID.selectedIndex === 0) {
          toast.info("Please select an invoice!");
        } else {
          setlist([...list, data]);
          saleinvoices.forEach((x, idx) => {
            document.getElementById(`${idx}multipleinvoice`).checked = false;
          });
          setinvoiceamountlist([]);
          setinvoiceidlist([]);
          setaffiliateAccid(null);
          if (SaleInvoiceListID !== null) {
            setSaleInvoiceListID("");
          }
          setdisplayinvoice(false);
          document.getElementById("invoicecheckbox").checked = false;
          document.getElementById("comPaymentamount").value = "";
          setAccountTitle("");
          document.getElementById("cashReceiptAccount").value = ""
          document.getElementById("cashPaymentNarration").value = "";
          setconversionamount(0);
          setinvoiceamount(null);
        }
      } else {
        setlist([...list, data]);
        saleinvoices.forEach((x, idx) => {
          document.getElementById(`${idx}multipleinvoice`).checked = false;
        });
        setinvoiceamountlist([]);
        setinvoiceidlist([]);
        setaffiliateAccid(null);
        setSaleInvoiceListID([]);

        if (SaleInvoiceListID !== null) {
          setSaleInvoiceListID("");
        }
        setdisplayinvoice(false);
        document.getElementById("invoicecheckbox").checked = false;
        document.getElementById("comPaymentamount").value = "";
        setAccountTitle("");
        document.getElementById("cashReceiptAccount").value = ""
        document.getElementById("cashPaymentNarration").value = "";
        setconversionamount(0);
        setinvoiceamount(null);
      }
    } else {
      toast.info("Please fill all the fields!");
    }
  };

  useEffect(() => {
    if (invoiceamountlist.length !== 0) {
      let sum = 0;

      invoiceamountlist.forEach((x) => {
        sum += parseFloat(x);
      });

      setinvoiceamount(sum);
      setconversionamount(sum);
    } else {
      setinvoiceamount(null);
      setconversionamount(0);
    }
  }, [invoiceamountlist]);
  useEffect(() => {
    let abc = [];
    invoiceidlist.forEach((x) => {
      console.log(x);
      abc.push(`PInv-${x}`);
    });
    setselectedInvoice(abc);
    console.log(SaleInvoiceListID, abc, invoiceidlist);
  }, [invoiceidlist, SaleInvoiceListID]);

  useEffect(() => {
    if (AccountTitle === "") {
      setinvoiceidlist([]);
      setSaleInvoiceListID([]);
      setinvoiceamount(null);
      setconversionamount(0);
      // document.getElementBy("purchaseinvoicedropdown").value = ""
    }
    console.log(AccountTitle);
  }, [AccountTitle]);

  console.log("ALL INVOICES", list);
  return (
    <>
      <div hidden>
        <Cashpaymentprint
          ref={componentRef}
          date={date}
          printdata={printdata}
        />
      </div>
      <div id="modalAddnewAccount" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close "
            onClick={() => {
              document.getElementById("modalAddnewAccount").style.display =
                "none";
            }}
          >
            &times;
          </span>
          <div>
            <table class="table table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Invoice No.
                  </th>
                  <th scope="col" className="th1">
                    Net Amount
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Select
                  </th>
                </tr>
              </thead>
              <tbody>
                {saleinvoices !== undefined ? (
                  saleinvoices.map((x, idx) => (
                    <tr
                    //onClick={()=>{
                    // setinvoicevalue(x.PurchaseInvoiceId)
                    // setinvoiceamount( parseFloat(x.NetTotalAmount))
                    // setconversionamount( parseFloat(x.NetTotalAmount))
                    // document.getElementById("modalAddnewAccount").style.display = "none"
                    //}}
                    >
                      <td>{idx + 1}</td>
                      <td>{x.Date.split("T")[0]}</td>
                      <td>PInv-{x.PurchaseInvSerialNo}</td>
                      <td>{x.NetTotalAmount?.toLocaleString()}</td>
                      <td>{x.Narration}</td>
                      <td>
                        <input
                          type="checkbox"
                          id={`${idx}multipleinvoice`}
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setinvoiceidlist([
                                ...invoiceidlist,
                                x.PurchaseInvSerialNo,
                              ]);
                              setSaleInvoiceListID([
                                ...SaleInvoiceListID,
                                x.PurchaseInvoiceId,
                              ]);
                              setinvoiceamountlist([
                                ...invoiceamountlist,
                                x.NetTotalAmount,
                              ]);
                            } else {
                              const rows = [...invoiceidlist];
                              rows.splice(idx, 1);
                              setinvoiceidlist(rows);

                              const rowsId = [...SaleInvoiceListID];
                              rowsId.splice(idx, 1);
                              setSaleInvoiceListID(rowsId);

                              const rows1 = [...invoiceamountlist];
                              rows1.splice(idx, 1);
                              setinvoiceamountlist(rows1);
                            }
                          }}
                        ></input>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="tableContainer2 overflow-auto pageheading Sitka mt-3">
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-2">
            <div className="col-2  text-right">
              <span className="txt">Invoice</span>
            </div>
            <div className="col-4  text-left">
              <span>
                <input
                  type="checkbox"
                  id="invoicecheckbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setconversionamount(0);
                      setPaymentaccount(initialdata?.SellerAccount);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""
                      setinvoiceamount(null);
                      setaffiliateAccid(null);
                    } else {
                      document.getElementById("purchaseinvoicedropdown").value =
                        "";
                      setconversionamount(0);
                      setPaymentaccount(initialdata?.PaymentAccount);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""
                      setinvoiceamount(null);
                      setaffiliateAccid(null);
                    }
                  }}
                  onClick={() => {
                    if (
                      document.getElementById("invoicecheckbox").checked ===
                      true
                    ) {
                      setdisplayinvoice(true);
                    } else {
                      setdisplayinvoice(false);
                    }
                  }}
                ></input>
              </span>
            </div>
            <div className="col-2 text-right">
              <span className="txt"> Account</span>
            </div>
            {/* <div className="col-4 text-left">
                <span>
                  {" "}
                  <input
                    onChange={onTextChange}
                    autoComplete="off"
                    type="text"
                    id="cashPaymentAccount"
                    className="input_border formInput "
                    // onChange={onTextChange}
                  ></input>
                  <span id="demo"></span>
                </span>
              </div> */}
            <div className="col-4  text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="cashReceiptAccount"
                  // value={AccountTitle}
                  list="accountList"
                  onChange={OnAccountChange}
                  className="input_border formInput "
                  autoComplete="off"
                ></input>
                <datalist id="accountList">
                  {Paymentaccount?.map((v, idx) => {
                    // console.log(v);
                    return (
                      <option attributeId={v?.Accid} value={v?.Accounttitle}>
                        {v?.Accounttitle}
                      </option>
                    );
                  })}
                </datalist>
              </span>

              <span id="demo"></span>
            </div>

            <div className="col-2 mt-3  text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4 mt-3  text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="cashPaymentDate"
                  className="input_border formInput"
                  defaultValue={date}
                ></input>
              </span>
            </div>
            {displayinvoice === false ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      id="comPaymentamount"
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === "NaN") {
                          e.target.value = "";
                        }
                        if (invoiceamount !== null) {
                          if (
                            parseFloat(
                              document
                                .getElementById("comPaymentamount")
                                .value.replace(/,/g, "")
                            ) > invoiceamount
                          ) {
                            document.getElementById("comPaymentamount").value =
                              invoiceamount;
                          }
                        }
                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("comPaymentamount")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Purchase Invoice</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    {/* <select
                    id="purchaseinvoicedropdown"
                    className="input_border formInput "
                    onChange={(e)=>{
                      setinvoiceamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                      setconversionamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                    }}
                  >
                    <option>Select</option>
                    {
                      saleinvoices.map((x)=>(
                        <option data-amount={x.NetTotalAmount}>{x.PurchaseInvoiceId}</option>
                      ))
                    }
                  </select> */}
                    <input
                      id="purchaseinvoicedropdown"
                      className="input_border formInput "
                      readOnly
                      onClick={() => {
                        document.getElementById(
                          "modalAddnewAccount"
                        ).style.display = "block";
                      }}
                      value={
                        selectedInvoice.length !== 0
                          ? selectedInvoice.toString()
                          : ""
                      }
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="row">
            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      disabled={
                        document.getElementById("invoicecheckbox").checked ===
                        true
                      }
                      id="comPaymentamount"
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === NaN) {
                          e.target.value = "";
                        }
                        if (invoiceamount) {
                          if (invoiceamount !== null) {
                            if (
                              parseFloat(
                                document
                                  .getElementById("comPaymentamount")
                                  .value.replace(/,/g, "")
                              ) > invoiceamount
                            ) {
                              document.getElementById(
                                "comPaymentamount"
                              ).value = invoiceamount;
                            }
                          }
                        }
                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("comPaymentamount")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            {displayinvoice === false ? (
              <>
                <div className="col-2 mt-3 text-right"></div>
                <div className="col-4 mt-3 text-left"></div>
              </>
            ) : (
              <></>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Narration</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                <textarea
                  style={{ height: "70px" }}
                  type="text"
                  id="cashPaymentNarration"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>

            <div className="col-6 mt-3 ">
              <span className="mr-3">
                <b>Amount in words: </b>{" "}
                {conversionamount &&
                  converter.toWords(
                    conversionamount !== null ||
                      conversionamount !== undefined ||
                      conversionamount !== NaN ||
                      conversionamount !== ""
                      ? conversionamount
                      : 0
                  )}
              </span>
            </div>
            <div className="col-6 mt-3  text-right">
              {/* <span>
                  <button
                    id="crvSave"
                    onClick={saveandnew}
                    className="tabletopbutton  text-right greenbutton"
                  >
                    {"Save & New"}
                  </button>
                </span> */}
              {/* <span>
                  <button
                    id="crvSave"
                    onClick={saveandprint}
                    className="tabletopbutton ml-2 text-right bluebutton"
                  >
                    {"Save & Print"}
                  </button>
                </span> */}
              <span>
                <button
                  id="crvSave"
                  onClick={addtolistbutton}
                  className="tabletopbutton ml-2 text-right greenbutton"
                >
                  {"Add to list"}
                </button>
              </span>
              <span>
                <button
                  id="crvSave"
                  onClick={saveandclose}
                  className="tabletopbutton ml-2 text-right redbutton"
                >
                  {"Save"}
                </button>
              </span>
              <span>
                <button
                  id="cpvCancel"
                  className="tabletopbutton ml-2 text-right "
                  onClick={props.formCancel}
                >
                  Cancel
                </button>
              </span>
              <div className="loaderclass ml-1 float-right">
                {" "}
                {loader2 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
               
              </div>
            </div>

            <div className="col-12  mt-3 ">
              <table class="table tableCash table-hover">
                <thead class="">
                  <tr>
                    <th>Sr.</th>
                    <th>Account</th>
                    <th>Amount</th>
                    <th>Purchase Invoice</th>
                    <th>Narration</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((x, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{x.AccountTitle}</td>
                      <td>{x.Amount.toLocaleString()}</td>
                      <td>{x.InvoiceSerialNo}</td>
                      <td>{x.Narration}</td>
                      <th>
                        <span onClick={() => handledeleterow(idx)}>
                          <img
                            alt="AddRow"
                            style={{
                              cursor: "pointer",
                              width: "19px",
                            }}
                            src={Delete}
                          ></img>
                        </span>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
