import React, { useState} from "react";
import { CashPaymentForm } from "./CashPaymentForm";
import { CashPaymentTable } from "./CashPaymentTable";
const CashPaymentMain = () => {
    const changecomponent = () => {
      setComponentState(<CashPaymentForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <CashPaymentTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <CashPaymentTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };
  export default CashPaymentMain;
