import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";

  export class Salelist extends Component {
    render() {
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div
            className="row m-3 p-0"
            // style={{ border: "5px solid darkgrey", height: "80%" }}
          >
            <div className="col-12 text-center mt-2">
              <h3>
                <b>
                  <u>
                    Sale{" "}
                    {this.props.printname !== undefined
                      ? this.props.printname
                      : null}
                  </u>
                </b>
              </h3>
  
              <hr style={{ backgroundColor: "black" }} />
              <div className="mt-4">
                <div class="table-responsive col-12">
                  {this.props.printname === "Invoice" ? (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" className="th1">
                            Sr.
                          </th>
                          <th scope="col" className="th1">
                            Date
                          </th>
                          <th scope="col" className="th1">
                            Vendor
                          </th>
                          <th scope="col" className="th1">
                            Inv No.
                          </th>
                          {/* <th scope="col" className="th1">
                            Discount{" "}
                          </th>
                          <th scope="col" className="th1">
                            Others{" "}
                          </th>
                          <th scope="col" className="th1">
                            Freight{" "}
                          </th>
                          <th scope="col" className="th1">
                            Net{" "}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.contractdata !== undefined
                          ? this.props.contractdata.map((x, idx) => (
                              <tr>
                                <td>{idx + 1}</td>
                                <td>
                                  {x.Date !== undefined
                                    ? x.Date.split("T")[0]
                                    : null}
                                </td>
                                <td>{x.ClientName}</td>
                                <td>SInv-{x.SaleInvoiceId}</td>
                                {/* <td>{x?.Discount}</td> */}
                                {/* <td>{x?.Others}</td> */}
                                {/* <td>{x?.Freight}</td> */}
                                {/* <td>{x?.NetTotalAmount.toLocaleString()}</td> */}
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  ) : (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" className="th1">
                            Sr.
                          </th>
                          <th scope="col" className="th1">
                            Date
                          </th>
                          <th scope="col" className="th1">
                            Customer
                          </th>
                          <th scope="col" className="th1">
                            Narration
                          </th>
                          <th scope="col" className="th1">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.contractdata !== undefined
                          ? this.props.contractdata.map((x, idx) => (
                              <tr>
                                <td>{idx + 1}</td>
                                <td>
                                  {x.Date !== undefined
                                    ? x.Date.split("T")[0]
                                    : null}
                                </td>
                                <td>{x.ClientName}</td>
                                <td>{x.Narration}</td>
                                <td>
                                  {x.TotalAmount !== null
                                    ? x.TotalAmount.toLocaleString()
                                    : null}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4 text-center">________________</div>
              <div className="col-4 text-center">________________</div>
              <div className="col-4 text-center">________________</div>
  
              <div className="col-4 text-center">Prepared By</div>
              <div className="col-4 text-center">Checked By</div>
              <div className="col-4 text-center">Approved By</div>
            </div>
          </div>
        </div>
      );
    }
  }