import React, { useState} from "react";
import { CashReceiptForm } from "./CashReceiptForm";
import { CashReceiptTable } from "./CashReceiptTable";

const CashReceiptMain = () => {
    const changecomponent = () => {
      setComponentState(<CashReceiptForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <CashReceiptTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <CashReceiptTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };
  export default CashReceiptMain;
