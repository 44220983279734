import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";

export default class BrandWisePurchasePrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />
            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Brand Wise Purchase Report</u>
              </b>
            </h3>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.
                      </th>
                      <th scope="col" className="th1">
                        Date
                      </th>
                      <th scope="col" className="th1">
                        Transaction Type
                      </th>

                      <th scope="col" className="th1">
                        Party
                      </th>
                      <th scope="col" className="th1">
                        Rate
                      </th>
                      <th scope="col" className="th1">
                        Bags
                      </th>
                      <th scope="col" className="th1">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.contractdata !== undefined
                      ? this.props.contractdata.map((x, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{x.EntryDate.split("T")[0]}</td>
                            <td>{x.TransactionType}</td>
                            <td>{x.Party}</td>
                            <td>{x.Rate.toLocaleString()}</td>
                            <td>{x.ProductQty}</td>
                            <td>{x.Amount.toLocaleString()}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
