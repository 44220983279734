import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Delete from "../../../Images/delete.png";
import Loader from "react-loader-spinner";
import { getallpopulateaccounts } from "../../actions/getpopulateaccounts";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
var converter = require("number-to-words");

export const CashReceiptForm = (props) => {
  const [Receiptlist, setReceiptlist] = useState([]);
  const [displayinvoice, setdisplayinvoice] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [initialdata, setinitialdata] = useState(null);
  const [saleinvoices, setsaleinvoices] = useState([]);
  const [invoiceamount, setinvoiceamount] = useState(null);
  const [conversionamount, setconversionamount] = useState(0);
  const [list, setlist] = useState([]);
  const [invoiceidlist, setinvoiceidlist] = useState([]);
  const [invoiceamountlist, setinvoiceamountlist] = useState([]);
  const [AccountTitle, setAccountTitle] = useState("");
  const [accountid, setaccountid] = useState(null);
  const [selectedInvoice, setselectedInvoice] = useState([]);
  const [SaleInvoiceListID, setSaleInvoiceListID] = useState([]);
  const [CashAcc, setCashAcc] = useState(null);
  
  var today = new Date();
  var todaydate = today.getDate();
  if (todaydate.toString().length === 1) {
    todaydate = "0" + todaydate.toString();
  }
  var currentmonth = today.getMonth();
  if (currentmonth.toString().length === 1) {
    currentmonth = "0" + (today.getMonth() + 1).toString();
  }
  var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;

  const paymentselleraccounts = () => {
    axios({
      method: "get",
      url: window.$url + "api/ReceiptVoucher/PopulateAccounts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setReceiptlist(result.data.ReceiptAccount);
        setinitialdata(result.data);
      },
      (error) => {
        toast.info(error);
      }
    );
  };

  useEffect(() => {
    paymentselleraccounts();
  }, []);

  const OnAccountChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("accountList").childNodes;
    let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        find = true;
        let item = Receiptlist?.find(
          (item, idx) =>
            // console.log(item)
            item?.Accid === parseInt(options[i].attributes.attributeId.value)
        );

        console.log(item);
        console.log(options);

        setAccountTitle(item.Accounttitle);
        document.getElementById("cashReceiptAccount").value = item.Accounttitle
        setaccountid(item.Accid);
        if (displayinvoice) {
          setsaleinvoices(item.SaleInvoice);
        } else {
          setsaleinvoices([]);
        }
        break;
      }
    }
    if (!find) {
      setaccountid("");
      setAccountTitle("");
      document.getElementById("cashReceiptAccount").value = ""

      setsaleinvoices([]);
    }
  };

  const handledeleterow = (idx) => {
    const rows = [...list];
    rows.splice(idx, 1);
    setlist(rows);
  };

  const saveandclose = () => {
    if (list.length !== 0) {
      setloader2(true);
      document.getElementById("crvSave").disabled = true;

      let Input = [];
      list.forEach((x) => {
        x.InvoiceId.map((x) => {
          console.log(x);
        });
        Input.push({
          ClientAccId: x.ClientAccId,
          InvoiceIds: x.InvoiceId !== null ? x.InvoiceId : null,
          EntryDate: x.EntryDate,
          Paymenttype: x.Paymenttype,
          VoucherType: x.VoucherType,
          VoucherDate: x.VoucherDate,
          Amount: x.Amount,
          Narration: x.Narration,
          AccId: x.AccId,
        });
      });

      axios({
        method: "post",
        url: window.$url + `api/ReceiptVoucher/AddReceiptVoucher`,
        data: JSON.stringify(Input),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          if (result.data?.IsSuccess) {
            setaccountid(null);
            toast.info(result.data.Message);
            props.formCancel();
          } else {
            toast.info(result.data.Message);
          }
        },
        (error) => {
          toast.info(error);
          setloader2(false);
          document.getElementById("crvSave").disabled = false;
        }
      );
    } else {
      toast.info("Please enter voucher details!");
    }
  };

  // let dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getallpopulateaccounts());
  // }, [dispatch]);

  useEffect(() => {
    if(initialdata){
      if (initialdata?.CashAccounts?.data?.length > 0) {
        setCashAcc(initialdata?.CashAccounts?.data[0].Accid);
      } else {
        setCashAcc(null);
      }
    }
  }, [initialdata]);

  const addtolistbutton = () => {
    console.log(CashAcc);
    var data = {
      AccountTitle: AccountTitle,
      ClientAccId: accountid,
      InvoiceId: SaleInvoiceListID !== null ? SaleInvoiceListID : null,
      InvoiceSerialNo:
        document.getElementById("purchaseinvoicedropdown") !== null
          ? document.getElementById("purchaseinvoicedropdown").value
          : null,
      EntryDate: moment().format("YYYY-MM-DD"),
      Paymenttype: "Cash",
      VoucherType: "CR Voucher",
      VoucherDate: document.getElementById("cashReceiptDate").value,
      Amount: parseFloat(
        document.getElementById("comPaymentamount").value.replace(/,/g, "")
      ),
      Narration: document.getElementById("cashPaymentNarration").value,
      AccId: CashAcc,
    };
    if (
      accountid !== null ||
      document.getElementById("comPaymentamount").value !== ""
      // document.getElementById("cashPaymentNarration").value !== ""
    ) {
      if (SaleInvoiceListID !== null) {
        if (SaleInvoiceListID.selectedIndex === 0) {
          toast.info("Please select an invoice!");
        } else {
          setlist([...list, data]);
          saleinvoices.forEach((x, idx) => {
            document.getElementById(`${idx}multipleinvoice`).checked = false;
          });
          setinvoiceamountlist([]);
          setinvoiceidlist([]);
          setSaleInvoiceListID([]);
          setaccountid(null);
          if (SaleInvoiceListID !== null) {
            setSaleInvoiceListID("");
          }
          // if (document.getElementById("purchaseinvoicedropdown").value !== null){
          //   document.getElementById("purchaseinvoicedropdown").value = ""
          // }
          setdisplayinvoice(false);
          document.getElementById("invoicecheckbox").checked = false;
          document.getElementById("comPaymentamount").value = "";
          setAccountTitle("");
          document.getElementById("cashReceiptAccount").value = "";

          document.getElementById("cashPaymentNarration").value = "";
          setconversionamount(0);
          setinvoiceamount(null);
        }
      } else {
        setlist([...list, data]);
        saleinvoices.forEach((x, idx) => {
          document.getElementById(`${idx}multipleinvoice`).checked = false;
        });
        setinvoiceamountlist([]);
        setinvoiceidlist([]);
        setSaleInvoiceListID([]);

        setaccountid(null);
        if (SaleInvoiceListID !== null) {
          setSaleInvoiceListID("");
        }
        // if (document.getElementById("purchaseinvoicedropdown").value !== null){
        //   document.getElementById("purchaseinvoicedropdown").value = ""
        // }
        setdisplayinvoice(false);
        document.getElementById("invoicecheckbox").checked = false;
        document.getElementById("comPaymentamount").value = "";
        document.getElementById("cashPaymentNarration").value = "";
        setAccountTitle("");
        document.getElementById("cashReceiptAccount").value = ""
        setconversionamount(0);
        setinvoiceamount(null);
      }
    } else {
      toast.info("Please fill all the fields!");
    }
  };

  useEffect(() => {
    if (invoiceamountlist.length !== 0) {
      let sum = 0;
      invoiceamountlist.forEach((x) => {
        sum += parseFloat(x);
      });
      setinvoiceamount(sum);
      setconversionamount(sum);
    } else {
      setinvoiceamount(null);
      setconversionamount(0);
    }
  }, [invoiceamountlist]);

  useEffect(() => {
    let abc = [];
    invoiceidlist.forEach((x) => {
      console.log(x);
      abc.push(`SInv-${x}`);
    });
    setselectedInvoice(abc);
  }, [invoiceidlist]);

  useEffect(() => {
    if (AccountTitle === "") {
      setinvoiceidlist([]);
      setSaleInvoiceListID([]);
      setinvoiceamount(null);
      setconversionamount(0);
    }
  }, [AccountTitle]);

  return (
    <>
      <div id="modalAddnewAccount" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close "
            onClick={() => {
              document.getElementById("modalAddnewAccount").style.display =
                "none";
            }}
          >
            &times;
          </span>
          <div>
            <table class="table table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Invoice No.
                  </th>
                  <th scope="col" className="th1">
                    Net Amount
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Select
                  </th>
                </tr>
              </thead>
              <tbody>
                {saleinvoices !== undefined ? (
                  saleinvoices.map((x, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{x.Date.split("T")[0]}</td>
                      <td>SInv-{x.SaleInvSerialNo}</td>
                      <td>{x?.NetTotalAmount?.toLocaleString()}</td>
                      <td>{x.Narration}</td>
                      <td>
                        <input
                          type="checkbox"
                          id={`${idx}multipleinvoice`}
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setinvoiceidlist([
                                ...invoiceidlist,
                                x.SaleInvSerialNo,
                              ]);
                              setSaleInvoiceListID([
                                ...SaleInvoiceListID,
                                x.SaleInvoiceId,
                              ]);

                              setinvoiceamountlist([
                                ...invoiceamountlist,
                                x.NetTotalAmount,
                              ]);
                            } else {
                              const rows = [...invoiceidlist];
                              rows.splice(idx, 1);
                              setinvoiceidlist(rows);

                              const rowsId = [...SaleInvoiceListID];
                              rowsId.splice(idx, 1);
                              setSaleInvoiceListID(rowsId);

                              const rows1 = [...invoiceamountlist];
                              rows1.splice(idx, 1);
                              setinvoiceamountlist(rows1);
                            }
                          }}
                        ></input>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className=" tableContainer2 overflow-auto pageheading Sitka mt-3">
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row  mt-2">
            <div className="col-2  text-right">
              <span className="txt">Invoice</span>
            </div>
            <div className="col-4  text-left">
              <span>
                <input
                  type="checkbox"
                  id="invoicecheckbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setconversionamount(0);
                      setReceiptlist(initialdata?.ClientAccounts);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""

                      setinvoiceamount(null);
                      setaccountid(null);
                    } else {
                      setconversionamount(0);
                      setReceiptlist(initialdata?.ReceiptAccount);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""
                      setinvoiceamount(null);
                      setaccountid(null);
                    }
                  }}
                  onClick={() => {
                    if (
                      document.getElementById("invoicecheckbox").checked ===
                      true
                    ) {
                      setdisplayinvoice(true);
                    } else {
                      setdisplayinvoice(false);
                    }
                  }}
                ></input>
              </span>
            </div>
            <div className="col-2 text-right">
              <span className="txt"> Account</span>
            </div>

            <div className="col-4  text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="cashReceiptAccount"
                  list="accountList"
                  onChange={OnAccountChange}
                  className="input_border formInput "
                  autoComplete="off"
                ></input>
                <datalist id="accountList">
                  {Receiptlist?.map((v, idx) => {
                    return (
                      <option attributeId={v?.Accid} value={v?.Accounttitle}>
                        {v?.Accounttitle}{v?.AccountTitle ? `- ${v?.AccountTitle}` : ""}
                      </option>
                    );
                  })}
                </datalist>
              </span>

              <span id="demo"></span>
            </div>

            <div className="col-2 mt-3  text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="cashReceiptDate"
                  defaultValue={date}
                  className="input_border formInput"
                ></input>
              </span>
            </div>

            {displayinvoice === false ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      id="comPaymentamount"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === "NaN") {
                          e.target.value = "";
                        }
                        if (invoiceamount && invoiceamount !== null) {
                          if (
                            parseFloat(
                              document
                                .getElementById("comPaymentamount")
                                .value.replace(/,/g, "")
                            ) > invoiceamount
                          ) {
                            document.getElementById("comPaymentamount").value =
                              invoiceamount;
                          }
                        }
                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("comPaymentamount")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Sale Invoice</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    <input
                      id="purchaseinvoicedropdown"
                      className="input_border formInput "
                      readOnly
                      onClick={() => {
                        document.getElementById(
                          "modalAddnewAccount"
                        ).style.display = "block";
                      }}
                      value={
                        selectedInvoice.length !== 0
                          ? selectedInvoice.toString()
                          : ""
                      }
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      id="comPaymentamount"
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === NaN) {
                          e.target.value = "";
                        }
                        if (invoiceamount) {
                          if (invoiceamount !== null) {
                            if (
                              parseFloat(
                                document
                                  .getElementById("comPaymentamount")
                                  .value.replace(/,/g, "")
                              ) > invoiceamount
                            ) {
                              document.getElementById(
                                "comPaymentamount"
                              ).value = invoiceamount;
                            }
                          }
                        }

                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("comPaymentamount")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            {displayinvoice === false ? (
              <>
                <div className="col-2 mt-3 text-right"></div>
                <div className="col-4 mt-3 text-left"></div>
              </>
            ) : (
              <></>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Narration</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                <textarea
                  style={{ height: "70px" }}
                  type="text"
                  id="cashPaymentNarration"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>

            <div className="col-6 mt-3 ">
              <span className="mr-3">
                <b>Amount in words: </b>{" "}
                {conversionamount &&
                  converter.toWords(
                    conversionamount !== null ||
                      conversionamount !== undefined ||
                      conversionamount !== NaN ||
                      conversionamount !== ""
                      ? conversionamount
                      : 0
                  )}
              </span>
            </div>
            <div className="col-6 mt-3  text-right">
              <span>
                {/* <button
                    id="crvSave"
                    onClick={saveandnew}
                    className="tabletopbutton  text-right greenbutton"
                  >
                    {"Save & New"}
                  </button> */}
              </span>
              <span>
                {/* <button
                    id="crvSave"
                    onClick={
                      saveandprint
                    }
                    className="tabletopbutton ml-2 text-right bluebutton"
                  >
                    {"Save & Print"}
                  </button> */}
              </span>
              <span>
                <button
                  id="crvSave"
                  onClick={addtolistbutton}
                  className="tabletopbutton ml-2 text-right greenbutton"
                >
                  {"Add to list"}
                </button>
              </span>
              <span>
                <button
                  id="crvSave"
                  onClick={saveandclose}
                  className="tabletopbutton ml-2 text-right redbutton"
                >
                  {"Save"}
                </button>
              </span>
              <span>
                <button
                  id="cpvCancel"
                  className="tabletopbutton ml-2 text-right "
                  onClick={props.formCancel}
                >
                  Cancel
                </button>
              </span>
              <div className="loaderclass ml-1 float-right">
                {" "}
                {loader2 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
                
              </div>
            </div>

            <div className="col-12  mt-3 ">
              <table class="table tableCash table-hover">
                <thead class="">
                  <tr>
                    <th>Sr.</th>
                    <th>Account</th>
                    <th>Amount</th>
                    <th>Sale Invoice</th>
                    <th>Narration</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((x, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{x.AccountTitle}</td>
                      <td>{x.Amount.toLocaleString()}</td>
                      <td>{x.InvoiceSerialNo}</td>
                      <td>{x.Narration}</td>
                      <th>
                        <span onClick={() => handledeleterow(idx)}>
                          <img
                            alt="AddRow"
                            style={{
                              cursor: "pointer",
                              width: "19px",
                            }}
                            src={Delete}
                          ></img>
                        </span>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
