import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";


 class Incomestatement extends Component {
    render() {
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div
            className="row m-3 p-0"
            // style={{ border: "5px solid darkgrey", height: "100%" }}
          >
            <div className="col-12 text-center mt-2">
              <h4>
                <b>
                  <u>Income Statement</u>
                </b>
              </h4>
  
              <hr style={{ backgroundColor: "black" }} />
              <div className="col-12">
                <div className="row mt-2">
                  <div class="table-responsive col-12">
                    <table class="table">
                      <thead>
                        <tr className="purple whiteText">
                          <th>ID</th>
                          <th>Particulars</th>
                          <th className=" text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.incomedata !== undefined &&
                        this.props.incomedata.Sales !== undefined
                          ? this.props.incomedata.Sales.map((val) => (
                              <tr>
                                <td className="nobordertop">{val.Id}</td>
                                <td className="nobordertop">{val.Name}</td>
  
                                <td className="nobordertop text-right">
                                  {parseFloat(val.Amount).toLocaleString()}
                                </td>
                              </tr>
                            ))
                          : null}
  
                        <tr>
                          <th className="nobordertop"></th>
                          <th className="nobordertop">OTHER INCOME</th>
                          <th className="nobordertop text-right"></th>
                        </tr>
  
                        {this.props.incomedata !== undefined &&
                        this.props.incomedata.OtherIncome !== undefined
                          ? this.props.incomedata.OtherIncome.map((x) => (
                              <tr>
                                <td className="nobordertop">{x.Id}</td>
                                <td className="nobordertop">{x.Name}</td>
  
                                <td className="nobordertop text-right">
                                  {x.Amount.toLocaleString()}
                                </td>
                              </tr>
                            ))
                          : null}
  
                        {/* <tr>
                      <th className="nobordertop"></th>
                      <th className="nobordertop">INDIRECT OTHER INCOME</th>
                      <th className="nobordertop text-right"></th>
                    </tr>
  
                    {data !== undefined && data.IndirectIncomeList !== undefined
                      ? data.IndirectIncomeList.map((x) => (
                        <tr>
                          <td className="nobordertop">{x.Id}</td>
                          <td className="nobordertop">{x.Name}</td>
  
                          <td className="nobordertop text-right">
                            {x.Amount.toLocaleString()}
                          </td>
                        </tr>
                      ))
                      : null} */}
  
                        <tr>
                          <th className="nobordertop"></th>
                          <th className="nobordertop">TOTAL</th>
                          <th className="bordertop text-right">
                            {this.props.incomedata !== undefined &&
                            this.props.incomedata.TotalSales !== undefined &&
                            this.props.incomedata.OtherIncomeSum !== undefined
                              ? (
                                  this.props.incomedata.OtherIncomeSum +
                                  this.props.incomedata.TotalSales
                                ).toLocaleString()
                              : null}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 text-center">
                    <h5>
                      <b>COST OF SALES</b>
                    </h5>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div class=" col-12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead></thead>
                        <tbody>
                          {this.props.incomedata !== undefined &&
                          this.props.incomedata.CostOfSales !== undefined
                            ? this.props.incomedata.CostOfSales.map((val) => (
                                <tr>
                                  <td className="nobordertop">{val.Id}</td>
                                  <td className="nobordertop">{val.Name}</td>
  
                                  <td className="nobordertop text-right">
                                    {parseFloat(val.Amount).toLocaleString()}
                                  </td>
                                </tr>
                              ))
                            : null}
  
                          {/* <tr>
                        <td className="nobordertop">+</td>
                        <td className="nobordertop"></td>
  
                        <td className="nobordertop text-right"></td>
                      </tr>
  
                      {data !== undefined && data.CostOfSales !== undefined ? (
                        <tr>
                          <td>{data.CostOfSales[2].id}</td>
                          <td>Purchase</td>
                          <td>{data.CostOfSales[2].Amount.toLocaleString()}</td>
                        </tr>
                      ) : null}
  
                      <tr>
                        <td className="nobordertop">-</td>
                        <td className="nobordertop"></td>
  
                        <td className="nobordertop text-right"></td>
                      </tr>
  
                      {data !== undefined && data.CostOfSales !== undefined ? (
                        <tr>
                          <td>{data.CostOfSales[1].id}</td>
                          <td>Closing Inventory</td>
                          <td>{data.CostOfSales[1].Amount.toLocaleString()}</td>
                        </tr>
                      ) : null} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div class="table-responsive col-12">
                    <table class="table">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <b>TOTAL COST OF SALES</b>
                            {/* (Opening Stock +
                        Purchases—Closing Stock) */}
                          </td>
  
                          <td className="nobordertop text-right">
                            {this.props.incomedata.CostOfSalesSum !== undefined
                              ? this.props.incomedata.CostOfSalesSum.toLocaleString()
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 text-center">
                    <h5>
                      <b>Direct Cost</b>
                    </h5>
                  </div>
                </div>
                <div className="row mt-2">
                  <div class=" col-12">
                    <div class="table-responsive">
                      <table class="table">
                        <thead></thead>
                        <tbody>
                          {this.props.incomedata !== undefined &&
                          this.props.incomedata.DirectCost !== undefined
                            ? this.props.incomedata.DirectCost.map((x) => (
                                <tr>
                                  <td className="nobordertop">{x.Id}</td>
                                  <td className="nobordertop">{x.Name}</td>
  
                                  <td className="nobordertop text-right">
                                    {x.Amount.toLocaleString()}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div class="table-responsive col-12">
                    <table class="table">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <b>TOTAL DIRECT COST</b>
                          </td>
                          <td className="nobordertop text-right">
                            {this.props.incomedata !== undefined &&
                            this.props.incomedata.DirectCostSum !== undefined
                              ? this.props.incomedata.DirectCostSum.toLocaleString()
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <b></b>
                          </td>
                          <td className="bordertop text-right"></td>
                        </tr>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <h5>
                              <b>GROSS PROFIT</b>
                            </h5>
                          </td>
                          <td className="nobordertop text-right">
                            <h5>
                              {this.props.incomedata !== undefined &&
                              this.props.incomedata.CostOfSalesSum !==
                                undefined &&
                              this.props.incomedata.OtherIncomeSum !==
                                undefined &&
                              this.props.incomedata.DirectCostSum !== undefined
                                ? (
                                    this.props.incomedata.OtherIncomeSum +
                                    this.props.incomedata.TotalSales -
                                    (this.props.incomedata.DirectCostSum +
                                      this.props.incomedata.CostOfSalesSum)
                                  ).toLocaleString()
                                : null}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 text-center">
                    <h5>
                      <b>OPERATING EXPENSES</b>
                    </h5>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div class=" col-8">
                    <div class="table-responsive">
                      <table class="table">
                        <thead></thead>
                        <tbody>
                          {this.props.incomedata !== undefined &&
                          this.props.incomedata.OperatingExpenseList !== undefined
                            ? this.props.incomedata.OperatingExpenseList.map(
                                (x) => (
                                  // x.Name !== "Duties and Taxes" ? (
                                  <tr>
                                    <td className="nobordertop">{x.Id}</td>
                                    <td className="nobordertop">{x.Name}</td>
  
                                    <td className="nobordertop text-right">
                                      {x.Amount.toLocaleString()}
                                    </td>
                                  </tr>
                                )
                                // ): null
                              )
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div class="table-responsive col-12">
                    <table class="table">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <b>TOTAL OPERATING EXPENSES</b>
                          </td>
                          <td className="bordertop text-right">
                            {this.props.incomedata !== undefined &&
                            this.props.incomedata.OperatingExpenseListSum !==
                              undefined
                              ? this.props.incomedata.OperatingExpenseListSum.toLocaleString()
                              : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <h5>
                              <b>Profit before taxation</b>
                            </h5>
                          </td>
                          <td className="bordertop text-right">
                            <h5>
                              {this.props.incomedata !== undefined &&
                              this.props.incomedata.OperatingExpenseListSum !==
                                undefined &&
                              this.props.incomedata.OtherIncomeSum !==
                                undefined &&
                              this.props.incomedata.DirectCostSum !== undefined
                                ? (
                                    this.props.incomedata.OtherIncomeSum +
                                    this.props.incomedata.TotalSales -
                                    (this.props.incomedata.DirectCostSum +
                                      this.props.incomedata.CostOfSalesSum +
                                      this.props.incomedata
                                        .OperatingExpenseListSum)
                                  ).toLocaleString()
                                : null}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <h5>
                              <b>Tax</b>
                            </h5>
                          </td>
                          <td className="nobordertop text-right">
                            <h5>{this.props.incomedata.TaxSum}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td className="nobordertop"></td>
                          <td className="nobordertop">
                            <h5>
                              <b>NET PROFIT FOR THE YEAR</b>
                            </h5>
                          </td>
                          <td className="bordertop text-right">
                            <h5>
                              {this.props.incomedata !== undefined &&
                              this.props.incomedata.OperatingExpenseListSum !==
                                undefined &&
                              this.props.incomedata.OtherIncomeSum !==
                                undefined &&
                              this.props.incomedata.DirectCostSum !== undefined
                                ? (
                                    this.props.incomedata.OtherIncomeSum +
                                    this.props.incomedata.TotalSales -
                                    (this.props.incomedata.DirectCostSum +
                                      this.props.incomedata.CostOfSalesSum +
                                      this.props.incomedata
                                        .OperatingExpenseListSum) -
                                    this.props.incomedata.TaxSum
                                  ).toLocaleString()
                                : null}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  export default Incomestatement