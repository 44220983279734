import React, { useState, useRef } from "react";
import axios from "axios";
import "../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from "react-toastify";

import CashBookPrint from "../Prints/CashBookPrint";
import Loader from "react-loader-spinner";

/* voucher start */
// cash payment page

const CashBook = () => {
  const [cashaccounts, setcashaccounts] = useState([]);
  const [credit, setCreditTotal] = useState();
  const [debit, setdebitTotal] = useState();
  const [balance, setbalanceTotal] = useState();
  const [open, setopen] = useState();
  const [curr, setcurr] = useState();
  const [loader1, setloader1] = useState(false);

  const apicashbook = () => {
    setCreditTotal();
    setdebitTotal();
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/CashBook?From=${
        document.getElementById("cashfrom").value
      }&To=${document.getElementById("cashto").value}`,

      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      // data: JSON.stringify({
      //   From: document.getElementById("cashfrom").value,
      //   To: document.getElementById("cashto").value,
      // }),
    }).then(
      (result) => {
        console.log("cashbook", result);
        if (result.data.success) {
          setcashaccounts(result.data.cash);
          setopen(parseFloat(result.data.open).toLocaleString());
          setcurr(parseFloat(result.data.current).toLocaleString());

          let debit = 0;
          let credit = 0,
            balance = 0;

          result.data.cash.map((val) =>
            val.transtype === "Debit"
              ? val.amount !== null
                ? (debit += parseFloat(val.amount))
                : null
              : null
          );

          result.data.cash.map((val) =>
            val.transtype === "Credit"
              ? val.amount !== null
                ? (credit += parseFloat(val.amount))
                : null
              : null
          );

          result.data.cash.map((val) => {
            balance += parseFloat(val.balance);
          });

          setbalanceTotal(balance.toLocaleString());
          setdebitTotal(debit.toLocaleString());
          setCreditTotal(credit.toLocaleString());
          setloader1(false);
        } else {
          toast.info(result.data.message);
          setloader1(false);
        }
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div hidden>
        <CashBookPrint
          ref={componentRef}
          allaccounts={cashaccounts}
          debitTotal={debit}
          creditTotal={credit}
          currentbalance={curr}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="col-12 pt-1">
            {/* <div className="row mt-1">
              <div className="col-6 text-left">
                <b>OpeningBalance= {open}</b>
              </div>
              <div className="col-6 text-right">
                <b>CurrentBalance= {curr}</b>
              </div>
            </div> */}
            <div className="row mt-2">
              <div className="col-4 text-right">
                From:
                <span>
                  {" "}
                  <input
                    type="date"
                    className="datefield"
                    id="cashfrom"
                    defaultValue={moment().format("YYYY-07-01")}
                  />
                </span>
              </div>
              <div className="col-4 text-right">
                To:
                <span>
                  {" "}
                  <input
                    type="date"
                    className="datefield"
                    id="cashto"
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </span>
              </div>
              <div className="col-4 text-right pr-5">
                <span>
                  <button
                    id="callapibutn"
                    onClick={apicashbook}
                    className="tabletopbutton   text-right ml-2 "
                  >
                    GO
                  </button>
                  <button
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span>
                <span className="loaderclass float-right">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table table-hover">
                  <thead>
                    <tr className="">
                      <th>Date</th>
                      <th>Account</th>
                      <th>Narration</th>
                      <th>Voucher No.</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashaccounts.map((x) => (
                      <tr>
                        <th>{moment(x.transdate).format("DD MMM, YY")}</th>
                        <th>{x?.acc?.accounttitle}</th>
                        <th>{x.narration}</th>
                        {x.referenceno == null ? (
                          <th>{x.vreferenceno}</th>
                        ) : (
                          <th>{x.referencetype.concat("-", x.referenceno)}</th>
                        )}
                        {x.transtype === "Debit" ? (
                          <th>{x.amount.toLocaleString()}</th>
                        ) : (
                          <th></th>
                        )}
                        {x.transtype === "Credit" ? (
                          <th>{x.amount.toLocaleString()}</th>
                        ) : (
                          <th></th>
                        )}
                        <th>{x.balance.toLocaleString()}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-md-4  ">
                Total Debit:
                <input
                  type="text"
                  id="debittotal"
                  value={debit}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
              <div className="col-md-4   ">
                Total Credit:
                <input
                  type="text"
                  id="credittotal"
                  value={credit}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
              <div className="col-md-4   ">
                Total Balance:
                <input
                  type="text"
                  id="balancetotal"
                  value={balance}
                  readOnly
                  className="partner_inputfields"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashBook;
