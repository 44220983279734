import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import DiscardChqueSummaryPrint from "./DiscardChqueSummaryPrint";

const DiscardChqueSummaryMain = () => {
  const [DiscardedChequedata, setDiscardedChequedata] = useState([]);
  const [newDiscardedChequedata, setnewDiscardedChequedata] = useState([]);
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [loader1, setloader1] = useState(false);

  const getAllDiscardedCheque = () => {
    setloader1(true);
    axios({
      method: "get",
      url: `${window.$url}api/Accounts/GetAllDiscardedCheque`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setDiscardedChequedata(result.data.DiscardedCheques);
        setnewDiscardedChequedata(result.data.DiscardedCheques);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    getAllDiscardedCheque();
  }, []);

  //Table Data fiter
  var suggestionlist = [];

  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setDiscardedChequedata(newDiscardedChequedata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newDiscardedChequedata
          .sort()
          .filter((v) => regex.test(v.Bank.BankAccountName));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setDiscardedChequedata([]);
      } else {
        setDiscardedChequedata(suggestionlist);
      }
    }
  };
  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setDiscardedChequedata(newDiscardedChequedata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newDiscardedChequedata
          .sort()
          .filter((v) => regex.test(v.Bank.BankName));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setDiscardedChequedata([]);
      } else {
        setDiscardedChequedata(suggestionlist);
      }
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div hidden>
        <DiscardChqueSummaryPrint
          ref={componentRef}
          DiscardedChequedata={DiscardedChequedata}
        />
      </div>
      <div className="tableContainer2 pageheading Sitka mt-2">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Bank"
                  className="input_border"
                  onChange={onTextChange2}
                ></input>
              </span>
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Account Name"
                  className="input_border"
                  onChange={onTextChange1}
                ></input>
              </span>

              <span className="ml-2">
                <button
                  id="callapibutn"
                  className="tabletopbutton"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>

              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row">
            <table class="table table-hover tableCash ">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  {/* <th scope="col" className="th1">
                    Date
                  </th> */}
                  <th scope="col" className="th1">
                    Bank Name
                  </th>
                  <th scope="col" className="th1">
                    Account Name
                  </th>
                  <th scope="col" className="th1">
                    Account Number
                  </th>
                  <th scope="col" className="th1">
                    Cheque No
                  </th>
                  <th scope="col" className="th1">
                    Cheque Book
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                </tr>
              </thead>
              <tbody>
                {DiscardedChequedata !== undefined
                  ? DiscardedChequedata.map((x, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        {/* <td>{x.Dateadded.split("T")[0]}</td> */}
                        <td>{x.Bank.BankName}</td>
                        <td>{x.Bank.BankAccountName}</td>
                        <td>{x.Bank.AccountNumber}</td>
                        <td>{x.ChequeNumber}</td>
                        <td>{x.ChequeBook.ChequeBookTitle}</td>
                        <td>{x.Narration}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default DiscardChqueSummaryMain;
