import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

import YarnMain from "../InventoryNew/Yarn/YarnMain";
import ItemWiseStockMain from "../InventoryNew/ItemWiseStock/ItemWiseStockMain";
import YarnWiseTransMain from "../InventoryNew/YarnWiseTrans/YarnWiseTransMain";
import BrandWiseSaleMain from "../InventoryNew/BrandWiseSale/BrandWiseSaleMain";
import BrandWisePurchaseMain from "../InventoryNew/BrandWisePurchase/BrandWisePurchaseMain";

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{width:"16%"}}
      className={`button1 ${active ? "button1Clicked" : ""} ${count === "Item"
        ? "blue"
        : count === "Item Wise Stock"
          ? "red"
          
                  : count === "Item Wise Trans."
                  ? "seaGreen"
                  : count === "Item Wise Trans."
                  ? "purple"
                  : count === "Item Type Wise Sale"
                  ? "darkred"
                  : count === "Item Type Wise Purchase"
                  ? "blue"
                  : count
        }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Inventory = () => {
  const button = [
    { button1: "Item", component: <YarnMain/> },
    { button1: "Item Wise Stock", component: <ItemWiseStockMain/> },
    { button1: "Item Wise Trans.", component: <YarnWiseTransMain/> },
    // { button1: "Item Wise Trans.", component: <YarnWiseTransMain/> },
    { button1: "Item Type Wise Sale", component: <BrandWiseSaleMain/> },
    { button1: "Item Type Wise Purchase", component: <BrandWisePurchaseMain /> },
    
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Item",
    component: <YarnMain/>,
  });


  return (
    <>
      <div style={{ height: "10%" }} >
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "87%" }} >
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Inventory;
