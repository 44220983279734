import React, { useState, useRef } from "react";
import axios from "axios";

import "../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";

import  Incomestatement  from "./IncomeStatementPrint";

/* voucher start */
// cash payment page

const IncomeStatementMain = () => {
  const [loader1, setloader1] = useState(false);
  const [from, setfrom] = useState(moment().format("YYYY-07-01"));
  const [to, setto] = useState(moment().format("YYYY-MM-DD"));
  const [incomedata, setincomedata] = useState([]);

  const apicashbook = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Reports/ProfitLossStatement?From=${from}&To=${to}`,
      method: "Get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("income statment", result);
        setincomedata(result.data);
        // setexpense(result.data.expenses);

        // let debit = 0;
        // let credit = 0;

        // result.data.income.map((val) =>
        //   val !== null
        //     ? val.currentBalance !== null
        //       ? (debit += parseInt(val.currentBalance))
        //       : null
        //     : null
        // );

        // result.data.expenses.map((val) =>
        //   val !== null
        //     ? val.currentBalance !== null
        //       ? (credit += parseInt(val.currentBalance))
        //       : null
        //     : null
        // );
        // setNetTotal((debit - credit).toLocaleString());
        // setdebitTotal(debit.toLocaleString());
        // setCreditTotal(credit.toLocaleString());
        setloader1(false);
      },
      (error) => {
        console.log(error);
        setloader1(false);
      }
    );
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div hidden>
        <Incomestatement ref={componentRef} incomedata={incomedata} />
      </div>{" "}
      <div className="SettingContainer1 mt-3 Sitk dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row mt-2">
            <div className="col-4 text-right">
              From:
              <span>
                {" "}
                <input
                  type="date"
                  className="datefield"
                  id="cashfrom"
                  onChange={(e) => setfrom(e.target.value)}
                  defaultValue={moment().format("YYYY-07-01")}
                />
              </span>
            </div>
            <div className="col-4 text-right">
              To:
              <span>
                {" "}
                <input
                  type="date"
                  className="datefield"
                  id="cashto"
                  onChange={(e) => setto(e.target.value)}
                  defaultValue={moment().format("YYYY-MM-DD")}
                />
              </span>
            </div>
            <div className="col-4 text-right pr-5">
              <span>
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={() => apicashbook()}
                >
                  GO
                </button>
              </span>
              <span className="ml-2 float-right">
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>

          <div className="col-12">
            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr className="">
                      <th>ID</th>
                      <th>Particulars</th>
                      <th className=" text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomedata !== undefined && incomedata.Sales !== undefined
                      ? incomedata.Sales.map((val) => (
                          <tr>
                            <td className="nobordertop">{val.Id}</td>
                            <td className="nobordertop">{val.Name}</td>

                            <td className="nobordertop text-right">
                              {parseFloat(val.Amount).toLocaleString()}
                            </td>
                          </tr>
                        ))
                      : null}

                    <tr>
                      <th className="nobordertop"></th>
                      <th className="nobordertop">OTHER INCOME</th>
                      <th className="nobordertop text-right"></th>
                    </tr>

                    {incomedata !== undefined &&
                    incomedata.OtherIncome !== undefined
                      ? incomedata.OtherIncome.map((x) => (
                          <tr>
                            <td className="nobordertop">{x.Id}</td>
                            <td className="nobordertop">{x.Name}</td>

                            <td className="nobordertop text-right">
                              {x.Amount.toLocaleString()}
                            </td>
                          </tr>
                        ))
                      : null}

                    {/* <tr>
                    <th className="nobordertop"></th>
                    <th className="nobordertop">INDIRECT OTHER INCOME</th>
                    <th className="nobordertop text-right"></th>
                  </tr>

                  {data !== undefined && data.IndirectIncomeList !== undefined
                    ? data.IndirectIncomeList.map((x) => (
                      <tr>
                        <td className="nobordertop">{x.Id}</td>
                        <td className="nobordertop">{x.Name}</td>

                        <td className="nobordertop text-right">
                          {x.Amount.toLocaleString()}
                        </td>
                      </tr>
                    ))
                    : null} */}

                    <tr>
                      <th className="nobordertop"></th>
                      <th className="nobordertop">TOTAL</th>
                      <th className="bordertop text-right">
                        {incomedata !== undefined &&
                        incomedata.TotalSales !== undefined &&
                        incomedata.OtherIncomeSum !== undefined
                          ? (
                              incomedata.OtherIncomeSum + incomedata.TotalSales
                            ).toLocaleString()
                          : null}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 text-center">
                <h5>
                  <b>COST OF SALES</b>
                </h5>
              </div>
            </div>

            <div className="row mt-2">
              <div class=" col-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      {incomedata !== undefined &&
                      incomedata.CostOfSales !== undefined
                        ? incomedata.CostOfSales.map((val) => (
                            <tr>
                              <td className="nobordertop">{val.Id}</td>
                              <td className="nobordertop">{val.Name}</td>

                              <td className="nobordertop text-right">
                                {parseFloat(val.Amount).toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}

                      {/* <tr>
                      <td className="nobordertop">+</td>
                      <td className="nobordertop"></td>

                      <td className="nobordertop text-right"></td>
                    </tr>

                    {data !== undefined && data.CostOfSales !== undefined ? (
                      <tr>
                        <td>{data.CostOfSales[2].id}</td>
                        <td>Purchase</td>
                        <td>{data.CostOfSales[2].Amount.toLocaleString()}</td>
                      </tr>
                    ) : null}

                    <tr>
                      <td className="nobordertop">-</td>
                      <td className="nobordertop"></td>

                      <td className="nobordertop text-right"></td>
                    </tr>

                    {data !== undefined && data.CostOfSales !== undefined ? (
                      <tr>
                        <td>{data.CostOfSales[1].id}</td>
                        <td>Closing Inventory</td>
                        <td>{data.CostOfSales[1].Amount.toLocaleString()}</td>
                      </tr>
                    ) : null} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <b>TOTAL COST OF SALES</b>
                        {/* (Opening Stock +
                      Purchases—Closing Stock) */}
                      </td>

                      <td className="nobordertop text-right">
                        {incomedata.CostOfSalesSum !== undefined
                          ? incomedata.CostOfSalesSum.toLocaleString()
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 text-center">
                <h5>
                  <b>Direct Cost</b>
                </h5>
              </div>
            </div>
            <div className="row mt-2">
              <div class=" col-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      {incomedata !== undefined &&
                      incomedata.DirectCost !== undefined
                        ? incomedata.DirectCost.map((x) => (
                            <tr>
                              <td className="nobordertop">{x.Id}</td>
                              <td className="nobordertop">{x.Name}</td>

                              <td className="nobordertop text-right">
                                {x.Amount.toLocaleString()}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <b>TOTAL DIRECT COST</b>
                      </td>
                      <td className="nobordertop text-right">
                        {incomedata !== undefined &&
                        incomedata.DirectCostSum !== undefined
                          ? incomedata.DirectCostSum.toLocaleString()
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <b></b>
                      </td>
                      <td className="bordertop text-right"></td>
                    </tr>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <h5>
                          <b>GROSS PROFIT</b>
                        </h5>
                      </td>
                      <td className="nobordertop text-right">
                        <h5>
                          {incomedata !== undefined &&
                          incomedata.CostOfSalesSum !== undefined &&
                          incomedata.OtherIncomeSum !== undefined &&
                          incomedata.DirectCostSum !== undefined
                            ? (
                                incomedata.OtherIncomeSum +
                                incomedata.TotalSales -
                                (incomedata.DirectCostSum +
                                  incomedata.CostOfSalesSum)
                              ).toLocaleString()
                            : null}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 text-center">
                <h5>
                  <b>OPERATING EXPENSES</b>
                </h5>
              </div>
            </div>

            <div className="row mt-2">
              <div class=" col-8">
                <div class="table-responsive">
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      {incomedata !== undefined &&
                      incomedata.OperatingExpenseList !== undefined
                        ? incomedata.OperatingExpenseList.map(
                            (x) => (
                              // x.Name !== "Duties and Taxes" ? (
                              <tr>
                                <td className="nobordertop">{x.Id}</td>
                                <td className="nobordertop">{x.Name}</td>

                                <td className="nobordertop text-right">
                                  {x.Amount.toLocaleString()}
                                </td>
                              </tr>
                            )
                            // ): null
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <b>TOTAL OPERATING EXPENSES</b>
                      </td>
                      <td className="bordertop text-right">
                        {incomedata !== undefined &&
                        incomedata.OperatingExpenseListSum !== undefined
                          ? incomedata.OperatingExpenseListSum.toLocaleString()
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <h5>
                          <b>Profit before taxation</b>
                        </h5>
                      </td>
                      <td className="bordertop text-right">
                        <h5>
                          {incomedata !== undefined &&
                          incomedata.OperatingExpenseListSum !== undefined &&
                          incomedata.OtherIncomeSum !== undefined &&
                          incomedata.DirectCostSum !== undefined
                            ? (
                                incomedata.OtherIncomeSum +
                                incomedata.TotalSales -
                                (incomedata.DirectCostSum +
                                  incomedata.CostOfSalesSum +
                                  incomedata.OperatingExpenseListSum)
                              ).toLocaleString()
                            : null}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <h5>
                          <b>Tax</b>
                        </h5>
                      </td>
                      <td className="nobordertop text-right">
                        <h5>{incomedata.TaxSum}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="nobordertop"></td>
                      <td className="nobordertop">
                        <h5>
                          <b>NET PROFIT FOR THE YEAR</b>
                        </h5>
                      </td>
                      <td className="bordertop text-right">
                        <h5>
                          {incomedata !== undefined &&
                          incomedata.OperatingExpenseListSum !== undefined &&
                          incomedata.OtherIncomeSum !== undefined &&
                          incomedata.DirectCostSum !== undefined
                            ? (
                                incomedata.OtherIncomeSum +
                                incomedata.TotalSales -
                                (incomedata.DirectCostSum +
                                  incomedata.CostOfSalesSum +
                                  incomedata.OperatingExpenseListSum) -
                                incomedata.TaxSum
                              ).toLocaleString()
                            : null}
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeStatementMain
