import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import "../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import moment from "moment";
import { toast } from "react-toastify";

import JournalPrint from "./GeneralJournalPrint";

const GeneralJournalMain = () => {
  const [stateledger, setLedger] = useState([]);
  const [creditTotal, setCreditTotal] = useState(0);
  const [debitTotal, setdebitTotal] = useState(0);
  const [statebalance, setBalance] = useState(0);
  const [category, setcategory] = useState("");
  const [stateAccountList, setStateAccountList] = useState([]);
  const [itemval, setitemval] = useState();
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [accountname, setaccountname] = useState("");
  const [fromdate, setfromdate] = useState(moment().format("YYYY-MM-DD"));
  const [todate, settodate] = useState(moment().format("YYYY-MM-DD"));
  const [accid, setaccid] = useState("");
  const [from, setfrom] = useState(moment().format("YYYY-07-01"));
  const [to, setto] = useState(moment().format("YYYY-MM-DD"));

  const [accountslist, setaccountslist] = useState([]);
  const [accountslistfilter, setaccountslistfilter] = useState([]);

  var suggestionlist = [];
  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value == "") {
      ReactDOM.render(null, document.getElementById("demo"));
    } else {
      console.log(value);
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = stateAccountList
          .sort()
          .filter((v) => regex.test(v.accounttitle));
      }
      console.log(suggestions);
      suggestionlist = suggestions;
      if (suggestionlist.length === 0) {
        ReactDOM.render(null, document.getElementById("demo"));
      } else {
        ReactDOM.render(
          <ul className="suggestbox" style={{ width: "65%" }}>
            {suggestionlist.map((item) =>
              item.accounttitle === undefined ? (
                <li className="listcss">{item}</li>
              ) : (
                <li className="listcss" onClick={() => getGeneralLedger(item)}>
                  {item.accounttitle}
                </li>
              )
            )}
          </ul>,
          document.getElementById("demo")
        );
      }
    }
  };

  var suggestionlist1 = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setaccountslist(accountslistfilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = accountslistfilter
          .sort()
          .filter((v) => regex.test(v.particular));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setaccountslist([]);
      } else {
        setaccountslist(suggestionlist);
      }
    }
  };

  function getGeneralLedger(item) {
    document.getElementById("nameinput").value = item.accounttitle;
    setaccountname(item.accounttitle);
    setitemval(item);
    setaccid(item.accid);

    ReactDOM.render(null, document.getElementById("demo"));
    console.log(itemval);
  }
  function getAccounts() {
    setloader2(true);
    axios({
      url: `${
        window.$url
      }/Accounts/GetAllAccounts?projectid=${localStorage.getItem("ProjectId")}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("kk", result);
        setStateAccountList(result.data);
        setloader2(false);
      },
      (error) => {
        console.log(error);
        setloader2(false);
      }
    );
  }

  const getgeneraljournal = () => {
    if (from === "" || to === "") {
      toast.info("Please fill all the fields!");
    } else {
      setloader1(true);
      axios({
        url: window.$url + `api/Reports/GeneralJournal?From=${from}&To=${to}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setloader1(false);
          console.log(result);
          setaccountslist(result.data);
          setaccountslistfilter(result.data);
          let debit = 0,
            credit = 0;
          result.data.forEach((x, idx) => {
            if (x.debit !== "") {
              debit += parseFloat(x.debit);
            }
            if (x.credit !== "") {
              credit += parseFloat(x.credit);
            }
          });

          setdebitTotal(debit.toLocaleString());
          setCreditTotal(credit.toLocaleString());
          setBalance((debit - credit).toLocaleString());
        },
        (error) => {
          setloader1(false);
          console.log(error);
        }
      );
    }
  };

 

  const callapi = () => {
    setloader1(true);
    document.getElementById("callapibutn").disabled = true;
    setCreditTotal(0);
    setdebitTotal(0);
    console.log(itemval);
    if (itemval != undefined) {
      axios({
        url: `${
          window.$url
        }/Reports/GeneralLedger?Projectid=${localStorage.getItem("ProjectId")}`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify({
          // AccId: 230,
          // From: "2020-10-1T12:00",
          // To: "2020-10-15T12:00"

          AccId: itemval.accid,
          From: document.getElementById("ledgerfrom").value,
          To: document.getElementById("ledgerto").value,
        }),
      }).then(
        (result) => {
          console.log("hello", result);
          console.log(stateledger);
          setLedger(result.data.account);
          setdebitTotal(parseFloat(result.data.debit).toLocaleString());
          setCreditTotal(parseFloat(result.data.credit).toLocaleString());
          setBalance(
            (
              parseFloat(result.data.debit) - parseFloat(result.data.credit)
            ).toLocaleString()
          );

          setcategory(result.data.category);
          setloader1(false);
          document.getElementById("callapibutn").disabled = false;
        },
        (error) => {
          console.log(error);
          setloader1(false);
          document.getElementById("callapibutn").disabled = false;
        }
      );
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div hidden>
        <JournalPrint
          ref={componentRef}
          allaccounts={stateledger}
          accountname={accountname}
          fromdate={fromdate}
          todate={todate}
          debitTotal={debitTotal}
          creditTotal={creditTotal}
          statebalance={statebalance}
          category={category}
          accountslist={accountslist}
        />
      </div>
      <div className="SettingContainer1 mt-3 Sitka dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span> From: </span>
              <input
                type="date"
                id="cashfrom"
                onChange={(e) => setfrom(e.target.value)}
                defaultValue={moment().format("YYYY-07-01")}
                className="input_border  ml-1"
              ></input>
              <span> To: </span>
              <input
                type="date"
                id="cashto"
                onChange={(e) => setto(e.target.value)}
                defaultValue={moment().format("YYYY-MM-DD")}
                className="input_border  ml-1"
              ></input>
              {/* <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Account"
                  className="input_border"
                  onChange={onTextChange}
                  id="cashPaymentAccount"
                ></input>{" "}
                <span id="demo"></span>
              </span> */}
              <span className="ml-2">

              <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={() => {
                    getgeneraljournal();
                  }}
                >
                  GO
                </button>
              </span>
              <span className="ml-2">
             
                <button
                  id="callapibutn"
                  className="tabletopbutton"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>

              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th className="th1" scope="col">
                    Sr.
                  </th>
                  <th>Date</th>
                  <th>Voucher No</th>
                  <th>Account title</th>
                  <th>Narration</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {accountslist.map((x, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{x.transDate.split("T")[0]}</td>
                    <td>{x.voucherNo}</td>
                    <td>{x.particular}</td>
                    <td>{x.narration}</td>
                    <td>
                      {x.debit !== ""
                        ? parseFloat(x.debit).toLocaleString()
                        : null}
                    </td>
                    <td>
                      {x.credit !== ""
                        ? parseFloat(x.credit).toLocaleString()
                        : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row ">
            <div className="col-md-3  ">
              Total Debit:
              <input
                type="text"
                className="input_border formInput"
                disabled
                placeholder={debitTotal}
                disabled
              />
            </div>
            <div className="col-md-3   ">
              Total Credit:
              <input
                type="text"
                className="input_border formInput"
                disabled
                placeholder={creditTotal}
                disabled
              />
            </div>
            <div className="col-md-3   ">
              Total Balance:
              <input
                type="text"
                className="input_border formInput"
                placeholder={statebalance}
                disabled
              />
            </div>
            <div className="col-md-3  "></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GeneralJournalMain;
