import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import ReactDOM from "react-dom";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Delete from "../../../Images/delete.png";
import moment from "moment";
import { IoMdAddCircle } from "react-icons/io";
import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { PurchaseContractIndividual } from "./PurchaseContractPrint";

export const PurchaseContractForm = (props) => {
  const [loader1, setloader1] = useState(false);
  const [tablerow, settablerow] = useState([]);
  const [brandsdata, setbrandsdata] = useState([]);
  const [yarndata, setyarndata] = useState([]);
  const [totalamount, settotalamount] = useState(0);
  const [taxavailable, settaxavailable] = useState("with tax");
  const [taxavailablebool, settaxavailablebool] = useState(true);
  const [showtaxoptions, setshowtaxoptions] = useState(true);
  const [printdata, setprintdata] = useState();
  const [saletax, setsaletax] = useState(0);
  const [agentdata, setagentdata] = useState([]);
  const [allaccount, setallaccount] = useState([]);
  const [CommAgent, setCommAgent] = useState(null);
  const [Accounttitle, setAccountTitle] = useState("");
  const [Accid, setAccid] = useState("");
  const [PurchaseContractNumber, setPurchaseContractNumber] = useState("");
  useEffect(() => {
    if (taxavailable === "with tax") {
      settaxavailablebool(true);
    } else if (taxavailable === "without tax") {
      settaxavailablebool(false);
    }
  }, [taxavailable]);

  useEffect(() => {
    if (tablerow.length === 0) {
      setshowtaxoptions(true);
      settaxavailable("with tax");
    } else {
      setshowtaxoptions(false);
    }
    console.log(tablerow);
  }, [tablerow]);

  console.log(PurchaseContractNumber);
  const handleaddrow = () => {
    const item = {
      itemname: "",
      qty: "",
      rate: "",
      brand: "",
      salestax: "",
      amount: "",
      brandid: "",
      yarnid: "",
      netamount: "",
    };
    settablerow([...tablerow, item]);
  };

  const handledeleterow = (idx) => {
    const rows = [...tablerow];
    rows.splice(idx, 1);
    settablerow(rows);
  };

  useEffect(() => {
    let val = 0;
    tablerow.forEach((x) => {
      if (x.netamount !== "") {
        val += parseFloat(x.netamount);
      }
    });
    settotalamount(val);
  }, [tablerow]);

  console.log();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPopulatePurchaseContracts = () => {
    axios({
      method: "get",
      url: window.$url + "api/Purchase/PopulatePurchaseContracts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        if (result.data.IsSuccess) {
          setPurchaseContractNumber(result.data.PurchaseContractNumber);
          setbrandsdata(result.data.Brand);
          setyarndata(result.data.Product);
          setsaletax(result.data.Tax[0].TaxAmount);
          setagentdata(result.data.Agents);
          setallaccount(result.data.AllAccounts);
        }
      },
      (error) => {
        toast.info(error);
      }
    );
  };

  useEffect(() => {
    getPopulatePurchaseContracts();
  }, []);

  //Customers autosuggestion code
  var suggestionlist = [];

  const OnSellerChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("sellerList").childNodes;
    let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = allaccount?.find(
          (item, idx) =>
            item.Accid === parseInt(options[i].attributes.attributeId.value)
        );

        console.log(item);
        console.log(options);

        setAccountTitle(item.Accounttitle);
        setAccid(item.Accid);

        break;
      }
      setAccountTitle("");
      setAccid("");
    }
  };

  console.log(Accounttitle);

  useEffect(() => {
    let val = 0;
    tablerow.forEach((x) => {
      if (x.netamount !== "") {
        val += parseFloat(x.netamount);
      }
    });
    document.getElementById("totalamount").value = val.toFixed(2);
  }, [CommAgent, Accounttitle, Accid]);

  const suggestionselected = (val) => {
    console.log(val);
    setAccountTitle(val.Accounttitle);
    setAccid(val.Accid);
    document.getElementById("sellername").value = val.Accounttitle;
    suggestionlist = [];
    ReactDOM.render(null, document.getElementById("sellerList"));
  };
  //TABLE FUNCTIONS

  //Yarn type autosuggestion code

  const OnItemChange = (e, idx) => {
    let value = e.target.value;
    var options = document.getElementById(`yarn${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = yarndata?.find(
          (x) =>
            `${x.ProductType} - (${x.Composition})` ===
            options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(
          `itemname${idx}`
        ).value = `${item.ProductType} - (${item.Composition})`;
        tablerow[idx].itemname = `${item.ProductType} - (${item.Composition})`;
        tablerow[idx].yarnid = item.ProductId;
        break;
      }
      // document.getElementById(`itemname${idx}`).value = "";
      tablerow[idx].itemname = "";
      tablerow[idx].yarnid = "";
    }
  };
  //Brand name  autosuggestion code
  const OnBrandChange = (e, idx) => {
    let value = e.target.value;
    var options = document.getElementById(`brand${idx}`).childNodes;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        let item = brandsdata?.find(
          (x) => x.BrandName === options[i].attributes.attributeId.value
        );

        console.log(item, idx);
        document.getElementById(`brandname${idx}`).value = item.BrandName;
        tablerow[idx].brand = item.BrandName;
        tablerow[idx].brandid = item.BrandId;
        break;
      }
      // document.getElementById(`brandname${idx}`).value = "";
      tablerow[idx].brand = "";
      tablerow[idx].brandid = "";
    }
  };

  const quantitychange = (e, idx) => {
    //   let newTableRow = JSON.parse(JSON.stringify(tablerow))
    tablerow[idx].qty = e.target.value;
    //TOTAL VALUE CALCULATION
    if (tablerow[idx].rate === "" || tablerow[idx].qty === "") {
      console.log(tablerow[idx].rate);
      document.getElementById(`netamount${idx}`).value = 0;
      document.getElementById("totalamount").value = 0;
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = 0;
        document.getElementById(`saletax${idx}`).value = 0;
        document.getElementById("totalamount").value = 0;
      }
    } else {
      let val1 = parseFloat(tablerow[idx].qty) * parseFloat(tablerow[idx].rate);
      let val2 = (parseFloat(saletax) / 100) * val1;

      document.getElementById(`netamount${idx}`).value = (val1 + val2).toFixed(
        2
      );
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`saletax${idx}`).value = val2.toFixed(2);
        tablerow[idx].salestax = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }
      tablerow[idx].netamount = (val1 + val2).toFixed(2);

      let val = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += parseFloat(x.netamount);
        }
      });
      // settotalamount(val)
      document.getElementById("totalamount").value = val.toFixed(2);
    }
    //   settablerow(newTableRow)
  };
  const ratechange = (e, idx) => {
    tablerow[idx].rate = e.target.value;

    if (tablerow[idx].rate === "" || tablerow[idx].qty === "") {
      console.log(tablerow[idx].rate);
      document.getElementById(`netamount${idx}`).value = 0;
      document.getElementById("totalamount").value = 0;
      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = 0;
        document.getElementById(`saletax${idx}`).value = 0;
        document.getElementById("totalamount").value = 0;
      }
    } else {
      //TOTAL VALUE CALCULATION
      let val1 = parseFloat(tablerow[idx].qty) * parseFloat(tablerow[idx].rate);
      let val2 = (parseFloat(saletax) / 100) * val1;

      document.getElementById(`netamount${idx}`).value = (val1 + val2).toFixed(
        2
      );

      if (taxavailablebool) {
        document.getElementById(`total${idx}`).value = val1;
        document.getElementById(`saletax${idx}`).value = val2.toFixed(2);
        tablerow[idx].salestax = val2.toFixed(2);
        tablerow[idx].amount = val1;
      }
      tablerow[idx].netamount = (val1 + val2).toFixed(2);
      let val = 0;
      tablerow.forEach((x) => {
        if (x.netamount !== "") {
          val += parseFloat(x.netamount);
        }
      });
      document.getElementById("totalamount").value = val.toFixed(2);
      // settotalamount(val)
    }
  };
  const saletaxchange = (e, idx) => {
    tablerow[idx].salestax = e.target.value;

    //TOTAL VALUE CALCULATION
    // let val1 = (parseFloat(tablerow[idx].qty)*parseFloat(tablerow[idx].rate))
    // let val2 = (parseFloat(tablerow[idx].salestax)/100)*val1

    // document.getElementById(`total${idx}`).value = val1 + val2
    // tablerow[idx].amount = val1 + val2

    // let val = 0;
    // tablerow.forEach((x)=>{
    //   if(x.amount!==""){
    //     val += parseFloat(x.amount)
    //   }
    // })
    // document.getElementById("totalamount").value = val
  };
  const amountchange = (e, idx) => {
    tablerow[idx].amount = e.target.value;
  };
  // console.log(document.getElementById("commissionamount").value)

  const netamountchange = (e, idx) => {
    tablerow[idx].netamount = e.target.value;
  };

  const addpurchasecontractnew = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.salestax),
        TaxPercentage: parseInt(saletax),
        Rate: x.rate,
        NetAmount: parseInt(x.netamount),
      });
    });

    let TotalQty = 0;
    array.map((x) => {
      TotalQty += Number(x.ProductQty);
    });

    let data = {
      Narration: document.getElementById("remarks").value,
      Tax: taxavailablebool,
      Date: document.getElementById("date").value,
      TotalAmount: parseInt(document.getElementById("totalamount").value),
      DeliveryDate: document.getElementById("deliverydate").value,
      CommisionAgentId: parseInt(CommAgent),
      CommisionAmount: parseInt(
        document.getElementById("commissionamount")?.value
      ),
      TotalQty: TotalQty,
      AccountId: Accid,
      PurchaseContractDetails: array,
    };
    console.log(data);

    if (Accid === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;
    if (
      CommAgent !== null &&
      document.getElementById("commissionpercentage").value === ""
    ) {
      toast.info("Please Add Commission %");
      return;
    }
    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Purchase/AddPurchaseContract`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setCommAgent(null);

          setAccid("");
          document.getElementById("commissionagent").value = "";
          document.getElementById("commissionagent").option = "";
          document.getElementById("commissionpercentage").value = "";
          document.getElementById("commissionamount").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("totalamount").value = 0;
          settaxavailable("with tax");
          getPopulatePurchaseContracts();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchasecontractclose = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: x.qty,
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.salestax),
        TaxPercentage: saletax,
        Rate: parseInt(x.rate),
        NetAmount: parseInt(x.netamount),
      });
    });
    let TotalQty = 0;
    array.map((x) => {
      TotalQty += Number(x.ProductQty);
    });

    let data = {
      Narration: document.getElementById("remarks").value,
      AccountId: Accid,
      Tax: taxavailablebool,
      TotalQty: TotalQty,
      Date: document.getElementById("date").value,
      TotalAmount: parseInt(document.getElementById("totalamount").value),
      DeliveryDate: document.getElementById("deliverydate").value,
      CommisionAgentId: parseInt(CommAgent),
      CommisionAmount: parseInt(
        document.getElementById("commissionamount")?.value
      ),
      PurchaseContractDetails: array,
    };

    if (Accid === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;
    if (
      CommAgent !== null &&
      document.getElementById("commissionpercentage").value === ""
    ) {
      toast.info("Please Add Commission %");
      return;
    }
    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Purchase/AddPurchaseContract`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setCommAgent(null);
          setAccid("");
          document.getElementById("commissionagent").value = "";
          document.getElementById("commissionagent").option = "";
          document.getElementById("commissionpercentage").value = "";
          document.getElementById("commissionamount").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("totalamount").value = 0;
          settaxavailable("with tax");
          props.formCancel();
          getPopulatePurchaseContracts();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  const addpurchasecontractprint = () => {
    let array = [];

    tablerow.forEach((x) => {
      array.push({
        ProductId: x.yarnid,
        ProductQty: parseInt(x.qty),
        Amount: parseInt(x.amount),
        BrandId: x.brandid,
        TaxAmount: parseInt(x.salestax),
        TaxPercentage: saletax,
        Rate: parseInt(x.rate),
        NetAmount: parseInt(x.netamount),
      });
    });
    let TotalQty = 0;
    array.map((x) => {
      TotalQty += Number(x.ProductQty);
    });
    let data = {
      Narration: document.getElementById("remarks").value,
      AccountId: Accid,
      Tax: taxavailablebool,
      TotalQty: TotalQty,

      Date: document.getElementById("date").value,
      TotalAmount: parseInt(document.getElementById("totalamount").value),
      DeliveryDate: document.getElementById("deliverydate").value,

      CommisionAgentId: parseInt(CommAgent),
      CommisionAmount: parseInt(
        document.getElementById("commissionamount")?.value
      ),
      PurchaseContractDetails: array,
    };

    if (Accid === "") {
      toast.info("Please Add Vendor");
      return;
    }
    if (tablerow.length === 0) {
      toast.info("Please Add atleast one detail");
      return;
    }
    let retu = false;

    tablerow.map((x, idx) => {
      if (x.yarnid === "") {
        toast.info(`Please Select Item at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.brandid === "") {
        toast.info(`Please Select Brand at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.qty === "") {
        toast.info(`Please Select No of Bags at row ${idx + 1}`);
        retu = true;
        return;
      }
      if (x.rate === "") {
        toast.info(`Please Select Rate at row ${idx + 1}`);
        retu = true;
        return;
      }
    });

    if (retu) return;

    if (
      CommAgent !== null &&
      document.getElementById("commissionpercentage").value === ""
    ) {
      toast.info("Please Add Commission %");
      return;
    }
    setloader1(true);
    document.getElementById("crvSave").disabled = true;
    axios({
      url: window.$url + `api/Purchase/AddPurchaseContract`,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
      method: "post",
    }).then(
      (result) => {
        setloader1(false);
        document.getElementById("crvSave").disabled = false;
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          settablerow([]);
          setCommAgent(null);
          setAccid("");
          document.getElementById("commissionagent").value = "";
          document.getElementById("commissionagent").option = "";
          document.getElementById("commissionpercentage").value = "";
          document.getElementById("commissionamount").value = "";
          document.getElementById("sellername").value = "";
          document.getElementById("remarks").value = "";
          document.getElementById("totalamount").value = 0;
          settaxavailable("with tax");

          setprintdata(result.data.PurchaseContract);
          getPopulatePurchaseContracts();
        }
      },
      (error) => {
        document.getElementById("crvSave").disabled = false;
        console.log(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    console.log(
      document.getElementById("commissionagent").options[
        document.getElementById("commissionagent").selectedIndex
      ].dataset.id
    );
  }, []);

  useEffect(() => {
    if (printdata !== undefined) {
      handlePrint();
    }
  }, [printdata]);

  useEffect(() => {
    console.log(tablerow);
  }, [tablerow]);

  return (
    <>
      <div hidden>
        <PurchaseContractIndividual ref={componentRef} printdata={printdata} />
      </div>
      <div
        className=" tableContainer2 overflow-auto pageheading Sitka mt-3"
        style={{ height: "95%" }}
      >
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-3">
            <div className="col-2 text-right">
              <span className="txt">Contract No</span>
            </div>
            <div className="col-4 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  // id="date"
                  disabled
                  className="input_border formInput"
                  value={`PCont-${PurchaseContractNumber}`}
                ></input>
              </span>
            </div>
            {/* <div className="col-4  text-left">
                <div className="row">
                  <div className="col-4 pr-0">
                    <input
                      placeholder="ID"
                      disabled
                      type="number"
                      id="sellerid"
                      className="input_border formInput "
                    ></input>
                  </div>
               
                </div>
  
                <span id="demo"></span>
              </div> */}
            <div className="col-2  text-right">
              <span className="txt">Vendor</span>
            </div>
            <div className="col-4  text-left">
              <span>
                {" "}
                <input
                  placeholder="Enter Name"
                  type="text"
                  id="sellername"
                  list="sellerList"
                  onChange={OnSellerChange}
                  // value={Accounttitle}
                  // name="Accounttitle"
                  className="input_border formInput "
                  autoComplete="off"
                ></input>
                <datalist id="sellerList">
                  {allaccount?.map((v, idx) => {
                    // console.log(v);
                    return (
                      <option attributeId={v?.Accid} value={v?.Accounttitle}>
                        {`${v?.Accounttitle}(${v?.HeadingNavigation?.AccountTitle})`}
                      </option>
                    );
                  })}
                </datalist>
              </span>
            </div>
            <div className="col-2  mt-3 text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4 mt-3  text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="date"
                  className="input_border formInput"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></input>
              </span>
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Delivery</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="date"
                  id="deliverydate"
                  className="input_border formInput"
                  defaultValue={moment().format("YYYY-MM-DD")}
                ></input>
              </span>
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Com. Agent</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <select
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setCommAgent(null);
                      document.getElementById("commissionpercentage").value =
                        "";
                      document.getElementById("commissionamount").value = "";
                    } else {
                      setCommAgent(
                        document.getElementById("commissionagent").options[
                          document.getElementById("commissionagent")
                            .selectedIndex
                        ].dataset.id
                      );
                    }
                  }}
                  id="commissionagent"
                  className="input_border formInput "
                >
                  <option value="" data-id="">
                    Select
                  </option>
                  {agentdata.map((x) => (
                    <option data-id={x.CommisionAgentId}>
                      {x.CommisionAgentName}
                    </option>
                  ))}
                </select>
              </span>
            </div>

            <div className="col-2 mt-3 text-right"></div>
            <div className="col-4 mt-3">
              {showtaxoptions ? (
                <div className="row">
                  <div className="col-6">
                    <input
                      onClick={(e) => {
                        settaxavailable(e.target.value);
                        setsaletax(17);
                      }}
                      type="radio"
                      id="wtax"
                      name="taxradio"
                      value="with tax"
                      defaultChecked
                    />{" "}
                    <label for="wtax">With Tax</label>
                  </div>
                  <div className="col-6">
                    <input
                      onClick={(e) => {
                        settaxavailable(e.target.value);
                        setsaletax(0);
                      }}
                      type="radio"
                      id="wotax"
                      name="taxradio"
                      value="without tax"
                    />{" "}
                    <label for="wotax">Without Tax</label>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Remarks</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <textarea
                  style={{ height: "40px" }}
                  id="remarks"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>
          </div>

          <div className="col-12  mt-3 ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th>Sr.</th>
                  <th>Item</th>
                  <th>Brand</th>
                  <th>No. of Bags</th>
                  <th>Rate</th>
                  {taxavailablebool ? <th>Amount</th> : <></>}
                  {taxavailablebool ? <th>Sales Tax</th> : <></>}
                  <th>Net Amount</th>
                  <th onClick={handleaddrow}>
                    <IoMdAddCircle className="drow" size="2em" color="black" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {tablerow.map((x, idx) => (
                  <tr key={idx}>
                    <th>{idx + 1}</th>
                    <th>
                      <input
                        key={`${x.itemname}${idx}`}
                        id={`itemname${idx}`}
                        onChange={(e) => {
                          OnItemChange(e, idx);
                        }}
                        defaultValue={x.itemname}
                        className="form-control"
                        type="text"
                        list={`yarn${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`yarn${idx}`}>
                        {yarndata?.map((v, idx) => {
                          return (
                            <option
                              attributeId={`${v.ProductType} - (${v.Composition})`}
                            >
                              {v.ProductType} - {v.Composition}
                            </option>
                          );
                        })}
                      </datalist>
                    </th>
                    <th>
                      <input
                        key={`${x.brand}${idx}`}
                        id={`brandname${idx}`}
                        onChange={(e) => {
                          OnBrandChange(e, idx);
                          console.log(tablerow);
                        }}
                        defaultValue={x.brand}
                        className="form-control"
                        type="text"
                        list={`brand${idx}`}
                        autoComplete="off"
                      ></input>
                      <datalist id={`brand${idx}`}>
                        {brandsdata?.map((v, idx) => {
                          return (
                            <option attributeId={v?.BrandName}>
                              {v.BrandName}
                            </option>
                          );
                        })}
                      </datalist>
                    </th>
                    <th>
                      <input
                        key={`${x.qty}${idx}`}
                        onChange={(e) => {
                          quantitychange(e, idx);
                        }}
                        defaultValue={x.qty}
                        className="form-control"
                      ></input>
                    </th>
                    <th>
                      <input
                        key={`${x.rate}${idx}`}
                        onChange={(e) => {
                          ratechange(e, idx);
                        }}
                        defaultValue={x.rate}
                        className="form-control"
                      ></input>
                    </th>

                    {taxavailablebool ? (
                      <>
                        <th>
                          <input
                            key={`${x.yarnid}${idx}`}
                            id={`total${idx}`}
                            onChange={(e) => {
                              amountchange(e, idx);
                            }}
                            defaultValue={x.amount}
                            disabled
                            className="form-control"
                          ></input>
                        </th>
                        <th>
                          <input
                            key={`${x.salestax}${idx}`}
                            id={`saletax${idx}`}
                            onChange={(e) => {
                              saletaxchange(e, idx);
                            }}
                            defaultValue={x.salestax}
                            disabled
                            className="form-control"
                          ></input>
                        </th>
                      </>
                    ) : (
                      <></>
                    )}
                    <th>
                      <input
                        key={`${x.yarnid}${idx}`}
                        id={`netamount${idx}`}
                        onChange={(e) => {
                          netamountchange(e, idx);
                        }}
                        defaultValue={x.netamount}
                        disabled
                        className="form-control"
                      ></input>
                    </th>

                    <th>
                      <span onClick={() => handledeleterow(idx)}>
                        <img
                          alt="AddRow"
                          style={{
                            cursor: "pointer",
                            width: "19px",
                            marginTop: "8px",
                          }}
                          src={Delete}
                        ></img>
                      </span>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1"></div>
            <div className="col-3 mt-1 text-right">Total:</div>
            <div className="col-3 mt-1">
              <input
                id="totalamount"
                className="input_border formInput"
                value={totalamount.toFixed(2)}
                disabled
              ></input>
            </div>

            {CommAgent !== null && (
              <>
                <div className="col-3 mt-1"></div>
                <div className="col-3 mt-1"></div>
                <div className="col-3 mt-1 text-right">Commission:</div>
                <div className="col-3 mt-1">
                  <div className="row">
                    <div className="col-6">
                      <input
                        id="commissionpercentage"
                        className="input_border formInput"
                        placeholder="%"
                        type="number"
                        disabled={CommAgent === null ? true : false}
                        onChange={() => {
                          document.getElementById("commissionamount").value = (
                            (parseFloat(
                              document.getElementById("commissionpercentage")
                                .value
                            ) /
                              100) *
                            parseFloat(
                              document.getElementById("totalamount").value
                            )
                          ).toFixed(2);
                          console.log(
                            document.getElementById("commissionamount").value
                          );
                        }}
                      ></input>
                    </div>
                    <div className="col-6 pl-0">
                      <input
                        id="commissionamount"
                        className="input_border formInput"
                        placeholder="pkr"
                        type="number"
                        disabled={CommAgent === null ? true : false}
                        onChange={() => {
                          document.getElementById(
                            "commissionpercentage"
                          ).value = (
                            (parseFloat(
                              document.getElementById("commissionamount").value
                            ) /
                              parseFloat(
                                document.getElementById("totalamount").value
                              )) *
                            100
                          ).toFixed(2);
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-12 mt-5  text-right">
            <span>
              <button
                id="crvSave"
                onClick={addpurchasecontractnew}
                className="tabletopbutton  text-right greenbutton"
              >
                {"Save & New"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchasecontractprint}
                className="tabletopbutton ml-2 text-right bluebutton"
              >
                {"Save & Print"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                onClick={addpurchasecontractclose}
                className="tabletopbutton ml-2 text-right redbutton"
              >
                {"Save & Close"}
              </button>
            </span>
            <span>
              <button
                id="crvSave"
                className="tabletopbutton ml-2 text-right "
                onClick={props.formCancel}
              >
                Cancel
              </button>
            </span>
            <span className=" ml-1 float-right">
              <div className="loaderclass ml-1">
                {" "}
                {loader1 && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
