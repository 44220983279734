import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import ItemWiseStockPrint from "./ItemWiseStockPrint";
import "../../../Bootstrap/haroon.css";
import moment from "moment";
const ItemWiseStockMain = () => {
  const [loader1, setloader1] = useState(false);
  const [contractdata, setcontractdata] = useState([]);
  const [contractdatafilter, setcontractdatafilter] = useState([]);
  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  var suggestionlist = [];

  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter(
            (v) =>

              (regex.test(v.Product.ProductType) ||
              regex.test(v.Product?.Composition))
            // regex.test(`${v.Product.ProductType} - (${v.Product?.Composition})`)

          );
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };
  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.Brand.BrandName));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };

  const getallpurchasecontract = () => {
    setloader1(true);
    axios({
      url: `${window.$url}api/Inventory/StockReport`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        setcontractdata(result.data.Stock);
        setcontractdatafilter(result.data.Stock);
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getallpurchasecontract();
  }, []);

  return (
    <>
      <div hidden>
        <ItemWiseStockPrint ref={componentRef1} contractdata={contractdata} />
      </div>
      <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
        <div className="col-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Item"
                  className="input_border  ml-1"
                  onChange={onTextChange1}
                ></input>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Item Type"
                  className="input_border  ml-1"
                  onChange={onTextChange2}
                ></input>
              </span>

              <span className="ml-2">
                <button
                  id="callapibutn"
                  className="tabletopbutton"
                  onClick={handlePrint1}
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                {/* <button
                    onClick={()=>{document.getElementById("modalAddnewAccount2").style.display = "block"}}
                    className="tabletopbutton1 text-right"
                  >
                    New
                  </button> */}
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Item
                  </th>
                  <th scope="col" className="th1">
                    Item Type
                  </th>
                  <th scope="col" className="th1">
                    Bags
                  </th>
                  <th scope="col" className="th1">
                    Price(WAC)
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "10pt" }}>
                {contractdata.map((x, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>
                      {x.Product?.ProductType} - ({x.Product?.Composition})
                    </td>
                    <td>{x.Brand.BrandName}</td>
                    <td>{x.QtyinHand}</td>
                    <td>{x.WeightedAvgCost.toLocaleString()}</td>
                    <td>{x.WeightedAvgCost * x.QtyinHand}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default ItemWiseStockMain;
