import React, { useState, useEffect, useRef } from "react";
import "../../../Bootstrap/haroon.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import BrandWiseSalePrint from "./BrandWiseSalePrint";
import moment from "moment";
const BrandWiseSaleMain = () => {
  const [loader1, setloader1] = useState(false);
  const [contractdata, setcontractdata] = useState([]);
  const [contractdatafilter, setcontractdatafilter] = useState([]);
  const [brandidstate, setbrandidstate] = useState(null);
  const [brandlist, setbrandlist] = useState([]);
  const [from, setfrom] = useState(moment().format("YYYY-07-01"));
  const [to, setto] = useState(moment().format("YYYY-MM-DD"));

  const getbrand = () => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + "api/Inventory/GetAllBrand",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setbrandlist(result.data.Brand);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    getbrand();
  }, []);

  var suggestionlist = [];
  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setcontractdata(contractdatafilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = contractdatafilter
          .sort()
          .filter((v) => regex.test(v.Date.split("T")[0]));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setcontractdata([]);
      } else {
        setcontractdata(suggestionlist);
      }
    }
  };

  const getallpurchasecontract = () => {
    if (from === "" || to === "" || brandidstate === null) {
      toast.info("Please fill all the fields!");
      setcontractdata([]);
    } else {
      setloader1(true);
      axios({
        url: `${window.$url}api/Reports/BrandWiseSale?brandId=${brandidstate}&From=${from}&To=${to}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setloader1(false);
          console.log(result);
          setcontractdata(result.data.Data);
          setcontractdatafilter(result.data.Data);
        },
        (error) => {
          setloader1(false);
          console.log(error);
        }
      );
    }
  };

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });
  return (
    <>
      <div hidden>
        <BrandWiseSalePrint ref={componentRef1} contractdata={contractdata} />
      </div>
      <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <select
                  type="text"
                  autoComplete="off"
                  style={{ height: "25px" }}
                  className="input_border  ml-1"
                  id="brandlist"
                  onChange={(e) => {
                    if (e.target.value !== 0) {
                      setbrandidstate(
                        e.target.options[e.target.selectedIndex].dataset.id
                      );
                    } else {
                      setbrandidstate(null);
                    }
                  }}
                >
                  <option>Select a Item type</option>
                  {brandlist !== undefined
                    ? brandlist.map((x) => (
                        <option data-id={x.BrandId}>{x.BrandName}</option>
                      ))
                    : null}
                </select>
              </span>
              <span> From: </span>
              <input
                type="date"
                id="cashfrom"
                onChange={(e) => setfrom(e.target.value)}
                defaultValue={moment().format("YYYY-07-01")}
                className="input_border  ml-1"
              ></input>
              <span> To: </span>
              <input
                type="date"
                id="cashto"
                onChange={(e) => setto(e.target.value)}
                defaultValue={moment().format("YYYY-MM-DD")}
                className="input_border  ml-1"
              ></input>

              <span>
                <button
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                  onClick={() => {
                    getallpurchasecontract();
                  }}
                >
                  GO
                </button>
                {/* <button
                id="callapibutn"
                className="tabletopbutton   text-right ml-2 "
                onClick={handlePrint}
              >
                Print
                </button> */}
              </span>

              <span className="ml-2">
                <button
                  onClick={handlePrint1}
                  id="callapibutn"
                  className="tabletopbutton   text-right ml-2 "
                >
                  Print
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Transaction Type
                  </th>
                  <th scope="col" className="th1">
                    Party
                  </th>
                  <th scope="col" className="th1">
                    Rate
                  </th>

                  <th scope="col" className="th1">
                    Bags
                  </th>

                  <th scope="col" className="th1">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "10pt" }}>
                {contractdata !== undefined
                  ? contractdata.map((x, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{x.EntryDate.split("T")[0]}</td>
                        <td>{x.TransactionType}</td>
                        <td>{x.Party}</td>
                        <td>{x.Rate.toLocaleString()}</td>
                        <td>{x.ProductQty}</td>
                        <td>{x.Amount.toLocaleString()}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandWiseSaleMain;
