import React, { Component } from "react";
import logo from "../../Images/seasonFour.jpg";

class TrialBalancePrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Trial Balance</u>
              </b>
            </h3>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>PARTICULARS</th>
                      <th>ACCOUNT CATEGORY</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                      <th>BALANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props?.allaccounts.map((x) => (
                      <tr>
                        <td>{x?.particular}</td>
                        <td>{x?.accountCategory}</td>
                        <td>{x?.debit.toLocaleString()}</td>
                        <td>{x?.credit.toLocaleString()}</td>
                        <td>{x?.balance.toLocaleString()}</td>
                        <td></td>
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td className="text-right">
                        <b>Total: </b>
                      </td>
                      <td>
                        <b>
                          {this.props.debitTotal !== undefined
                            ? this.props.debitTotal
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {this.props.creditTotal !== undefined
                            ? this.props.creditTotal
                            : null}
                        </b>
                      </td>
                      <td>
                        <b>
                          {this.props.Total !== undefined
                            ? this.props.Total.toLocaleString()
                            : null}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrialBalancePrint;
