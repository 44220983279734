import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";


export default class LedgerPrint extends Component {
    render() {
      console.log(this.props)
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div className="row m-3 p-0" style={{ height: "80%" }}>
            <div className="col-12 text-center mt-2">
              <h4 className="col-12 headingscss">
                <b>Ledger</b>
              </h4>
              <h6 className="col-12">
                <b>
                  {this.props.accountname} 
                </b>
              </h6>
              
              <span className=" col-12">
                <b>
                  From {this.props.fromdate} to {this.props.todate}
                </b>
              </span>
              <hr style={{ backgroundColor: "black" }} />
              <div className="mt-3">
                <div class="table-responsive col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th className="th1" scope="col">
                          Sr.
                        </th>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Account Category</th>
                        <th>Narration</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props?.accountlist !== undefined
                        ? this.props?.accountlist.map((x, idx) => (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>{x.dateadded.split("T")[0]}</td>
                              <td>{x.referenceno}</td>
                              <td>{x.acc.accountcategory}</td>
                              <td>{x.narration}</td>
                              <td>
                                {x.transtype === "Debit"
                                  ? parseFloat(x.amount).toLocaleString()
                                  : null}
                              </td>
                              <td>
                                {x.transtype === "Credit"
                                  ? parseFloat(x.amount).toLocaleString()
                                  : null}
                              </td>
                              <td>{parseFloat(x.balance).toLocaleString()}</td>
                            </tr>
                          ))
                        : null}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                        <td>
                          <b>
                            Total Debit:{" "}
                            {this.props.debitTotal !== undefined
                              ? this.props.debitTotal
                              : null}
                          </b>
                        </td>
                        <td>
                          <b>
                            Total Credit:{" "}
                            {this.props.creditTotal !== undefined
                              ? this.props.creditTotal
                              : null}
                          </b>
                        </td>
                        <td>
                          <b>
                            Balance:{" "}
                            {this.props.balancetotal !== undefined
                              ? this.props.balancetotal
                              : null}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }