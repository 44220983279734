import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

import { SaleContractMain } from "../SalesNew/SaleContract/SaleContractMain";
import { SaleInvoiceMain } from "../SalesNew/SaleInvoice/SaleInvoiceMain";
import { SaleRegisterMain } from "../SalesNew/SaleRegister/SaleRegisterMain";
import { DeliveryReport } from "../SalesNew/SaleDeliveryReport/SaleDeliveryReportMain";
import CustomerMain from "../SalesNew/Customer/CustomerMain";
import ClientSaleSummaryMain from "../SalesNew/ClientSaleSummary/ClientSaleSummaryMain";

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
        count === "Customers"
          ? "seaGreen"
          : count === "Sale Invoice"
          ? "red"
          : count === "Sale Contract"
          ? "blue"
          : // : count === "Sale Register"
          //   ? "purple"
          count === "Brand Wise Sale"
          ? "red"
          : count === "Client Summary"
          ? "red"
          : count === "Delivery Report"
          ? "purple"
          : count
      }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Sale = () => {
  const button = [
    {
      button1: "Sale Contract",
      component: <SaleContractMain />,
    },
    { button1: "Sale Invoice", component: <SaleInvoiceMain /> },
    // { button1: "Sale Register", component: <SaleRegisterMain/>},
    // <SaleRegister />
    { button1: "Customers", component: <CustomerMain /> },
    { button1: "Client Summary", component: <ClientSaleSummaryMain /> },
    { button1: "Delivery Report", component: <DeliveryReport /> },

    // { button1: "Brand Wise Sale", component: <BrandWiseSale /> },
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Sale Contract",
    component: <SaleContractMain />,
  });

  return (
    <>
      <div style={{ height: "10%" }}>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "87%" }}>
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Sale;
