import React, { useState, useEffect } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

import {
  AccountPayablePDC,
  AccountReceivablePDC
  
} from "./AccountTable";
import ChartOfAccountMain from "../AccountsNew/ChartOfAccount/ChartOfAccountMain";
import AccountReceivableMain from "../AccountsNew/AccountReceivable/AccountReceivableMain";
import AccountPayableMain from "../AccountsNew/AccountPayable/AccountPayableMain";
import BankAccountsMain from "../AccountsNew/BankAccounts/BankAccountsMain";

// Primary Buttons color assignment
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${
          count === "Chart Of Accounts"
          ? "blue"
          : count === "Payables"
          ? "red"
          : count === "Receivables"
          ? "seaGreen"
          : count === "Bank Accounts"
          ? "darkred"
          : count === "PDC Payables"
          ? "purple"
          : count === "PDC Receivables"
          ? "blue"
          : count
      }`}
    >
      {count}
    </button>
  );
};

//Secondary Buttons
const Btngroup2 = ({ active, count, onClick, styling }) => {
  return (
    <button
      onClick={onClick}
      className={
        active ? `secondarybuttonClickedaccounts ${styling}` : "secondarybuttonaccounts"
      }
    >
      {count}
    </button>
  );
};

// Accounts page 
const Accounts = () => {
  const [chosen, setChosen] = useState({
    component: <ChartOfAccountMain />,
  });

  const ChartOfAccount = [
    {
      component: <ChartOfAccountMain />,
    },
  ];
  //payables
  const Payables = [
    {
      component: <AccountPayableMain />,
    },
  ];
  const Receivables = [
    {
      component: <AccountReceivableMain />,
    },
  ];
  const Payablespdc = [
    {
      component: <AccountPayablePDC />,
    },
    
  ];
  const Receivablespdc = [
    {
      component: <AccountReceivablePDC />,
    },
  ];
  const Bankaccounts = [
    {
      component: <BankAccountsMain />,
    },
  ];
  
  
 
  const button = [
    // { button1: "Voucher", button2: Voucher },
    { button1: "Chart Of Accounts", button2: ChartOfAccount },
    { button1: "Payables", button2: Payables },
    { button1: "Receivables", button2: Receivables },
    // { button1: "PDC Payables", button2: Payablespdc },
    // { button1: "PDC Receivables", button2: Receivablespdc },
    // { button1: "Bank Accounts", button2: Bankaccounts },
    
  ];
  {
    /* INITIAL CHOSEN VALUES*/
  }
  const [chosen2, setChosen2] = useState({
    button1: "Chart Of Accounts",
    button2: ChartOfAccount,
  });
  return (
    <>
      <div>
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
              setChosen(b.button2[0]);
            }}
          />
        ))}
        <br></br>
        {/* MAPPING SECONDARY BUTTONS ON THE PRODUCTION PAGE */}
        {chosen2.button2.map((b) =>
          b.button !== undefined ? (
            <Btngroup2
              key={b.button}
              count={b.button}
              styling={b.underline}
              active={b.button === chosen.button}
              onClick={() => setChosen(b)}
            />
          ) : null
        )}
      </div>
      {/*Work Order BUTTONS*/}
      {chosen2.button2.map((b) =>
        b.button === chosen.button ? b.component : null
      )}
    </>
  );
};

export default Accounts;
