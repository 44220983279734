import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import {   
    Bankpaymentprint,
  } from "./BankPaymentPrint";
import { getallbankaccounts } from "../../actions/getbankaccounts";
import { getallbankpaymentvouchers } from "../../actions/getallbankpaymentvouchers";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RiDeleteBack2Fill } from "react-icons/ri";
var converter = require("number-to-words");

export const BankPaymentForm = (props) => {
    const [banklist, setbanklist] = useState([]);
    const [Paymentaccount, setPaymentaccount] = useState([]);
    const [printdata, setprintdata] = useState();
    const [loader2, setloader2] = useState(false);
    const [loader3, setloader3] = useState(false);
  
    const [clientAccid, setclientAccid] = useState("");
  
    const [displayinvoice, setdisplayinvoice] = useState(false);
    const [initialdata, setinitialdata] = useState(null);
    const [saleinvoices, setsaleinvoices] = useState([]);
    const [invoiceamount, setinvoiceamount] = useState(null);
    const [chequedetails, setchequedetails] = useState(null);
    const [onlinecheque, setonlinecheque] = useState(true);
    const [conversionamount, setconversionamount] = useState(0);
    const [showencash, setshowencash] = useState(false);
  
    const [invoiceidlist, setinvoiceidlist] = useState([]);
    const [invoiceamountlist, setinvoiceamountlist] = useState([]);
    const [AccountTitle, setAccountTitle] = useState("")
    const [selectedInvoice, setselectedInvoice] = useState([])
    const [PurchaseInvoiceListID, setSaleInvoiceListID] = useState([])
 
    var today = new Date();
    var todaydate = today.getDate();
    if (todaydate.toString().length === 1) {
      todaydate = "0" + todaydate.toString();
    }
    var currentmonth = today.getMonth();
    if (currentmonth.toString().length === 1) {
      currentmonth = "0" + (today.getMonth() + 1).toString();
    }
    var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;
  
    const paymentselleraccounts = () => {
      setloader2(true);
      axios({
        method: "get",
        url: window.$url + "api/PaymentVoucher/PopulateAccounts",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          setPaymentaccount(result.data.PaymentAccount);
          setinitialdata(result.data);
          setloader2(false);
        },
        (error) => {
          toast.info(error);
          setloader2(false);
        }
      );
    };
    useEffect(() => {
      paymentselleraccounts();
    }, []);

  
    const OnAccountChange = (e) => {
      let value = e.target.value;
      console.log(e.target.value);
      var options = document.getElementById("accountList").childNodes;
      let find = false;
      for (var i = 0; i < options.length; i++) {
        if (options[i]?.value === value) {
          find = true;
          let item = Paymentaccount?.find(
            (item, idx) =>
            // console.log(item)
              item?.Accid === parseInt(options[i].attributes.attributeId.value)
          );
  
          console.log(item);
          console.log(options);

          setAccountTitle(item.Accounttitle);
          document.getElementById("cashReceiptAccount").value = item.Accounttitle;  
          setclientAccid(item.Accid);
          if (displayinvoice) {
            setsaleinvoices(item.PurchaseInvoice);
          } else {
            setsaleinvoices([]);
          }
          break;
        }
       
      }
      if (!find) {
        setclientAccid("");
        setAccountTitle("")
        document.getElementById("cashReceiptAccount").value = "";  
        setsaleinvoices([]);
         }
    };
   
  
   
    const saveandnew = () => {
      if (document.getElementById("bankReceiptDate").value === "") {
        toast.info("Date is Required");
      } else if(showencash && document.getElementById('encashdate').value === ""){
        toast.info("Encash date is Required");
      }
      else if (
        document.getElementById("bankPaymentlistbank").selectedIndex === 0
      ) {
        toast.info("Select Bank");
      } else if (AccountTitle === "") {
        toast.info("Select an Account");
      } else if (document.getElementById("bankReceiptDebit").value === "") {
        toast.info("Enter an Amount ");
      // } else if (document.getElementById("bankReceiptNarration").value === "") {
      //   toast.info("Enter a Narration");
      } else {
        document.getElementById("crvSave").disabled = true;
        document.getElementById("crvCancel").disabled = true;
        setloader2(true);
        var data1 = {
          EntryDate: moment().format("YYYY-MM-DD"),
          InvoiceIds:
          PurchaseInvoiceListID !== null
        ? PurchaseInvoiceListID
        : null,
          InvoiceSerialNo:
            document.getElementById("purchaseinvoicedropdown") !== null
              ? document
                  .getElementById("purchaseinvoicedropdown")
                  .value
              : null,
          SellerAccId: clientAccid,
          AccId: document.getElementById("bankPaymentlistbank").options[
            document.getElementById("bankPaymentlistbank").selectedIndex
          ].dataset.id,
          Amount: parseFloat(
            document.getElementById("bankReceiptDebit").value.replace(/,/g, "")
          ),
          ChequeNumber:
            document.getElementById("bankReceiptCheque").value === ""
              ? null
              : document.getElementById("bankReceiptCheque").value,
          Paymenttype: "Bank",
          VoucherDate: document.getElementById("bankReceiptDate").value,
          Narration:
            document.getElementById("bankReceiptNarration").value,
          VoucherType: "B-PV",
          EncashDate:
            document.getElementById("encashdate") !== null
              ? document.getElementById("encashdate").value
              : null,
          ChequeType:
            document.getElementById("paymenttype").value,
          ChequeBookId:
            chequedetails !== null ? chequedetails.ChequeBookId : null,
          BankCharges: document.getElementById("bankcharges").value ? document.getElementById("bankcharges").value : 0,
        };
        var data2 = {
          EntryDate: moment().format("YYYY-MM-DD"),
          InvoiceIds:
          PurchaseInvoiceListID !== null
        ? PurchaseInvoiceListID
        : null,
          InvoiceSerialNo:
            document.getElementById("purchaseinvoicedropdown") !== null
              ? document
                  .getElementById("purchaseinvoicedropdown")
                  .value
              : null,
          SellerAccId: clientAccid,
          AccId: document.getElementById("bankPaymentlistbank").options[
            document.getElementById("bankPaymentlistbank").selectedIndex
          ].dataset.id,
          Amount: parseFloat(
            document.getElementById("bankReceiptDebit").value.replace(/,/g, "")
          ),
          BankCharges: document.getElementById("bankcharges").value ? document.getElementById("bankcharges").value : 0,
          Paymenttype: "Bank",
          VoucherDate: document.getElementById("bankReceiptDate").value,
          Narration: 
            document.getElementById("bankReceiptNarration").value,
          VoucherType: "B-PV",
        };
  
        if (document.getElementById("bankReceiptCheque").value === "") {
          var data = data2;
        } else {
          var data = data1;
        }
  
        axios({
          method: "post",
          url: window.$url + `api/PaymentVoucher/AddPaymentVoucher`,
          data: JSON.stringify([data]),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            console.log(result);
            paymentselleraccounts()
            dispatch(getallbankaccounts())
            dispatch(getallbankpaymentvouchers());
            toast.info(result.data.Message);
            saleinvoices.forEach((x, idx) => {
              document.getElementById(`${idx}multipleinvoice`).checked = false;
            });
            setinvoiceamountlist([]);
            setinvoiceidlist([]);
            if (PurchaseInvoiceListID !== null) {
              setSaleInvoiceListID("");
            }
            document.getElementById("bankReceiptDebit").value = "";
            document.getElementById("bankReceiptCheque").value = "";
            document.getElementById("bankPaymentlistbank").selectedIndex = 0;
            document.getElementById("paymenttype").selectedIndex = 0;
            document.getElementById("bankReceiptNarration").value = "";
            setAccountTitle("")
            document.getElementById("bankcharges").value = "";
            document.getElementById("cashReceiptAccount").value = "";            
            document.getElementById("invoicecheckbox").checked = false;
            setshowencash(false);
            setconversionamount(0);
            setonlinecheque(true);
            setinvoiceamount(null);
            setdisplayinvoice(false);
            setclientAccid(null);
            setloader2(false);  
            document.getElementById("crvSave").disabled = false;
            document.getElementById("crvCancel").disabled = false;
          },
          (error) => {
            toast.info(error);
            setloader2(false);
  
            document.getElementById("crvSave").disabled = false;
            document.getElementById("crvCancel").disabled = false;
          }
        );
      }
    };
    const saveandclose = () => {
      if (document.getElementById("bankReceiptDate").value === "") {
        toast.info("Date is Required");
      }
      else if (document.getElementById("bankPaymentlistbank").selectedIndex === 0) {
        toast.info("Select Bank");
      }
      else if (document.getElementById("bankPaymentAccount").value === "") {
        toast.info("Select an Account");
      }
  
      else if (document.getElementById("bankReceiptDebit").value === "") {
        toast.info("Enter an Amount ");
      }
      else if (document.getElementById("bankReceiptNarration").value === "") {
        toast.info("Enter a Narration");
  
      }
      else {
        document.getElementById("crvSave").disabled = true;
        document.getElementById("crvCancel").disabled = true;
        setloader2(true);
        var data1 = {
          EntryDate:moment().format('YYYY-MM-DD'),
          InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
          SellerAccId: clientAccid,
          AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
          Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
          ChequeNumber: document.getElementById("bankReceiptCheque").value,
          Paymenttype: "Bank",
          VoucherDate: document.getElementById("bankReceiptDate").value,
          Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')} / ${document.getElementById("bankReceiptCheque").value === "" ? null :document.getElementById("bankReceiptCheque").value}`,
          VoucherType: "B-PV",
          ChequeType:document.getElementById("paymenttype").options[document.getElementById("paymenttype").selectedIndex].value,
          EncashDate:document.getElementById("encashdate").value,
          ChequeBookId:chequedetails.ChequeBookId,
        };
        var data2 = {
          EntryDate:moment().format('YYYY-MM-DD'),
          InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
          SellerAccId: clientAccid,
          AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
          Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
  
          Paymenttype: "Bank",
          VoucherDate: document.getElementById("bankReceiptDate").value,
          Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')}`,
          VoucherType: "B-PV",
        };
  
        if(document.getElementById("bankReceiptCheque").value === ""){
          var data = data2
        }else{
          var data = data1
        }
  
        axios({
          method: "post",
          url: window.$url + `api/PaymentVoucher/AddPaymentVoucher`,
          data: JSON.stringify([data]),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            console.log(result);
            paymentselleraccounts()
            dispatch(getallbankaccounts())
            dispatch(getallbankpaymentvouchers())
            toast.info(result.data.Message);
            document.getElementById("bankReceiptDebit").value = "";
            document.getElementById("bankReceiptCheque").value = "";
            document.getElementById("bankPaymentlistbank").selectedIndex = 0;
            document.getElementById("bankReceiptNarration").value = "";
            document.getElementById("bankPaymentAccount").value = "";
            setclientAccid(null)
            setloader2(false);
  
        document.getElementById("crvSave").disabled = false;
        document.getElementById("crvCancel").disabled = false;
  
        props.formCancel()
          },
          (error) => {
            toast.info(error);
            setloader2(false);
  
        document.getElementById("crvSave").disabled = false;
        document.getElementById("crvCancel").disabled = false;
          }
        );
      }
    };

 
    // const saveandprint = () => {
    //   if (document.getElementById("bankReceiptDate").value === "") {
    //     toast.info("Date is Required");
    //   }
    //   else if (document.getElementById("bankPaymentlistbank").selectedIndex === 0) {
    //     toast.info("Select Bank");
    //   }
    //   else if (document.getElementById("bankPaymentAccount").value === "") {
    //     toast.info("Select an Account");
    //   }
  
    //   else if (document.getElementById("bankReceiptDebit").value === "") {
    //     toast.info("Enter an Amount ");
    //   }
    //   else if (document.getElementById("bankReceiptNarration").value === "") {
    //     toast.info("Enter a Narration");
  
    //   }
    //   else {
    //     document.getElementById("crvSave").disabled = true;
    //     document.getElementById("crvCancel").disabled = true;
    //     setloader2(true);
    //     var data1 = {
    //       EntryDate:moment().format('YYYY-MM-DD'),
    //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
    //       SellerAccId: clientAccid,
    //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
    //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
    //       ChequeNumber: document.getElementById("bankReceiptCheque").value === "" ? null :document.getElementById("bankReceiptCheque").value,
    //       Paymenttype: "Bank",
    //       VoucherDate: document.getElementById("bankReceiptDate").value,
    //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')} / ${document.getElementById("bankReceiptCheque").value === "" ? null :document.getElementById("bankReceiptCheque").value}`,
    //       VoucherType: "B-PV",
    //       ChequeBookId:chequedetails.ChequeBookId,
    //     };
    //     var data2 = {
    //       EntryDate:moment().format('YYYY-MM-DD'),
    //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
    //       SellerAccId: clientAccid,
    //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
    //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
  
    //       Paymenttype: "Bank",
    //       VoucherDate: document.getElementById("bankReceiptDate").value,
    //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')}`,
    //       VoucherType: "B-PV",
    //     };
  
    //     if(document.getElementById("bankReceiptCheque").value === ""){
    //       var data = data2
    //     }else{
    //       var data = data1
    //     }
  
    //     axios({
    //       method: "post",
    //       url: window.$url + `api/PaymentVoucher/AddPaymentVoucher`,
    //       data: JSON.stringify(data),
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
    //         "content-type": "application/json",
    //       },
    //     }).then(
    //       (result) => {
    //         console.log(result);
  
    //         toast.info(result.data.Message);
    //         if(result.data.IsSuccess){
    //           setprintdata({
    //             VoucherNumber:result.data.Id,
    //             ChequeNumber:document.getElementById("bankReceiptCheque").value,
    //             Acc:{
    //               Accounttitle:document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].value
    //             },
    //             Voucherdate:document.getElementById("bankReceiptDate").value,
    //             Account:{
    //               Accounttitle:document.getElementById("bankPaymentAccount").value
    //             },
    //             PurchaseInvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
    //             Accountid:clientAccid,
    //             Narration:document.getElementById("bankReceiptNarration").value,
    //             Credit:document.getElementById("bankReceiptDebit").value
    //           })
    //           dispatch(getallbankpaymentvouchers())
    //           document.getElementById("bankReceiptDebit").value = "";
    //         document.getElementById("bankReceiptCheque").value = "";
    //         document.getElementById("bankPaymentlistbank").selectedIndex = 0;
    //         document.getElementById("bankReceiptNarration").value = "";
    //         document.getElementById("bankPaymentAccount").value = "";
    //         setclientAccid(null)
    //         }
  
    //         setloader2(false);
  
    //     document.getElementById("crvSave").disabled = false;
    //     document.getElementById("crvCancel").disabled = false;
    //       },
    //       (error) => {
    //         toast.info(error);
    //         setloader2(false);
  
    //     document.getElementById("crvSave").disabled = false;
    //     document.getElementById("crvCancel").disabled = false;
    //       }
    //     );
    //   }
    // };
    var i = 0;
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
 
    let dispatch = useDispatch();
  
  
    let bankaccounts = useSelector(
      (state) => state.GetAllBankAccountsDataReducer
    );
  
    useEffect(() => {
      dispatch(getallbankaccounts());
    }, [dispatch]);
  
    useEffect(() => {
      if (printdata !== undefined) {
        handlePrint();
      }
    }, [printdata]);
    useEffect(() => {
      if (bankaccounts !== undefined) {
        setbanklist(bankaccounts.data);
      }
    }, [bankaccounts]);
 
  
    useEffect(() => {
      if (invoiceamountlist.length !== 0) {
        let sum = 0;
  
        invoiceamountlist.forEach((x) => {
          sum += parseFloat(x);
        });
  
        setinvoiceamount(sum);
        setconversionamount(sum);
      } else {
        setinvoiceamount(null);
        setconversionamount(0);
      }
    }, [invoiceamountlist]);

    useEffect(() => {
      
      let abc  = []
      invoiceidlist.forEach((x) => {
        console.log(x)
        abc.push(`PInv-${x}`)
      });
      setselectedInvoice(abc)
      console.log(PurchaseInvoiceListID , abc , invoiceidlist)
    }, [invoiceidlist , PurchaseInvoiceListID])

    useEffect(() => {
      if(AccountTitle === ""){
        setinvoiceidlist([])
        setSaleInvoiceListID([])
        setinvoiceamount(null);
        setconversionamount(0);
        // document.getElementBy("purchaseinvoicedropdown").value = ""
      }
      console.log(AccountTitle)
    }, [AccountTitle])
  
    const discardcheque = () => {
      let data = {
        ChequeBookId: chequedetails.ChequeBookId,
        ChequeNumber: chequedetails.CurrentNumber,
        Narration: document.getElementById("discardreason").value,
        BankId: document.getElementById("bankPaymentlistbank").options[
          document.getElementById("bankPaymentlistbank").selectedIndex
        ].dataset.bankid,
      };
  
      if (
        chequedetails === null ||
        document.getElementById("discardreason").value === ""
      ) {
        toast.info("Please select a bank and add narration!");
      } else {
        setloader3(true);
        axios({
          url: window.$url + "api/Accounts/DiscardChequeNumber",
          method: "post",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
            "content-type": "application/json",
          },
          data: JSON.stringify(data),
        }).then(
          (result) => {
            setloader3(false);
            toast.info(result.data.Message);
            if (result.data.IsSuccess) {
              setbanklist(result.data.Bank);
              setonlinecheque(true);
              dispatch(getallbankaccounts());
              setonlinecheque(false);
              setshowencash(false);
  
              document.getElementById("bankPaymentlistbank").selectedIndex = 0;
              document.getElementById("bankReceiptCheque").value = "";
              document.getElementById("discardreason").value = "";
              document.getElementById("modalAddnewAccount2").style.display =
                "none";
            }
            console.log(result);
          },
          (error) => {
            setloader3(false);
            console.log(error);
          }
        );
      }
    };
  
    return (
      <>
        <div hidden>
          <Bankpaymentprint
            ref={componentRef}
            date={date}
            printdata={printdata}
          />
        </div>
        <div id="modalAddnewAccount2" class="modalscreen ">
          <div class="modal-content-screen1">
            <div className="col-12 text-right">
              <span
                class="close "
                onClick={() => {
                  document.getElementById("modalAddnewAccount2").style.display =
                    "none";
                }}
              >
                &times;
              </span>
            </div>
  
            <div className="row mt-2">
              <div className="col-12 text-center">
                <h4>Discard Cheque</h4>
              </div>
  
              <div className="col-6 mt-3">
                Cheque Number:{" "}
                <b>
                  {chequedetails !== null ? chequedetails.CurrentNumber : null}
                </b>
              </div>
              <div className="col-6 mt-3">
                Reason:{"  "}
                <input id="discardreason" className="input_border formInput" />
              </div>
              <div className="col-12 mt-3 text-right">
                <span>
                  <button
                    id="crvSave"
                    onClick={discardcheque}
                    className="tabletopbutton  text-right greenbutton"
                  >
                    {"Discard"}
                  </button>
                  <div className="loaderclass ml-1 float-right">
                    {" "}
                    {loader3 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="modalAddnewAccount" class="modalscreen ">
          <div class="modal-content-screen1">
            <span
              class="close "
              onClick={() => {
                document.getElementById("modalAddnewAccount").style.display =
                  "none";
              }}
            >
              &times;
            </span>
            <div>
              <table class="table table-hover">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Date
                    </th>
                    <th scope="col" className="th1">
                      Narration
                    </th>
                    <th scope="col" className="th1">
                      Invoice No.
                    </th>
                    <th scope="col" className="th1">
                      Net Amount
                    </th>
                    <th scope="col" className="th1">
                      Select
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {saleinvoices !== undefined ? (
                    saleinvoices.map((x, idx) => (
                      <tr
                      //onClick={()=>{
                      // setinvoicevalue(x.PurchaseInvoiceId)
                      // setinvoiceamount( parseFloat(x.NetTotalAmount))
                      // setconversionamount( parseFloat(x.NetTotalAmount))
                      // document.getElementById("modalAddnewAccount").style.display = "none"
                      //}}
                      >
                        <td>{idx + 1}</td>
                        <td>{x.Date.split("T")[0]}</td>
                        <td>{x.Narration}</td>
                        <td>PInv-{x.PurchaseInvSerialNo}</td>
                        <td>{x.NetTotalAmount}</td>
                        <td>
                          <input
                            type="checkbox"
                            id={`${idx}multipleinvoice`}
                            onClick={(e) => {
                              if (e.target.checked === true) {
                                setinvoiceidlist([
                                  ...invoiceidlist,
                                  x.PurchaseInvSerialNo,
                                ]);
                                setSaleInvoiceListID([
                                  ...PurchaseInvoiceListID,
                                  x.PurchaseInvoiceId,
                                ]);
                                setinvoiceamountlist([
                                  ...invoiceamountlist,
                                  x.NetTotalAmount,
                                ]);
                              } else {
                                const rows = [...invoiceidlist];
                                rows.splice(idx, 1);
                                setinvoiceidlist(rows);

                                const rowsId = [...PurchaseInvoiceListID];
                                rowsId.splice(idx, 1);
                                setSaleInvoiceListID(rowsId)
  
                                const rows1 = [...invoiceamountlist];
                                rows1.splice(idx, 1);
                                setinvoiceamountlist(rows1);
                              }
                            }}
                          ></input>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div className=" tableContainer2 overflow-auto pageheading Sitka mt-3">
          <div className="col-md-12 col-lg-12 col-xl-12 ">
            <div className="row mt-3">
              <div className="col-2 text-right">
                <span className="txt">Payment Type</span>
              </div>
              <div className="col-4 text-left">
                <span>
                  {" "}
                  <select
                    type="text"
                    id="paymenttype"
                    className="input_border formInput "
                    onChange={(e) => {
                      document.getElementById("bankPaymentlistbank").selectedIndex = 0;
                      setchequedetails(null);
                      if (e.target.selectedIndex === 0) {
                        setonlinecheque(true);
                        setshowencash(false);
                      } else if (e.target.selectedIndex === 1) {
                        setonlinecheque(false);
                        setshowencash(false);
                      } else {
                        setonlinecheque(false);
                        setshowencash(true);
                      }
                    
                    }}
                  >
                    <option value="online">Online</option>
                    <option value="cheque">Cheque</option>
                    <option value="Post Dated Cheque">Post Dated Cheque</option>
                  </select>
                </span>
              </div>
  
              {showencash ? (
                <>
                  <div className="col-2 text-right">
                    <span className="txt">Encash Date</span>
                  </div>
                  <div className="col-4 text-left">
                    <span>
                      {" "}
                      <input
                        type="date"
                        id="encashdate"
                        className="input_border formInput"
                      ></input>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-2 text-right"></div>
                  <div className="col-4 text-left"></div>
                </>
              )}
  
              <div className="col-2 mt-3 text-right">
                <span className="txt">Bank List</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <select
                    type="text"
                    id="bankPaymentlistbank"
                    className="input_border formInput "
                    onChange={(e) => {
                        setchequedetails(
                          bankaccounts?.data[
                            e.target.options[e.target.selectedIndex]?.dataset.idx
                          ]?.ChequeBook
                        );
                    }}
                  >
                    <option value="">Select</option>
                    {banklist !== undefined
                      ? banklist.map((x, idx) => (
                          <option
                            data-id={x.Accid}
                            data-idx={idx}
                          >{`${x.Accounttitle} (${x.AccountNumber})`}</option>
                        ))
                      : null}
                  </select>
                </span>
              </div>
              <div className="col-2 mt-3  text-right">
                <span className="txt">Date</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="date"
                    defaultValue={date}
                    id="bankReceiptDate"
                    className="input_border formInput"
                  ></input>
                </span>
              </div>
  
              {onlinecheque ? (
                <>
                  <div className="col-2 mt-3 text-right">
                    <span className="txt">Cheque No.</span>
                  </div>
                  <div className="col-4 mt-3 text-left">
                    <span>
                      {" "}
                      <input
                        type="number"
                        disabled
                        id="bankReceiptCheque"
                        className="input_border formInput "
                      ></input>
                    </span>
                    {"  "}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-2 mt-3 text-right">
                    <span className="txt">Cheque No.</span>
                  </div>
                  <div className="col-4 mt-3 text-left">
                    <span>
                      {" "}
                      <input
                        type="number"
                        disabled
                        id="bankReceiptCheque"
                        className="input_border formInput "
                        key={
                          chequedetails !== null
                            ? chequedetails.CurrentNumber
                            : null
                        }
                        defaultValue={
                          chequedetails !== null
                            ? chequedetails.CurrentNumber
                            : null
                        }
                      ></input>
                    </span>
                    {"  "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        (document.getElementById(
                          "modalAddnewAccount2"
                        ).style.display = "block")
                      }
                    >
                      <RiDeleteBack2Fill size="1.7em" />
                    </span>
                  </div>
                </>
              )}
              <div className="col-2 mt-3 text-right">
                <span className="txt">Invoice</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  <input               

                    type="checkbox"
                    id="invoicecheckbox"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setconversionamount(0);
                        setPaymentaccount(initialdata.SellerAccount);
                        setAccountTitle("")
                        document.getElementById("cashReceiptAccount").value = "";  
                        setinvoiceamount(null);
                        setclientAccid(null);
                      } else {
                        setconversionamount(0);
                        setPaymentaccount(initialdata.PaymentAccount);
                        setAccountTitle("")
                        document.getElementById("cashReceiptAccount").value = "";  
                        setinvoiceamount(null);
                        setclientAccid(null);
                      }
                    }}
                    onClick={() => {
                      if (
                        document.getElementById("invoicecheckbox").checked ===
                        true
                      ) {
                        setdisplayinvoice(true);
                      } else {
                        setdisplayinvoice(false);
                      }
                    }}
                  ></input>
                </span>
              </div>
              <div className="col-2 mt-3 text-right">
                <span className="txt"> Account</span>
              </div>
              {/* <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="text"
                    id="bankPaymentAccount"
                    onChange={onTextChange}
                    autoComplete="off"
                    className="input_border formInput "
                  ></input>
                </span>
                <span id="demo"></span>
              </div> */}
              <div className="col-4  mt-3  text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="cashReceiptAccount"
                  // value={AccountTitle}
                  list="accountList"
                  onChange={OnAccountChange}
                  className="input_border formInput "
                  autoComplete="off"
                ></input>
                <datalist id="accountList">
                  {Paymentaccount?.map((v, idx) => {
                    // console.log(v);
                    return (
                      <option attributeId={v?.Accid} value={v?.Accounttitle}>
                        {v?.Accounttitle} {v?.AccountTitle ? `- ${v?.AccountTitle}` : ""}
                      </option> 
                    );
                  })}
                </datalist>
              </span>

              <span id="demo"></span>
            </div>
  
              {displayinvoice ? (
                <>
                  <div className="col-2 mt-3 text-right">
                    <span className="txt">Purchase Invoice</span>
                  </div>
                  <div className="col-4 mt-3 text-left">
                    <span>
                      {" "}
                      {/* <select
                    id="purchaseinvoicedropdown"
                    className="input_border formInput "
                    onChange={(e)=>{
                      setinvoiceamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                      setconversionamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                    }}
                  >
  
                    <option>Select</option>
                    {
                      saleinvoices.map((x)=>(
                        <option data-amount={x.NetTotalAmount}>{x.PurchaseInvoiceId}</option>
                      ))
                    }
                  </select> */}
                      <input
                        id="purchaseinvoicedropdown"
                        className="input_border formInput "
                        readOnly
                        onClick={() => {
                          document.getElementById(
                            "modalAddnewAccount"
                          ).style.display = "block";
                        }}
                        value={
                          selectedInvoice.length !== 0
                            ? selectedInvoice.toString()
                            : ""
                        }
                      ></input>
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              {displayinvoice === false ? (
                <>
                  <div className="col-2 mt-3 text-right">
                    <span className="txt">Amount</span>
                  </div>
                  <div className="col-4 mt-3 text-left">
                    <span>
                      {" "}
                      <input
                        type="text"
                        id="bankReceiptDebit"
                        key={invoiceamount}
                        defaultValue={invoiceamount}
                        className="input_border formInput "
                        onChange={(e) => {
                          if (e.target.value.length > 0)
                            e.target.value = parseInt(
                              e.target.value.replace(/,/g, "")
                            ).toLocaleString();
                          if (e.target.value === "NaN") {
                            e.target.value = "";
                          }
                          if (invoiceamount !== null) {
                            if (
                              parseFloat(
                                document
                                  .getElementById("bankReceiptDebit")
                                  .value.replace(/,/g, "")
                              ) > invoiceamount
                            ) {
                              document.getElementById("bankReceiptDebit").value =
                                invoiceamount;
                            }
                          }
                          if (e.target.value === "") {
                            e.target.value = 0;
                          }
                          setconversionamount(
                            parseFloat(
                              document
                                .getElementById("bankReceiptDebit")
                                .value.replace(/,/g, "")
                            )
                          );
                        }}
                      ></input>
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
  
              <div className="col-2 mt-3 text-right">
                <span className="txt">Narration</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <textarea
                    style={{ height: "40px" }}
                    type="text"
                    id="bankReceiptNarration"
                    className="input_border formInput"
                  ></textarea>
                </span>
              </div>
  
              {displayinvoice ? (
                <>
                  <div className="col-2 mt-3 text-right">
                    <span className="txt">Amount</span>
                  </div>
                  <div className="col-4 mt-3 text-left">
                    <span>
                      {" "}
                      <input
                        type="text"
                        id="bankReceiptDebit"
                        key={invoiceamount}
                        defaultValue={invoiceamount}
                        className="input_border formInput "
                        onChange={(e) => {
                          if (e.target.value.length > 0)
                            e.target.value = parseInt(
                              e.target.value.replace(/,/g, "")
                            ).toLocaleString();
                          if (e.target.value === "NaN") {
                            e.target.value = "";
                          }
                          if (invoiceamount !== null) {
                            if (
                              parseFloat(
                                document
                                  .getElementById("bankReceiptDebit")
                                  .value.replace(/,/g, "")
                              ) > invoiceamount
                            ) {
                              document.getElementById("bankReceiptDebit").value =
                                invoiceamount;
                            }
                          }
                          if (e.target.value === "") {
                            e.target.value = 0;
                          }
                          setconversionamount(
                            parseFloat(
                              document
                                .getElementById("bankReceiptDebit")
                                .value.replace(/,/g, "")
                            )
                          );
                        }}
                      ></input>
                    </span>
                  </div>
                  <div className="col-6 "></div>
                </>
              ) : (
                <></>
              )}
              <div className="col-2 mt-3 text-right">
                <span className="txt">Bank Charges</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="number"
                    id="bankcharges"
                    autoComplete="off"
                    className="input_border formInput "
                  ></input>
                </span>
              </div>
  
              <div className="col-6 mt-3 ">
                <span className="mr-3">
                  <b>Amount in words: </b>{" "}
                  {converter.toWords(
                    conversionamount !== null ||
                      conversionamount !== undefined ||
                      conversionamount !== ""
                      ? conversionamount
                      : 0
                  )}
                </span>
              </div>
              <div className="col-6 mt-3  text-right">
                <span>
                  <button
                    id="crvSave"
                    onClick={saveandnew}
                    className="tabletopbutton  text-right greenbutton"
                  >
                    {"Save & New"}
                  </button>
                </span>
                {/* <span>
                  <button
                    id="crvSave"
                    onClick={saveandprint}
                    className="tabletopbutton ml-2 text-right bluebutton"
                  >
                    {"Save & Print"}
                  </button>
                </span> */}
                <span>
                  <button
                    id="crvSave"
                    onClick={saveandclose}
                    className="tabletopbutton ml-2 text-right redbutton"
                  >
                    {"Save & Close"}
                  </button>
                </span>
                <span>
                  <button
                    id="crvCancel"
                    className="tabletopbutton ml-2 text-right "
                    onClick={props.formCancel}
                  >
                    Cancel
                  </button>
                </span>
                <div className="loaderclass ml-1 float-right">
                  {" "}
                  {loader2 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };