import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";

export default class JournalPrint extends Component {
    render() {
      return (
        <div
          style={{
            backgroundColor: "white",
            height: "100%",
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div className="row m-3 p-0" style={{ height: "80%" }}>
            <div className="col-12 text-center mt-2">
              <h4 className="headingscss">
                <b>General Journal</b>
              </h4>
              <hr style={{ backgroundColor: "black" }} />
              <div className="mt-3">
                <div class="table-responsive col-12">
                  <table class="table pb-3 pt-3">
                    <thead>
                      <tr>
                        <th className="th1" scope="col">
                          Sr.
                        </th>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Account title</th>
                        <th>Narration</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.accountslist != undefined
                        ? this.props.accountslist.map((x, idx) => (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>{x.transDate.split("T")[0]}</td>
                              <td>{x.voucherNo}</td>
                              <td>{x.particular}</td>
                              <td>{x.narration}</td>
                              <td>
                                {x.debit !== ""
                                  ? parseFloat(x.debit).toLocaleString()
                                  : null}
                              </td>
                              <td>
                                {x.credit !== ""
                                  ? parseFloat(x.credit).toLocaleString()
                                  : null}
                              </td>
                            </tr>
                          ))
                        : null}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <b>Total: </b>
                        </td>
  
                        <td>
                          <b>
                            {this.props.creditTotal !== undefined
                              ? this.props.creditTotal.toLocaleString()
                              : null}
                          </b>
                        </td>
                        <td>
                          <b>
                            {this.props.statebalance !== undefined
                              ? this.props.statebalance.toLocaleString()
                              : null}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  