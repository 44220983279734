const alljournalvouchersdata = {data: [], loading: true};

export const GetAllJournalVouchersDataReducer = (state = alljournalvouchersdata, action) =>{
    switch(action.type){
        case 'getalljournalvouchersdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}