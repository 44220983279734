
import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { TiPrinter } from "react-icons/ti";

import "../../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { PurchaseContractIndividual, Purchaselist } from "./PurchaseContractPrint";

export const PurchaseContractTable = (props) => {
    const [loader1, setloader1] = useState(false);
    const [contractdata, setcontractdata] = useState([]);
    const [contractdetailsdata, setcontractdetailsdata] = useState([]);
    const [contractdatafilter, setcontractdatafilter] = useState([]);
    const [printdata, setprintdata] = useState();
    const [closedata, setclosedata] = useState(null);
  
    const componentRef1 = useRef();
    const handlePrint1 = useReactToPrint({
      content: () => componentRef1.current,
    });
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const getallpurchasecontract = () => {
      setloader1(true);
      axios({
        url: `${window.$url}api/Purchase/GetAllPurchaseContracts`,
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          setloader1(false);
          console.log(result);
          setcontractdata(result.data.PurchaseContract);
          setcontractdatafilter(result.data.PurchaseContract);
        },
        (error) => {
          setloader1(false);
          console.log(error);
        }
      );
    };
  
    var suggestionlist = [];
  
    const onTextChange1 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) => regex.test(v.PurchaseContSerialNumber));
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    const onTextChange2 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) => regex.test(v.SellerName));
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    const onTextChange3 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) =>
              regex.test(
                v.Date !== null && v.Date !== undefined
                  ? v.Date.split("T")[0]
                  : null
              )
            );
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    const onTextChange4 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setcontractdata(contractdatafilter);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = contractdatafilter
            .sort()
            .filter((v) =>
              regex.test(
                v.CommisionAgent !== null
                  ? v.CommisionAgent.CommisionAgentName
                  : null
              )
            );
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setcontractdata([]);
        } else {
          setcontractdata(suggestionlist);
        }
      }
    };
    useEffect(() => {
      getallpurchasecontract();
    }, []);
    useEffect(() => {
      if (contractdetailsdata.length !== 0) {
        document.getElementById("modalAddnewAccount").style.display = "block";
      } else {
        document.getElementById("modalAddnewAccount").style.display = "none";
      }
    }, [contractdetailsdata]);
  
    const closebutton = () => {
      console.log("close");
      let data = {
        loginModel: {
          UserName: document.getElementById("username").value,
          Password: document.getElementById("password").value,
        },
        ContractId: closedata.PurchaseContractId,
      };
  
      setloader1(true);
      axios({
        method: "post",
        data: JSON.stringify(data),
        url: window.$url + "api/Purchase/CancelContract",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          toast.info(result.data.Message);
          if (result.data.IsSuccess) {
            document.getElementById("username").value = "";
            document.getElementById("password").value = "";
            setclosedata(null);
            getallpurchasecontract();
          }
  
          setloader1(false);
        },
        (error) => {
          toast.info(error);
          setloader1(false);
        }
      );
    };
  
    console.log(contractdetailsdata);
  
    useEffect(() => {
      if (closedata !== null) {
        document.getElementById("modalAddnewAccount4").style.display = "block";
      } else {
        document.getElementById("modalAddnewAccount4").style.display = "none";
      }
    }, [closedata]);
    return (
      <>
        <div hidden>
          <PurchaseContractIndividual ref={componentRef} printdata={printdata} />
          <Purchaselist
            ref={componentRef1}
            printname={"Contract"}
            contractdata={contractdata}
          />
        </div>
        <div id="modalAddnewAccount4" class="modalscreen ">
          <div class="modal-content-screen">
            <div className="col-12 text-right">
              <span
                class="close "
                onClick={() => {
                  setclosedata(null);
                }}
              >
                &times;
              </span>
            </div>
  
            <div className="row mt-2">
              <div className="col-12 text-center">
                <h4>Are You Sure?</h4>
              </div>
  
              <div className="col-4 text-right mt-1">User Name:</div>
              <div className="col-8 text-center mt-1">
                <input type="text" className="logininput" id="username" />
              </div>
  
              <div className="col-4 text-right mt-2">Password:</div>
              <div className="col-8 text-center mt-2">
                <input
                  type="password"
                  className="logininput"
                  id="password"
                  onChange={(e) => {
                    if (e.target.value.length < 5) {
                      console.log("length");
                      document.getElementById("entercredentials").disabled = true;
                    } else {
                      console.log("length greater");
                      document.getElementById(
                        "entercredentials"
                      ).disabled = false;
                    }
                  }}
                />
              </div>
  
              <div className="col-12 text-center mt-2">
                <button
                  className="tabletopbutton pb-1 greenbutton"
                  id="entercredentials"
                  onClick={closebutton}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div id="modalAddnewAccount" class="modalscreen ">
          <div class="modal-content-screen1">
            <span
              class="close "
              onClick={() => {
                setcontractdetailsdata([]);
              }}
            >
              &times;
            </span>
            <div>
              <table class="table table-hover">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Yarn Type
                    </th>
                    <th scope="col" className="th1">
                      Brand
                    </th>
                    <th scope="col" className="th1">
                      No. Of Bags
                    </th>
                    <th scope="col" className="th1">
                      Rate
                    </th>
                    <th scope="col" className="th1">
                      Amount
                    </th>
                    <th scope="col" className="th1">
                      S.Tax
                    </th>
                    <th scope="col" className="th1">
                      Net Amount
                    </th>
                    <th scope="col" className="th1">
                      <TiPrinter
                        onClick={handlePrint}
                        style={{ cursor: "pointer" }}
                        size="1.5em"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contractdetailsdata !== null
                    ? contractdetailsdata.map((x, idx) => (
                        <tr>
                          <th scope="col" className="th1">
                            {idx + 1}
                          </th>
                          <th scope="col" className="th1">
                          {x.Product.ProductType} {x.Product.Composition ? ` - (${x.Product.Composition})` : ""}
                          </th>
                          <th scope="col" className="th1">
                            {x.Brand.BrandName}
                          </th>
                          <th scope="col" className="th1">
                            {x.ProductQty}
                          </th>
                          <th scope="col" className="th1">
                            {x.Rate}
                          </th>
                          <th scope="col" className="th1">
                            {x.Amount}
                          </th>
                          <th scope="col" className="th1">
                            {x.TaxAmount}
                          </th>
                          <th scope="col" className="th1">
                            {x.NetAmount}
                          </th>
                          <th scope="col" className="th1">
                            {}
                          </th>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
          <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
            <div className="row row1_style input_border ">
              <div className="col-12">
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Date"
                    className="input_border"
                    onChange={onTextChange3}
                  ></input>
                </span>
  
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Contract No."
                    className="input_border"
                    onChange={onTextChange1}
                  ></input>
                </span>
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Customer"
                    className="input_border"
                    onChange={onTextChange2}
                  ></input>
                </span>
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Agent"
                    className="input_border"
                    onChange={onTextChange4}
                  ></input>
                </span>
                <span className="ml-2">
                  <button
                    onClick={handlePrint1}
                    id="callapibutn"
                    className="tabletopbutton   text-right ml-2 "
                  >
                    Print
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <button
                    onClick={props.changecomponent}
                    className="tabletopbutton1 text-right"
                  >
                    New
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <div className="loaderclass">
                    {" "}
                    {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="row ">
              <table class="table tableCash table-hover">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Date
                    </th>
                    <th scope="col" className="th1">
                      Contract No.
                    </th>
                    <th scope="col" className="th1">
                      Vendor
                    </th>
                    <th scope="col" className="th1">
                      Com. Agent / Amount
                    </th>
                    <th scope="col" className="th1">
                      Narration
                    </th>
                    <th scope="col" className="th1">
                      Amount
                    </th>
                    <th scope="col" className="th1">
                      Status
                    </th>
                    <th scope="col" className="th1"></th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "10pt" }}>
                  {contractdata !== undefined
                    ? contractdata.map((x, idx) => (
                        <tr style={{ cursor: "pointer" }}>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {idx + 1}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.Date.split("T")[0]}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            PCont-{x.PurchaseContSerialNumber}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.Account.Accounttitle}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.CommisionAgent !== null
                              ? x.CommisionAgent.CommisionAgentName
                              : null}{" "}
                            / {x.CommisionAmount}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.Narration}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.TotalAmount !== null
                              ? x.TotalAmount.toLocaleString()
                              : null}
                          </td>
                          <td
                            onClick={() => {
                              setcontractdetailsdata(x.PurchaseContractDetails);
                              setprintdata(x);
                            }}
                          >
                            {x.Status}
                          </td>
                          <td>
                            {x.Status === "Pending" ? (
                              <button
                                id={`${idx}closebutton`}
                                className="tabletopbutton redbutton ml-1"
                                onClick={() => {
                                  setclosedata(x);
                                }}
                              >
                                Inactive
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };