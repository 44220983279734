import React, { lazy, Suspense, useState } from "react";
import LazyLoader from "../../LazyLoader/LazyLoader";
const SellerForm = lazy(()=> import ("./SellerForm"))
const SellerTable = lazy(()=> import ("./SellerTable"))


const SellerMain = () => {
  const [showForm, setshowForm] = useState(false);
  const [seller, setseller] = useState(null);

  return (
    <Suspense fallback={<LazyLoader/>}>
      {showForm ? (
        <SellerForm seller={seller} setshowForm={setshowForm} showForm={showForm} />
      ) : (
        <SellerTable setseller={setseller} setshowForm={setshowForm} showForm={showForm} />
      )}
    </Suspense>
  );
};

export default SellerMain;
