import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import YarnTypePrint, { BrandPrint } from "./YarnPrint";
import "../../../Bootstrap/haroon.css";
import { toast } from "react-toastify";
import Delete from "../../../Images/delete.png";

const YarnMain = () => {
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [brandlist, setbrandlist] = useState([]);
  const [brandlistfilter, setbrandlistfilter] = useState([]);
  const [yarnlist, setyarnlist] = useState([]);
  const [yarnlistfilter, setyarnlistfilter] = useState([]);
  const [tabchosen, settabchosen] = useState("yarn");
  console.log("brandlist", brandlist);
  // useEffect(()=>{
  //     if(tabchosen === "yarn"){
  //         document.getElementById("yarnbuttonid").style.backgroundColor = "white"
  //         document.getElementById("yarnbuttonid").style.borderBottom = "none"
  //         document.getElementById("yarnbuttonid").style.opacity = "1"

  //         document.getElementById("brandbuttonid").style.backgroundColor = "lightgrey"
  //         document.getElementById("brandbuttonid").style.borderBottom = "1px solid black"
  //         document.getElementById("brandbuttonid").style.opacity = "0.6"
  //     }else{
  //         document.getElementById("brandbuttonid").style.backgroundColor = "white"
  //         document.getElementById("brandbuttonid").style.borderBottom = "none"
  //         document.getElementById("brandbuttonid").style.opacity = "1"

  //         document.getElementById("yarnbuttonid").style.backgroundColor = "lightgrey"
  //         document.getElementById("yarnbuttonid").style.borderBottom = "1px solid black"
  //         document.getElementById("yarnbuttonid").style.opacity = "0.6"
  //     }
  // },[tabchosen])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  var suggestionlist = [];
  var suggestionlist1 = [];

  const onTextChange1 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setyarnlist(yarnlistfilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = yarnlistfilter
          .sort()
          .filter((v) => regex.test(v.ProductType));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setyarnlist([]);
      } else {
        setyarnlist(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbrandlist(brandlistfilter);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = brandlistfilter
          .sort()
          .filter((v) => regex.test(v.BrandName));
      }
      suggestionlist1 = suggestions;
      console.log(suggestionlist1);

      if (suggestionlist1.length === 0) {
        setbrandlist([]);
      } else {
        setbrandlist(suggestionlist1);
      }
    }
  };

  const addbrandnew = () => {
    let data = {
      BrandName: document.getElementById("journalCheque").value,
    };

    if (document.getElementById("journalCheque").value === "") {
      toast.info("Please fill the field");
    } else {
      document.getElementById("callapibutn").disabled = true;
      setloader1(true);
      axios({
        method: "post",
        url: window.$url + "api/Inventory/AddBrand",
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          document.getElementById("callapibutn").disabled = false;
          toast.info(result.data.Messege);
          if (result.data.IsSuccess) {
            document.getElementById("journalCheque").value = "";
          }
          setloader1(false);
          getbrand();
        },
        (error) => {
          toast.info(error);
          document.getElementById("callapibutn").disabled = false;
          setloader1(false);
        }
      );
    }
  };
  const addbrandclose = () => {
    let data = {
      BrandName: document.getElementById("journalCheque").value,
    };

    if (document.getElementById("journalCheque").value === "") {
      toast.info("Please fill the field");
    } else {
      document.getElementById("callapibutn").disabled = true;
      setloader1(true);
      axios({
        method: "post",
        url: window.$url + "api/Inventory/AddBrand",
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          document.getElementById("callapibutn").disabled = false;
          toast.info(result.data.Messege);
          if (result.data.IsSuccess) {
            document.getElementById("journalCheque").value = "";
            document.getElementById("modalAddnewAccount2").style.display =
              "none";
          }
          setloader1(false);
          getbrand();
        },
        (error) => {
          toast.info(error);
          document.getElementById("callapibutn").disabled = false;
          setloader1(false);
        }
      );
    }
  };
  const getbrand = () => {
    setloader2(true);
    axios({
      method: "get",
      url: window.$url + "api/Inventory/GetAllBrand",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setbrandlist(result.data.Brand);
        setbrandlistfilter(result.data.Brand);
        setloader2(false);
      },
      (error) => {
        toast.info(error);
        setloader2(false);
      }
    );
  };
  const getYarn = () => {
    setloader3(true);
    axios({
      method: "get",
      url: window.$url + "api/Inventory/GetAllProducts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setyarnlist(result.data.Product);
        setyarnlistfilter(result.data.Product);
        setloader3(false);
      },
      (error) => {
        toast.info(error);
        setloader3(false);
      }
    );
  };
  const addyarnnew = () => {
    let data = {
      BrandId: Number(document.getElementById("type").value),
      Name: document.getElementById("name").value,
      Description: document.getElementById("description").value,
      ProductId: 0,
    };

    if (
      document.getElementById("type").value === "" ||
      document.getElementById("description").value === "" ||
      document.getElementById("name").value === ""
    ) {
      toast.info("Please fill all the fields");
    } else {
      document.getElementById("callapibutn").disabled = true;
      setloader1(true);
      axios({
        method: "post",
        url: window.$url + "api/Inventory/AddProduct",
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          document.getElementById("callapibutn").disabled = false;
          toast.info(result.data.Messege);
          if (result.data.IsSuccess) {
            document.getElementById("type").value = "";
            document.getElementById("description").value = "";
            document.getElementById("name").value = "";
          }
          setloader1(false);
          getYarn();
        },
        (error) => {
          toast.info(error);
          document.getElementById("callapibutn").disabled = false;
          setloader1(false);
        }
      );
    }
  };
  const addyarnclose = () => {
    let data = {
      BrandId: Number(document.getElementById("type").value),
      Name: document.getElementById("name").value,
      Description: document.getElementById("description").value,
      ProductId: 0,
    };

    if (
      document.getElementById("type").value === "" ||
      document.getElementById("description").value === "" ||
      document.getElementById("name").value === ""
    ) {
      toast.info("Please fill all the fields");
    } else {
      document.getElementById("callapibutn").disabled = true;
      setloader1(true);
      axios({
        method: "post",
        url: window.$url + "api/Inventory/AddProduct",
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          document.getElementById("callapibutn").disabled = false;
          toast.info(result.data.Messege);
          if (result.data.IsSuccess) {
            document.getElementById("type").value = "";
            document.getElementById("description").value = "";
            document.getElementById("name").value = "";
            document.getElementById("modalAddnewAccount2").style.display =
              "none";
          }
          getYarn();
          setloader1(false);
          document.getElementById("modalAddnewAccount2").style.display = "none";
        },
        (error) => {
          toast.info(error);
          document.getElementById("callapibutn").disabled = false;
          setloader1(false);
        }
      );
    }
  };

  useEffect(() => {
    getbrand();
    getYarn();
  }, []);

  const [BrandIdDel, setBrandIdDel] = useState(null);
  const [YarnIdDel, setYarnIdDel] = useState(null);
  const [YArnloader, setYArnloader] = useState(false);
  const [BrandLoader, setBrandLoader] = useState(false);
  const handleDeleteYarn = () => {
    console.log(YarnIdDel);
    setYArnloader(true);
    axios({
      method: "delete",
      url: window.$url + `api/Inventory/DeleteProduct?id=${YarnIdDel}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Messege);
        document.getElementById("yarnDeleteModal").style.display = "none";
        setYarnIdDel(null);
        if (result.data.IsSuccess) {
          getYarn();
        }
        setYArnloader(false);
      },
      (error) => {
        // document.getElementById("yarnDeleteModal").style.display = "none";
        // setYarnIdDel(null);
        toast.info(error);
        setYArnloader(false);
      }
    );
  };

  useEffect(() => {
    if (YarnIdDel !== null) {
      document.getElementById("yarnDeleteModal").style.display = "block";
    } else {
      document.getElementById("yarnDeleteModal").style.display = "none";
    }
  }, [YarnIdDel]);
  const handleDeleteBrand = () => {
    console.log(BrandIdDel);
    setBrandLoader(true);
    axios({
      method: "delete",
      url: window.$url + `api/Inventory/DeleteBrand?id=${BrandIdDel}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Messege);
        document.getElementById("brandDeleteModal").style.display = "none";
        setBrandIdDel(null);
        if (result.data.IsSuccess) {
          getbrand();
        }
        setBrandLoader(false);
      },
      (error) => {
        // document.getElementById("brandDeleteModal").style.display = "none";
        // setBrandIdDel(null);
        toast.info(error);
        setBrandLoader(false);
      }
    );
  };

  useEffect(() => {
    if (BrandIdDel !== null) {
      document.getElementById("brandDeleteModal").style.display = "block";
    } else {
      document.getElementById("brandDeleteModal").style.display = "none";
    }
  }, [BrandIdDel]);

  return (
    <>
      <div id="yarnDeleteModal" class="modalscreen ">
        <div class="modal-content-screen_Half">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setYarnIdDel(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            {YArnloader ? (
              <span className="col-12 w-100 d-flex justify-content-center">
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </span>
            ) : (
              <>
                <div className="col-6 text-center">
                  <button
                    id="callapibutn"
                    className="tabletopbutton pb-1 greenbutton"
                    onClick={handleDeleteYarn}
                  >
                    {"YES"}
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    id="callapibutn"
                    className="tabletopbutton pb-1 ml-1 redbutton"
                    onClick={() => {
                      setYarnIdDel(null);
                    }}
                  >
                    {"CANCEL"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div id="brandDeleteModal" class="modalscreen ">
        <div class="modal-content-screen_Half">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setBrandIdDel(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            {BrandLoader ? (
              <span className="col-12 w-100 d-flex justify-content-center">
                <Loader
                  type="Oval"
                  color="black"
                  height={30}
                  width={30}
                  radius={12}
                />
              </span>
            ) : (
              <>
                <div className="col-6 text-center">
                  <button
                    id="callapibutn"
                    className="tabletopbutton pb-1 greenbutton"
                    onClick={handleDeleteBrand}
                  >
                    {"YES"}
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    id="callapibutn"
                    className="tabletopbutton pb-1 ml-1 redbutton"
                    onClick={() => {
                      setBrandIdDel(null);
                    }}
                  >
                    {"CANCEL"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div hidden>
        <YarnTypePrint ref={componentRef} yarnlist={yarnlist} />
        <BrandPrint ref={componentRef1} brandlist={brandlist} />
      </div>
      <div id="modalAddnewAccount2" class="modalscreen ">
        <div class="modal-content-screen1">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                document.getElementById("modalAddnewAccount2").style.display =
                  "none";
                document.getElementById("type").value = "";
                document.getElementById("description").value = "";
                document.getElementById("name").value = "";
              }}
            >
              &times;
            </span>
          </div>

          {tabchosen === "yarn" ? (
            <div className="row mt-3">
              <div className="col-12 text-center">
                <b>Item</b>
              </div>
              <div className="col-1 mt-3 text-right">
                <span className="txt">Name</span>
              </div>
              <div className="col-3 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="text"
                    id="name"
                    className="input_border formInput "
                  ></input>
                </span>
              </div>
              <div className="col-1 mt-3 text-right">
                <span className="txt">Type</span>
              </div>
              <div className="col-3 mt-3 text-left">
                <span>
                  {" "}
                  <select id="type" className="input_border formInput ">
                    <option value="">Select</option>
                    {brandlist?.map((item, idx) => (
                      <option key={idx} value={item?.BrandId}>
                        {item?.BrandName}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="col-1 mt-3 text-right">
                <span className="txt">Description</span>
              </div>
              <div className="col-3 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="text"
                    id="description"
                    className="input_border formInput "
                  ></input>
                </span>
              </div>
              <div className="col-2 mt-3 text-right"></div>
              <div className="col-4 mt-3 text-left"></div>
              <div className="col-6 text-right mt-3">
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 greenbutton"
                  onClick={addyarnnew}
                >
                  {"Save & New"}
                </button>
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 ml-1 redbutton"
                  onClick={addyarnclose}
                >
                  {"Save & Close"}
                </button>
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 ml-1"
                  onClick={() => {
                    document.getElementById(
                      "modalAddnewAccount2"
                    ).style.display = "none";
                    document.getElementById("type").value = "";
                    document.getElementById("description").value = "";
                    document.getElementById("name").value = "";
                  }}
                >
                  Cancel
                </button>
              </div>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-12 text-center">
                <b>Item Type</b>
              </div>
              <div className="col-2 mt-3 text-right">
                <span className="txt">Type Name</span>
              </div>
              <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="text"
                    id="journalCheque"
                    className="input_border formInput "
                  ></input>
                </span>
              </div>
              <div className="col-6 text-right mt-3">
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 greenbutton"
                  onClick={addbrandnew}
                >
                  {"Save & New"}
                </button>
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 ml-1 redbutton"
                  onClick={addbrandclose}
                >
                  {"Save & Close"}
                </button>
                <button
                  id="callapibutn"
                  className="tabletopbutton pb-1 ml-1"
                  onClick={() => {
                    document.getElementById(
                      "modalAddnewAccount2"
                    ).style.display = "none";
                  }}
                >
                  Cancel
                </button>
              </div>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="SettingContainer1 mt-3 Sitka datatablecssjournal">
        <div className="row m-0 p-0">
          {/* <h6 className="col-6 text-center mt-2 mb-2">Brand</h6> */}
          <h6 className="col-6 text-center mt-2 mb-2">Item Type</h6>
          <h6 className="col-6  text-center mt-2 mb-2">Item</h6>
          <div className="col-6 tableHeight">
            <div className="row row1_style input_border ">
              <div className="col-12">
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    // placeholder="Search By Brand"
                    placeholder="Search By Item Type"
                    onChange={onTextChange2}
                    className="input_border"
                  ></input>
                </span>
                <span className="ml-2">
                  <button
                    id="callapibutn"
                    className="tabletopbutton"
                    onClick={handlePrint1}
                  >
                    Print
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <button
                    onClick={() => {
                      document.getElementById(
                        "modalAddnewAccount2"
                      ).style.display = "block";
                      settabchosen("brand");
                    }}
                    className="tabletopbutton1 text-right"
                  >
                    New
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <div className="loaderclass">
                    {" "}
                    {loader2 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="row ">
              <table class="table tableCash table-hover">
                <thead class="" style={{ borderLeft: "1px solid #cccccc" }}>
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      {/* Brand Name */}
                      Item Type
                    </th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "10pt" }}>
                  {brandlist.map((x, idx) => (
                    <tr>
                      <td scope="col" className="th1">
                        {idx + 1}
                      </td>
                      <td scope="col" className="th1">
                        {x.BrandName}
                      </td>
                      <th scope="col">
                        <span
                          onClick={() => {
                            setBrandIdDel(x.BrandId);
                            // handleDeleteYarn();
                          }}
                        >
                          <img
                            alt="Delete"
                            style={{
                              cursor: "pointer",
                              width: "19px",
                              marginTop: "8px",
                            }}
                            src={Delete}
                          ></img>
                        </span>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6 tableHeight">
            <div className="row row1_style input_border ">
              <div className="col-12">
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Item"
                    onChange={onTextChange1}
                    className="input_border"
                  ></input>
                </span>
                <span className="ml-2">
                  <button
                    id="callapibutn"
                    className="tabletopbutton"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <button
                    onClick={() => {
                      document.getElementById(
                        "modalAddnewAccount2"
                      ).style.display = "block";
                      settabchosen("yarn");
                    }}
                    className="tabletopbutton1 text-right"
                  >
                    New
                  </button>
                </span>
                <span className="ml-2 float-right">
                  <div className="loaderclass">
                    {" "}
                    {loader3 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="row ">
              <table class="table tableCash table-hover">
                <thead class="" style={{ borderRight: "1px solid #cccccc" }}>
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Item Type
                    </th>{" "}
                    <th scope="col" className="th1">
                      Name
                    </th>
                    <th scope="col" className="th1">
                      Description
                    </th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "10pt" }}>
                  {yarnlist?.map((x, idx) => (
                    <tr>
                      <td scope="col" className="th1">
                        {idx + 1}
                      </td>
                      <td scope="col" className="th1">
                        {x?.Brand?.BrandName}
                      </td>
                      <td scope="col" className="th1">
                        {x?.Name}
                      </td>
                      <td scope="col" className="th1">
                        {x.Description}
                      </td>
                      <th scope="col">
                        <span
                          onClick={() => {
                            setYarnIdDel(x.ProductId);
                            // handleDeleteYarn();
                          }}
                        >
                          <img
                            alt="Delete"
                            style={{
                              cursor: "pointer",
                              width: "19px",
                              marginTop: "8px",
                            }}
                            src={Delete}
                          ></img>
                        </span>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YarnMain;
