import React, { useState, useEffect } from "react";
import logologin from "../Images/seasonFour.jpg";
import "../Bootstrap/haroon.css";
import ActivityIndicator from "react-activity-indicator";
import axios from "axios";
import { Button, Modal, Badge } from "react-bootstrap";
import { GoEye } from "react-icons/go";
import Loader from "react-loader-spinner";
import moment from "moment";
//import {Link} from "react-router-dom";

//import {Redirect} from "react-router-dom";

import { useHistory } from "react-router-dom";
import ActionToday from "material-ui/svg-icons/action/today";
import { LocalDining } from "@material-ui/icons";

const Login = () => {
  const [passwordvisible, setpasswordvisible] = React.useState(false);
  const [loading, setloading] = useState(false);
  let history = useHistory();
  if (localStorage.getItem("Token") !== null) {
    history.push({
      pathname: "/Dashboard",
    });
  }

  let date = moment();
  let today = date.format();

  const signinfunction = () => {
    let data = {
      UserName: document.getElementById("username").value,
      Password: document.getElementById("password").value,
    };

    setloading(true);
    axios({
      url: `${window.$url}api/Auth/Login`,
      data: JSON.stringify(data),
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setloading(false);
        if (result.data.isSuccess) {
          localStorage.setItem("Token", result.data.token);
          history.push("/Dashboard");
        } else {
          alert(result.data.message);
        }
      },
      (error) => {
        console.log(error);
        setloading(false);
      }
    );
  };

  return (
    <div>
      <div className="row m-0 p-0">
        <div className="col-5"></div>

        <div className="col-5"></div>

        <div className="col-5"></div>
        <div className="col-2 loginheading"></div>
        <div className="col-5"></div>

        <div className="loginbox">
          <div className="col-12 text-center loginimage">
            <img src={logologin} alt="logo" className="loginlogo" />
          </div>
          <div className="col-12 logintext">Username</div>
          <div className="col-12 mt-1">
            <input type="text" className="logininput" id="username" />
          </div>

          <div className="col-12 mt-5 pt-1 logintext">Password</div>
          <div className="col-12 mt-1">
            {""}
            <input
              type={passwordvisible ? "text" : "password"}
              className="logininput"
              id="password"
            />
            <GoEye
              onClick={() => {
                setpasswordvisible(!passwordvisible);
              }}
              size={"1.3em"}
              id="togglePassword"
              style={{ marginLeft: "-30px" }}
            />
          </div>
          {/* <div className="col-12 text-right" style={{marginTop:"40px"}}>
            <span className="password">
              forgot password?
            </span>
          </div> */}
          {/* <div id="activityind" className="activityindicator">
            <ActivityIndicator
              number={4}
              diameter={40}
              borderWidth={1}
              duration={300}
              activeColor="#212529"
              borderColor="white"
              borderWidth={5}
              borderRadius="0%"
            />
          </div> */}
          <button
            // onClick={()=>{
            //   history.push("/Dashboard")
            // }}
            onClick={signinfunction}
            className="col-12 loginbutton mt-5 p-2 signin"
          >
            {" "}
            {loading ? (
              <Loader type="Oval" color="white" height={15} width={15} />
            ) : (
              "Sign in"
            )}
          </button>
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-4"></div>
        <div className="col-4">
          <ul className="loginlist">
            <li className="loginlistitem">Terms</li>
            <li className="loginlistitem">Privacy</li>
            <li className="loginlistitem">Security</li>
            <li className="loginlistitem">Contact Admin</li>
          </ul>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};

export default Login;
