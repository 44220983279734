import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";

export class Clientsaleprint extends Component {
    render() {
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div
            className="row m-3 p-0"
            // style={{ border: "5px solid darkgrey", height: "80%" }}
          >
            <div className="col-12 text-center mt-2">
              <h3>
                <b>
                  <u>Clients Sale Summary</u>
                </b>
              </h3>
  
              <hr style={{ backgroundColor: "black" }} />
              <div className="mt-4">
                <div class="table-responsive col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="th1">
                          Sr.
                        </th>
                        <th scope="col" className="th1">
                          PartyName
                        </th>
  
                        {/* <th scope="col" className="th1">
                          Discount{" "}
                        </th>
                        <th scope="col" className="th1">
                          Others{" "}
                        </th>
                        <th scope="col" className="th1">
                          Freight{" "}
                        </th>*/}
                        <th scope="col" className="th1">
                          Total{" "}
                        </th>
                        <th scope="col" className="th1">
                          Paid{" "}
                        </th>
  
                        {/* <th scope="col" className="th1">
                          Net{" "}
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.contractdata !== undefined
                        ? this.props.contractdata.map((x, idx) => (
                            <tr>
                              <td>{1 + idx}</td>
                              <td>{x.PartyName}</td>
  
                              {/* <td>{x.Discount.toLocaleString()}</td>
                              <td>{x.Others.toLocaleString()}</td>
                              <td>{x.Freight.toLocaleString()}</td> */}
                              {/* <td>{x.TotalAmount?.toLocaleString()}</td> */}
  
                              <td>{x.NetTotalAmount?.toLocaleString()}</td>
                              <td>{x.PaidAmount?.toLocaleString()}</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-4 text-center">________________</div>
              <div className="col-4 text-center">________________</div>
              <div className="col-4 text-center">________________</div>
  
              <div className="col-4 text-center">Prepared By</div>
              <div className="col-4 text-center">Checked By</div>
              <div className="col-4 text-center">Approved By</div>
            </div>
          </div>
        </div>
      );
    }
  }
  
  