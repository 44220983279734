import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";

export class PurchaseInvoiceIndividual extends Component {
  render() {
    console.log(this.props.printdata);
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4>
              <b>
                <u>Purchase Invoice</u>
              </b>
            </h4>
            <h5>
              <b>Seller Name: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.Account?.Accounttitle
                : null}
            </h5>
            <h5>
              <b>Invoice No.: </b>
              PInv-
              {this.props.printdata !== undefined
                ? this.props.printdata.PurchaseInvSerialNo
                : null}
            </h5>


            <div className="row">
              <div className="col-2">
                <h5 className="text-left">
                  {
                    this.props.printdata !== undefined &&
                    this.props.printdata.PurchaseContract !== null &&
                  <b>Contract No.: </b>

                  }
                </h5>
              </div>
              <div className="col-6">
                <h5 className="text-left">
                {this.props.printdata !== undefined &&
              this.props.printdata.PurchaseContract !== null
                ? `PCont-${this.props.printdata.PurchaseContract?.PurchaseContSerialNumber}`
                : null}
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-2">
                <h5 className="text-left">
                  <b>Entry Date: </b>
                </h5>
              </div>
              <div className="col-6">
                <h5 className="text-left">
                  {this.props.printdata !== undefined
                    ? this.props.printdata.Date.split("T")[0]
                    : null}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <h5 className="text-left">
                  {this.props.printdata?.PurchaseContract !== null &&
                  this.props.printdata?.PurchaseContract?.CommisionAgent?.CommisionAgentName !==
                    null && <b>Com Agent/ Amount: </b>}
                </h5>
              </div>
              <div className="col-6">
                <h5 className="text-left">
                  {this.props.printdata?.PurchaseContract !== null &&
                  this.props.printdata?.PurchaseContract?.CommisionAgent?.CommisionAgentName !==
                    null && (
                    <>
                      {this.props.printdata?.PurchaseContract?.CommisionAgent?.CommisionAgentName}
                      /{this.props.printdata?.CommisionAmount}
                    </>
                  )}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <h5 className="text-left">
                <b>Narration: </b>
                  
                </h5>
              </div>
              <div className="col-6">
                <h5 className="text-left">
                {this.props.printdata !== undefined
                    ? this.props.printdata.Narration
                    : null}
                </h5>
              </div>
            </div>
           

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.
                      </th>
                      <th scope="col" className="th1">
                        Yarn Type
                      </th>
                      <th scope="col" className="th1">
                        Brand
                      </th>
                      <th scope="col" className="th1">
                        No. Of Bags
                      </th>
                      <th scope="col" className="th1">
                        Rate
                      </th>
                      <th scope="col" className="th1">
                        Amount
                      </th>
                      <th scope="col" className="th1">
                        S.Tax
                      </th>
                      <th scope="col" className="th1">
                        Net Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.printdata !== undefined
                      ? this.props.printdata.PurchaseInvoiceDetails.map(
                          (x, idx) => (
                            <tr>
                              <th scope="col" className="th1">
                                {idx + 1}
                              </th>
                              <th scope="col" className="th1">
                              {x.Product.ProductType} {x.Product.Composition ? ` - (${x.Product.Composition})` : ""}
                              </th>
                              <th scope="col" className="th1">
                                {x.Brand.BrandName}
                              </th>
                              <th scope="col" className="th1">
                                {x.ProductQty}
                              </th>
                              <th scope="col" className="th1">
                                {x.Rate}
                              </th>
                              <th scope="col" className="th1">
                                {x.Amount}
                              </th>
                              <th scope="col" className="th1">
                                {x.TaxAmount}
                              </th>
                              <th scope="col" className="th1">
                                {x.NetAmount}
                              </th>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="row">
                {/* <div className="col-8 text-right">
                    <b>Total Amount:</b>
                  </div>
                  <div className="col-4 ">
                    {this.props.printdata !== undefined
                      ? this.props.printdata.TotalAmount
                      : null}
                  </div>
                  <div className="col-8 text-right">
                    <b>Discount:</b>
                  </div>
                  <div className="col-4 ">
                    {this.props.printdata !== undefined
                      ? this.props.printdata.Discount
                      : null}
                  </div>
                  <div className="col-8 text-right">
                    <b>Freight:</b>
                  </div>
                  <div className="col-4 ">
                    {this.props.printdata !== undefined
                      ? this.props.printdata.Freight
                      : null}
                  </div>
                  <div className="col-8 text-right">
                    <b>Others:</b>
                  </div>
                  <div className="col-4 ">
                    {this.props.printdata !== undefined
                      ? this.props.printdata.Others
                      : null}
                  </div> */}
                <div className="col-8 text-right">
                  <b>Net Total Amount:</b>
                </div>
                <div className="col-4 ">
                  {this.props.printdata !== undefined
                    ? this.props.printdata.NetTotalAmount
                    : null}
                </div>
                {/* <div className="col-8 text-right">
                    <b>Due Date:</b>
                  </div>
                  <div className="col-4 ">
                    {this.props.printdata !== undefined
                      ? this.props.printdata.DueDate
                      : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 text-right">
                    <b>Receiver:</b>
                  </div> */}
                <div className="col-4 text-right"></div>
              </div>
              <div className="row mt-4">
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>

                <div className="col-4 text-center">Prepared By</div>
                <div className="col-4 text-center">Checked By</div>
                <div className="col-4 text-center">Approved By</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class PurchaseInvoicelist extends Component {
  render() {
    console.log(this.props.contractdata);
    console.log(this.props.printname);
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>
                  Purchase{" "}
                  {this.props.printname !== undefined
                    ? this.props.printname
                    : null}
                </u>
              </b>
            </h3>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.
                      </th>
                      <th scope="col" className="th1">
                        Date
                      </th>
                      <th scope="col" className="th1">
                        Inv No.
                      </th>
                      <th scope="col" className="th1">
                        Contract No.
                      </th>
                      <th scope="col" className="th1">
                        Vendor
                      </th>
                      <th scope="col" className="th1">
                        Narration
                      </th>
                      {/* <th scope="col" className="th1">
                              Discount{" "}
                            </th>
                            <th scope="col" className="th1">
                              Others{" "}
                            </th>
                            <th scope="col" className="th1">
                              Freight{" "}
                            </th> */}
                      <th scope="col" className="th1">
                        Net Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.contractdata !== undefined
                      ? this.props.contractdata.map((x, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>
                              {x.Date !== undefined
                                ? x.Date.split("T")[0]
                                : null}
                            </td>
                            <td>
                              {x.PurchaseInvSerialNo !== null
                                ? `PInv - ${x.PurchaseInvSerialNo}`
                                : ""}
                            </td>
                            <td>
                              {x.PurchaseContract !== null
                                ? `PCont - ${x.PurchaseContract?.PurchaseContSerialNumber}`
                                : ""}
                            </td>
                            <td>{x.Account?.Accounttitle}</td>
                            <td>{x.Narration}</td>

                            {/* <td>{x.Discount}</td>
                                  <td>{x.Others}</td>
                                  <td>{x.Freight}</td> */}
                            <td>{x.NetTotalAmount.toLocaleString()}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>

            <div className="col-4 text-center">Prepared By</div>
            <div className="col-4 text-center">Checked By</div>
            <div className="col-4 text-center">Approved By</div>
          </div>
        </div>
      </div>
    );
  }
}
