import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

export const AccountPayablePDC = (props) => {
  const [buyersdata, setbuyersdata] = useState([]);
  const [newbuyersdata, setnewbuyersdata] = useState([]);
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [paiddata, setpaiddata] = useState(null);
  const [canceldata, setcanceldata] = useState(null);

  const getPayables = () => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + "api/PaymentVoucher/PDCPayables",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setbuyersdata(result.data.Data);
        setnewbuyersdata(result.data.Data);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  const paidbutton = () => {
    let data = {
      VoucherNumber: paiddata.VoucherNumber,
    };
    setpaiddata(null);
    setloader1(true);
    axios({
      method: "post",
      data: JSON.stringify(data),
      url: window.$url + "api/PaymentVoucher/AddPDCPayables",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Message);

        setloader1(false);
        getPayables();
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  const cancelbutton = () => {
    let data = {
      loginModel: {
        UserName: document.getElementById("username").value,
        Password: document.getElementById("password").value,
      },
      ChequeId: canceldata.PostDatedCheques[0].PostDatedChequeId,
    };

    setloader1(true);
    axios({
      method: "post",
      data: JSON.stringify(data),
      url: window.$url + "api/PaymentVoucher/CancelCheque",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          setcanceldata(null);
          getPayables();
        }

        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    getPayables();
  }, []);

  //Table Data fiter
  var suggestionlist = [];
  var suggestionlist1 = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      let suggestions1 = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.clientName));
        const regex1 = new RegExp(`${value}`, "i");
        suggestions1 = suggestionbuyersdata
          .sort()
          .filter((v) => regex1.test(v.clientCnic));
      }
      suggestionlist = suggestions;
      suggestionlist1 = suggestions1;
      console.log(suggestionlist);

      if (suggestionlist.length === 0 && suggestionlist1.length !== 0) {
        setbuyersdata(suggestionlist1);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newbuyersdata
          .sort()
          .filter((v) => regex.test(v.accounttitle));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setbuyersdata([]);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  useEffect(() => {
    if (paiddata !== null) {
      document.getElementById("modalAddnewAccount3").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount3").style.display = "none";
    }
  }, [paiddata]);

  useEffect(() => {
    if (canceldata !== null) {
      document.getElementById("modalAddnewAccount4").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount4").style.display = "none";
    }
  }, [canceldata]);

  return (
    <>
      <div hidden></div>
      <div id="modalAddnewAccount3" class="modalscreen ">
        <div class="modal-content-screen1">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setpaiddata(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            <div className="col-6 text-center">
              <button
                id="callapibutn"
                className="tabletopbutton pb-1 greenbutton"
                onClick={paidbutton}
              >
                {"YES"}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                id="callapibutn"
                className="tabletopbutton pb-1 ml-1 redbutton"
                onClick={() => setpaiddata(null)}
              >
                {"CANCEL"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="modalAddnewAccount4" class="modalscreen ">
        <div class="modal-content-screen">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setcanceldata(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            <div className="col-4 text-right mt-1">User Name:</div>
            <div className="col-8 text-center mt-1">
              <input type="text" className="logininput" id="username" />
            </div>

            <div className="col-4 text-right mt-2">Password:</div>
            <div className="col-8 text-center mt-2">
              <input
                type="password"
                className="logininput"
                id="password"
                onChange={(e) => {
                  if (e.target.value.length < 5) {
                    document.getElementById("entercredentials").disabled = true;
                  } else {
                    document.getElementById(
                      "entercredentials"
                    ).disabled = false;
                  }
                }}
              />
            </div>

            <div className="col-12 text-center mt-2">
              <button
                className="tabletopbutton pb-1 greenbutton"
                id="entercredentials"
                onClick={cancelbutton}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableContainer2 pageheading Sitka mt-2">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Account"
                  className="input_border"
                ></input>
              </span>

              <span className="ml-2">
                <button id="callapibutn" className="tabletopbutton">
                  Print
                </button>
              </span>

              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row">
            <table class="table table-hover tableCash ">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Voucher Number
                  </th>
                  <th scope="col" className="th1">
                    Account
                  </th>
                  <th scope="col" className="th1">
                    Bank
                  </th>
                  <th scope="col" className="th1">
                    Cheque
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                  <th scope="col" className="th1">
                    Encash
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {buyersdata.map((x, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{x.Dateadded.split("T")[0]}</td>
                    <td>{x.VoucherNumber}</td>
                    <td>{x.Account.Accounttitle}</td>
                    <td>{x.Acc.Accounttitle}</td>
                    <td>{x.ChequeNumber}</td>
                    <td>{x.Narration}</td>
                    <td>{x.Credit.toLocaleString()}</td>
                    <td>{x?.PostDatedCheques[0]?.EncashDate?.split("T")[0]}</td>
                    <td>
                      {x?.PostDatedCheques[0]?.Status === "Pending" ? (
                        <button
                          id={`${idx}statusbutton`}
                          onClick={() => setpaiddata(x)}
                          className="tabletopbutton ml-1"
                        >
                          Paid
                        </button>
                      ) : x?.PostDatedCheques[0]?.Status === "Canceled" ? (
                        "Cancelled"
                      ) : (
                        "Paid"
                      )}
                    </td>
                    <td>
                      {x?.PostDatedCheques[0]?.Status === "Pending" ? (
                        <button
                          id={`${idx}cancelbutton`}
                          className="tabletopbutton redbutton ml-1"
                          onClick={() => setcanceldata(x)}
                        >
                          Cancel
                        </button>
                      ) : x?.PostDatedCheques[0]?.Status === "Canceled" ? (
                        ""
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const ChequeSummary = (props) => {
  const [buyersdata, setbuyersdata] = useState([]);
  const [newbuyersdata, setnewbuyersdata] = useState([]);
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [paiddata, setpaiddata] = useState(null);
  const [canceldata, setcanceldata] = useState(null);

  const getPayables = () => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + "api/Reports/ChequeList",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setbuyersdata(result.data.ChequeList);
        setnewbuyersdata(result.data.ChequeList);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    getPayables();
  }, []);

  //Table Data fiter
  var suggestionlist = [];
  var suggestionlist1 = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      let suggestions1 = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.clientName));
        const regex1 = new RegExp(`${value}`, "i");
        suggestions1 = suggestionbuyersdata
          .sort()
          .filter((v) => regex1.test(v.clientCnic));
      }
      suggestionlist = suggestions;
      suggestionlist1 = suggestions1;
      console.log(suggestionlist);

      if (suggestionlist.length === 0 && suggestionlist1.length !== 0) {
        setbuyersdata(suggestionlist1);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newbuyersdata
          .sort()
          .filter((v) => regex.test(v.accounttitle));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setbuyersdata([]);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  return (
    <>
      <div hidden></div>

      <div className="tableContainer2 pageheading Sitka mt-2">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Account"
                  className="input_border"
                ></input>
              </span>

              <span className="ml-2">
                <button id="callapibutn" className="tabletopbutton">
                  Print
                </button>
              </span>

              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row">
            <table class="table table-hover tableCash ">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Voucher Number
                  </th>
                  <th scope="col" className="th1">
                    Account
                  </th>
                  <th scope="col" className="th1">
                    Bank
                  </th>
                  <th scope="col" className="th1">
                    Cheque
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Credit
                  </th>
                  <th scope="col" className="th1">
                    Debit
                  </th>
                  <th scope="col" className="th1">
                    Encash
                  </th>
                </tr>
              </thead>
              <tbody>
                {buyersdata !== undefined
                  ? buyersdata.map((x, idx) => (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{x.Dateadded.split("T")[0]}</td>
                        <td>{x.VoucherNumber}</td>
                        <td>{x.Account.Accounttitle}</td>
                        <td>{x.Acc.Accounttitle}</td>
                        <td>{x.ChequeNumber}</td>
                        <td>{x.Narration}</td>
                        <td>{x.Credit}</td>
                        <td>{x.Debit}</td>
                        <td>
                          {x.EncashDate.length !== 0
                            ? x.EncashDate[0].EncashDate.split("T")[0]
                            : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const AccountReceivablePDC = (props) => {
  const [buyersdata, setbuyersdata] = useState([]);
  const [newbuyersdata, setnewbuyersdata] = useState([]);
  const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [multiple, setmultiple] = useState([]);
  const [clientName, setclientName] = useState("");
  const [paiddata, setpaiddata] = useState(null);
  const [canceldata, setcanceldata] = useState(null);

  //Table Data fiter
  var suggestionlist = [];
  var suggestionlist1 = [];
  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      let suggestions1 = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionbuyersdata
          .sort()
          .filter((v) => regex.test(v.clientName));
        const regex1 = new RegExp(`${value}`, "i");
        suggestions1 = suggestionbuyersdata
          .sort()
          .filter((v) => regex1.test(v.clientCnic));
      }
      suggestionlist = suggestions;
      suggestionlist1 = suggestions1;
      console.log(suggestionlist);

      if (suggestionlist.length === 0 && suggestionlist1.length !== 0) {
        setbuyersdata(suggestionlist1);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setbuyersdata(newbuyersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newbuyersdata
          .sort()
          .filter((v) => regex.test(v.accounttitle));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setbuyersdata([]);
      } else {
        setbuyersdata(suggestionlist);
      }
    }
  };

  const [sumstate, setsumstate] = useState(0);
  const openmodaljournaltable = (x) => {
    let sum = 0;
    x.contract[0].forEach((y) => {
      sum = sum + y.totalPrice;
    });

    x.jointContracts.forEach((z) => {
      sum = sum + z.totalPrice;
    });
    setsumstate(sum);
    console.log("xvalue", x);
    setmultiple(x);
    setclientName(x.clientName);
    document.getElementById("modalAddnewJournal").style.display = "block";
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getPayables = () => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + "api/ReceiptVoucher/PDCReceivable",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setbuyersdata(result.data.Data);
        setnewbuyersdata(result.data.Data);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  const paidbutton = () => {
    let data = {
      VoucherNumber: paiddata.VoucherNumber,
    };
    setpaiddata(null);
    setloader1(true);
    axios({
      method: "post",
      data: JSON.stringify(data),
      url: window.$url + "api/ReceiptVoucher/AddPDCReceivable",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Message);

        setloader1(false);
        getPayables();
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  const cancelbutton = () => {
    let data = {
      loginModel: {
        UserName: document.getElementById("username").value,
        Password: document.getElementById("password").value,
      },
      ChequeId: canceldata.PostDatedCheques[0].PostDatedChequeId,
    };

    setloader1(true);
    axios({
      method: "post",
      data: JSON.stringify(data),
      url: window.$url + "api/PaymentVoucher/CancelCheque",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          setcanceldata(null);
          getPayables();
        }

        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    if (paiddata !== null) {
      document.getElementById("modalAddnewAccount3").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount3").style.display = "none";
    }
  }, [paiddata]);

  useEffect(() => {
    if (canceldata !== null) {
      document.getElementById("modalAddnewAccount4").style.display = "block";
    } else {
      document.getElementById("modalAddnewAccount4").style.display = "none";
    }
  }, [canceldata]);

  useEffect(() => {
    getPayables();
  }, []);

  return (
    <>
      <div hidden></div>
      <div id="modalAddnewAccount3" class="modalscreen ">
        <div class="modal-content-screen1">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setpaiddata(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            <div className="col-6 text-center">
              <button
                id="callapibutn"
                className="tabletopbutton pb-1 greenbutton"
                onClick={paidbutton}
              >
                {"YES"}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                id="callapibutn"
                className="tabletopbutton pb-1 ml-1 redbutton"
                onClick={() => setpaiddata(null)}
              >
                {"CANCEL"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="modalAddnewAccount4" class="modalscreen ">
        <div class="modal-content-screen">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                setcanceldata(null);
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Are You Sure?</h4>
            </div>

            <div className="col-4 text-right mt-1">User Name:</div>
            <div className="col-8 text-center mt-1">
              <input type="text" className="logininput" id="username" />
            </div>

            <div className="col-4 text-right mt-2">Password:</div>
            <div className="col-8 text-center mt-2">
              <input
                type="password"
                className="logininput"
                id="password"
                onChange={(e) => {
                  if (e.target.value.length < 5) {
                    document.getElementById("entercredentials").disabled = true;
                  } else {
                    document.getElementById(
                      "entercredentials"
                    ).disabled = false;
                  }
                }}
              />
            </div>

            <div className="col-12 text-center mt-2">
              <button
                className="tabletopbutton pb-1 greenbutton"
                id="entercredentials"
                disabled
                onClick={cancelbutton}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="tableContainer2 pageheading Sitka mt-2">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search By Account"
                  onChange={onTextChange2}
                  className="input_border"
                ></input>
              </span>

              <span className="ml-2">
                <button
                  id="callapibutn"
                  className="tabletopbutton"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </span>

              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row">
            <table class="table table-hover tableCash ">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.No
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Voucher Number
                  </th>
                  <th scope="col" className="th1">
                    Account
                  </th>
                  <th scope="col" className="th1">
                    Bank
                  </th>
                  <th scope="col" className="th1">
                    Cheque
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Amount
                  </th>
                  <th scope="col" className="th1">
                    Encash
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {buyersdata.map((x, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{x.Dateadded.split("T")[0]}</td>
                    <td>{x.VoucherNumber}</td>
                    <td>{x.Account.Accounttitle}</td>
                    <td>{x.Acc.Accounttitle}</td>
                    <td>{x.ChequeNumber}</td>
                    <td>{x.Narration}</td>
                    <td>{x.Debit.toLocaleString()}</td>
                    <td>{x.PostDatedCheques[0]?.EncashDate?.split("T")[0]}</td>
                    <td>
                      {x.PostDatedCheques[0]?.Status === "Pending" ? (
                        <button
                          id={`${idx}statusbutton`}
                          onClick={() => setpaiddata(x)}
                          className="tabletopbutton ml-1"
                        >
                          Received
                        </button>
                      ) : x.PostDatedCheques[0]?.Status === "Canceled" ? (
                        "Cancelled"
                      ) : (
                        "Received"
                      )}
                    </td>
                    <td>
                      {x.PostDatedCheques[0]?.Status === "Pending" ? (
                        <button
                          id={`${idx}cancelbutton`}
                          className="tabletopbutton redbutton ml-1"
                          onClick={() => setcanceldata(x)}
                        >
                          Cancel
                        </button>
                      ) : x.PostDatedCheques[0]?.Status === "Canceled" ? (
                        ""
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};


