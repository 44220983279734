import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import Delete from "../../../Images/delete.png";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import BankReceiptPrint from "../../Prints/BankRecieptPrint";
import { getallpopulateaccounts } from "../../actions/getpopulateaccounts";
import { getallbankaccounts } from "../../actions/getbankaccounts";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
var converter = require("number-to-words");

export const BankReceiptForm = (props) => {
  const [banklist, setbanklist] = useState([]);
  const [Receiptlist, setReceiptlist] = useState([]);
  const [accountid, setaccountid] = useState(null);
  const [printdata, setprintdata] = useState();
  const [loader1, setloader1] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [displayinvoice, setdisplayinvoice] = useState(false);
  const [initialdata, setinitialdata] = useState(null);
  const [saleinvoices, setsaleinvoices] = useState([]);
  const [invoiceamount, setinvoiceamount] = useState(null);
  const [chequedetails, setchequedetails] = useState(null);
  const [onlinecheque, setonlinecheque] = useState(true);
  const [showencash, setshowencash] = useState(false);
  const [conversionamount, setconversionamount] = useState(0);
  const [list, setlist] = useState([]);
  const [AccountTitle, setAccountTitle] = useState("");
  const [invoiceidlist, setinvoiceidlist] = useState([]);
  const [invoiceamountlist, setinvoiceamountlist] = useState([]);
  const [selectedInvoice, setselectedInvoice] = useState([]);
  const [SaleInvoiceListID, setSaleInvoiceListID] = useState([]);

  var today = new Date();
  var todaydate = today.getDate();
  if (todaydate.toString().length === 1) {
    todaydate = "0" + todaydate.toString();
  }
  var currentmonth = today.getMonth();
  if (currentmonth.toString().length === 1) {
    currentmonth = "0" + (today.getMonth() + 1).toString();
  }
  var date = today.getFullYear() + "-" + currentmonth + "-" + todaydate;

  const OnAccountChange = (e) => {
    let value = e.target.value;
    console.log(e.target.value);
    var options = document.getElementById("accountList").childNodes;
    let find = false;
    for (var i = 0; i < options.length; i++) {
      if (options[i]?.value === value) {
        find = true;
        let item = Receiptlist?.find(
          (item, idx) =>
            // console.log(item)
            item?.Accid === parseInt(options[i].attributes.attributeId.value)
        );

        console.log(item);
        console.log(options);

        setAccountTitle(item.Accounttitle);
        document.getElementById("cashReceiptAccount").value = item.Accounttitle
        setaccountid(item.Accid);
        if (displayinvoice) {
          setsaleinvoices(item.SaleInvoice);
        } else {
          setsaleinvoices([]);
        }
        break;
      }
    }
    if (!find) {
      setaccountid("");
      setAccountTitle("");
      document.getElementById("cashReceiptAccount").value = ""
      setsaleinvoices([]);
    }
  };

  // const saveandnew = () => {
  //   if (document.getElementById("bankReceiptDate").value === "") {
  //     toast.info("Date is Required");
  //   }
  //   else if (document.getElementById("bankPaymentlistbank").selectedIndex === 0) {
  //     toast.info("Select Bank");
  //   }
  //   else if (document.getElementById("bankPaymentAccount").value === "") {
  //     toast.info("Select an Account");
  //   }

  //   else if (document.getElementById("bankReceiptDebit").value === "") {
  //     toast.info("Enter an Amount ");
  //   }
  //   else if (document.getElementById("bankReceiptNarration").value === "") {
  //     toast.info("Enter a Narration");

  //   }
  //   else {
  //     setloader2(true);
  //     document.getElementById("crvSave").disabled = true;
  //     document.getElementById("crvCancel").disabled = true;
  //     var data1 = {
  //       EntryDate: moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       ClientAccId: accountid,
  //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
  //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
  //       ChequeNumber: document.getElementById("bankReceiptCheque").value,
  //       Paymenttype: "Bank",
  //       VoucherDate: document.getElementById("bankReceiptDate").value,
  //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')} / ${document.getElementById("bankReceiptCheque").value}`,
  //       VoucherType: "B-RV",
  //     };

  //     var data2 = {
  //       EntryDate: moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       ClientAccId: accountid,
  //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
  //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),

  //       Paymenttype: "Bank",
  //       VoucherDate: document.getElementById("bankReceiptDate").value,
  //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')}`,
  //       VoucherType: "B-RV",
  //       ChequeBookId:null,
  //     };

  //     if(document.getElementById("bankReceiptCheque").value===""){
  //       var data = data2
  //     }else{
  //       var data = data1
  //     }

  //     axios({
  //       method: "post",
  //       url:
  //         window.$url +
  //         `api/ReceiptVoucher/AddReceiptVoucher`,
  //       data: JSON.stringify(data),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
  //         "content-type": "application/json",
  //       },
  //     }).then(
  //       (result) => {
  //         console.log(result);
  //         dispatch(getallbankreceiptvouchers())
  //         toast.info(result.data.Message);
  //         document.getElementById("bankReceiptNarration").value = "";
  //         document.getElementById("bankReceiptCheque").value = "";
  //         document.getElementById("bankPaymentlistbank").selectedIndex = 0;
  //         document.getElementById("bankReceiptDebit").value = "";
  //         document.getElementById("bankPaymentAccount").value = "";
  //         setaccountid(null)
  //         setloader2(false);

  //     document.getElementById("crvSave").disabled = false;
  //     document.getElementById("crvCancel").disabled = false;
  //       },
  //       (error) => {
  //         toast.info(error);
  //         setloader2(false);

  //     document.getElementById("crvSave").disabled = false;
  //     document.getElementById("crvCancel").disabled = false;
  //       }
  //     );
  //   }
  // };
  const saveandclose = () => {
    let array = [];

    list.forEach((x) => {
      if (x.ChequeNumber === "") {
        array.push({
          EntryDate: x.EntryDate,
          InvoiceIds: x.InvoiceId !== null ? x.InvoiceId : null,
          ClientAccId: x.ClientAccId,
          AccId: x.AccId,
          Amount: x.Amount,

          Paymenttype: x.Paymenttype,
          VoucherDate: x.VoucherDate,
          Narration: x.Narration,
          VoucherType: x.VoucherType,
          BankCharges: x.BankCharges,
          ChequeBookId: x.null,
        });
      } else {
        array.push({
          EntryDate: x.EntryDate,
          InvoiceIds: x.InvoiceId !== null ? x.InvoiceId : null,
          ClientAccId: x.ClientAccId,
          AccId: x.AccId,
          Amount: x.Amount,
          ChequeNumber: x.ChequeNumber,
          Paymenttype: x.Paymenttype,
          VoucherDate: x.VoucherDate,
          Narration: x.Narration,
          BankCharges: x.BankCharges,
          VoucherType: x.VoucherType,
          ChequeType: x.ChequeType,
          EncashDate: x.EncashDate,
        });
      }
    });

    if (list.length === 0) {
      toast.info("Please fill in all the voucher details!");
    } else {
      setloader1(true);
      document.getElementById("crvSave").disabled = true;
      document.getElementById("crvCancel").disabled = true;

      axios({
        method: "post",
        url: window.$url + `api/ReceiptVoucher/AddReceiptVoucher`,
        data: JSON.stringify(array),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          if (result.data?.IsSuccess) {
            toast.info(result.data.Message);
            props.formCancel();
            setloader1(false);
          } else {
            setloader1(false);
          }
        },
        (error) => {
          toast.info(error);
          setloader1(false);

          document.getElementById("crvSave").disabled = false;
          document.getElementById("crvCancel").disabled = false;
        }
      );
    }
  };
  // const saveandprint = () => {
  //   if (document.getElementById("bankReceiptDate").value === "") {
  //     toast.info("Date is Required");
  //   }
  //   else if (document.getElementById("bankPaymentlistbank").selectedIndex === 0) {
  //     toast.info("Select Bank");
  //   }
  //   else if (document.getElementById("bankPaymentAccount").value === "") {
  //     toast.info("Select an Account");
  //   }

  //   else if (document.getElementById("bankReceiptDebit").value === "") {
  //     toast.info("Enter an Amount ");
  //   }
  //   else if (document.getElementById("bankReceiptNarration").value === "") {
  //     toast.info("Enter a Narration");

  //   }
  //   else {
  //     setloader2(true);
  //     document.getElementById("crvSave").disabled = true;
  //     document.getElementById("crvCancel").disabled = true;
  //     var data1 = {
  //       EntryDate: moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       ClientAccId: accountid,
  //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
  //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),
  //       ChequeNumber: document.getElementById("bankReceiptCheque").value,
  //       Paymenttype: "Bank",
  //       VoucherDate: document.getElementById("bankReceiptDate").value,
  //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')} / ${document.getElementById("bankReceiptCheque").value}`,
  //       VoucherType: "B-RV",
  //     };
  //     var data2 = {
  //       EntryDate: moment().format('YYYY-MM-DD'),
  //       InvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //       ClientAccId: accountid,
  //       AccId: document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].dataset.id,
  //       Amount: parseFloat(document.getElementById("bankReceiptDebit").value.replace(/,/g, '')),

  //       Paymenttype: "Bank",
  //       VoucherDate: document.getElementById("bankReceiptDate").value,
  //       Narration: `${document.getElementById("bankReceiptNarration").value} / ${moment().format('YYYY-MM-DD')}`,
  //       VoucherType: "B-RV",
  //       ChequeBookId:null,
  //     };

  //     if(document.getElementById("bankReceiptCheque").value===""){
  //       var data = data2
  //     }else{
  //       var data = data1
  //     }

  //     axios({
  //       method: "post",
  //       url:
  //         window.$url +
  //         `api/ReceiptVoucher/AddReceiptVoucher`,
  //       data: JSON.stringify(data),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("Token")}`,
  //         "content-type": "application/json",
  //       },
  //     }).then(
  //       (result) => {
  //         console.log(result);
  //         if(result.data.IsSuccess){
  //           setprintdata({
  //             VoucherNumber:result.data.Id,
  //             ChequeNumber:document.getElementById("bankReceiptCheque").value,
  //             Acc:{
  //               Accounttitle:document.getElementById("bankPaymentlistbank").options[document.getElementById("bankPaymentlistbank").selectedIndex].value
  //             },
  //             Voucherdate:document.getElementById("bankReceiptDate").value,
  //             Account:{
  //               Accounttitle:document.getElementById("bankPaymentAccount").value
  //             },
  //             SaleInvoiceId:document.getElementById("purchaseinvoicedropdown")!==null?document.getElementById("purchaseinvoicedropdown").options[document.getElementById("purchaseinvoicedropdown").selectedIndex].value:null,
  //             Accountid:accountid,
  //             Narration:document.getElementById("bankReceiptNarration").value,
  //             Debit:document.getElementById("bankReceiptDebit").value
  //           })

  //           dispatch(getallbankreceiptvouchers())
  //           document.getElementById("bankReceiptNarration").value = "";
  //         document.getElementById("bankReceiptCheque").value = "";
  //         document.getElementById("bankPaymentlistbank").selectedIndex = 0;
  //         document.getElementById("bankReceiptDebit").value = "";
  //         document.getElementById("bankPaymentAccount").value = "";
  //         setaccountid(null)
  //         }

  //         toast.info(result.data.Message);

  //         setloader2(false);

  //     document.getElementById("crvSave").disabled = false;
  //     document.getElementById("crvCancel").disabled = false;

  //       },
  //       (error) => {
  //         toast.info(error);
  //         setloader2(false);

  //     document.getElementById("crvSave").disabled = false;
  //     document.getElementById("crvCancel").disabled = false;
  //       }
  //     );
  //   }
  // };
  var i = 0;

  const paymentselleraccounts = () => {
    setloader1(true);
    axios({
      method: "get",
      url: window.$url + "api/ReceiptVoucher/PopulateAccounts",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log(result);
        setReceiptlist(result.data.ReceiptAccount);
        setinitialdata(result.data);
        setloader1(false);
      },
      (error) => {
        toast.info(error);
        setloader1(false);
      }
    );
  };

  useEffect(() => {
    paymentselleraccounts();
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printdatafunction = (val) => {
    setprintdata(val);
  };

  let dispatch = useDispatch();
  let bankaccounts = useSelector(
    (state) => state.GetAllBankAccountsDataReducer
  );
  let populateaccounts = useSelector(
    (state) => state.GetAllPopulateAccountsDataReducer
  );

  useEffect(() => {
    dispatch(getallpopulateaccounts());
    dispatch(getallbankaccounts());
  }, [dispatch]);

  useEffect(() => {
    if (printdata !== undefined) handlePrint();
  }, [printdata]);
  useEffect(() => {
    if (bankaccounts !== undefined) {
      setbanklist(bankaccounts.data);
    }
  }, [bankaccounts]);

  const discardcheque = () => {
    let data = {
      ChequeBookId: chequedetails.ChequeBookId,
      ChequeNumber: chequedetails.CurrentNumber,
      Narration: document.getElementById("discardreason").value,
      BankId: document.getElementById("bankPaymentlistbank").options[
        document.getElementById("bankPaymentlistbank").selectedIndex
      ].dataset.bankid,
    };

    if (
      chequedetails === null ||
      document.getElementById("discardreason").value === ""
    ) {
      toast.info("Please select a bank and add narration!");
    } else {
      setloader3(true);
      axios({
        url: window.$url + "api/Accounts/DiscardChequeNumber",
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
        data: JSON.stringify(data),
      }).then(
        (result) => {
          setloader3(false);
          toast.info(result.data.Message);
          if (result.data.IsSuccess) {
            setbanklist(result.data.Bank);
            setonlinecheque(true);
            document.getElementById("bankPaymentlistbank").selectedIndex = 0;
            document.getElementById("bankReceiptCheque").value = "";
            document.getElementById("discardreason").value = "";
            document.getElementById("modalAddnewAccount2").style.display =
              "none";
          }
          console.log(result);
        },
        (error) => {
          setloader3(false);
          console.log(error);
        }
      );
    }
  };

  const addtolistbutton = () => {
    var data = {
      BankAccountTitle: document.getElementById("bankPaymentlistbank").options[
        document.getElementById("bankPaymentlistbank").selectedIndex
      ].value,
      AccountTitle: AccountTitle,

      EntryDate: moment().format("YYYY-MM-DD"),
      InvoiceId: SaleInvoiceListID !== null ? SaleInvoiceListID : null,
      InvoiceSerialNo:
        document.getElementById("purchaseinvoicedropdown") !== null
          ? document.getElementById("purchaseinvoicedropdown").value
          : null,
      ClientAccId: accountid,
      AccId: document.getElementById("bankPaymentlistbank").options[
        document.getElementById("bankPaymentlistbank").selectedIndex
      ].dataset.id,
      Amount: parseFloat(
        document.getElementById("bankReceiptDebit").value.replace(/,/g, "")
      ),
      ChequeNumber: document.getElementById("bankReceiptCheque").value,
      Paymenttype: "Bank",
      VoucherDate: document.getElementById("bankReceiptDate").value,
      Narration: document.getElementById("bankReceiptNarration").value,
      VoucherType: "B-RV",
      ChequeType:
        document.getElementById("paymenttype").options[
          document.getElementById("paymenttype").selectedIndex
        ].value,
      EncashDate:
        document.getElementById("encashdate") !== null
          ? document.getElementById("encashdate").value
          : null,
      BankCharges: document.getElementById("bankcharges").value
        ? document.getElementById("bankcharges").value
        : 0,
    };

    if (accountid === null) {
      toast.info("Please select an Account!");
    } else if (
      showencash &&
      document.getElementById("encashdate").value === ""
    ) {
      toast.info("Please select encash date!");
    } else if (document.getElementById("bankReceiptDebit").value === "") {
      toast.info("Please enter amount!");
    }
    // else if (document.getElementById("bankReceiptNarration").value === "") {
    //   toast.info("Please enter narration!")
    // }
    else if (
      document.getElementById("bankPaymentlistbank").selectedIndex === 0
    ) {
      toast.info("Please select bank!");
    } else {
      if (SaleInvoiceListID !== null) {
        if (SaleInvoiceListID.selectedIndex === 0) {
          toast.info("Please select an invoice!");
        } else {
          setlist([...list, data]);
          saleinvoices.forEach((x, idx) => {
            document.getElementById(`${idx}multipleinvoice`).checked = false;
          });
          setinvoiceidlist([]);
          setinvoiceamountlist([]);
          setaccountid(null);
          if (SaleInvoiceListID !== null) {
            setSaleInvoiceListID("");
          }
          setdisplayinvoice(false);
          setshowencash(false);
          setonlinecheque(true);
          document.getElementById("invoicecheckbox").checked = false;
          document.getElementById("bankReceiptCheque").value = "";
          document.getElementById("bankReceiptDebit").value = "";
          document.getElementById("bankcharges").value = "";
          setAccountTitle("");
          document.getElementById("cashReceiptAccount").value = ""
          document.getElementById("bankReceiptNarration").value = "";
          document.getElementById("bankPaymentlistbank").selectedIndex = 0;
          document.getElementById("paymenttype").selectedIndex = 0;
          setconversionamount(0);
          setinvoiceamount(null);
        }
      } else {
        setlist([...list, data]);
        saleinvoices.forEach((x, idx) => {
          document.getElementById(`${idx}multipleinvoice`).checked = false;
        });
        setinvoiceidlist([]);
        setinvoiceamountlist([]);
        setaccountid(null);
        if (SaleInvoiceListID !== null) {
          setSaleInvoiceListID("");
        }
        setdisplayinvoice(false);
        setshowencash(false);
        setonlinecheque(true);
        document.getElementById("invoicecheckbox").checked = false;
        document.getElementById("bankReceiptCheque").value = "";
        document.getElementById("bankReceiptDebit").value = "";
        setAccountTitle("");
        document.getElementById("cashReceiptAccount").value = ""
        document.getElementById("bankcharges").value = "";
        document.getElementById("bankReceiptNarration").value = "";
        document.getElementById("bankPaymentlistbank").selectedIndex = 0;
        document.getElementById("paymenttype").selectedIndex = 0;
        setconversionamount(0);
        setinvoiceamount(null);
      }
    }
  };

  const handledeleterow = (idx) => {
    const rows = [...list];
    rows.splice(idx, 1);
    setlist(rows);
  };

  useEffect(() => {
    if (invoiceamountlist.length !== 0) {
      let sum = 0;

      invoiceamountlist.forEach((x) => {
        sum += parseFloat(x);
      });

      setinvoiceamount(sum);
      setconversionamount(sum);
    } else {
      setinvoiceamount(null);
      setconversionamount(0);
    }
  }, [invoiceamountlist]);
  useEffect(() => {
    let abc = [];
    invoiceidlist.forEach((x) => {
      console.log(x);
      abc.push(`SInv-${x}`);
    });
    setselectedInvoice(abc);
    console.log(SaleInvoiceListID);
  }, [invoiceidlist]);

  useEffect(() => {
    if (AccountTitle === "") {
      setinvoiceidlist([]);
      setSaleInvoiceListID([]);
      setinvoiceamount(null);
      setconversionamount(0);
      // document.getElementBy("purchaseinvoicedropdown").value = ""
    }
    console.log(AccountTitle);
  }, [AccountTitle]);

  console.log(list);
  return (
    <>
      <div hidden>
        <BankReceiptPrint
          ref={componentRef}
          date={date}
          printdata={printdata}
        />
      </div>
      <div id="modalAddnewAccount2" class="modalscreen ">
        <div class="modal-content-screen1">
          <div className="col-12 text-right">
            <span
              class="close "
              onClick={() => {
                document.getElementById("modalAddnewAccount2").style.display =
                  "none";
              }}
            >
              &times;
            </span>
          </div>

          <div className="row mt-2">
            <div className="col-12 text-center">
              <h4>Discard Cheque</h4>
            </div>

            <div className="col-6 mt-3">
              Cheque Number:{" "}
              <b>
                {chequedetails !== null ? chequedetails.CurrentNumber : null}
              </b>
            </div>
            <div className="col-6 mt-3">
              Reason:{"  "}
              <input id="discardreason" className="input_border formInput" />
            </div>
            <div className="col-12 mt-3 text-right">
              <span>
                <button
                  id="crvSave"
                  onClick={discardcheque}
                  className="tabletopbutton  text-right greenbutton"
                >
                  {"Discard"}
                </button>
                <div className="loaderclass ml-1 float-right">
                  {" "}
                  {loader3 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="modalAddnewAccount" class="modalscreen ">
        <div class="modal-content-screen1">
          <span
            class="close "
            onClick={() => {
              document.getElementById("modalAddnewAccount").style.display =
                "none";
            }}
          >
            &times;
          </span>
          <div>
            <table class="table table-hover">
              <thead class="">
                <tr>
                  <th scope="col" className="th1">
                    Sr.
                  </th>
                  <th scope="col" className="th1">
                    Date
                  </th>
                  <th scope="col" className="th1">
                    Narration
                  </th>
                  <th scope="col" className="th1">
                    Invoice No.
                  </th>
                  <th scope="col" className="th1">
                    Net Amount
                  </th>
                  <th scope="col" className="th1">
                    Select
                  </th>
                </tr>
              </thead>
              <tbody>
                {saleinvoices !== undefined ? (
                  saleinvoices.map((x, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{x.Date.split("T")[0]}</td>
                      <td>{x.Narration}</td>
                      <td>SInv-{x.SaleInvSerialNo}</td>
                      <td>{x.NetTotalAmount?.toLocaleString()}</td>
                      <td>
                        <input
                          type="checkbox"
                          id={`${idx}multipleinvoice`}
                          onClick={(e) => {
                            if (e.target.checked === true) {
                              setinvoiceidlist([
                                ...invoiceidlist,
                                x.SaleInvSerialNo,
                              ]);
                              setSaleInvoiceListID([
                                ...SaleInvoiceListID,
                                x.SaleInvoiceId,
                              ]);

                              setinvoiceamountlist([
                                ...invoiceamountlist,
                                x.NetTotalAmount,
                              ]);
                            } else {
                              const rows = [...invoiceidlist];
                              rows.splice(idx, 1);
                              setinvoiceidlist(rows);

                              const rowsId = [...SaleInvoiceListID];
                              rowsId.splice(idx, 1);
                              setSaleInvoiceListID(rowsId);

                              const rows1 = [...invoiceamountlist];
                              rows1.splice(idx, 1);
                              setinvoiceamountlist(rows1);
                            }
                          }}
                        ></input>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className=" tableContainer2 overflow-auto pageheading Sitka mt-3">
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-3">
            <div className="col-2 text-right">
              <span className="txt">Payment Type</span>
            </div>
            <div className="col-4 text-left">
              <span>
                {" "}
                <select
                  type="text"
                  id="paymenttype"
                  className="input_border formInput "
                  onChange={(e) => {
                    if (e.target.selectedIndex === 0) {
                      setonlinecheque(true);
                      setshowencash(false);
                    } else if (e.target.selectedIndex === 1) {
                      setonlinecheque(false);
                      setshowencash(false);
                    } else {
                      setonlinecheque(false);
                      setshowencash(true);
                    }
                  }}
                >
                  <option>Online</option>
                  <option>Cheque</option>
                  <option>Post Dated Cheque</option>
                </select>
              </span>
            </div>

            {showencash ? (
              <>
                <div className="col-2 text-right">
                  <span className="txt">Encash Date</span>
                </div>
                <div className="col-4 text-left">
                  <span>
                    {" "}
                    <input
                      type="date"
                      id="encashdate"
                      className="input_border formInput"
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="col-2 text-right"></div>
                <div className="col-4 text-left"></div>
              </>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Bank List</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <select
                  type="text"
                  id="bankPaymentlistbank"
                  className="input_border formInput "
                  onChange={(e) => {
                    setchequedetails(
                      bankaccounts.data[
                        e.target.options[e.target.selectedIndex].dataset.idx
                      ].ChequeBook
                    );
                  }}
                >
                  <option>Select</option>
                  {banklist !== undefined
                    ? banklist.map((x, idx) => (
                        <option
                          data-id={x.Accid}
                          data-bankid={x.BankId}
                          data-idx={idx}
                        >{`${x.Accounttitle} (${x.AccountNumber})`}</option>
                      ))
                    : null}
                </select>
              </span>
            </div>
            <div className="col-2 mt-3  text-right">
              <span className="txt">Date</span>
            </div>
            <div className="col-4  mt-3 text-left">
              <span>
                {" "}
                <input
                  type="date"
                  defaultValue={date}
                  id="bankReceiptDate"
                  className="input_border formInput"
                ></input>
              </span>
            </div>
            {onlinecheque ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Cheque No.</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="number"
                      disabled
                      id="bankReceiptCheque"
                      className="input_border formInput "
                    ></input>
                  </span>
                  {"  "}
                </div>
              </>
            ) : (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Cheque No.</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="number"
                      id="bankReceiptCheque"
                      className="input_border formInput "
                    ></input>
                  </span>
                </div>
              </>
            )}
            <div className="col-2 mt-3 text-right">
              <span className="txt">Invoice</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                <input
                  type="checkbox"
                  id="invoicecheckbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setconversionamount(0);
                      setReceiptlist(initialdata?.ClientAccounts);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""
                      setinvoiceamount(null);
                      setaccountid(null);
                    } else {
                      setconversionamount(0);
                      setReceiptlist(initialdata?.ReceiptAccount);
                      setAccountTitle("");
                      document.getElementById("cashReceiptAccount").value = ""
                      setinvoiceamount(null);
                      setaccountid(null);
                    }
                  }}
                  onClick={() => {
                    if (
                      document.getElementById("invoicecheckbox").checked ===
                      true
                    ) {
                      setdisplayinvoice(true);
                    } else {
                      setdisplayinvoice(false);
                    }
                  }}
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt"> Account</span>
            </div>
            {/* <div className="col-4 mt-3 text-left">
                <span>
                  {" "}
                  <input
                    type="text"
                    id="bankPaymentAccount"
                    onChange={onTextChange}
                    autoComplete="off"
                    className="input_border formInput "
                  ></input>
                </span>
                <span id="demo"></span>
              </div> */}
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="cashReceiptAccount"
                  // value={AccountTitle}
                  list="accountList"
                  onChange={OnAccountChange}
                  className="input_border formInput "
                  autoComplete="off"
                ></input>
                <datalist id="accountList">
                  {Receiptlist?.map((v, idx) => {
                    // console.log(v);
                    return (
                      <option attributeId={v?.Accid} value={v?.Accounttitle}>
                        {v?.Accounttitle} {v?.AccountTitle ? `- ${v?.AccountTitle}` : ""}
                      </option>
                    );
                  })}
                </datalist>
              </span>

              <span id="demo"></span>
            </div>

            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Sale Invoice</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    {/* <select
                    id="purchaseinvoicedropdown"
                    className="input_border formInput "
                    onChange={(e)=>{
                      setinvoiceamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                      setconversionamount( parseFloat(e.target.options[e.target.selectedIndex].dataset.amount))
                    }}
                  >
  
                    <option>Select</option>
                    {
                      saleinvoices.map((x)=>(
                        <option data-amount={x.TotalAmount}>{x.SaleInvoiceId}</option>
                      ))
                    }
                  </select> */}
                    <input
                      id="purchaseinvoicedropdown"
                      className="input_border formInput "
                      readOnly
                      onClick={() => {
                        document.getElementById(
                          "modalAddnewAccount"
                        ).style.display = "block";
                      }}
                      value={
                        selectedInvoice.length !== 0
                          ? selectedInvoice.toString()
                          : ""
                      }
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}

            {displayinvoice === false ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      id="bankReceiptDebit"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === "NaN") {
                          e.target.value = "";
                        }
                        if (invoiceamount !== null) {
                          if (
                            parseFloat(
                              document
                                .getElementById("bankReceiptDebit")
                                .value.replace(/,/g, "")
                            ) > invoiceamount
                          ) {
                            document.getElementById("bankReceiptDebit").value =
                              invoiceamount;
                          }
                        }
                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("bankReceiptDebit")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="col-2 mt-3 text-right">
              <span className="txt">Narration</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <textarea
                  style={{ height: "40px" }}
                  type="text"
                  id="bankReceiptNarration"
                  className="input_border formInput"
                ></textarea>
              </span>
            </div>
            {displayinvoice ? (
              <>
                <div className="col-2 mt-3 text-right">
                  <span className="txt">Amount</span>
                </div>
                <div className="col-4 mt-3 text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      id="bankReceiptDebit"
                      key={invoiceamount}
                      defaultValue={invoiceamount}
                      className="input_border formInput "
                      onChange={(e) => {
                        if (e.target.value.length > 0)
                          e.target.value = parseInt(
                            e.target.value.replace(/,/g, "")
                          ).toLocaleString();
                        if (e.target.value === "NaN") {
                          e.target.value = "";
                        }
                        if (invoiceamount !== null) {
                          if (
                            parseFloat(
                              document
                                .getElementById("bankReceiptDebit")
                                .value.replace(/,/g, "")
                            ) > invoiceamount
                          ) {
                            document.getElementById("bankReceiptDebit").value =
                              invoiceamount;
                          }
                        }
                        if (e.target.value === "") {
                          e.target.value = 0;
                        }
                        setconversionamount(
                          parseFloat(
                            document
                              .getElementById("bankReceiptDebit")
                              .value.replace(/,/g, "")
                          )
                        );
                      }}
                    ></input>
                  </span>
                </div>
                <div className="col-6 "></div>
              </>
            ) : (
              <></>
            )}

            <div className="col-2 mt-3 text-right">
              <span className="txt">Bank Charges</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="number"
                  id="bankcharges"
                  autoComplete="off"
                  className="input_border formInput "
                ></input>
              </span>
            </div>

            <div className="col-6 mt-3 ">
              <span className="mr-3">
                <b>Amount in words: </b>{" "}
                {conversionamount &&
                  converter.toWords(
                    conversionamount !== null ||
                      conversionamount !== undefined ||
                      conversionamount !== NaN ||
                      conversionamount !== ""
                      ? conversionamount
                      : 0
                  )}
              </span>
            </div>
            <div className="col-6 mt-3  text-right">
              <span>
                <button
                  id="crvSave"
                  onClick={addtolistbutton}
                  className="tabletopbutton ml-2 text-right greenbutton"
                >
                  {"Add to list"}
                </button>
              </span>
              <span>
                <button
                  id="crvSave"
                  onClick={saveandclose}
                  className="tabletopbutton ml-2 text-right redbutton"
                >
                  {"Save"}
                </button>
              </span>
              <span>
                <button
                  id="crvCancel"
                  className="tabletopbutton ml-2 text-right "
                  onClick={props.formCancel}
                >
                  Cancel
                </button>
              </span>
              <div className="loaderclass ml-1 float-right">
                {" "}
                {(loader1 || populateaccounts.loading) && (
                  <Loader
                    type="Oval"
                    color="black"
                    height={30}
                    width={30}
                    radius={12}
                  />
                )}
              </div>
            </div>
            <div className="col-12  mt-3 ">
              <table class="table tableCash table-hover">
                <thead class="">
                  <tr>
                    <th>Sr.</th>
                    <th>Account</th>
                    <th>Bank</th>
                    <th>Cheque</th>
                    <th>Payment Type</th>
                    <th>Amount</th>
                    <th>Sale Invoice</th>
                    <th>Narration</th>
                    <th>Bank Charges</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((x, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{x.AccountTitle}</td>
                      <td>{x.BankAccountTitle}</td>
                      <td>{x.ChequeNumber}</td>
                      <td>{x.ChequeType}</td>
                      <td>{x.Amount.toLocaleString()}</td>
                      <td>{x.InvoiceSerialNo}</td>
                      <td>{x.Narration}</td>
                      <td>{x.BankCharges}</td>
                      <td>
                        <span onClick={() => handledeleterow(idx)}>
                          <img
                            alt="AddRow"
                            style={{
                              cursor: "pointer",
                              width: "19px",
                            }}
                            src={Delete}
                          ></img>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
