import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import {
  CommissionAgentlist,
} from "../printpreviews";
import "../../Bootstrap/haroon.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";


export const CommissionAgentPage = (props) => {
  const [loader1, setloader1] = useState(false);
  const [authrepshow, setauthrepshow] = useState(false);
  const [customertype, setcustomertype] = useState(
    props.seller !== null ? props.seller.SellerType : "Agent Name"
  );
  const [editable, seteditable] = useState(
    props.seller !== null ? true : false
  );
  console.log(props.seller);
  const addcustomernew = () => {
    let data1, data2;

    if (props.seller !== null) {
      data1 = {
        CommisionAgentId: props.seller.CommisionAgentId,
        AgentCnic: document.getElementById("buyerCnic").value,
        CommisionAgentName: document.getElementById("buyerName").value,
        CellNumber: document.getElementById("buyerCell").value,
        Email: document.getElementById("buyerEmail").value,
        Address: document.getElementById("buyerAddress").value,
        City: document.getElementById("buyerCity").value,
        Country: document.getElementById("buyerCountry").value,
        Zip: document.getElementById("buyerZip").value,
      };
    } else {
      if (
        document.getElementById("buyerName").value === ""
      ) {
        toast.info("Please Add Name");
        return;
      }
      if (
        document.getElementById("buyerCnic").value === ""
      ) {
        toast.info("Please Add CNIC");
        return;
      }
      data2 = {
        AgentCnic: document.getElementById("buyerCnic").value,
        CommisionAgentName: document.getElementById("buyerName").value,
        CellNumber: document.getElementById("buyerCell").value,
        Email: document.getElementById("buyerEmail").value,
        Address: document.getElementById("buyerAddress").value,
        City: document.getElementById("buyerCity").value,
        Country: document.getElementById("buyerCountry").value,
        Zip: document.getElementById("buyerZip").value,
      };
    }
    setloader1(true);
    axios({
      url: window.$url + `api/Purchase/AddCommisionAgent`,
      data: JSON.stringify(props.seller !== null ? data1 : data2),
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          document.getElementById("buyerCnic").value = "";
          document.getElementById("buyerName").value = "";
          document.getElementById("buyerCell").value = "";
          document.getElementById("buyerEmail").value = "";
          document.getElementById("buyerAddress").value = "";
          document.getElementById("buyerCity").value = "";
          document.getElementById("buyerCountry").value = "";
          document.getElementById("buyerZip").value = "";
        }
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  const addcustomerclose = () => {
    let data1, data2;

    if (props.seller !== null) {
      data1 = {
        CommisionAgentId: props.seller.CommisionAgentId,
        AgentCnic: document.getElementById("buyerCnic").value,
        CommisionAgentName: document.getElementById("buyerName").value,
        CellNumber: document.getElementById("buyerCell").value,
        Email: document.getElementById("buyerEmail").value,
        Address: document.getElementById("buyerAddress").value,
        City: document.getElementById("buyerCity").value,
        Country: document.getElementById("buyerCountry").value,
        Zip: document.getElementById("buyerZip").value,
      };
    } else {
      if (
        document.getElementById("buyerName").value === ""
      ) {
        toast.info("Please Add Name");
        return;
      }
      if (
        document.getElementById("buyerCnic").value === ""
      ) {
        toast.info("Please Add CNIC");
        return;
      }
      data2 = {
        AgentCnic: document.getElementById("buyerCnic").value,
        CommisionAgentName: document.getElementById("buyerName").value,
        CellNumber: document.getElementById("buyerCell").value,
        Email: document.getElementById("buyerEmail").value,
        Address: document.getElementById("buyerAddress").value,
        City: document.getElementById("buyerCity").value,
        Country: document.getElementById("buyerCountry").value,
        Zip: document.getElementById("buyerZip").value,
      };
    }
    setloader1(true);
    axios({
      url: window.$url + `api/Purchase/AddCommisionAgent`,
      data: JSON.stringify(props.seller !== null ? data1 : data2),
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        console.log(result);
        toast.info(result.data.Message);
        if (result.data.IsSuccess) {
          document.getElementById("buyerCnic").value = "";
          document.getElementById("buyerName").value = "";
          document.getElementById("buyerCell").value = "";
          document.getElementById("buyerEmail").value = "";
          document.getElementById("buyerAddress").value = "";
          document.getElementById("buyerCity").value = "";
          document.getElementById("buyerCountry").value = "";
          document.getElementById("buyerZip").value = "";
          props.formCancel();
        }
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (customertype === "Company Name") {
      setauthrepshow(true);
    } else {
      setauthrepshow(false);
    }
  }, [customertype]);

  return (
    <>
      <div className="SettingContainer1 mt-3 Sitka" style={{ height: "90%" }}>
        <div className="col-md-12 col-lg-12 col-xl-12 ">
          <div className="row mt-3">
            {editable ? (
              <>
                <div className="col-2 text-right">
                  <span className="txt">Agent Name</span>
                </div>
                <div className="col-4  text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      disabled
                      defaultValue={
                        props.seller !== null
                          ? props.seller.CommisionAgentName
                          : null
                      }
                      id="buyerName"
                      autoComplete="off"
                      className="input_border formInput "
                    ></input>
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="col-2 text-right">
                  <span className="txt">Agent Name</span>
                </div>
                <div className="col-4  text-left">
                  <span>
                    {" "}
                    <input
                      type="text"
                      defaultValue={
                        props.seller !== null
                          ? props.seller.CommisionAgentName
                          : null
                      }
                      id="buyerName"
                      autoComplete="off"
                      className="input_border formInput "
                    ></input>
                  </span>
                </div>
              </>
            )}
            <div className="col-2  text-right">
              <span className="txt">Cell No.</span>
            </div>
            <div className="col-4 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  defaultValue={
                    props.seller !== null ? props.seller.CellNumber : null
                  }
                  id="buyerCell"
                  className="input_border formInput"
                ></input>
              </span>
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Email</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="email"
                  defaultValue={
                    props.seller !== null ? props.seller.Email : null
                  }
                  id="buyerEmail"
                  className="input_border formInput "
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt">CNIC</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="buyerCnic"
                  defaultValue={
                    props.seller !== null ? props.seller.AgentCnic : null
                  }
                  className="input_border formInput"
                  maxLength={15}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9\-]/g, "");

                    if (e.target.value.length === 5) {
                      e.target.value = `${e.target.value}-`;
                    }
                    if (e.target.value.length === 13) {
                      e.target.value = `${e.target.value}-`;
                    }
                    var input = document.getElementById("buyerCnic");
                    input.onkeydown = function (event) {
                      var key = event.keyCode || event.charCode;
                      if (key == 8) {
                        input.value = "";
                      }
                    };
                  }}
                  placeholder="xxxxx-xxxxxxx-x"
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt">City</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="buyerCity"
                  defaultValue={
                    props.seller !== null ? props.seller.City : null
                  }
                  className="input_border formInput "
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt">Country</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="text"
                  id="buyerCountry"
                  defaultValue={
                    props.seller !== null ? props.seller.Country : null
                  }
                  className="input_border formInput "
                ></input>
              </span>
            </div>
            <div className="col-2 mt-3 text-right">
              <span className="txt">Zip</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <input
                  type="number"
                  defaultValue={props.seller !== null ? props.seller.Zip : null}
                  id="buyerZip"
                  className="input_border formInput "
                ></input>
              </span>
            </div>

            <div className="col-2 mt-3 text-right">
              <span className="txt">Address</span>
            </div>
            <div className="col-4 mt-3 text-left">
              <span>
                {" "}
                <textarea
                  id="buyerAddress"
                  defaultValue={
                    props.seller !== null ? props.seller.Address : null
                  }
                  className="input_border text_area_input  "
                ></textarea>
              </span>
            </div>
            <div className="col-6 mt-3 ">{/* empty */}</div>
            <div className="col-12 mt-3  text-right">
              <span>
                <button
                  id="crvSave"
                  className="tabletopbutton  text-right greenbutton"
                  onClick={addcustomernew}
                >
                  {"Save & New"}
                </button>
              </span>

              <span>
                <button
                  id="crvSave"
                  className="tabletopbutton ml-2 text-right redbutton"
                  onClick={addcustomerclose}
                >
                  {"Save & Close"}
                </button>
              </span>
              <span>
                <button
                  id="cpvCancel"
                  className="tabletopbutton ml-2 text-right "
                  onClick={props.formCancel}
                >
                  Cancel
                </button>
              </span>
              <span className="ml-2 float-right">
                <div className="loaderclass">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export const CommissionAgentTable = () => {
  const changecomponent = () => {
    setComponentState("form");
  };

  const formCancel = () => {
    setComponentState("table");
  };

  const [seller, setseller] = useState(null);
  const [componentState, setComponentState] = useState("table");

  return (
    <>
      {componentState === "table" ? (
        <CommissionAgentTablecomponent
          changecomponent={changecomponent}
          setseller={setseller}
        />
      ) : (
        <CommissionAgentPage formCancel={formCancel} seller={seller} />
      )}
    </>
  );
};



export const CommissionAgentTablecomponent = (props) => {
  const [sellersdata, setsellersdata] = useState([]);
  const [newsellersdata, setnewsellersdata] = useState([]);
  const [suggestionsellersdata, setsuggestionsellersdata] = useState([]);
  const [loader1, setloader1] = useState(false);
  const [firstPage, setfirstPage] = useState();
  const [lastPage, setlastPage] = useState();
  const [nextPage, setnextPage] = useState();
  const [previousPage, setpreviousPage] = useState();
  const [paginationdata, setpaginationdata] = useState({
    pageNumber: 0,
    pageSize: 0,
  });

  //Table Data fiter
  var suggestionlist = [];
  var suggestionlist1 = [];

  const onTextChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setsellersdata(newsellersdata);
    } else {
      let suggestions = [];
      let suggestions1 = [];

      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = suggestionsellersdata
          .sort()
          .filter((v) => regex.test(v.CommisionAgentName));
        const regex1 = new RegExp(`${value}`, "i");
        suggestions1 = suggestionsellersdata
          .sort()
          .filter((v) => regex1.test(v.AgentCnic));
      }
      suggestionlist = suggestions;
      suggestionlist1 = suggestions1;

      console.log(suggestionlist);

      if (suggestionlist.length === 0 && suggestionlist1.length !== 0) {
        setsellersdata(suggestionlist1);
      } else {
        setsellersdata(suggestionlist);
      }
    }
  };

  const onTextChange2 = (e) => {
    const value = e.target.value;
    if (value === "") {
      setsellersdata(newsellersdata);
    } else {
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`${value}`, "i");
        suggestions = newsellersdata.sort().filter((v) => regex.test(v.City));
      }
      suggestionlist = suggestions;
      console.log(suggestionlist);

      if (suggestionlist.length === 0) {
        setsellersdata([]);
      } else {
        setsellersdata(suggestionlist);
      }
    }
  };

  console.log(sellersdata);
  const getNextdata = (page) => {
    if (page === null || page === undefined || page === "") {
      toast.info("There is no more page to Show");
    } else {
      setloader1(true);
      axios({
        method: "get",
        url: page,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          setsellersdata(result.data.data);
          setnewsellersdata(result.data.data);
          setsuggestionsellersdata(result.data.data);
          setpaginationdata({
            pageNumber: result.data.pageNumber,
            pageSize: result.data.pageSize,
          });

          setpreviousPage(
            result.data.previousPage +
              `&projectid=${localStorage.getItem("ProjectId")}`
          );
          setnextPage(
            result.data.nextPage +
              `&projectid=${localStorage.getItem("ProjectId")}`
          );
          setlastPage(
            result.data.lastPage +
              `&projectid=${localStorage.getItem("ProjectId")}`
          );
          setfirstPage(
            result.data.firstPage +
              `&projectid=${localStorage.getItem("ProjectId")}`
          );

          setloader1(false);
        },
        (error) => {
          toast.info(error);
          setloader1(false);
        }
      );
    }
  };

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  const getsellers = () => {
    setloader1(true);
    axios({
      url: window.$url + `api/Purchase/GetAllAgents`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        setloader1(false);
        setsellersdata(result.data.Agents);
        setnewsellersdata(result.data.Agents);
        setsuggestionsellersdata(result.data.Agents);
        console.log(result);
      },
      (error) => {
        setloader1(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getsellers();
  }, []);

  return (
    <>
      <div hidden>
        <CommissionAgentlist ref={componentRef1} sellersdata={sellersdata} />
      </div>
      <div className="SettingContainer1 mt-3 Sitka dataTablecsSales">
        <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
          <div className="row row1_style input_border ">
            <div className="col-12">
              <span>
                {" "}
                <input
                  type="text"
                  onChange={onTextChange}
                  autoComplete="off"
                  placeholder="Search By Name/CNIC"
                  className="input_border"
                ></input>
                <input
                  type="text"
                  onChange={onTextChange2}
                  autoComplete="off"
                  placeholder="Search By City"
                  className="input_border ml-2"
                ></input>
              </span>
              <span className="ml-2">
                <button
                  id="callapibutn"
                  onClick={handlePrint1}
                  className="tabletopbutton"
                >
                  Print
                </button>
              </span>
              <span className=" ml-1 float-right">
                <button
                  onClick={() => {
                    props.setseller(null);
                    props.changecomponent();
                  }}
                  className="tabletopbutton1 text-right"
                >
                  New
                </button>
              </span>
              <span className=" ml-1 float-right">
                <div className="loaderclass ml-1">
                  {" "}
                  {loader1 && (
                    <Loader
                      type="Oval"
                      color="black"
                      height={30}
                      width={30}
                      radius={12}
                    />
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className="row ">
            <table class="table tableCash table-hover">
              <thead class="">
                <tr>
                  <th className="th1" scope="col">
                    Sr.
                  </th>
                  <th className="th1">Name</th>
                  <th className="th1">Cell No</th>
                  <th className="th1">Email</th>
                  <th className="th1">CNIC</th>
                  <th className="th1">City</th>
                  <th className="th1">Address</th>
                </tr>
              </thead>
              <tbody>
                {sellersdata !== undefined
                  ? sellersdata.map((x, idx) => (
                      <tr
                        onClick={() => {
                          props.setseller(x);
                          props.changecomponent();
                        }}
                      >
                        <td>{idx + 1}</td>
                        <td>{x.CommisionAgentName}</td>

                        <td>{x.CellNumber}</td>
                        <td>{x.Email}</td>
                        <td>{x.AgentCnic}</td>
                        <td>{x.City}</td>
                        <td>{x.Address}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};


