import React, { Component } from "react";
import logo from "../../Images/seasonFour.jpg";
class CashReceiptAllPrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h3>
              <b>
                <u>Cash Receipts</u>
              </b>
            </h3>
            <br />
            <h5>{this.props.date !== undefined ? this.props.date : null}</h5>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-4">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="th1">
                        Sr.No
                      </th>
                      <th scope="col" className="th1">
                        Date
                      </th>
                      <th scope="col" className="th1">
                        Voucher Number
                      </th>
                      <th scope="col" className="th1">
                        Invoice Number
                      </th>
                      <th scope="col" className="th1">
                        Account
                      </th>
                      <th scope="col" className="th1">
                        Narration
                      </th>
                      <th scope="col" className="th1">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.Voucherlist.map((x, idx) => (
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td class="trpointer">
                          {x.Voucherdate.substring(0, 10)}
                        </td>
                        <td class="trpointer">{x.SecondaryKey}</td>
                        <td class="trpointer">
                          {x.SaleInvoice.map((inv) => {
                            return `SInv-${inv?.SaleInvSerialNo}`;
                          }).toString()}
                        </td>
                        <td class="trpointer">{x.Account.Accounttitle}</td>
                        <td class="trpointer">{x.Narration}</td>
                        <td class="trpointer">
                          {x.Debit !== null ? x.Debit.toLocaleString() : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>
            <div className="col-4 text-center">________________</div>

            <div className="col-4 text-center">Prepared By</div>
            <div className="col-4 text-center">Checked By</div>
            <div className="col-4 text-center">Approved By</div>
          </div>
        </div>
      </div>
    );
  }
}
export default CashReceiptAllPrint;
