import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../../Bootstrap/haroon.css";
import { useReactToPrint } from "react-to-print";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import AccountReceivablePrint from "./AccountReceivablePrint";

 const AccountReceivableMain = () => {
    const [buyersdata, setbuyersdata] = useState([]);
    const [newbuyersdata, setnewbuyersdata] = useState([]);
    const [suggestionbuyersdata, setsuggestionbuyersdata] = useState([]);
    const [loader1, setloader1] = useState(false);
    const [multiple, setmultiple] = useState([]);
    const [clientName, setclientName] = useState("");
  
    //Table Data fiter
    var suggestionlist = [];
    var suggestionlist1 = [];
    const onTextChange = (e) => {
      const value = e.target.value;
      if (value === "") {
        setbuyersdata(newbuyersdata);
      } else {
        let suggestions = [];
        let suggestions1 = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = suggestionbuyersdata
            .sort()
            .filter((v) => regex.test(v.clientName));
          const regex1 = new RegExp(`${value}`, "i");
          suggestions1 = suggestionbuyersdata
            .sort()
            .filter((v) => regex1.test(v.clientCnic));
        }
        suggestionlist = suggestions;
        suggestionlist1 = suggestions1;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0 && suggestionlist1.length !== 0) {
          setbuyersdata(suggestionlist1);
        } else {
          setbuyersdata(suggestionlist);
        }
      }
    };
  
    const onTextChange2 = (e) => {
      const value = e.target.value;
      if (value === "") {
        setbuyersdata(newbuyersdata);
      } else {
        let suggestions = [];
        if (value.length > 0) {
          const regex = new RegExp(`${value}`, "i");
          suggestions = newbuyersdata
            .sort()
            .filter((v) => regex.test(v.accounttitle));
        }
        suggestionlist = suggestions;
        console.log(suggestionlist);
  
        if (suggestionlist.length === 0) {
          setbuyersdata([]);
        } else {
          setbuyersdata(suggestionlist);
        }
      }
    };
  
    const [sumstate, setsumstate] = useState(0);
    const openmodaljournaltable = (x) => {
      let sum = 0;
      x.contract[0].forEach((y) => {
        sum = sum + y.totalPrice;
      });
  
      x.jointContracts.forEach((z) => {
        sum = sum + z.totalPrice;
      });
      setsumstate(sum);
      console.log("xvalue", x);
      setmultiple(x);
      setclientName(x.clientName);
      document.getElementById("modalAddnewJournal").style.display = "block";
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const getPayables = () => {
      setloader1(true);
      axios({
        method: "get",
        url: window.$url + "api/Reports/AccountReceivables",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          console.log(result);
          setbuyersdata(result.data.accounts);
          setnewbuyersdata(result.data.accounts);
          setloader1(false);
        },
        (error) => {
          toast.info(error);
          setloader1(false);
        }
      );
    };
  
    useEffect(() => {
      getPayables();
    }, []);
  
    return (
      <>
        <div hidden>
          <AccountReceivablePrint ref={componentRef} buyersdata={buyersdata} />
        </div>
        <div className="tableContainer2 pageheading Sitka mt-2">
          <div className="col-md-12 col-lg-12 col-xl-12 tableHeight">
            <div className="row row1_style input_border ">
              <div className="col-12">
                <span>
                  {" "}
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search By Account"
                    onChange={onTextChange2}
                    className="input_border"
                  ></input>
                </span>
  
                <span className="ml-2">
                  <button
                    id="callapibutn"
                    className="tabletopbutton"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </span>
  
                <span className="ml-2 float-right">
                  <div className="loaderclass">
                    {" "}
                    {loader1 && (
                      <Loader
                        type="Oval"
                        color="black"
                        height={30}
                        width={30}
                        radius={12}
                      />
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="row">
              <table class="table table-hover tableCash ">
                <thead class="">
                  <tr>
                    <th scope="col" className="th1">
                      Sr.
                    </th>
                    <th scope="col" className="th1">
                      Account Name
                    </th>
                    <th scope="col" className="th1">
                      Particular Description
                    </th>
                    <th scope="col" className="th1">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buyersdata?.map((x, idx) => (
                    <tr>
                      <td scope="col" className="th1">
                        {idx + 1}
                      </td>
                      <td scope="col" className="th1">
                        {x.accounttitle}
                      </td>
                      <td scope="col" className="th1">
                        {x.accountdes}
                      </td>
                      <td scope="col" className="th1">
                        {x.currentBalance}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default AccountReceivableMain