import React, { useState, useEffect } from "react";
import "../Bootstrap/haroon.css";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
//import { Chart } from 'react-charts'

const chartColors = [
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775",
];

const Dashboard = () => {
  const [statecashreceived, setcashreceived] = useState([]);
  const [statecashpaid, setcashpaid] = useState([]);
  const [stateFromTo, setFromTo] = useState([]);
  const [dataval, setdataval] = useState([]);
  const [newplotcount, setnewplotcount] = useState([]);
  const [paid, setpaid] = useState([]);
  const [received, setreceived] = useState([]);
  const [finallist, setfinallist] = useState([]);

  let history = useHistory();
  const testdata = [
    {
      label: "Paid",
      data: paid,
    },
    {
      label: "Received",
      data: received,
    },
    // {
    //   label: 'Paid',
    //   //data: [[26,5188.8],[29,4593],[25,2725],[27,8000],[19,220]]
    //   data:paid
    // },
    // {
    //   label: 'Received',
    //   //data: [[25,14],[27,2000],[28,100571],[29, 15155],[19, 250],[26, 203300],[30, 3000]]
    //   data:received
    // }
  ];
  const testaxes = React.useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );
  const getunitscount = () => {
    axios({
      method: "get",
      url:
        window.$url +
        `/Units/Count?ProjectId=${localStorage.getItem("ProjectId")}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    }).then(
      (result) => {
        console.log("count", result.data);

        setnewplotcount(result.data);
      },
      (error) => {
        alert(error);
      }
    );
  };

  const getCashSummary = () => {
    axios({
      url: `${window.$url}api/Reports/CashSummary`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("CashSummary", result);
        setcashreceived(result.data.CashTransactionsReceived);
        setcashpaid(result.data.CashTransactionsPaid);
        setFromTo(result.data.Dates);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const linedata = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
    labels: stateFromTo.map(
      (val) =>
        val.split("T")[0].split("-")[1] + "-" + val.split("T")[0].split("-")[2]
    ),

    datasets: [
      {
        label: "Cash Paid",
        data: statecashpaid.map((val) => val.Amount),
        fill: true,
        backgroundColor: "RGBA(68,64,142,0.2)",
        borderColor: "RGBA(68,64,142,1)",
      },
      {
        label: "Cash Received",
        data: statecashreceived.map((val) => val.Amount),

        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor : "rgba(75,192,192,1)",
      },
    ],
  };

  const [recoverydataval, setrecoverydataval] = useState([]);
  useEffect(() => {
    getAccountsChecklist();
    //  DashBoardRecoverySheet();
    getCashSummary();
  }, []);

  const getAccountsChecklist = () => {
    axios({
      url: `${window.$url}api/Reports/AccountsChecklist`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("Checklist", result);
        setdataval(result.data.Data);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  // const DashBoardRecoverySheet = () => {
  //   axios({
  //     url: `${window.$url}api/Reports/DashBoardRecoverySheet?Projectid=${localStorage.getItem("ProjectId")}`,
  //     method: "get",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("Token")}`,
  //       "content-type": "application/json",
  //     },
  //   }).then(
  //     (result) => {
  //       console.log("DashBoardRecoverySheet", result);
  //       setrecoverydataval(result.data.Data);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  const data = {
    labels: ["CashInHand", "Banks", "Receivable", "Payable"],
    datasets: [
      {
        label: "Account Check List",
        data: [
          Math.round(dataval !== undefined ? dataval.CashInHand : null),
          Math.round(dataval !== undefined ? dataval.Banks : null),
          Math.round(dataval !== undefined ? dataval.Receivable : null),
          Math.round(dataval !== undefined ? dataval.Payables : null),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: [
      "January",
      "Feburary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Recovery Check List",
        data: [
          Math.round(recoverydataval !== undefined ? recoverydataval[0] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[1] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[2] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[3] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[4] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[5] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[6] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[7] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[8] : null),
          Math.round(recoverydataval !== undefined ? recoverydataval[9] : null),
          Math.round(
            recoverydataval !== undefined ? recoverydataval[10] : null
          ),
          Math.round(
            recoverydataval !== undefined ? recoverydataval[11] : null
          ),
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  const datapie = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Sold", "Available", "Reserved"],
    datasets: [
      {
        data: [
          newplotcount !== undefined ? newplotcount.Sold : 0,
          newplotcount !== undefined ? newplotcount.Available : 0,
          newplotcount !== undefined ? newplotcount.Reserved : 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const getReceivedPaid = () => {
    axios({
      url: `${
        window.$url
      }/Reports/ReceivedPaidChecklist?ProjectId=${localStorage.getItem(
        "ProjectId"
      )}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        "content-type": "application/json",
      },
    }).then(
      (result) => {
        console.log("PaidReceived", result);

        // let paidarray = []
        // let receivedarray = []

        // result.data.Paid.forEach((x)=>{
        //   paidarray.push([
        //     parseInt(x.Date.split("T")[0].split("-")[2]),x.Amount
        //   ])
        // })

        // result.data.Received.forEach((x)=>{
        //   receivedarray.push([
        //     parseInt(x.Date.split("T")[0].split("-")[2]),x.Amount
        //   ])
        // })

        // setpaid(paidarray)
        // setreceived(receivedarray)
        let arr3 = [["x", "Paid", "Received"]];
        let j = 0,
          i = 0;

        while (j < result.data.Received.length && i < result.data.Paid.length) {
          if (
            parseInt(result.data.Paid[i].Date.split("T")[0].split("-")[2]) <
            parseInt(result.data.Received[j].Date.split("T")[0].split("-")[2])
          ) {
            arr3.push([
              result.data.Paid[i].Date.split("T")[0],
              result.data.Paid[i].Amount,
              0,
            ]);
            i++;
          } else if (
            parseInt(result.data.Received[j].Date.split("T")[0].split("-")[2]) <
            parseInt(result.data.Paid[i].Date.split("T")[0].split("-")[2])
          ) {
            arr3.push([
              result.data.Received[j].Date.split("T")[0],
              0,
              result.data.Received[j].Amount,
            ]);
            j++;
          } else {
            arr3.push([
              result.data.Paid[i].Date.split("T")[0],
              result.data.Paid[i].Amount,
              result.data.Received[j].Amount,
            ]);
            i++;
            j++;
          }
        }
        while (j < result.data.Received.length) {
          arr3.push([
            result.data.Received[j].Date.split("T")[0],
            0,
            result.data.Received[j].Amount,
          ]);
          j++;
        }
        while (i < result.data.Paid.length) {
          arr3.push([
            result.data.Paid[i].Date.split("T")[0],
            result.data.Paid[i].Amount,
            0,
          ]);
          i++;
        }

        setfinallist(arr3);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div
      className="container-fluid"
      style={{
        overflowY: "auto",
        height: "90vh",
      }}
    >
      <div className="row ">
        <div className="dashboardtoprow mt-2 pr-2  col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <Bar data={data2} options={options} />
          {/* <CanvasJSChart options = {optionstest}/> */}
        </div>
      </div>
      <div className="row  w-105">
        <div className="dashboardbttomrow mt-2  w-50 ">
          <Bar data={data} options={options} />
        </div>
        <div className="dashboardbttomrow mt-2 ml-2  w-40">
          {/* <CanvasJSChart options={optionspie} /> */}
          {/* <div className="col-12 text-center ">Cash History</div> */}

          {/* <Chart data={testdata} axes={testaxes} /> */}
          {/* <Chart
            // width={'600px'}
            height={"300px"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={finallist}
            options={{
              hAxis: {
                title: "Day",
              },
              vAxis: {
                title: "Amount",
              },
              pointSize: 3,
              chartArea: {
                bottom: 30,
                top: 10,
              },
            }}
            rootProps={{ "data-testid": "2" }}
          /> */}
          {/* <h5 className="text-center">Cash History(last 30 days)</h5> */}
          <Line height={150} data={linedata} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
