import React, { Component } from "react";
import logo from "../../Images/seasonFour.jpg";

class BankReceiptPrint extends Component {
  render() {
    return (
      <div
        className="Sitka"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div className="row m-0 p-0">
          <div className="col-8 text-left mt-4">
            <h2 style={{ color: "black" }}>Season Four</h2>
            <hr />

            <h6 style={{ color: "grey", marginTop: "5px" }}>
              <i>
                 Contact: 99408640 || Email: Season4-kw@hotmail.com ||
                Address: Shuwaikh Industrial Area - Block 3 - Streat -53 - Shop No 1 - Near Souk Mera.
              </i>
            </h6>
            <div
              style={{
                borderTop: "2px dotted black",
                borderBottom: "2px dotted black",
                height: "7px",
              }}
            ></div>
          </div>
          <div className="col-4 text-center">
            {/* IMAGE COMES HERE */}
            <img
              src={logo}
              style={{ height: "90px", width: "auto", marginTop: "30px" }}
              alt="Company logo"
            />
          </div>
        </div>
        <div
          className="row m-3 p-0"
          // style={{ border: "5px solid darkgrey", height: "80%" }}
        >
          <div className="col-12 text-center mt-2">
            <h4>
              <b>
                <u>Bank Receipt</u>
              </b>
            </h4>
            <h5>
              <b>Voucher No.: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.SecondaryKey
                : null}
            </h5>
            <h5>
              {this.props.printdata !== undefined &&
              this.props.printdata.ChequeNumber !== null ? (
                <b>Cheque No.: </b>
              ) : null}
              {this.props.printdata !== undefined
                ? this.props.printdata.ChequeNumber
                : null}
            </h5>

            <h5 className="text-left">
              {this.props.printdata !== undefined &&
              this.props.printdata?.SaleInvoice.length > 0 ? (
                <b>Invoice No.: </b>
              ) : (
                ""
              )}
              {this.props.printdata !== undefined
                ? this.props.printdata?.SaleInvoice.map((inv) => {
                    return `SInv-${inv?.SaleInvSerialNo}`;
                  }).toString()
                : null}
            </h5>
            <h5 className="text-left">
              <b>Entry Date: </b>
              {this.props.printdata !== undefined
                ? this.props.printdata.Voucherdate.split("T")[0]
                : null}
            </h5>

            <hr style={{ backgroundColor: "black" }} />
            <div className="mt-3">
              <div class="table-responsive col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>SR.</th>
                      <th>PARTICULARS</th>
                      <th>NARRATION</th>
                      <th>DEBIT</th>
                      <th>CREDIT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.printdata !== undefined
                      ? this.props.printdata.Transaction.map((x, idx) => (
                          <tr>
                            <td>{idx + 1}</td>
                            <td>{x.Acc.Accounttitle}</td>
                            <td>{x.Narration}</td>
                            <td>
                              {x.Transtype === "Debit"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                            <td>
                              {x.Transtype === "Credit"
                                ? x.Amount.toLocaleString()
                                : null}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="row mt-4">
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>
                <div className="col-4 text-center">________________</div>

                <div className="col-4 text-center">Prepared By</div>
                <div className="col-4 text-center">Checked By</div>
                <div className="col-4 text-center">Approved By</div>
              </div>
              <div className="row mt-5">
                <div className="col-8 text-right">
                  <b>Print Date:</b>
                </div>
                <div className="col-4 ">{this.props.date}</div>
              </div>
              <div className="row">
                <div className="col-8 text-right">
                  <b>Receiver:</b>
                </div>
                <div className="col-4 text-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankReceiptPrint;
