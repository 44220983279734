import React, { useState} from "react";
import { BankPaymentForm } from "./BankPaymentForm";
import { BankPaymentTable } from "./BankPaymentTable";
 const BankPaymentMain = () => {
    const changecomponent = () => {
      setComponentState(<BankPaymentForm formCancel={formCancel} />);
    };
  
    const formCancel = () => {
      setComponentState(
        <BankPaymentTable changecomponent={changecomponent} />
      );
    };
  
    const [componentState, setComponentState] = useState(
      <BankPaymentTable changecomponent={changecomponent} />
    );
  
    return <>{componentState}</>;
  };
  export default BankPaymentMain;
