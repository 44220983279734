import React, { useState } from "react";
import "../../Bootstrap/haroon.css";
import "../../Bootstrap/App.css";

  import {
    CommissionAgentTable,
  } from "../Purchase/PurchaseTable";

// PRIMARY PRODUCTION BUTTONS COMPONENTS
const Btngroup = ({ active, count, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`button1 ${active ? "button1Clicked" : ""} ${count === "Commission Agent"
        ? "blue"
        : count === "General Journal"
          ? "red"
          
                  : count
        }`}
    >
      {count}
    </button>
  );
};

// PRODUCTION PAGE COMPONENT
const Sale = () => {
  const button = [
    { button1: "Commission Agent", component: <CommissionAgentTable></CommissionAgentTable> },
    
  ];
  const [chosen2, setChosen2] = useState({
    button1: "Commission Agent",
    component: <CommissionAgentTable></CommissionAgentTable>,
  });


  return (
    <>
      <div style={{ height: "10%" }} >
        {/* MAPPING PRIMARY BUTTONS ON THE PRODUCTION PAGE */}
        {button.map((b) => (
          <Btngroup
            key={b.button1}
            count={b.button1}
            active={b.button1 === chosen2.button1}
            onClick={() => {
              setChosen2(b);
            }}
          />
        ))}
      </div>
      <div style={{ height: "87%" }} >
        {button.map((b) =>
          b.button1 === chosen2.button1 ? b.component : null
        )}
      </div>
    </>
  );
};

export default Sale;
