import React, { Component } from "react";
import logo from "../../../Images/seasonFour.jpg";


class Balancesheet extends Component {
    render() {
      return (
        <div
          className="Sitka"
          style={{ backgroundColor: "white", height: "100%" }}
        >
          <div className="row m-0 p-0">
            <div className="col-8 text-left mt-4">
              <h2 style={{ color: "black" }}>Season Four</h2>
              <hr />
  
              <h6 style={{ color: "grey", marginTop: "5px" }}>
                <i>
                  Contact: +92-41-2660788 || Email: haroon@harooncorporation.com
                  || Address: P-417 Nisar Colony, Samundri Road, Faisalabad -
                  Pakistan.
                </i>
              </h6>
              <div
                style={{
                  borderTop: "2px dotted black",
                  borderBottom: "2px dotted black",
                  height: "7px",
                }}
              ></div>
            </div>
            <div className="col-4 text-center">
              {/* IMAGE COMES HERE */}
              <img
                src={logo}
                style={{ height: "90px", width: "auto", marginTop: "30px" }}
                alt="Company logo"
              />
            </div>
          </div>
          <div
            className="row m-3 p-0"
            // style={{ border: "5px solid darkgrey", height: "100%" }}
          >
            <div className="col-12 text-center mt-2">
              <h4>
                <b>
                  <u>Balance Sheet</u>
                </b>
              </h4>
  
              <hr style={{ backgroundColor: "black" }} />
              <div className="col-12">
                <div className="row mt-5">
                  <div className="col-6 green_txt">
                    <h5>
                      <b>ASSETS</b>
                    </h5>
                  </div>
                  <div className="col-6 red_txt">
                    <h5>
                      <b>EQUITY AND LIABILITIES</b>
                    </h5>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 green_txt">
                    <h5>
                      <b>
                        <u> </u>
                      </b>
                    </h5>
                  </div>
                  <div className="col-6 red_txt">
                    <h5>
                      <b>
                        <u>EQUITY</u>
                      </b>
                    </h5>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6">
                    <div class="table-responsive">
                      <table class="table">
                        <thead></thead>
                        {/* FIXED ASSETS */}
                        <tbody>
                          {this.props.assets !== undefined
                            ? this.props.assets.map((x, idx) => (
                                <tr>
                                  <td>{idx + 1}</td>
                                  <td>{x.accounttitle}</td>
                                  <td>{x.currentBalance.toLocaleString()}</td>
                                </tr>
                              ))
                            : null}
  
                          <tr>
                            <td></td>
                            <td className="nobordertop">
                              <b>TOTAL</b>
                            </td>
  
                            <td className="bordertop text-right">
                              {this.props.assetsbalance.toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="table-responsive">
                      <table class="table">
                        <thead></thead>
                        {/* CAPITAL TABLE */}
                        <tbody>
                          {this.props.equity !== undefined
                            ? this.props.equity.map((x, idx) => (
                                <tr>
                                  <td>{idx + 1}</td>
                                  <td>{x.accounttitle}</td>
                                  <td>{x.currentBalance.toLocaleString()}</td>
                                </tr>
                              ))
                            : null}
  
                          <tr>
                            <td></td>
                            <td className="nobordertop">
                              <b>TOTAL</b>
                            </td>
  
                            <td className="bordertop text-right">
                              {this.props.equitybalance.toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 green_txt">
                    <h5>
                      <b>
                        <u> </u>
                      </b>
                    </h5>
                  </div>
                  <div className="col-6 red_txt">
                    <h5>
                      <b>
                        <u>LIABILITIES</u>
                      </b>
                    </h5>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div class=" col-6"></div>
                  <div class=" col-6">
                    <div className="row">
                      <div class="table-responsive">
                        <table class="table">
                          <thead></thead>
                          <tbody>
                            {/* CURRENT LIABILITIES */}
                            {this.props.liabilities !== undefined
                              ? this.props.liabilities.map((x, idx) => (
                                  <tr>
                                    <td>{idx + 1}</td>
                                    <td>{x.accounttitle}</td>
                                    <td>{x.currentBalance.toLocaleString()}</td>
                                  </tr>
                                ))
                              : null}
                            <tr>
                              <td></td>
                              <td className="nobordertop">
                                <b>TOTAL</b>
                              </td>
  
                              <td className="bordertop text-right">
                                {this.props.liabilitiesbalance.toLocaleString()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 red_txt">
                        <h5>
                          <b> </b>
                        </h5>
                      </div>
                      <div className="col-6  text-right">
                        <h5>
                          <b>
                            <u></u>
                          </b>
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div class="table-responsive col-6">
                        <table class="table">
                          <thead></thead>
  
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-6  text-right">
                        <h5>
                          <b>
                            <u></u>
                          </b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="row mt-2">
                  <div className="col-6 ">
                    <div className="row">
                      <div className="col-6 green_txt">
                        <h5>
                          <b>TOTAL ASSETS</b>
                        </h5>
                      </div>
                      <div className="col-6  text-right">
                        <h5>
                          <b>
                            <u>{this.props.assetsbalance.toLocaleString()}</u>
                          </b>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <div className="row">
                      <div className="col-6 red_txt">
                        <h5>
                          <b>LIABILITIES + EQUITY</b>
                        </h5>
                      </div>
                      <div className="col-6  text-right">
                        <h5>
                          <b>
                            <u>
                              {(
                                this.props.liabilitiesbalance +
                                this.props.equitybalance
                              ).toLocaleString()}
                            </u>
                          </b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  export default Balancesheet